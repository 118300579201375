import axios, {AxiosResponse} from 'axios'
import {TransfersReportsQueryResponse} from './_models'
import { Option } from 'react-multi-select-component'
import { Response } from '../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL + '/api'
const REPORTS_URL = `${API_URL}/reports`
const TRANSFER_URL = `${API_URL}/transfers`


const getTransferReports = (query: string): Promise<TransfersReportsQueryResponse> => {
    return axios
        .get(`${REPORTS_URL}/transfers?${query}`)
        .then((d: AxiosResponse<TransfersReportsQueryResponse>) => d.data)
}

const getTransferStatus = (): Promise<Option[] | undefined> => {
    return axios.get(`${TRANSFER_URL}/statuses?all=1`)
        .then((response: AxiosResponse<Response<Option[]>>) => response.data)
        .then((response: Response<Option[]>) => response.data)
}

const downloadServerFile = (filter: string | undefined) => {
    var filterAdjust =  filter !== undefined ? `?${filter}`: ''
    return axios({
      url: `${REPORTS_URL}/transfers/export${filterAdjust}`, //your url
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);
  
      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'Relatorio_transferência.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
  
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  }

export {getTransferReports, getTransferStatus, downloadServerFile};