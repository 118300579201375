import { useState } from 'react'
import { formatMask } from '../../../../utils/formatMonetaryToBr'
import { Billing, TBillingInstallment } from '../../core/_models'
import { formatDate } from '../../../../utils/dayJs/format'
import { handlePayment, sendLinkChangeCartReq } from './core/_request'
import Swal from 'sweetalert2'
import { cpfMask } from '../../../chooseFranchise/utils/mask'
import { PageOverlayer } from '../../../../components/PageOverlayer'

interface IPaymentRecurrentModal {
  billingInstallment: TBillingInstallment | undefined
  billing: Billing | null
  closeModal: () => void
}

export const PaymentRecurrentModal = ({
  billingInstallment,
  billing,
  closeModal,
}: IPaymentRecurrentModal) => {
  const [loading, setLoading] = useState(false)

  const hadlePaymentCart = async () => {
    Swal.fire({
      title: 'Deseja tentar realizar a cobrança ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-danger', // Classe personalizada para o botão de confirmação
        cancelButton: 'btn btn-secondary', // Classe personalizada para o botão de cancelamento (opcional)
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true)
          await handlePayment(Number(billing?.recurrencePayment.id), Number(billingInstallment?.id))
          setLoading(false)
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!!!',
            text: 'Cobrança realizada com sucesso!',
            timer: 3000,
          })

          setTimeout(function () {
            window.location.reload()
          }, 2000)
        } catch (error: any) {
          setLoading(false)
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: error.response.data.message,
            timer: 3000,
          })
        }
      }
    })
  }
  return (
    <>
      {loading && <PageOverlayer />}
      <div className='modal-content'>
        <div className='modal-body mx-5 mx-xl-7 my-7'>
          <div className='row mb-6 mt-4'>
            <div className='table-responsive'>
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-120px'>Preço</th>
                    <th className='min-w-120px'>Forma de pagamento</th>
                    <th className='min-w-120px'>Dt de vencimento</th>
                    <th className='min-w-120px'>Parcelas</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>{formatMask(billingInstallment?.amount)}</b>
                    </td>

                    <td>
                      <b>{billing?.paymentOption?.name}</b>
                    </td>

                    <td>
                      <b>{formatDate(String(billing?.createdAt))}</b>
                    </td>

                    <td>
                      <b>
                        {billingInstallment?.installmentsNumber ?? billingInstallment?.installment} /{' '}
                        {billing?.installments.length}
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="border border-gray-300 border-dashed rounded" style={{ backgroundColor: 'rgb(249, 234, 233)' }}>
              <div className='container m-5'>
                <div className="d-flex flex-wrap py-5">
                  <div className="flex-equal me-5">
                    <table className="table table-flush fw-semibold gy-1">
                      <tbody>
                        <tr>
                          <td className="text-black fw-bold min-w-125px w-125px">Nome</td>
                          <td className="text-gray-800">{billing?.recurrencePayment?.creditCard?.holderName}</td>
                        </tr>
                        <tr>
                          <td className="text-black fw-bold min-w-125px w-125px">Número</td>
                          <td className="text-gray-800">**** {billing?.recurrencePayment?.creditCard?.lastNumbers}</td>
                        </tr>
                        <tr>
                          <td className="text-black fw-bold min-w-125px w-125px">Validade</td>
                          <td className="text-gray-800">{billing?.recurrencePayment?.creditCard?.expirationMonth}/{billing?.recurrencePayment?.creditCard?.expirationYear}</td>
                        </tr>
                        <tr>
                          <td className="text-black fw-bold min-w-125px w-125px">Tipo</td>
                          <td className="text-gray-800">{billing?.paymentOption.category.name}</td>
                        </tr>
                        <tr>
                          <td className="text-black fw-bold min-w-125px w-125px">CPF</td>
                          <td className="text-gray-800">{cpfMask(billing?.recurrencePayment?.creditCard?.holderDocument)}{' '}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="flex-equal">
                    <table className="table table-flush fw-semibold gy-1">
                      <tbody>
                        <tr>
                          <td>
                            <button className='btn btn-danger col-11 p-11' onClick={() => hadlePaymentCart()}>
                              Pagar
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='modal-footer d-flex align-items-center justify-content-center'>
          <button onClick={() => closeModal()} className='btn btn-secondary'>
            Voltar
          </button>
        </div>
      </div>
    </>
  )
}
