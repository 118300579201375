import { FC, useEffect, useState } from 'react'
import Select from 'react-select'
import { usePeople } from '../../coreGlobal/providers/PersonProvider'
import { cpfMask } from '../../utils/cpfMask'
import { cnpjMask } from '../../utils/cnpjMask'
import { ISelect } from '../../coreGlobal/models/default'
import { validatePerson } from '../../coreGlobal/_resquest'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../app/modules/auth'
import { linkClient } from '../../pages/clients/list/core/_requests'

type Props = {
  formik: any
  type: number
  complete: string
  mode?: 'UPDATE' | 'CREATE'
}

const ProfileForm: FC<Props> = ({ formik, type, complete, mode = 'CREATE' }) => {
  const isCreate = mode == 'CREATE'
  const isBrazilian = formik.values?.nationality == 22
  const {
    countries,
    getDataCountries,
    country,
    setCountry,
    hideAvatarRadio,
    setHideAvatarRadio,
    reduceTimeRadio,
    setReduceTimeRadio,
    getDatahowMets,
    minor,
    setMinor,
    howMetOptions,
  } = usePeople()

  const [storageDocument] = useState(formik.values.document)
  const navigate = useNavigate()
  const { currentFranchise } = useAuth()
  const [dataPerson, setDataPerson] = useState<any>()
  const [howMetValue, setHowMetValue] = useState<ISelect | undefined>()

  const handleCheckboxChange = () => {
    setReduceTimeRadio(!reduceTimeRadio)
    formik.setFieldValue('reduceTime', !reduceTimeRadio)
    if (reduceTimeRadio) {
      Swal.fire({
        title: 'Deseja aumentar o tempo de agendamento?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
      }).then(async (result) => {
        if (!result.isConfirmed) {
          setReduceTimeRadio(true)
          formik.setFieldValue('reduceTime', reduceTimeRadio)
        }
      })
    }
  }
  
  const handleCheckboxChangeHideAvatar = () => {
    setHideAvatarRadio(!hideAvatarRadio)
    formik.setFieldValue('hideAvatar', !hideAvatarRadio)
    if (!hideAvatarRadio) {
      Swal.fire({
        title: 'Deseja utilizar o avatar neutro?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
      }).then(async (result) => {
        if (!result.isConfirmed) {
          setHideAvatarRadio(false)
          formik.setFieldValue('hideAvatar', hideAvatarRadio)
        }
      })
    }
  }

  const handleCheckboxChangeHidMinor = () => {
    setMinor(!hideAvatarRadio)
    formik.setFieldValue('minor', !hideAvatarRadio)
    if (!hideAvatarRadio) {
      Swal.fire({
        title: 'É menor de 18 anos? ',
        text: "Para validação do CPF será necessário digitar a data de nascimento.",
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
      }).then(async (result) => {
        if (!result.isConfirmed) {
          setMinor(false)
          formik.setFieldValue('minor', hideAvatarRadio)
        }
      })
    }
  }

  useEffect(() => {
    async function initialize() {
      if (shouldFetchData()) {
        await fetchData()
      }
      updateRadioValues()
      function shouldFetchData() {
        return type === 1 && countries.length === 1
      }

      async function fetchData() {
        await Promise.all([getDataCountries(), getDatahowMets()])
      }
      function updateRadioValues() {
        setReduceTimeRadio(formik.values.reduceTime)
        setHideAvatarRadio(formik.values.hideAvatar)
      }
    }
    initialize()
  }, [])

  const fillProfile = ({ birth_date, name, gender }) => {
    formik.setFieldValue('birthDate', birth_date)
    formik.setFieldValue('name', name)
    formik.setFieldValue('gender', gender)
  }

  useEffect(() => {
    if (formik.values.nationality && countries.length > 1) {
      const nationality = countries.find((country: ISelect) => {
        return country.value === String(formik.values.nationality)
      })
      setCountry(nationality)
    }
  }, [formik.values.nationality, countries])

  useEffect(() => {
    if (formik.values.howMet && howMetOptions && howMetOptions.length > 1) {
      setHowMetValue(
        howMetOptions.find((value: ISelect) => {
          return value.value == formik.values.howMet
        })
      )
    }
  }, [formik.values.howMet, howMetOptions])

  const checkFranchiseStatus = (franchises, currentFranchise) => {
    const entries = Object.entries(franchises)

    const matchedFranchise = entries.find(([key]) => key === String(currentFranchise?.id))

    return matchedFranchise
      ? { status: true, name: matchedFranchise[1] }
      : { status: false, name: entries.length > 0 ? entries[0][1] : null }
  }
  const validateDocument = async (value: string, birthDate?: string) => {

    try {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: `Buscando dados`,
        html: `
        <div style="text-align: center;">
          <p margin-top: 1rem;"></p>
          <div class="spinner-border" role="status" style="width: 3rem; height: 3rem; color: #f1416c; border-width: 0.25em;"></div>
          <p margin-top: 1rem;"></p>
        </div>
      `,
        showConfirmButton: false,
        allowOutsideClick: false,
      })

      const data = await validatePerson(value, birthDate)
      setDataPerson(data)

      Swal.close();

      fillProfile({
        birth_date: data.birth_date || '',
        gender: data.gender || '',
        name: data.name || '',
      })
    } catch (error: any) {
      const responseDta = error.response.data

      fillProfile({
        birth_date: '',
        gender: '',
        name: '',
      })
      if (error.response.status === 400) {
        if (responseDta.type === 1) {
          const isMainFranchise = checkFranchiseStatus(
            responseDta.data.franchises,
            currentFranchise
          )

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `
             <p>Cliente já cadastrado ${isMainFranchise.status ? 'na franquia atual' : 'na franquia ' + isMainFranchise.name
              }</p>
            <div class="text-start">
              <div class="mb-2"><b>Nome:</b> ${responseDta?.data?.name}</div>
              <div class="mb-2"><b>E-mail:</b> ${responseDta?.data?.email}</div>
                <div class="mb-2"><b>CPF:</b> ${value}</div>
            </div>
           
          `,
            confirmButtonText: 'Dados do cliente',
            cancelButtonText: 'Tentar Outro',
            showCancelButton: true,
            showConfirmButton: isMainFranchise.status == true ? true : false,
            showDenyButton: isMainFranchise.status == true ? false : true,
            denyButtonText: 'Vincular cliente',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            customClass: {
              confirmButton: 'btn btn-danger',
              cancelButton: 'btn btn-secondary',
            },
          }).then(async (result) => {
            const idPeople = error.response.data.data.id
            if (result.isConfirmed) {
              navigate(`/people/list/${idPeople}`)
              return
            }
            if (result.isDenied) {
              try {
                await linkClient(value)
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: `Cliente vinculado com sucesso`,
                  showConfirmButton: false,
                  timer: 2000,
                })
                navigate(`/people/list/${idPeople}`)

                return
              } catch (error: any) {
                Swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: 'Oops...',
                  text: error.response.data.message,
                  showConfirmButton: true,
                })
              }
            }
            formik.setFieldValue('document', '')
          })

          return
        } else {
          await Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `
             <p>${error.response.data.error}</p>
            <div class="text-start">
              <div class="mb-2"><b>CPF:</b> ${cpfMask(value)}</div>
            </div>
           
          `,
            showConfirmButton: true,
            customClass: {
              confirmButton: 'btn btn-danger',
              cancelButton: 'btn btn-secondary',
            },
          })

          formik.setFieldValue('document', '')

          return
        }
      }

      if (error.response.status === 500) {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Tente novamente mais tarde',
          timer: 2000,
        })

        formik.setFieldValue('document', '')

        return
      }

      if (error.response.status === 422) {
        let errorText = ''
        for (let key in error.response.data.errors) {
          errorText += '<li>' + error.response.data.errors[key][0] + '</li>'
        }
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Ops! Revise seu formulário e tente novamente!',
          timer: 2000,
        })
      } else {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.message,
          timer: 2000,
        })
      }
    }
  }

  function validatedate(value: string) {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (regex.test(value)) {
      const year = parseInt(value.split('-')[0], 10);
      if (year > 1900) {
        return true;
      } else {
        return false;
      }
    }
  }

  useEffect(() => {
    if (type != 1) {
      return
    }
    const document = String(formik.values.document)
    const birthDate = String(formik.values.birthDate)

    if (storageDocument == document) {
      return
    }

    if (isBrazilian && document.length == 14 && !minor) {
      validateDocument(document)
    } else if (isBrazilian && document.length == 14 && minor && (birthDate.length == 10 && validatedate(birthDate))) {
      validateDocument(document, birthDate)
    } 
  }, [formik.values.document])

  useEffect(() => {
    if (type != 1 || !minor) {
      return
    }
    const document = String(formik.values.document)
    const birthDate = String(formik.values.birthDate)

    if (storageDocument == document) {
      return
    }

    if (isBrazilian && document.length == 14 && minor && (birthDate.length == 10 && validatedate(birthDate))) {
      validateDocument(document, birthDate)
    }
  }, [formik.values.birthDate])

  return (
    <div className='card mb-5 mb-xl-10 shadow-sm'>
      <div
        className='card-header collapsible cursor-pointer rotate'
        data-bs-toggle='collapse'
        data-bs-target='#kt_profile_card_collapsible'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Dados Pessoais:</h3>
        </div>
        <div className='card-toolbar rotate-180'>
          <i className='ki-duotone ki-down fs-1'></i>
        </div>
      </div>
      <div id='kt_profile_card_collapsible' className='collapse show'>
        {type == 1 && (
          <div className='card-body'>
            <div className='row mb-6'>
              <div className='column col-lg-2'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className='required'>Nacionalidade</span>
                </label>
                <div className='col-lg-10 fv-row'>
                  <Select
                    className='react-select-styled react-select-solid'
                    classNamePrefix='react-select'
                    onChange={(option) => {
                      formik.setFieldValue('nationality', String(option?.value))
                      if (String(option?.value) == '22') {
                        formik.setFieldValue('document', cpfMask(formik.values?.document))
                      }
                    }}
                    options={countries}
                    value={country}
                    placeholder='Selecione a nacionalidade'
                  />
                  {formik.errors?.PersonValiSchema && formik.errors?.nationality ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors?.nationality}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='column col-lg-2'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span>Menor de 18 anos? </span>
                </label>
                <div className='col-lg-12 fv-row'>
                  <div className='form-check form-switch form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='minor'
                      checked={formik.values?.age < 18 ? true : minor }
                      onChange={handleCheckboxChangeHidMinor}
                      disabled={!isCreate}
                    />
                  </div>
                </div>
              </div>
              <div className='column col-lg-4'>
                <label className='col-lg-5 col-form-label required fw-bold fs-6'>
                  {isBrazilian ? `CPF: ` : `Documento: `}
                </label>
                <div className='col-lg-10 fv-row'>
                  <input
                    {...formik.getFieldProps('document')}
                    type='text'
                    disabled={!isCreate}
                    className={`form-control form-control-lg form-control-solid ${!isCreate && 'opacity-75'
                      }`}
                    style={{ cursor: isCreate ? 'auto' : 'not-allowed' }}
                    placeholder='Documento'
                    onChange={(e) => {
                      if (!isCreate) return
                      if (!isBrazilian) {
                        formik.setFieldValue('document', e.target.value)
                        return
                      }
                      formik.setFieldValue('document', cpfMask(e.target.value))
                    }}
                    maxLength={isBrazilian ? 14 : 30}
                  />
                  {formik.errors?.document ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors?.document}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='column col-lg-4'>
                <label className={`col-lg-12 col-form-label fw-bold fs-6 ${complete} required`}>
                  Data de Nascimento:
                </label>
                <div className='col-lg-10 fv-row'>
                  <input
                    type='date'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Digite a data'
                    {...formik.getFieldProps('birthDate')}
                    onChange={(e) => {
                      const value = e.target.value
                      formik.setFieldValue('birthDate', value)
                    }}
                    maxLength={10}
                    disabled={!isCreate || !minor}
                  />
                  {formik.errors?.birthDate ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors?.birthDate}</div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='column col-lg-8'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Nome</span>
                </label>
                <div className='col-lg-11 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Nome'
                    {...formik.getFieldProps('name')}
                    disabled={true}
                  />
                  {formik.errors?.name ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors?.name}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='column col-lg-4'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Nome Social</span>
                </label>
                <div className='col-lg-10 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Nome Social'
                    {...formik.getFieldProps('preferredName')}
                  />
                  {formik.errors?.preferredName ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors?.preferredName}</div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='column col-lg-4'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>RG</span>
                </label>
                <div className='col-lg-10 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='RG'
                    {...formik.getFieldProps('rg')}
                    maxLength={11}
                  />
                  {formik.errors?.rg ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors?.rg}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='column col-lg-4'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className={complete}>Profissão</span>
                </label>
                <div className='col-lg-10 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Profissão'
                    {...formik.getFieldProps('job')}
                    maxLength={25}
                  />
                  {formik.errors?.job ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors?.job}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='column col-lg-4'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Gênero</span>
                </label>
                <div className='col-lg-10 fv-row'>
                  <select
                    className='form-select form-select-lg form-select-solid'
                    {...formik.getFieldProps('gender')}
                    onChange={(e) => {
                      const value = e.target.value
                      formik.setFieldValue('gender', value)
                    }}
                  >
                    <option value=''>Selecione o Gênero</option>
                    <option value='M'>Masculino</option>
                    <option value='F'>Feminino</option>
                  </select>
                  {formik.errors?.gender ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors?.gender}</div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='column col-lg-4'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className={complete}>Como ficou sabendo da Dr. Laser?</span>
                </label>
                <div className='col-lg-10 fv-row'>
                  <Select
                    id={`howMet`}
                    className='react-select-styled react-select-solid '
                    classNamePrefix='react-select'
                    onChange={(option) => {
                      setHowMetValue(option!)
                      formik.setFieldValue(`howMet`, String(option?.value))
                    }}
                    options={howMetOptions}
                    value={howMetValue}
                    placeholder='Selecione'
                  />
                  {formik.errors?.howMet ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors?.howMet}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              {formik.values?.howMet == 8 && (
                <div className='column col-lg-8'>
                  <label className='col-lg-12 col-form-label fw-bold fs-6'>
                    <span className={complete}>Observação outros</span>
                  </label>
                  <div className='col-lg-11 fv-row'>
                    <textarea
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Insira uma observação'
                      {...formik.getFieldProps('howMetObservation')}
                      maxLength={255}
                    />
                    {formik.errors?.howMetObservation ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors?.howMetObservation}</div>
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
            <div className='row mb-6'>
              <div className='column col-lg-4'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className='required'>Reduzir tempo de agendamento</span>
                </label>
                <div className='col-lg-12 fv-row'>
                  <div className='form-check form-switch form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='reduceTimeRadio'
                      checked={reduceTimeRadio}
                      onChange={handleCheckboxChange}
                    />
                    {/* <label className="form-check-label">
                        <h6>Destacar</h6>
                      </label> */}
                  </div>
                </div>
              </div>
              <div className='column col-lg-8'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span>Avatar Neutro</span>
                </label>
                <div className='col-lg-12 fv-row'>
                  <div className='form-check form-switch form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='hideAvatarRadio'
                      checked={hideAvatarRadio}
                      onChange={handleCheckboxChangeHideAvatar}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {type == 2 && (
          <div className='card-body'>
            <div className='row mb-6'>
              <div className='column col-lg-4'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>CNPJ:</label>
                <div className='col-lg-10 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='CNPJ'
                    {...formik.getFieldProps('document')}
                    onChange={(e) => {
                      formik.setFieldValue('document', cnpjMask(e.target.value))
                    }}
                    maxLength={18}
                  />
                  {formik.errors?.document ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors?.document}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='column col-lg-4'>
                <label className='col-lg-5 col-form-label required fw-bold fs-6'>
                  Nome da Franquia:
                </label>
                <div className='col-lg-10 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Nome da Franquia'
                    {...formik.getFieldProps('name')}
                    maxLength={255}
                  />
                  {formik.errors?.name ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors?.name}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='column col-lg-4'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>E-mail:</label>
                <div className='col-lg-12 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='E-mail'
                    {...formik.getFieldProps('franchiseEmail')}
                    maxLength={255}
                  />
                  {formik.errors?.franchiseEmail ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors?.franchiseEmail}</div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='column col-lg-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Razão social:
                </label>
                <div className='col-lg-11 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Razão social'
                    {...formik.getFieldProps('socialReason')}
                    maxLength={255}
                  />
                  {formik.errors?.socialReason ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors?.socialReason}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='column col-lg-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Nome fantasia:
                </label>
                <div className='col-lg-12 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Nome fantasia'
                    {...formik.getFieldProps('fantasyName')}
                    maxLength={255}
                  />
                  {formik.errors?.fantasyName ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors?.fantasyName}</div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='column col-lg-6'>
                <label className='col-lg-12 col-form-label required fw-bold fs-6'>
                  Horário de funcionamento:
                </label>
                <div className='row mb-12'>
                  <div className='col-lg-5 fv-row'>
                    <input
                      type='time'
                      step={60}
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Horário inícial'
                      {...formik.getFieldProps('startTime')}
                    />
                    {formik.errors?.startTime ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors?.startTime}</div>
                      </div>
                    ) : null}
                  </div>
                  <div className='col-lg-1 fv-row'>
                    <b>as</b>
                  </div>
                  <div className='col-lg-5 fv-row'>
                    <input
                      type='time'
                      step={60}
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Horário final'
                      {...formik.getFieldProps('endTime')}
                    />
                    {formik.errors?.endTime ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors?.endTime}</div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default ProfileForm
