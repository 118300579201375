/* eslint-disable jsx-a11y/anchor-is-valid */
import { DashboardProvider } from '../../contexts/dashboard'
import { Dashboard } from './dashboard'
import {CustomProvider} from 'rsuite'
import ptBR from 'rsuite/locales/pt_BR'

export const DashboardPage = () => {
  return (
    <DashboardProvider>
      <CustomProvider locale={ptBR}>
        <Dashboard/>
      </CustomProvider>
    </DashboardProvider>
  )
}