import React, {createContext, useContext, useState, type SetStateAction, type FC} from 'react'

import type {WithChildren} from '../_metronic/helpers'
import {subDays} from 'date-fns'
import moment from 'moment'
import {useDashboardData} from '../pages/dashboard/hooks/getDataDashboard'
import {QueryObserverResult, RefetchOptions, RefetchQueryFilters} from 'react-query'
import {IParsedDashboard} from '../pages/dashboard/utils/parseDashboard'

export interface DashboardContextType {
  currentInitDta: string
  setCurrentInitDta: React.Dispatch<React.SetStateAction<string>>

  currentEndDta: string
  setCurrentEndDta: React.Dispatch<React.SetStateAction<string>>

  choosedDtaInit: Date
  setChoosedDtaInit: React.Dispatch<SetStateAction<Date>>

  choosedDtaEnd: Date
  setChoosedDtaEnd: React.Dispatch<SetStateAction<Date>>

  query: {
    data: IParsedDashboard | undefined
    isLoading: boolean
    error: unknown
    refetch: <TPageData>(
      options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<any, unknown>>
  }
}

const DashboardContext = createContext<DashboardContextType>({} as DashboardContextType)

const DashboardProvider: FC<WithChildren> = ({children}) => {
  const [currentInitDta, setCurrentInitDta] = useState<string>(
    moment().subtract(29, 'days').format('YYYY-MM-DD')
  )

  const [currentEndDta, setCurrentEndDta] = useState<string>(moment().format('YYYY-MM-DD'))

  const [choosedDtaInit, setChoosedDtaInit] = useState<Date>(subDays(new Date(), 29))
  const [choosedDtaEnd, setChoosedDtaEnd] = useState<Date>(new Date())

  const {data, isLoading, error, refetch} = useDashboardData(currentInitDta, currentEndDta)

  return (
    <DashboardContext.Provider
      value={{
        currentInitDta,
        currentEndDta,

        setCurrentInitDta,
        setCurrentEndDta,

        setChoosedDtaInit,
        setChoosedDtaEnd,

        choosedDtaInit,
        choosedDtaEnd,

        query: {
          data,
          error,
          isLoading,
          refetch,
        },
      }}
    >
      {children}
    </DashboardContext.Provider>
  )
}

export {DashboardProvider, DashboardContext}
