import { useEffect, useState } from "react";
import { MultiSelect, Option } from "react-multi-select-component"
import "../../../../../../style/multiSelectSucess.css"
import { getTransferStatus } from "../../core/_requests";

const ListSearchSubscriptionStatus = ({ setSubscriptionStatusSearch, selectsSaleStatus, setSelectsSaleStatus }) => {
    
    const initialSelectValue = [{ value: '', label: '' }];
    const [saleStatus, setSaleStatus] = useState<Option[]>(initialSelectValue);
    
    useEffect(() => {
        async function init() {
            const data = await getTransferStatus()
            setSaleStatus(data!)
        }
        init();
    }, [])

    return (
        <>
            <div className='mb-5'>
                <label className='form-label fs-6 fw-bold'>Pesquisar por status:</label>
                <MultiSelect
                    className='form-control rmscStyle'
                    data-kt-user-table-filter='status'
                    options={saleStatus}
                    value={selectsSaleStatus}
                    onChange={(option) => {
                        const valores = option.map((item) => item.value)
                        setSubscriptionStatusSearch(valores)
                        setSelectsSaleStatus(option)
                    }}
                    labelledBy={''}
                    overrideStrings={{
                        selectSomeItems: 'Selecione o(os) status',
                        search: 'Procurar',
                        selectAll: 'Selecionar todos',
                        allItemsAreSelected: 'Todos status selecionados',
                      }}
                />
            </div>
        </>
    )
}

export { ListSearchSubscriptionStatus }
