import { useParams } from 'react-router-dom'
import { useState } from 'react'
import Swal from 'sweetalert2'
import { syncOldUsersByFranchiseId, syncOldClientsByFranchiseId, syncOldSalesByFranchiseId, syncOldSchedulesByFranchiseId } from '../core/_request'
import { LoadingSpinner } from '../../../utils/loading/loadingSpinner'
import useAuthorization from '../../../hooks/useAuthorization'
import SettingOptionForm from './SettingOptionForm'

export const SettingOption = ({ franchiseId, config }) => {
  const [loadingPage, setLoadingPage] = useState(false)
  let { id } = useParams()

  const isAuthorizedToConfigs = useAuthorization('franchises.configs')

  async function syncUsers(content: string) {
    dialog(content, syncOldUsersByFranchiseId)
  }

  const syncClients = (content: string) => {
    dialog(content, syncOldClientsByFranchiseId)
  }

  const syncSales = (content: string) => {
    dialog(content, syncOldSalesByFranchiseId)
  }

  const syncSchedules = (content: string) => {
    dialog(content, syncOldSchedulesByFranchiseId)
  }

  const dialog = (content: string, call: any) => {
    Swal.fire({
      title: `${content}`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-danger', // Classe personalizada para o botão de confirmação
        cancelButton: 'btn btn-secondary' // Classe personalizada para o botão de cancelamento (opcional)
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoadingPage(true)
        try {
          await call(Number(id))
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Salvo com sucesso',
            showConfirmButton: false,
            timer: 1500,
          })
        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = ''
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>'
            }
            await Swal.fire({
              icon: 'error',
              title: 'Ops! tente novamente!',
              html: errorText,
            })
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
            })
          }
        } finally {
          setLoadingPage(false)
        }
      }
    })
  }

  return (
    <>
      {loadingPage ? (
        <LoadingSpinner title={'Sincroninzando'} />
      ) : (
        <>
          {isAuthorizedToConfigs && (
            <>
              <div className='card mb-5 mb-xl-10'>
                <div className='card card-custom'>
                  <div className='card-header'>
                    <h3 className='card-title fw-bolder m-0'>Configurações Gerais</h3>
                    <div className='card-toolbar'></div>
                  </div>
                  <div className='card-body'>
                    <SettingOptionForm franchiseId={franchiseId} config={config} loading={false} />
                  </div>
                </div>
              </div> 
                <div className='card mb-5 mb-xl-10'>
                <div className='card card-custom'>
                  <div className='card-header'>
                    <h3 className='card-title fw-bolder m-0'>Sincronizar Dados da Franquia</h3>
                    <div className='card-toolbar'></div>
                  </div>
                  <div className='card-body'>
                      <div className='row mt-6 d-flex justify-content-between align-items-center'>
                        <div className='col-auto'>
                          <h6 className='card-title'>Sincronizar usuários servidor antigo:</h6>
                        </div>
                        <div className='col-auto'>
                          <button
                            onClick={(e) => {
                              e.preventDefault()
                              syncUsers('Deseja sincronizar os usuários?')
                            }}
                            className='btn btn-danger'
                          >
                            Sincronizar usuários
                          </button>
                        </div>
                      </div>
                      <div className='row mt-6 d-flex justify-content-between align-items-center'>
                      <div className='col-auto'>
                        <h6 className='card-title'>Sincronizar clientes servidor antigo:</h6>
                      </div>
                      <div className='col-auto'>
                        <button
                          onClick={(e) => {
                            e.preventDefault()
                            syncClients('Deseja sincronizar os clientes?')
                          }}
                          className='btn btn-danger'
                        >
                          Sincronizar clientes
                        </button>
                      </div>
                    </div>  
                    <div className='row mt-6 d-flex justify-content-between align-items-center'>
                      <div className='col-auto'>
                        <h6 className='card-title'>Sincronizar vendas servidor antigo:</h6>
                      </div>
                      <div className='col-auto'>
                        <button
                          onClick={(e) => {
                            e.preventDefault()
                            syncSales('Deseja sincronizar as vendas?')
                          }}
                          className='btn btn-danger'
                        >
                          Sincronizar vendas
                        </button>
                      </div>
                    </div>
                    <div className='row mt-6 d-flex justify-content-between align-items-center'>
                      <div className='col-auto'>
                        <h6 className='card-title'>Sincronizar agenda servidor antigo:</h6>
                      </div>
                      <div className='col-auto'>
                        <button
                          onClick={(e) => {
                            e.preventDefault()
                            syncSchedules('Deseja sincronizar os agendamentos?')
                          }}
                          className='btn btn-danger'
                        >
                          Sincronizar agenda
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}
