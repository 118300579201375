import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {Header} from './list/components/header/Header'
import {Table} from './list/table/Table'
import {KTCard} from '../../_metronic/helpers'
import {RoomEditModal} from './components/modal/RoomEditModal'
import {RoomFormProvider, useRoomForm} from './core/RoomFormProvider'
import {useEffect} from 'react'

const List = () => {
  const {room, setLoadingPage, getDataCategories, getDataMachines, getDataProducts} = useRoomForm()
  useEffect(() => {
    function init() {
      setLoadingPage(true)
      getDataCategories()
      getDataProducts('')
      getDataMachines()
      setLoadingPage(false)
    }

    init()
  }, [])

  return (
    <>
      <KTCard>
        <Header />
        <Table />
      </KTCard>
      {room !== null && <RoomEditModal room={room} />}
    </>
  )
}

const ListWrapper = () => (
  <RoomFormProvider>
    <QueryRequestProvider>
      <QueryResponseProvider>
        <ListViewProvider>
          <List />
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  </RoomFormProvider>
)

export {ListWrapper}
