import {useFormik} from 'formik'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import {useEffect, useState} from 'react'
import {TCategoryRequest} from '../../coreGlobal/models/products/category'
import {useNavigate, useParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import {getCategoryById, updateCategory} from './core/_request'
import {categorySchema} from './core/validation/CategoriesVali'
import {LoadingSpinner} from '../../utils/loading/loadingSpinner'
import {CategoryForm} from './components/CategoryForm'
import {BuildRequest} from './core/BuildRequest'
import {PageOverlayer} from '../../components/PageOverlayer'

const CategoryBreadcrumbs: Array<PageLink> = [
  {
    title: 'Categorias',
    path: '/categories',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const Update = () => {
  let {id} = useParams()
  const [loading, setLoading] = useState(false)
  const [loadingPage, setLoadingPage] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const getCategory = async () => {
      setLoadingPage(true)
      const data = await getCategoryById(String(id))
      formik.setValues({
        name: data.name,
        observation: data.observation,
      })
      setLoadingPage(false)
    }
    try {
      getCategory()
    } catch (error: any) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: error?.response?.data?.message,
        showConfirmButton: true,
      })
    }
  }, [])

  const [categoryInitialValues] = useState<TCategoryRequest>({
    name: '',
    observation: '',
  })

  const formik = useFormik({
    initialValues: categoryInitialValues,
    validationSchema: categorySchema,
    onSubmit: async (values) => {
      const valuesFilter = await BuildRequest(values)
      submit(valuesFilter)
    },
  })

  const submit = async (req: TCategoryRequest) => {
    await Swal.fire({
      title: 'Deseja salvar a Categoria?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true)
        try {
          await updateCategory(String(id), req)
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Salvo com sucesso',
            showConfirmButton: false,
            timer: 1500,
          })

          navigate('/products/categories/list', {replace: true})
        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = ''
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>'
            }
            await Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText,
            })
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 2000,
            })
          }
        } finally {
          setLoading(false)
        }
      }
    })
  }

  return <>{loadingPage ? <PageOverlayer /> : <CategoryForm formik={formik} />}</>
}

export const CategoryEditPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={CategoryBreadcrumbs}>Atualizar categoria</PageTitle>
      <Update />
    </>
  )
}
