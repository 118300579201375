import {useMemo} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import moment from 'moment'
import {useDashboard} from '../../../hooks/useDashboard'

interface ContractsProps {
  className: string
}

export const Contracts = ({className}: ContractsProps) => {
  const {
    query: {data, isLoading},
  } = useDashboard()

  const totalSales = data?.signedSales?.total ?? 0
  const signedSales = data?.signedSales?.signed ?? 0

  const percentOfSales = totalSales > 0 ? parseFloat(((signedSales / totalSales) * 100).toFixed(2)) : 0;

  return (
    <div
      className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end ${className}`}
      style={{
        backgroundColor: '#F1416C',
        backgroundImage: `url('${toAbsoluteUrl('/media/patterns/vector-1.png')}')`,
      }}
    >
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>{totalSales}</span>
          <span className='text-white opacity-75 pt-1 fw-semibold fs-6'>
            {totalSales > 1 || totalSales == 0 ? 'Vendas' : 'Venda'}
          </span>
        </div>
      </div>
      {isLoading && (
        <div className='card-body d-flex align-items-center pt-0'>
          <div className='text-center w-100 align-items-center justify-content-center'>
            <span className='text-white'>Carregando...</span>
          </div>
        </div>
      )}
      {!isLoading && (
        <div className='card-body d-flex align-items-end pt-0'>
          <div className='d-flex align-items-center flex-column mt-3 w-100'>
            <div className='d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-auto mb-2'>
              <span>
                {signedSales}
                {(signedSales > 1 || signedSales == 0) ? ' Vendas assinadas ' : ' Venda assinada '}
              </span>
              <span>{percentOfSales}%</span>
            </div>

            <div className='h-8px mx-3 w-100 bg-white bg-opacity-50 rounded'>
              <div
                className='bg-white rounded h-8px'
                role='progressbar'
                style={{width: `${percentOfSales}%`}}
                aria-valuenow={percentOfSales}
                aria-valuemin={0}
                aria-valuemax={100}
              ></div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
