import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL + '/api'

const NOTIFICATION_URL = `${API_URL}/notifications/mark-as-read`
export const cleanNotifications = () => {
  return axios
    .post(NOTIFICATION_URL)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}
