import { useQueryFilterDatesData, useQueryResponseData, useQueryResponseLoading } from '../core/QueryResponseProvider'
import { ListPagination } from '../components/pagination/ListPagination'
import { KTCardBody } from '../../../../../_metronic/helpers'
import { LoadingListLoading } from '../../../../../utils/loading/loadingListLoading'
import { formatMask } from '../../../../../utils/formatMonetaryToBr'

const Table = () => {
  const user = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const filter = useQueryFilterDatesData()
  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive mb-10'>
        <table
          id='kt_table_reportSales'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              <td className='min-w-125px'>Nome</td>
              {Array.from({ length: filter.daysInMonth }).map((_, i) => {
                const day = String(i + 1).padStart(2, '0');
                return <td>{day}</td>
              })}
              <td className='min-w-125px'>Valor Total Com Desconto</td>
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold'>
            {
              user.length > 0 ? (
                user?.map((userData: any) => {
                  const daysArray = Object.keys(userData)
                    .filter(key => key.startsWith("day_"))
                    .sort((a, b) => Number(a.split("")[1]) - Number(b.split("")[1]))
                    .map(key => ({ key, value: userData[key] }));
                  return <tr>
                    <td>{userData.name}</td>
                    {daysArray?.map((day) => {
                      return <td>{formatMask(day.value)}</td>
                    })}
                    <td>{formatMask(userData.total)}</td>
                  </tr>
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      Nenhum registro encontrado
                    </div>
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>
      <ListPagination />
      {isLoading && <LoadingListLoading title="Carregando" />}
    </KTCardBody>
  )
}

export { Table }
