import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {linkSalesTemporarily} from '../../../coreGlobal/_resquest'
import {useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2'

interface IExistClient {
  data: any
  close: () => void
  oldId: any
}

export const ExistClientModal = ({data, close, oldId}: IExistClient) => {
  const navigate = useNavigate()

  const linkSales = async () => {
    await Swal.fire({
      title: 'Deseja vincular o cliente?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-danger', // Classe personalizada para o botão de confirmação
        cancelButton: 'btn btn-secondary' // Classe personalizada para o botão de cancelamento (opcional)
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const req = {
            clientId: data?.id,
            oldClientId: oldId,
          }

          await linkSalesTemporarily(req)

          await Swal.fire({
            icon: 'success',
            title: 'Cliente vinculado com sucesso!',
            timer: 2000,
          })

          close()
          navigate('/people/list')
        } catch (error: any) {
          await Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.response.data.message,
            timer: 2000,
          })
        }
      }
    })
  }

  return (
    <>
      <div className='modal fade show d-block'>
        <div className='modal-dialog modal-dialog-centered mw-700px'>
          <div className='modal-content' style={{margin: '60px'}}>
            <div className='modal-header'>
              <button type='button' className='btn-close' onClick={close}></button>
            </div>
            <div className='modal-body'>
              <div className='text-center'>
                <h3>Opss...</h3>
                <KTIcon iconName='exclamation-circle' className='fs-5 h-50 text-danger mb-4' />
                <h2>Cliente já cadastrado</h2>
              </div>

              <div className='col-12'>
                <span className='fw-bold d-block text-danger mb-3 mt-6'>{data.name}</span>
              </div>

              <div className='col-12 mb-2'>
                <b>E-mail:</b> {data.email || 'Não informado.'}
              </div>
            </div>

            <div className='modal-footer d-flex justify-content-between'>
              <button
                className='btn btn-danger'
                onClick={() => {
                  window.open(toAbsoluteUrl(`/people/list/${data.id}`), '_blank')
                }}
              >
                Ver cliente
              </button>
              <button
                className='btn btn-danger'
                onClick={() => {
                  linkSales()
                }}
              >
                Vincular
              </button>
              <button className='btn btn-secondary' onClick={close}>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}
