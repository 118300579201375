import {FC, useEffect, useState} from 'react'
import Select from 'react-select'
import {usePeople} from '../../coreGlobal/providers/PersonProvider'
import {cpfMask} from '../../utils/cpfMask'
import {cnpjMask} from '../../utils/cnpjMask'
import {ISelect} from '../../coreGlobal/models/default'
import {validatePerson, validatePersonTemporarily} from '../../coreGlobal/_resquest'
import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'
import {useClientTemporarilyUpdate} from '../../pages/ClientsTemporarily/Provider/UpdateClientProvider'

type Props = {
  formik: any
  type: number
  complete: string
}
const ProfileTemporarilyForm: FC<Props> = ({formik, type, complete}) => {
  const isBrazilian = formik.values?.nationality == 22
  const {
    countries,
    getDataCountries,
    country,
    setCountry,
    hideAvatarRadio,
    setHideAvatarRadio,
    reduceTimeRadio,
    setReduceTimeRadio,
    setOpenClientModal,
    setDataValidateDoc,
  } = useClientTemporarilyUpdate()

  const [storageDocument] = useState(formik.values.document)
  const navigate = useNavigate()
  const handleCheckboxChange = () => {
    setReduceTimeRadio(!reduceTimeRadio)
    formik.setFieldValue('reduceTime', !reduceTimeRadio)
    if (reduceTimeRadio) {
      Swal.fire({
        title: 'Deseja aumentar o tempo de agendamento?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-danger', // Classe personalizada para o botão de confirmação
          cancelButton: 'btn btn-secondary' // Classe personalizada para o botão de cancelamento (opcional)
        }
      }).then(async (result) => {
        if (!result.isConfirmed) {
          setReduceTimeRadio(true)
          formik.setFieldValue('reduceTime', reduceTimeRadio)
        }
      })
    }
  }

  const handleCheckboxChangeHideAvatar = () => {
    setHideAvatarRadio(!hideAvatarRadio)
    formik.setFieldValue('hideAvatar', !hideAvatarRadio)
    if (!hideAvatarRadio) {
      Swal.fire({
        title: 'Deseja utilizar o avatar neutro?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-danger', // Classe personalizada para o botão de confirmação
          cancelButton: 'btn btn-secondary' // Classe personalizada para o botão de cancelamento (opcional)
        }
      }).then(async (result) => {
        if (!result.isConfirmed) {
          setHideAvatarRadio(false)
          formik.setFieldValue('hideAvatar', hideAvatarRadio)
        }
      })
    }
  }

  useEffect(() => {
    const init = async () => {
      if (type === 1 && countries.length === 1) {
        await getDataCountries()
      }
      setReduceTimeRadio(formik.values.reduceTime)
      setHideAvatarRadio(formik.values.hideAvatar)
    }
    init()
  }, [])

  useEffect(() => {
    if (formik.values.nationality && countries.length > 1) {
      const nationality = countries.find((country: ISelect) => {
        return country.value === String(formik.values.nationality)
      })
      setCountry(nationality)
    }
  }, [formik.values.nationality, countries])

  const validateDocument = async (value: string) => {
    try {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: `Buscando dados`,
        showConfirmButton: false,
        timer: 1000,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading()
        },
      })
      try {
        const data = await validatePersonTemporarily(value)
        setDataValidateDoc(data)
        return 200
      } catch (error) {
        return 400
      }
    } catch (error: any) {
      return error.response.status
    }
  }

  const verifyDocument = async (document) => {
    const getResponseValidateDocument = await validateDocument(String(document))
    if (getResponseValidateDocument === 200) {
      setOpenClientModal(true)
      return
    }
  }
  useEffect(() => {
    if (type != 1) return
    const document = String(formik.values.document)
    if (storageDocument == document) return
    if (isBrazilian && document.length == 14) {
      verifyDocument(document)
    }
  }, [formik.values.document])

  return (
    <div className='card mb-5 mb-xl-10 shadow-sm'>
      <div
        className='card-header collapsible cursor-pointer rotate'
        data-bs-toggle='collapse'
        data-bs-target='#kt_profile_card_collapsible'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Dados Pessoais:</h3>
        </div>
        <div className='card-toolbar rotate-180'>
          <i className='ki-duotone ki-down fs-1'></i>
        </div>
      </div>
      <div id='kt_profile_card_collapsible' className='collapse show'>
        {type == 1 && (
          <div className='card-body'>
            <div className='row mb-6'>
              <div className='column col-lg-4'>
                <label className='col-lg-5 col-form-label fw-bold fs-6'>
                  <span className='required'>Nacionalidade</span>
                </label>
                <div className='col-lg-10 fv-row'>
                  <Select
                    className='react-select-styled react-select-solid'
                    classNamePrefix='react-select'
                    onChange={(option) => {
                      formik.setFieldValue('nationality', String(option?.value))
                      if (String(option?.value) == '22') {
                        formik.setFieldValue('document', cpfMask(formik.values?.document))
                      }
                    }}
                    options={countries}
                    value={country}
                    placeholder='Selecione a nacionalidade'
                  />
                  {formik.touched?.nationality &&
                  formik.errors?.PersonValiSchema &&
                  formik.errors?.nationality ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors?.nationality}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='column col-lg-4'>
                <label className='col-lg-5 col-form-label required fw-bold fs-6'>
                  {isBrazilian ? `CPF: ` : `Documento: `}
                </label>
                <div className='col-lg-10 fv-row'>
                  <input
                    {...formik.getFieldProps('document')}
                    type='text'
                    className={`form-control form-control-lg form-control-solid`}
                    style={{cursor: 'auto'}}
                    placeholder='Documento'
                    onChange={(e) => {
                      if (!isBrazilian) {
                        formik.setFieldValue('document', e.target.value)
                        return
                      }
                      formik.setFieldValue('document', cpfMask(e.target.value))
                    }}
                    maxLength={isBrazilian ? 14 : 30}
                  />
                  {formik.touched?.document && formik.errors?.document ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors?.document}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='column col-lg-4'>
                <label className={`col-lg-12 col-form-label fw-bold fs-6 ${complete}`}>
                  Data de Nascimento:
                </label>
                <div className='col-lg-12 fv-row'>
                  <input
                    type='date'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Digite a data'
                    {...formik.getFieldProps('birthDate')}
                    onChange={(e) => {
                      const value = e.target.value
                      formik.setFieldValue('birthDate', value)
                    }}
                    maxLength={10}
                  />
                  {formik.touched?.birthDate && formik.errors?.birthDate ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors?.birthDate}</div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='column col-lg-8'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Nome</span>
                </label>
                <div className='col-lg-11 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Nome'
                    {...formik.getFieldProps('name')}
                  />
                  {formik.touched?.name && formik.errors?.name ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors?.name}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='column col-lg-4'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Nome Social</span>
                </label>
                <div className='col-lg-12 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Nome Social'
                    {...formik.getFieldProps('preferredName')}
                  />
                  {formik.touched?.preferredName && formik.errors?.preferredName ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors?.preferredName}</div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='column col-lg-4'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>RG</span>
                </label>
                <div className='col-lg-11 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='RG'
                    {...formik.getFieldProps('rg')}
                    maxLength={11}
                  />
                  {formik.touched?.rg && formik.errors?.rg ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors?.rg}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='column col-lg-4'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className={complete}>Profissão</span>
                </label>
                <div className='col-lg-11 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Profissão'
                    {...formik.getFieldProps('job')}
                    maxLength={25}
                  />
                  {formik.touched?.job && formik.errors?.job ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors?.job}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='column col-lg-4'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Gênero</span>
                </label>
                <div className='col-lg-12 fv-row'>
                  <select
                    className='form-select form-select-lg form-select-solid'
                    {...formik.getFieldProps('gender')}
                    onChange={(e) => {
                      const value = e.target.value
                      formik.setFieldValue('gender', value)
                    }}
                  >
                    <option value=''>Selecione o Gênero</option>
                    <option value='M'>Masculino</option>
                    <option value='F'>Feminino</option>
                  </select>
                  {formik.touched?.gender && formik.errors?.gender ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors?.gender}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='column col-lg-4'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span className='required'>Reduzir tempo de agendamento</span>
                </label>
                <div className='col-lg-12 fv-row'>
                  <div className='form-check form-switch form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='reduceTimeRadio'
                      checked={reduceTimeRadio}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                </div>
              </div>
              <div className='column col-lg-4'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span>Avatar Neutro</span>
                </label>
                <div className='col-lg-12 fv-row'>
                  <div className='form-check form-switch form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='hideAvatarRadio'
                      checked={hideAvatarRadio}
                      onChange={handleCheckboxChangeHideAvatar}
                    />
                  </div>
                </div>
              </div>

              <div className='column col'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                  <span>ID Cliente</span>
                </label>

                <div className='col-lg-12 fv-row'>
                  <input
                    className='form-control form-control-lg form-control-solid'
                    type='text'
                    {...formik.getFieldProps('oldClientId')}
                    disabled
                    id='oldClientId'
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {type == 2 && (
          <div className='card-body'>
            <div className='row mb-6'>
              <div className='column col-lg-4'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>CNPJ:</label>
                <div className='col-lg-10 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='CNPJ'
                    {...formik.getFieldProps('document')}
                    onChange={(e) => {
                      formik.setFieldValue('document', cnpjMask(e.target.value))
                    }}
                    maxLength={18}
                  />
                  {formik.touched?.document && formik.errors?.document ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors?.document}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='column col-lg-4'>
                <label className='col-lg-5 col-form-label required fw-bold fs-6'>
                  Nome da Franquia:
                </label>
                <div className='col-lg-10 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Nome da Franquia'
                    {...formik.getFieldProps('name')}
                    maxLength={255}
                  />
                  {formik.touched?.name && formik.errors?.name ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors?.name}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='column col-lg-4'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>E-mail:</label>
                <div className='col-lg-12 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='E-mail'
                    {...formik.getFieldProps('franchiseEmail')}
                    maxLength={255}
                  />
                  {formik.touched?.franchiseEmail && formik.errors?.franchiseEmail ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors?.franchiseEmail}</div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='column col-lg-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Razão social:
                </label>
                <div className='col-lg-11 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Razão social'
                    {...formik.getFieldProps('socialReason')}
                    maxLength={255}
                  />
                  {formik.touched?.socialReason && formik.errors?.socialReason ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors?.socialReason}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='column col-lg-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Nome fantasia:
                </label>
                <div className='col-lg-12 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Nome fantasia'
                    {...formik.getFieldProps('fantasyName')}
                    maxLength={255}
                  />
                  {formik.touched?.fantasyName && formik.errors?.fantasyName ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors?.fantasyName}</div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='column col-lg-6'>
                <label className='col-lg-12 col-form-label required fw-bold fs-6'>
                  Horário de funcionamento:
                </label>
                <div className='row mb-12'>
                  <div className='col-lg-5 fv-row'>
                    <input
                      type='time'
                      step={60}
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Horário inícial'
                      {...formik.getFieldProps('startTime')}
                    />
                    {formik.touched?.startTime && formik.errors?.startTime ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors?.startTime}</div>
                      </div>
                    ) : null}
                  </div>
                  <div className='col-lg-1 fv-row'>
                    <b>as</b>
                  </div>
                  <div className='col-lg-5 fv-row'>
                    <input
                      type='time'
                      step={60}
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Horário final'
                      {...formik.getFieldProps('endTime')}
                    />
                    {formik.touched?.endTime && formik.errors?.endTime ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors?.endTime}</div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default ProfileTemporarilyForm
