import {SplitInfoCartao} from '../../../../../components/SalePaymentConfig/components/SplitInfoCartao'
import {SplitInfoCartaoRecorrente} from '../../../../../components/SalePaymentConfig/components/SplitInfoCartaoRecorrente'
import {SplitInfoPix} from '../../../../../components/SalePaymentConfig/components/SplitInfoPix'
import {BillingTransfer, BillingTransferOrigin} from '../core/_models'

interface ISplit {
  payment: BillingTransfer[] | BillingTransferOrigin[]
}

const SwitchMethodTransfer = ({pay}: {pay: BillingTransfer | BillingTransferOrigin}) => {
  if (pay.amount === 0) return <></>

  switch (Number(pay.method)) {
    case 1:
      return <SplitInfoPix value={pay.amount} date={new Date()} />
    case 2:
      return (
        <SplitInfoCartao value={pay.amount} date={new Date()} installments={pay.installments} />
      )
    case 3:
      return (
        <SplitInfoCartaoRecorrente
          value={pay.amount}
          date={new Date()}
          installments={pay.installments}
        />
      )

    default:
      if (pay.hasInstallment) {
        return (
          <>
            <SplitInfoCartaoRecorrente
              value={pay.amount}
              date={new Date()}
              installments={pay.installments}
            />
          </>
        )
      }
      return <></>
  }
}

export const SplitTransfer = ({payment}: ISplit) => {
  return (
    <div className='col-12'>
      {payment?.map((pay: BillingTransfer | BillingTransferOrigin) => {
        return <SwitchMethodTransfer pay={pay} />
      })}
    </div>
  )
}
