import {useNavigate, useParams} from 'react-router-dom'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import React, {useEffect, useState} from 'react'
import {LoadingSpinner} from '../../utils/loading/loadingSpinner'
import {cpfMask} from '../../utils/cpfMask'
import {phoneMask} from '../../utils/phoneMask'
import {getUsersById} from './core/_resquest'
import {formatDate} from '../../utils/dayJs/format'
import {cepMask} from '../chooseFranchise/utils/mask'
import {IUserResponse} from '../../coreGlobal/models/user'
import {PageOverlayer} from '../../components/PageOverlayer'

const UsersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Usuário',
    path: '/users',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const Show = () => {
  let {id} = useParams()
  const [loadingPage, setLoadingPage] = useState(false)
  const [user, setUser] = useState<IUserResponse>()
  const navigate = useNavigate()
  const handleBackButton = () => {
    navigate(-1)
  }

  useEffect(() => {
    const init = async () => {
      setLoadingPage(true)
      const data = await getUsersById(String(id))
      setUser(data)
      setLoadingPage(false)
    }
    init()
  }, [])

  return (
    <>
      {loadingPage ? (
        <PageOverlayer />
      ) : (
        <>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-header'>
              <div className='d-flex justify-content-between align-items-center'>
                <h3 className='fw-bolder m-0'>Informações do Usuário </h3>
              </div>
              <div className='card-title m-0 d-flex justify-content-end'>
                <button className='btn btn-danger' onClick={handleBackButton}>
                  Voltar
                </button>
              </div>
            </div>
            <div className='card-body py-3'>
              <div className='table-responsive'>
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  <tbody>
                    <tr>
                      <td>
                        <b>Nome:</b> {user?.name ? user.name : ' '}{' '}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>CPF:</b> {user?.cpf ? cpfMask(user.cpf) : ' '}{' '}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>E-mail:</b> {user?.email ? user.email : ' '}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>E-mail Verificado:</b> {user?.emailVerified ? 'Sim' : 'não'}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Telefone:</b> {user?.phone ? phoneMask(user.phone) : ' '}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Idade:</b> {user?.person?.physicalPerson?.age}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Data de Nascimento:</b>{' '}
                        {user?.person?.physicalPerson?.birthDate
                          ? formatDate(user?.person.physicalPerson.birthDate)
                          : ''}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Trabalho:</b> {user?.person?.physicalPerson?.job}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Nacionalidade:</b> {user?.person?.physicalPerson?.nationality?.name}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-header'>
              <div className='d-flex justify-content-between align-items-center'>
                <h3 className='fw-bolder m-0'>Informações de Endereço </h3>
              </div>
            </div>
            <div className='card-body py-3'>
              <div className='table-responsive'>
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  <tbody>
                    {user?.person?.addresses?.map((address, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td>
                            <b>CEP:</b> {cepMask(address?.zipCode)}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Rua:</b> {address?.street}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Número:</b> {address?.number}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Complemento:</b> {address?.complement}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Bairro: </b>
                            {address?.neighborhood}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Cidade:</b> {address?.city?.name} - {address?.city?.state?.uf}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Estado:</b> {address?.city?.state?.name}
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export const UserShowPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={UsersBreadcrumbs}>Visualizar Usuário</PageTitle>
      <Show />
    </>
  )
}
