import { useEffect, useState } from "react";
import { MultiSelect, Option } from "react-multi-select-component"
import { getCategories, getSaleStatus } from "../../core/_requests";

const ListSearchSubscriptionType = ({ setSubscriptionTypeSearch,selectsTypes, setSelectsTypes }) => {
    
    const initialSelectValue = [{ value: '1', label: 'Avulso' },{ value: '2', label: 'Pacote' }];
    const [types, setTypes] = useState<Option[]>(initialSelectValue);


    return (
        <>
            <div className='mb-5'>
                <label className='form-label fs-6 fw-bold'>Pesquisar pelo tipo:</label>
                <MultiSelect
                    className='react-select-styled react-select-solid'
                    data-kt-user-table-filter='type'
                    options={types}
                    value={selectsTypes}
                    onChange={(option) => {
                        const valores = option.map((item) => item.value)
                        setSubscriptionTypeSearch(valores)
                        setSelectsTypes(option)
                    }}
                    labelledBy={''}
                    overrideStrings={{
                        selectSomeItems: 'Selecione pelo(os) tipo',
                        search: 'Procurar',
                        selectAll: 'Selecionar todos',
                        allItemsAreSelected: 'Todos os tipos selecionados',
                      }}
                />
            </div>
        </>
    )
}

export { ListSearchSubscriptionType }
