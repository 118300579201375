import { KTIcon } from '../../../../../_metronic/helpers'
import useAuthorization from '../../../../../hooks/useAuthorization'
import { useCreateGroupForm } from '../../core/CreateViewProvider'

const ListToolbar = () => {
    const { setShowCreateModal } = useCreateGroupForm()
    const handleToCreate = () => { setShowCreateModal(true) }
    const isAuthorized = useAuthorization("groups.create");
    if (!isAuthorized) return <></>

    return (
        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
            <button type='button' className='btn btn-light-danger' onClick={() => handleToCreate()}>
                <KTIcon iconName='plus' className='fs-2' />
                Adicionar Grupo
            </button>
        </div>
    )
}

export { ListToolbar }