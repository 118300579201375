import { useCart } from "../../core/cartProvider"


export const BudgetButton = ({ budget, action }) => {

  const { enableBudget, budgetForSale } = useCart();

  return (
    <button
      onClick={action}
      disabled={!enableBudget}
      className='btn btn-light-danger w-100 text-center d-flex align-items-center justify-content-center'
      style={{ overflow: 'hidden', lineHeight: '1' }}
    >
      <i className="ki-duotone ki-finance-calculator fs-2" >
        <span className="path1"></span>
        <span className="path2"></span>
        <span className="path3"></span>
        <span className="path4"></span>
        <span className="path5"></span>
        <span className="path6"></span>
        <span className="path7"></span>
      </i> {budget}
    </button>
  )
}