/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { formatMonetaryToBr } from '../../../../../utils/formatMonetaryToBr'
import { IBillingRequest } from '../../../../../coreGlobal/models/billing'

type Props = {
    billing: IBillingRequest
}

const PriceCell: FC<Props> = ({ billing }) => (
    <div className='d-flex flex-column'>
        <a href='#' className='text-gray-800 text-hover-danger mb-1'>
            {billing.amount ? formatMonetaryToBr(billing.amount.toString()) : 'N/A'}
        </a>
    </div>
)

export { PriceCell }
