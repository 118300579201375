import axios, { AxiosResponse } from 'axios'
import { SaleReportsQueryResponse } from './_models'
import { SearchReportsClients } from '../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL + '/api'
const REPORTS_URL = `${API_URL}/reports`

const getClientReports = (query: string): Promise<SaleReportsQueryResponse> => {
  return axios
    .get(`${REPORTS_URL}/customers?${query}`)
    .then((d: AxiosResponse<SaleReportsQueryResponse>) => d.data)
}

const downloadServerFile = (filter: string | undefined) => {
  var filterAdjust =  filter !== undefined ? `?${filter}`: ''
  return axios({
    url: `${REPORTS_URL}/customers/export${filterAdjust}`, //your url
    method: 'GET',
    responseType: 'blob', // important
  }).then((response) => {
    // create file link in browser's memory
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'Relatorio_clientes.xlsx'); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
}

export { getClientReports, downloadServerFile };