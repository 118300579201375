import {formatMonetaryToBr} from '../../../../utils/formatMonetaryToBr'

export const AmountTransfer = ({transfer}) => {
  const dataTransferValues = transfer?.additionalData
  return (
    <>
      <div className='card mt-4'>
        <div className='card-header'>
          <div className='card-title'>
            <h2 className='fw-bolder'>Valores</h2>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='row'>
            <div className='col-12'>
              <div className='fs-5 mt-2 text-dark fw-bold mb-3'>
                Repasse Cliente Origem:{' '}
                {formatMonetaryToBr(
                  dataTransferValues?.transferValues?.clientTransferValueToOrigin
                )}
              </div>

              <div className='fs-5 mt-2 text-dark fw-bold mb-3'>
                Repasse Cliente Destino:{' '}
                {formatMonetaryToBr(
                  dataTransferValues?.transferValues?.clientTransferValueToDestination
                )}
              </div>

              <div className='fs-5 mt-2 text-dark fw-bold'>
                Repasse Franquia:{' '}
                {formatMonetaryToBr(dataTransferValues?.transferValues?.franchiseTransferValue)}
              </div>
              <hr />
              {dataTransferValues?.transferFine?.length !== 0 && (
                <>
                  <div className='fs-5 mt-4 text-dark fw-bold'>
                    Valor da multa:{' '}
                    {formatMonetaryToBr(dataTransferValues?.transferFine?.amount ?? 0)}
                  </div>

                  <div className='fs-5 mt-2 text-dark fw-bold'>
                    Unidade a Receber:{' '}
                    {dataTransferValues?.transferFine?.franchise === 1
                      ? transfer?.originFranchise?.name
                      : transfer?.destinationFranchise?.name}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
