import { useEffect, useState } from "react";
import { MultiSelect, Option } from "react-multi-select-component"
import { getAvailableProducts } from "../../core/_requests";

const ListSearchSubscriptionItems = ({ setSubscriptionItemsSearch, selectsItems, setSelectsItems }) => {

    const initialSelectValue = [{ value: '', label: '' }];
    const [products, setProducts] = useState<Option[]>(initialSelectValue);

    useEffect(() => {
        async function init() {
            const data = await getAvailableProducts()
            setProducts(data!)
        }
        init();
    }, [])

    return (
        <>
            <div className='mb-5'>
                <label className='form-label fs-6 fw-bold'>Pesquisar por item:</label>
                <MultiSelect
                    className='react-select-styled react-select-solid'
                    data-kt-user-table-filter='items'
                    options={products}
                    value={selectsItems}
                    onChange={(option) => {
                        const valores = option.map((item) => item.value)
                        setSubscriptionItemsSearch(valores)
                        setSelectsItems(option)
                    }}
                    labelledBy={''}
                    overrideStrings={{
                        selectSomeItems: 'Selecione o(os) itens',
                        search: 'Procurar',
                        selectAll: 'Selecionar todos',
                        allItemsAreSelected: 'Todos itens selecionados',
                      }}
                />
            </div>
        </>
    )
}

export { ListSearchSubscriptionItems }
