import { FC, useEffect, useState } from 'react'
import { cepMask } from '../../utils/cepMask'
import { cleanMask } from '../../utils/cleanMask'
import { useAdress } from '../../coreGlobal/providers/AdressProvider'
import Swal from 'sweetalert2'
import { LoadingSweetAlter } from '../../utils/loading/loadingSweetAlter'
import { getCepByViaCep } from '../../coreGlobal/_resquest'

type Props = {
  formik: any
  complete: string
}

const AddressForm: FC<Props> = ({ formik, complete }) => {
  const { dataStates, getDataCep, dataCep, getDataStates, loadingPage, setLoadingPage } = useAdress()

  useEffect(() => {
    try {
      getDataStates()
    } catch (error: any) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: error.response.data.message,
        timer: 5000,
      })
      setLoadingPage(false)
    }
  }, [])

  useEffect(() => {
    if (!dataCep?.data.message) {
      if (dataCep?.data.address) {
        formik.setFieldValue('addresses[0].street', dataCep?.data.address)
      }

      if (dataCep?.data.neighbourhood) {
        formik.setFieldValue('addresses[0].neighborhood', dataCep?.data.neighbourhood)
      }

      const findState = dataStates?.find((state) => state.uf === dataCep?.data.state)
      if (findState) {
        formik.setFieldValue('addresses[0].state', findState.id)
        const findCity = findState.cities?.find((city) => city.name === dataCep?.data.city)
        if (findCity) {
          formik.setFieldValue('addresses[0].city', findCity.id)
        }
      }
    } else {
      formik.setFieldValue('addresses[0].street', '')
      formik.setFieldValue('addresses[0].neighborhood', '')
      formik.setFieldValue('addresses[0].state', '')
      formik.setFieldValue('addresses[0].city', '')
      formik.setFieldValue('addresses[0].complement', '')
      formik.setFieldValue('addresses[0].number', '')

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Cep inválido',
      })
    }
  }, [dataCep])

  return (
    <>
      {loadingPage && <LoadingSweetAlter title={'Buscando dados'} />}
      <div className='row mb-6'>
        <label className={`col-lg-1 col-form-label fw-bold fs-6 ${complete}`}>CEP:</label>
        <div className='col-lg-5 fv-row'>
          <input
            {...formik.getFieldProps(`addresses[0].zipCode`)}
            type='text'
            className='form-control form-control-lg form-control-solid'
            placeholder='Insira o CEP'
            onChange={async (e) => {
              formik.setFieldValue('addresses[0].zipCode', cepMask(e.target.value))
              if (cleanMask(e.target.value)?.length == 8) {
                setLoadingPage(true)
                await getDataCep(cleanMask(e.target.value))
                setLoadingPage(false)
              }
            }}
            maxLength={9}
          />
          {!Array.isArray(formik?.errors?.addresses) &&
            (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors?.addresses}</div>
              </div>
            )}
          {formik.touched?.addresses &&
            formik.touched?.addresses[0]?.zipCode &&
            formik.errors?.addresses &&
            formik.errors?.addresses[0]?.zipCode && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors?.addresses[0]?.zipCode}</div>
              </div>
            )}
        </div>
      </div>
      <div className='row mb-6'>
        <div className='column col-lg-6'>
          <label className={`col-lg-4 col-form-label fw-bold fs-6 ${complete}`}>Logradouro:</label>
          <div className='fv-row'>
            <input
              {...formik.getFieldProps('addresses[0].street')}
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder='Logradouro'
              maxLength={255}
            />
            {formik.touched?.addresses &&
              formik.touched?.addresses[0]?.street &&
              formik.errors?.addresses &&
              formik.errors?.addresses[0]?.street && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors?.addresses[0]?.street}</div>
                </div>
              )}
          </div>
        </div>
        <div className='column col-lg-6'>
          <label className={`col-lg-4 col-form-label fw-bold fs-6 ${complete}`}>Bairro:</label>
          <div className='fv-row'>
            <input
              {...formik.getFieldProps('addresses[0].neighborhood')}
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder='Bairro'
              maxLength={255}
            />
            {formik.touched?.addresses &&
              formik.touched?.addresses[0]?.neighborhood &&
              formik.errors?.addresses &&
              formik.errors?.addresses[0]?.neighborhood && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors?.addresses[0]?.neighborhood}</div>
                </div>
              )}
          </div>
        </div>
      </div>
      <div className='row mb-6'>
        <div className='column col-lg-3'>
          <label className={`col-lg-6 col-form-label fw-bold fs-6 ${complete}`}>Número:</label>
          <div className='fv-row'>
            <input
              {...formik.getFieldProps('addresses[0].number')}
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder='Número'
              maxLength={255}
            />
            {formik.errors?.addresses &&
              formik.errors?.addresses[0]?.number && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors?.addresses[0]?.number}</div>
                </div>
              )}
          </div>
        </div>
        <div className='column col-lg-9'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>Complemento:</label>
          <div className='fv-row'>
            <input
              {...formik.getFieldProps('addresses[0].complement')}
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder='Complemento'
              maxLength={255}
            />
            {formik.touched?.addresses &&
              formik.touched?.addresses[0]?.complement &&
              formik.errors?.addresses &&
              formik.errors?.addresses[0]?.complement && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors?.addresses[0]?.complement}</div>
                </div>
              )
            }
          </div>
        </div>
      </div>
      <div className='row mb-6'>
        <div className='column col-lg-6'>
          <label className={`col-lg-4 col-form-label fw-bold fs-6 ${complete}`}>Estado (UF):</label>
          <div className='fv-row'>
            <select
              {...formik.getFieldProps('addresses[0].state')}
              className='form-select form-select-lg form-select-solid'
            >
              <option value={-1}>Selecione um estado</option>
              {dataStates
                ?.sort((a, b) => a.name.localeCompare(b.name)) // Ordenar os estados por uf em ordem alfabética
                .map((state) => (
                  <option key={state.id} value={state.id}>
                    {state.name}
                  </option>
                ))}
            </select>
            {formik.touched?.addresses &&
              formik.touched?.addresses[0]?.state &&
              formik.errors?.addresses &&
              formik.errors?.addresses[0]?.state && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors?.addresses[0]?.state}</div>
                </div>
              )
            }
          </div>
        </div>
        <div className='column col-lg-6'>
          <label className={`col-lg-4 col-form-label fw-bold fs-6 ${complete}`}>Cidade:</label>
          <div className='fv-row'>
            <select
              className='form-select form-select-lg form-select-solid'
              {...formik.getFieldProps('addresses[0].city')}
            >
              <option value={-1}>Selecione uma cidade</option>
              {formik.values.addresses &&
                dataStates
                  ?.find((state) => state.id === parseInt(formik.values.addresses[0]?.state))
                  ?.cities.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.name}
                    </option>
                  ))}
            </select>
            {formik.touched?.addresses &&
              formik.touched?.addresses[0]?.city &&
              formik.errors?.addresses &&
              formik.errors?.addresses[0]?.city && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors?.addresses[0]?.city}</div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default AddressForm
