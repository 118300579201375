import * as Yup from 'yup'

export const AddressValidSchema = Yup.object().shape({
  zipCode: Yup.string()
    .matches(/^\d{5}-\d{3}$/, 'Insira um CEP')
    .required('Insira o número de CEP.'),
  street: Yup.string()
    .min(3, 'O logradouro deve ter no mínimo 3 caracteres.')
    .max(100, 'O logradouro não pode ter mais de 100 caracteres.')
    .required('Insira o logradouro.'),
  number: Yup.string().required('Insira o número do endereço.'),
  complement: Yup.string()
    .min(3, 'O complemento deve ter no mínimo 3 caracteres.')
    .max(20, 'O complemento não pode ter mais de 20 caracteres.'),
  neighborhood: Yup.string()
    .min(3, 'O bairro deve ter no mínimo 3 caracteres.')
    .max(50, 'O bairro não pode ter mais de 50 caracteres.')
    .required('Insira o bairro.'),
  state: Yup.number()
    .required('Selecione um estado.')
    .notOneOf([-1], 'Selecione uma UF primeiramente.'),
  city: Yup.number()
    .required('Selecione uma cidade.')
    .notOneOf([-1], 'Selecione uma cidade primeiramente.'),

})
