import {useEffect} from 'react'
import {ContractListModalHeader} from './ContractListModalHeader'
import {useContractContext} from "../../core/ContractProvider";
import {KTIcon} from "../../../../_metronic/helpers";
import {useFormik} from "formik";
import {IContract as initialValues} from "../../core/_models";
import { postFile } from '../../core/_request';
import Swal from 'sweetalert2';

interface ISaleID {
  saleId: any | null
}

const ContractListModal = ({saleId}: ISaleID) => {
  const {files, getFiles, downloadFile} = useContractContext()

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  useEffect(() => {
    async function init() {
      await getFiles(saleId)
    }

    init()
  }, []);

  // const ContractSchema = []

  const formik = useFormik({
    initialValues,
    validationSchema: null,
    onSubmit: async (values) => {
      // downloadServerFile()
     

      try {
   
        
        const formData = new FormData();
        formData.append('file', values.file,values.file.name);
        formData.append('directory', saleId);
    

        await postFile(formData)
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Sucesso ao gerar Contrato!!',
          timer: 3000,
        })
      } catch (error: any) {
        await Swal.fire({
          icon: 'warning',
          title: 'Oops...',
          text: error.response.data.message ?? 'Erro ao gerar salvar contrato.',
          timer: 3000,
        })
      } 
    },
  })

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-800px'>
          <div className='modal-content'>
            <ContractListModalHeader/>
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <div className='row mb-6 mt-4'>
                <div className="col-12">
                  <div className="table-responsive col-12">
                    <table style={{width: '100%'}}>
                      <thead>
                      <tr>
                        <th>Nome</th>
                        <th>Ações</th>
                      </tr>
                      </thead>
                      <tbody>
                      {files.map((file, i) => {
                        return <>
                          <tr>
                            <td>{file}</td>
                            <td className='text-start'>
                              <button
                                onClick={() => {
                                  downloadFile(file)
                                }}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                              >
                                <KTIcon iconName='eye' className='fs-3'/>
                              </button>
                            </td>
                          </tr>
                        </>
                      })}
                      </tbody>
                    </table>
                  </div>
                  <div className="separator my-10"></div>
                  <div className="col-12">
                    <form id='kt_modal_add_file_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                      <input type="file" className="form-control form-control-lg form-control-solid" 
                      multiple={false}
                      
                      onChange={(event) => { 
                          const file = event?.currentTarget?.files
                         
                          formik.setFieldValue('file', file![0])}}
                      />
                      <div className='text-center pt-15'>
                        <button
                          type='submit'
                          className='btn btn-danger'
                          data-kt-users-modal-action='submit'
                          disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                        >
                          <span className='indicator-label'>Salvar</span>
                          {(formik.isSubmitting) && (
                            <span className='indicator-progress'>
                              Aguarde...{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export {ContractListModal}
