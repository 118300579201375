import {useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'

interface IPartner {
  data: any
  close: any
}

export const PartnerNotFoundModal = ({data, close}: IPartner) => {
  return (
    <>
      <div className='modal fade show d-block'>
        <div className='modal-dialog modal-dialog-centered mw-700px'>
          <div className='modal-content' style={{margin: '60px'}}>
            <div className='modal-header'>
              <button type='button' className='btn-close' onClick={close}></button>
            </div>
            <div className='modal-body'>
              <div className='text-center'>
                <h3>Opss...</h3>
                <div className='my-3'>
                  <i className='bi bi-x-circle-fill text-danger' style={{fontSize: '5rem'}}></i>
                </div>
                <KTIcon iconName='exclamation-circle' className='fs-5 h-50 text-danger mb-4' />
                <h2>Franquias não possuem a mesma parceria</h2>
              </div>
            </div>

            <div className='modal-footer d-flex justify-content-center'>
              <button className='btn btn-secondary' onClick={close}>
                OK
              </button>

              <button
                className='btn btn-danger'
                onClick={() => {
                  window.open(
                    toAbsoluteUrl(`/franchises/list/${data.id}/edit#partnership`),
                    '_blank'
                  )
                }}
              >
                Editar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}
