import {useEffect, useState} from 'react'
import {LoadingSpinner} from '../../../../../utils/loading/loadingSpinner'
import {getPaymentOption} from '../../../../sales/create/core/_request'
import {ISelectPaymentOption} from '../../../../../coreGlobal/models/default'
import {useFranchiseTransferCart} from '../core/FranchiseTransferProvider'
import {KTIcon} from '../../../../../_metronic/helpers'
import {formatMask} from '../../../../../utils/formatMonetaryToBr'
import {getPaymentOptionTransfer} from '../core/_request'

const AUTOMATIC_PAYMENTS = [1, 2, 3, 4]

const methodSize = (length, index) => {
  if (index == 0 && length === 1) {
    return 'col-10 p-0'
  }

  if (index == 0 && length != 0) {
    return 'col-12 p-0'
  }

  if (index != 0 && index < length - 1) {
    return 'col-10 p-0'
  }

  return 'col-7 p-0'
}
const SwitchAction = ({position, length, addMethodPayment, removeMethodPayment}) => {
  if (position === 1 && length > 1) return <></>
  if (position == 1 && length == 1) {
    return (
      <div className='col-2 p-0 d-flex align-items-center justify-content-center '>
        <span
          onClick={() => addMethodPayment('DESTINATION')}
          className='btn btn-light-danger font-weight-bold w-auto'
        >
          <KTIcon iconName='plus' className='svg-icon svg-icon-danger' />
        </span>
      </div>
    )
  }

  if (position !== 0 && position !== length) {
    return (
      <div className='col-2 p-0 d-flex align-items-center justify-content-center '>
        <span
          onClick={() => removeMethodPayment()}
          className='btn btn-light-danger font-weight-bold w-auto'
        >
          <KTIcon iconName='trash' className='svg-icon svg-icon-danger' />
        </span>
      </div>
    )
  }

  if (position !== 0 && position === length) {
    return (
      <>
        <div className='col-2 p-0 ms-2 me-2 d-flex align-items-center justify-content-center '>
          <span
            onClick={() => addMethodPayment('DESTINATION')}
            className='btn btn-light-danger font-weight-bold w-auto'
          >
            <KTIcon iconName='plus' className='svg-icon svg-icon-danger' />
          </span>
        </div>
        <div className='col-2 p-0 d-flex align-items-center justify-content-center '>
          <span
            onClick={() => removeMethodPayment()}
            className='btn btn-light-danger font-weight-bold w-auto'
          >
            <KTIcon iconName='trash' className='svg-icon svg-icon-danger' />
          </span>
        </div>
      </>
    )
  }

  return <></>
}
export const PaymentRecurrentClientDestination = () => {
  const [paymentOption, setStates] = useState<ISelectPaymentOption[] | undefined>([])
  const [loadingPage, setLoadingPage] = useState(false)
  const [optionInstallment, setOptionInstallment] = useState<any>()
  const {franchiseDestination} = useFranchiseTransferCart()

  useEffect(() => {
    async function init() {
      setLoadingPage(true)
      try {
        const data = await getPaymentOptionTransfer(String(franchiseDestination))
        const dataTyped = data?.map((item) => {
          const newData = {
            value: item.id,
            label: item.name,
            numberInstallment: item.maxInstallments,
            installment_enabled: item.installmentEnabled,
          }
          return newData
        })

        setStates(dataTyped)
      } catch (error) {
      } finally {
        setLoadingPage(false)
      }
    }

    init()
  }, [])
  const {
    billingDestination,
    addMethodPaymentBilling,
    removeMethodPaymentBilling,
    handleChangeTypeMethodPaymentBilling,
    handleChangeValueMethodPaymentBilling,
    handleChangeInstallmentsBilling,
  } = useFranchiseTransferCart()

  const hasInstallment = (id, index, e) => {
    const data = paymentOption?.find((val) => {
      return val.value == id && val.installment_enabled == true
    })

    if (data !== undefined) {
      handleChangeTypeMethodPaymentBilling(index, e, true, 'DESTINATION')
      const installmentsArray = Array.from(
        {length: Number(data?.numberInstallment)},
        (_, i) => i + 1
      )
      setOptionInstallment(installmentsArray)
      return
    }

    handleChangeTypeMethodPaymentBilling(index, e, false, 'DESTINATION')
  }

  return (
    <>
      {loadingPage ? (
        <LoadingSpinner title={'Carregando forma de pagamento'} />
      ) : (
        billingDestination?.map((pay, index) => {
          const billingMethodSize = methodSize(billingDestination.length, index)

          return (
            <div className='mb-8 w-100'>
              <label>{index + 1}. Forma:</label>
              <div className='row m-0 col-12'>
                <div className={billingMethodSize}>
                  <select
                    className='form-select form-select-lg form-control form-control-lg form-control-solid mb-3 mb-lg-0 border border-dark'
                    value={pay.method}
                    onChange={(e) => {
                      hasInstallment(e.target.value, index, e)
                    }}
                  >
                    {paymentOption?.some((option) =>
                      AUTOMATIC_PAYMENTS.includes(Number(option.value))
                    ) && (
                      <optgroup label='Automáticos:'>
                        {paymentOption
                          .filter((option) => AUTOMATIC_PAYMENTS.includes(Number(option.value)))
                          .map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                      </optgroup>
                    )}

                    {paymentOption?.some(
                      (option) => !AUTOMATIC_PAYMENTS.includes(Number(option.value))
                    ) && (
                      <optgroup label='Manuais:'>
                        {paymentOption
                          .filter((option) => !AUTOMATIC_PAYMENTS.includes(Number(option.value)))
                          .map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                      </optgroup>
                    )}
                  </select>
                </div>
                <SwitchAction
                  position={index + 1}
                  length={billingDestination.length}
                  addMethodPayment={addMethodPaymentBilling}
                  removeMethodPayment={() => {
                    removeMethodPaymentBilling(pay.paymentRef, 'DESTINATION')
                  }}
                />
              </div>
              <div className='row mt-2 m-0'>
                <div className={'col-8 p-0'}>
                  <label>Valor:</label>
                  <input
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 border border-dark col-5'
                    value={formatMask(pay.amount)}
                    onChange={(e) => {
                      handleChangeValueMethodPaymentBilling(
                        index,
                        formatMask(e.target.value),
                        'DESTINATION'
                      )
                    }}
                  />
                </div>

                {pay.hasInstallment && (
                  <div className='col-4 p-0 ps-2'>
                    <label>N. Parcelas:</label>
                    <select
                      className='form-select form-select-lg form-control form-control-lg form-control-solid mb-3 mb-lg-0 border border-dark'
                      value={pay.installments}
                      onChange={(e) =>
                        handleChangeInstallmentsBilling(index, e.target.value, 'DESTINATION')
                      }
                    >
                      {optionInstallment.map((val) => (
                        <option value={val}>{val}</option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
            </div>
          )
        })
      )}
    </>
  )
}
