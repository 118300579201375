/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {TransferResponseList} from '../../core/_models'
import {formatDateTime} from '../../../../../utils/dayJs/format'

type Props = {
  transfer: TransferResponseList
}

const CreatedInCell: FC<Props> = ({transfer}) => (
  <div className='d-flex flex-column'>
    <a href='#' className='text-gray-800 text-hover-danger mb-1'>
      {transfer.createdAt ? formatDateTime(transfer.createdAt.toString()) : 'N/A'}
    </a>
  </div>
)

export {CreatedInCell}
