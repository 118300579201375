import {KTCard} from '../../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {PageOverlayer} from '../../../../components/PageOverlayer'
import {Header} from './components/header/Header'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider, useQueryResponseLoading} from './core/QueryResponseProvider'
import {FilterProvider} from './FilterProvider'
import {Table} from './table/Table'
import {Totalizer} from './Totalizer'

const groupBreadcrumbs: Array<PageLink> = [
  {
    title: 'Relatório de Usuários',
    path: '/reports/reportUser/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ReportUserPage = () => {
  const {itemIdForUpdate} = useListView()
  const isLoading = useQueryResponseLoading()
  return (
    <>
      <PageTitle breadcrumbs={groupBreadcrumbs}>Relatório de Usuários</PageTitle>
      <QueryRequestProvider>
        <QueryResponseProvider>
          <ListViewProvider>
            <>
              <FilterProvider>
                {isLoading && <PageOverlayer />}
                <KTCard>
                  <Header />
                  <Totalizer />
                  <Table />
                </KTCard>
                {itemIdForUpdate !== undefined}
              </FilterProvider>
            </>
          </ListViewProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  )
}

export default ReportUserPage
