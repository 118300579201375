// @ts-nocheck
import clsx from 'clsx'
import { FC } from 'react'
import { Row } from 'react-table'
import { User } from '../../core/_models'
import { useNavigate } from 'react-router-dom'
import { useListView } from '../../core/ListViewProvider'
import Swal from 'sweetalert2'
import { deleteGroup } from '../../core/_requests'
import useAuthorization from '../../../../../hooks/useAuthorization'
import { TooltipWrapper } from '../../../../../components/Tooltip'

type Props = {
  row: Row<User>
}
// eslint-disable-next-line react-hooks/rules-of-hooks

const CustomRow: FC<Props> = ({ row }) => {
  const id = String(row.original.id) as string
  const name = String(row.original.name) as string

  const navigate = useNavigate()
  const isAuthorizedToView = useAuthorization('groups.view')
  const handleToShow = () => navigate(`/users/groups/list/${id}`)

  const isAuthorizedToUpdate = useAuthorization('groups.update')
  const { setItemIdForUpdate } = useListView()
  const handleToEdit = () => {
    setItemIdForUpdate(id)
  }

  const isAuthorizedToDelete = useAuthorization('groups.delete')
  const handleRemoveGroup = () => {
    Swal.fire({
      title: `Deseja deletar o Grupo ${name}?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-danger', // Classe personalizada para o botão de confirmação
        cancelButton: 'btn btn-secondary' // Classe personalizada para o botão de cancelamento (opcional)
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteGroup(id)
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Deletado com sucesso',
            showConfirmButton: false,
            timer: 1500,
          })
        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = ''
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>'
            }
            Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText,
            })
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 2000,
            })
          }
        } finally {
          window.location.reload()
        }
        return
      }
    })
  }

  return (
    <tr {...row.getRowProps()}>
      {row.cells.map((cell) => {
        return (
          <td
            {...cell.getCellProps()}
            className={clsx({ 'text-end min-w-100px': cell.column.id === 'actions' })}
          >
            {cell.render('Cell')}
          </td>
        )
      })}

      <td className='text-center min-w-100px'>
        {isAuthorizedToView && (
          <TooltipWrapper
            tooltipText={'Visualizar'}
            tooltipPlacement={'top'}
          >
            <button className="btn btn-link btn-color-muted btn-active-color-danger" onClick={() => handleToShow()}>
              <i className="ki-duotone ki-book-open me-5 fs-1">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                <span className="path4"></span>
              </i>
            </button>
          </TooltipWrapper>
        )}
        {isAuthorizedToUpdate && (
          <TooltipWrapper
            tooltipText={'Editar'}
            tooltipPlacement={'top'}
          >
            <button className="btn btn-link btn-color-muted btn-active-color-danger" onClick={() => handleToEdit()}>
              <i className="ki-duotone ki-pencil me-5 fs-1">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </button>
          </TooltipWrapper>
        )}
        {
          isAuthorizedToDelete &&
          <TooltipWrapper
            tooltipText={'Excluir'}
            tooltipPlacement={'top'}
          >
            <button className="btn btn-link btn-color-muted btn-active-color-danger" onClick={() => {
              handleRemoveGroup()
            }}>
              <i className="ki-duotone ki-trash me-5 fs-1">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                <span className="path4"></span>
                <span className="path5"></span>
              </i>
            </button>
          </TooltipWrapper>
        }
      </td>
    </tr>
  )
}
export { CustomRow }
