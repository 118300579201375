/* eslint-disable jsx-a11y/anchor-is-valid */
import {useMemo} from 'react'
import moment from 'moment'
import {useDashboard} from '../../../hooks/useDashboard'
import {formatMonetaryToBr} from '../../../utils/formatMonetaryToBr'
import {capitalizeWords} from '../../../coreGlobal/Helpers/utils'
import {ChartSalesByProduct} from './charts/ChartSalesByProduct'

interface Sale {
  id: number
  name: string
  total_price: string
}

interface SalesProps {
  className: string
}

export function Sales({ className }: SalesProps) {
  const {currentInitDta, currentEndDta, query} = useDashboard()

  const salesByProduct = query.data?.sales?.itens ?? []
  const totalPriceSales = query.data?.sales?.total ?? 0
  const isLoading = query.isLoading;

  const verifyMouth = useMemo(() => {
    if (!currentInitDta || !currentEndDta) return

    const mouthText = moment(currentInitDta).format('MMMM')
    const mouthsText = mouthText + ' - ' + moment(currentEndDta).format('MMMM')

    if (moment(currentInitDta).month() == moment(currentEndDta).month()) {
      return mouthText
    }
    return mouthsText
  }, [currentInitDta, currentEndDta])

  return (
    <div className={`card card-flush ${className}`}>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>
              {formatMonetaryToBr(totalPriceSales)}
            </span>
          </div>
          <span className='text-gray-400 pt-1 fw-semibold fs-6'>
            Receita de {capitalizeWords(verifyMouth)}
          </span>
        </div>
      </div>

      { isLoading && 
      <div className='card-body pt-1 pb-4 d-flex flex-wrap flex-center p-4 px-10'>
        <div className='d-flex flex-center w-100'>
        <div className="text-center w-100 align-items-center justify-content-center">
                <span>Carregando...</span>
              </div>
        </div>
      </div>
      } 
      { !isLoading && 
      <div className='card-body pt-1 pb-4 d-flex flex-wrap flex-start p-4 px-10'>
        <div className='d-flex flex-center w-100'>
            <ChartSalesByProduct className='w-100 h-100' sales={salesByProduct} />
        </div>
      </div>
      }  
    </div>
  )
}
