import { useMemo } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { CustomHeaderColumn } from './columns/CustomHeaderColumn'
import { CustomRow } from './columns/CustomRow'
import { useQueryResponseData, useQueryResponseLoading, useQueryTotalsData } from '../core/QueryResponseProvider'
import { ReportSalesColumns } from './columns/_columns'
import { SaleReports } from '../core/_models'
import { ListLoading } from '../components/loading/ListLoading'
import { ListPagination } from '../components/pagination/ListPagination'
import { KTCardBody } from '../../../../../_metronic/helpers'

const Table = () => {
    const user = useQueryResponseData()
    const isLoading = useQueryResponseLoading()
    const data = useMemo(() => user, [user])
    const columns = useMemo(() => ReportSalesColumns, [])
    const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
        columns,
        data,
    })

    return (
        <KTCardBody className='py-4'>
            <div className='table-responsive mb-10'>
                <table
                    id='kt_table_reportSales'
                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    {...getTableProps()}
                >
                    <thead>
                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                            {headers.map((column: ColumnInstance<SaleReports>) => (
                                <CustomHeaderColumn key={column.id} column={column} />
                            ))}
                        </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                        {rows.length > 0 ? (
                            rows.map((row: Row<SaleReports>, i) => {

                                prepareRow(row)
                                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                            })
                        ) : (
                            <tr>
                                <td colSpan={7}>
                                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                        Nenhum registro encontrado
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <ListPagination />
            {isLoading && <ListLoading />}
        </KTCardBody>
    )
}

export { Table }
