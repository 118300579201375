import {useEffect, useState} from 'react'
import {useFranchiseTransferCart} from '../core/FranchiseTransferProvider'
import {FTPaymentClientOriginModal} from './FTPaymentClientOriginModal'
import {FTPaymentClientDestinationModal} from './FTPaymentClientDestinationModal'
import {formatToDecimal} from '../Utils/formatMask'
import {formatMonetaryToBr} from '../../../../../utils/formatMonetaryToBr'

export const FTChoicePayment = ({formik}) => {
  const {dataCalculate, setHiddenButton, transferFineFranchse, amountFine} =
    useFranchiseTransferCart()
  const [openPaymentClientOriginModal, setOpenPaymentClientOriginModal] = useState(false)
  const [openPaymentClientDestinationModal, setOpenPaymentClientDestinationModal] = useState(false)
  const [valueOrigin, setValueOrigin] = useState(dataCalculate?.clientTransferValueToOrigin)
  const [valueDestination, setValueDestination] = useState(
    dataCalculate?.clientTransferValueToDestination
  )

  const verifyFineDestination = () => {
    if (transferFineFranchse === 1) {
      let origVal = dataCalculate?.clientTransferValueToOrigin + Number(formatToDecimal(amountFine))
      setValueOrigin(origVal)
      setValueDestination(dataCalculate?.clientTransferValueToDestination)
      return
    }
    if (transferFineFranchse === 2) {
      let destinVal =
        dataCalculate?.clientTransferValueToDestination + Number(formatToDecimal(amountFine))
      setValueDestination(destinVal)
      setValueOrigin(dataCalculate?.clientTransferValueToOrigin)
      return
    }
    setValueDestination(dataCalculate?.clientTransferValueToDestination)
    setValueOrigin(dataCalculate?.clientTransferValueToOrigin)
  }

  useEffect(() => {
    verifyFineDestination()
  }, [
    transferFineFranchse,
    amountFine,
    formik.values.hasClientOriginValue,
    formik.values.hasClientDestinationValue,
  ])
  return (
    <>
      {openPaymentClientOriginModal && (
        <>
          <FTPaymentClientOriginModal
            close={() => {
              setOpenPaymentClientOriginModal(false)
              setHiddenButton(false)
            }}
            formik={formik}
            data={valueOrigin}
          />
        </>
      )}

      {openPaymentClientDestinationModal && (
        <FTPaymentClientDestinationModal
          close={() => {
            setOpenPaymentClientDestinationModal(false)
            setHiddenButton(false)
          }}
          formik={formik}
          data={valueDestination}
        />
      )}

      {!openPaymentClientOriginModal && !openPaymentClientDestinationModal && (
        <>
          <div className='container'>
            <div className='card-body pt-9 pb-0'>
              <div className='card-body py-7'>
                <div className='row align-items-center mb-4'>
                  <div className='col'>
                    <h1>Entre unidades</h1>
                    <div className='col-5 text-center fs-3 fw-bold mt-3'>
                      Valor total repasse:{' '}
                      {formatMonetaryToBr(dataCalculate?.franchiseTransferValue)}
                    </div>
                  </div>
                </div>

                <div className='row align-items-center mt-12'>
                  <div className='col'>
                    <h1>Cliente</h1>
                    <div className='row align-items-center mt-3'>
                      <div className='col-6 text-center fs-3 fw-bold'>
                        Valor devido Origem: {formatMonetaryToBr(valueOrigin)}
                      </div>

                      <div className='col text-end'>
                        <button
                          className='btn bg-redlaser text-white'
                          type='button'
                          disabled={valueOrigin != 0 ? false : true}
                          onClick={() => {
                            setOpenPaymentClientOriginModal(true)
                            setHiddenButton(true)
                          }}
                        >
                          Selecionar Pagamento
                        </button>
                      </div>
                    </div>

                    <div className='row align-items-center mt-3'>
                      <div className='col text-center fs-3 fw-bold'>
                        Valor devido Destino: {formatMonetaryToBr(valueDestination)}
                      </div>

                      <div className='col-6 text-end'>
                        <button
                          className='btn bg-redlaser text-white'
                          type='button'
                          disabled={valueDestination != 0 ? false : true}
                          onClick={() => {
                            setOpenPaymentClientDestinationModal(true)
                            setHiddenButton(true)
                          }}
                        >
                          Selecionar Pagamento
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
