// @ts-nocheck
import { Column } from 'react-table'
import { CustomHeader } from './CustomHeader'
import { SelectionHeader } from './SelectionHeader'
import { User } from '../../core/_models'
import { CustonCell } from './CustonCell'
import { formatMask } from '../../../../../../utils/formatMonetaryToBr'
import { formatDateTime } from '../../../../../../utils/dayJs/format'
import { PaymentHeader } from './PaymentHeader'

const ReportSalesColumns: ReadonlyArray<Column<User>> = [

  {
    Header: (props) => <CustomHeader tableProps={props} title='Contrato' className='min-w-125px' />,
    id: 'contract',
    Cell: ({ ...props }) => <CustonCell value={props.data[props.row.index].contract} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Status' className='min-w-125px' />,
    id: 'statusName',
    Cell: ({ ...props }) => <CustonCell value={props.data[props.row.index].statusName} />,
  },

  {
    Header: (props) => <CustomHeader tableProps={props} title='Dt Contrato' className='min-w-125px' />,
    id: 'createdAt',
    Cell: ({ ...props }) => <CustonCell value={formatDateTime(props.data[props.row.index].createdAt)} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='valor desconto' className='min-w-50px' />,
    id: 'discount',
    Cell: ({ ...props }) => <CustonCell value={formatMask(props.data[props.row.index].discount)} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='nome cliente' className='min-w-125px' />
    ),
    id: 'client_name',
    Cell: ({ ...props }) => <CustonCell value={props.data[props.row.index].clientName} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='unidade' className='min-w-125px' />
    ),
    id: 'franchiseName',
    Cell: ({ ...props }) => <CustonCell value={`${props.data[props.row.index].franchiseName}`} />,
  },
  {
    Header: (props) => (
      <PaymentHeader tableProps={props} title='Formas de pagamento' className='min-w-125px' />
    ),
    id: 'payments',
    Cell: ({ ...props }) => <CustonCell value={
      <div className='card-body py-3 mt-3'>
        <div className='table-responsive'>
          <table
            className='table table-bordered table-hover table-row-bordered table-row-gray-100'
          >
            <tbody>
              {props?.data[props.row.index]?.payments?.map((item, index) => (
                <tr key={`payments${index}`}>
                  <td>
                    {item.paymentOptions}
                  </td>
                  <td>
                    {formatMask(item.price)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    } />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Vr total' className='min-w-125px' />
    ),
    id: 'totalPrice',
    Cell: ({ ...props }) => <CustonCell value={formatMask(props.data[props.row.index].totalPrice)} />,
  },

]

export { ReportSalesColumns }
