import React, {useEffect, useState} from 'react'
import {MedicalConditionFormProv} from '../core/FormProvider'
import {LoadingSpinner} from '../../../utils/loading/loadingSpinner'
import {MultiSelect} from 'react-multi-select-component'
interface IMedicalCondition {
  formik: any
}

const MedicalConditionForm: React.FC<IMedicalCondition> = ({formik}) => {
  const {
    categories,
    getDataCategories,
    products,
    selectsProducts,
    setSelectsProducts,
    getDataProducts,
    selectsCategories,
    setSelectsCategories,
    setProducts,
    checkboxClockNewSales,
    setCheckboxClockNewSales,
    checkboxBlockNewAppointments,
    setCheckboxblockNewAppointments,
    checkboxBlockExistingAppointments,
    setCheckBlockExistingAppointments,
    hideCategory,
    setHideCategory,
    hideProduct,
    setHideProduct,
  } = MedicalConditionFormProv()

  const [loadingProduct, setLoadingProduct] = useState(false)
  const [loadingCategory, setLoadingCategory] = useState(false)

  function statusChange(value: string) {
    switch (value) {
      case 'RESTRICTED':
        setHideCategory(true)
        setLoadingProduct(true)
        setCheckboxClockNewSales(true)
        setCheckboxblockNewAppointments(true)
        setCheckBlockExistingAppointments(true)
        formik.setFieldValue('blockNewSales', true)
        formik.setFieldValue('blockNewAppointments', true)
        formik.setFieldValue('blockExistingAppointments', true)
        getDataCategories()
        setLoadingProduct(false)
        break
      default:
        setHideCategory(false)
        setHideProduct(false)
        formik.setFieldValue('products', [])
        formik.setFieldValue('categories', [])
        setSelectsCategories([])
        setSelectsProducts([])
        setCheckboxClockNewSales(false)
        setCheckboxblockNewAppointments(false)
        setCheckBlockExistingAppointments(false)
        formik.setFieldValue('blockNewSales', false)
        formik.setFieldValue('blockNewAppointments', false)
        formik.setFieldValue('blockExistingAppointments', false)
        break
    }
  }

  function categoryChange(value: number[]) {
    if (value.length) {
      setHideProduct(true)
      setLoadingCategory(true)
      getDataProducts(String(value))
      setLoadingCategory(false)
    } else {
      formik.setFieldValue('products', [])
      setSelectsProducts([])
      setHideProduct(false)
      setProducts([{value: '', label: ''}])
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case 'checkboxClockNewSales':
        formik.setFieldValue('blockNewSales', event.target.checked)
        setCheckboxClockNewSales(event.target.checked)
        break
      case 'checkboxBlockNewAppointments':
        formik.setFieldValue('blockNewAppointments', event.target.checked)
        setCheckboxblockNewAppointments(event.target.checked)
        break
      case 'checkboxBlockExistingAppointments':
        formik.setFieldValue('blockExistingAppointments', event.target.checked)
        setCheckBlockExistingAppointments(event.target.checked)
        break
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        role='button'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Dados:</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <div className='card-body border-top p-9'>
          <div className='row mb-6'>
            <label className='col-lg-2 col-form-label fw-bold fs-6'>
              <span className='required'>Nome</span>
            </label>

            <div className='col-lg-10 fv-row'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='Nome da condição médica'
                {...formik.getFieldProps('name')}
                maxLength={15}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors?.name}</div>
                </div>
              ) : null}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-2 col-form-label fw-bold fs-6'>
              <span>Descrição</span>
            </label>
            <div className='col-lg-10 fv-row'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='Informe uma descrição'
                {...formik.getFieldProps('description')}
                maxLength={255}
              />
              {formik.touched.description && formik.errors.description ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors?.description}</div>
                </div>
              ) : null}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-2 col-form-label fw-bold fs-6'>
              <span className='required'>Status</span>
            </label>
            <div className='col-lg-10 fv-row'>
              <select
                className='form-select form-select-lg form-select-solid'
                {...formik.getFieldProps('status')}
                onChange={(e) => {
                  const value = e.target.value
                  formik.setFieldValue('status', value)
                  statusChange(value)
                }}
              >
                <option value=''>Selecione o Status</option>
                <option value='ACTIVE'>Ativo</option>
                <option value='RESTRICTED'>Bloqueado</option>
              </select>
              {formik.touched?.status && formik.errors?.status ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors?.status}</div>
                </div>
              ) : null}
            </div>
          </div>
          {hideCategory ? (
            loadingCategory ? (
              <LoadingSpinner title={'Carregando categoria'} />
            ) : (
              <>
                <div className='row mb-12'>
                  <div className='column col-lg-4'>
                    <label className='col-lg-12 col-form-label fw-bold fs-6'>
                      <span className='required'>Bloquear novas vendas</span>
                    </label>
                    <div className='col-lg-12 fv-row'>
                      <div className='form-check form-switch form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='checkboxClockNewSales'
                          checked={checkboxClockNewSales}
                          onChange={handleChange}
                        />
                        {formik.errors?.blockNewSales ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors?.blockNewSales}</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className='column col-lg-4'>
                    <label className='col-lg-12 col-form-label fw-bold fs-6'>
                      <span className='required'>Bloquear novos agendamentos</span>
                    </label>
                    <div className='col-lg-12 fv-row'>
                      <div className='form-check form-switch form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='checkboxBlockNewAppointments'
                          checked={checkboxBlockNewAppointments}
                          onChange={handleChange}
                        />
                        {formik.errors?.blockNewSales ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors?.blockNewSales}</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className='column col-lg-4'>
                    <label className='col-lg-12 col-form-label fw-bold fs-6'>
                      <span className='required'>Bloquear agendamentos Existentes</span>
                    </label>
                    <div className='col-lg-12 fv-row'>
                      <div className='form-check form-switch form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='checkboxBlockExistingAppointments'
                          checked={checkboxBlockExistingAppointments}
                          onChange={handleChange}
                        />
                        {formik.errors?.blockNewSales ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors?.blockNewSales}</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-2 col-form-label fw-bold fs-6'>
                    <span className='required'>Categoria</span>
                  </label>
                  <div className='col-lg-3 fv-row'>
                    <MultiSelect
                      className='react-select-styled react-select-solid'
                      options={categories}
                      value={selectsCategories}
                      onChange={(option) => {
                        const values = option?.map((item) => item.value)
                        formik.setFieldValue('categories', values)
                        setSelectsCategories(option)
                        const selectedCategories = option.reduce((result, item) => {
                          return `${result}${item.value},`
                        }, '')
                        categoryChange(values)
                        getDataProducts(selectedCategories)
                      }}
                      labelledBy=''
                      overrideStrings={{
                        selectSomeItems: 'Selecione a(as) categorias',
                        search: 'Procurar',
                        selectAll: 'Selecionar todos',
                        allItemsAreSelected: 'Todos itens selecionados',
                      }}
                    />
                    {formik.errors?.categories ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors?.categories}</div>
                      </div>
                    ) : null}
                  </div>
                  {hideProduct ? (
                    loadingProduct ? (
                      <LoadingSpinner title={'Carregando produtos'} />
                    ) : (
                      <>
                        <label className='col-lg-1 col-form-label fw-bold fs-6'>
                          <span className='required'>Produtos</span>
                        </label>
                        <div className='col-lg-6 fv-row'>
                          <MultiSelect
                            className='react-select-styled react-select-solid'
                            options={products}
                            value={selectsProducts}
                            onChange={(option) => {
                              const valores = option.map((item) => item.value)
                              formik.setFieldValue('products', valores)
                              setSelectsProducts(option)
                            }}
                            labelledBy=''
                            overrideStrings={{
                              selectSomeItems: 'Selecione o(os) items',
                              search: 'Procurar',
                              selectAll: 'Selecionar todos',
                              allItemsAreSelected: 'Todos itens selecionados',
                            }}
                          />
                          {formik.errors?.products ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors?.products}</div>
                            </div>
                          ) : null}
                        </div>
                      </>
                    )
                  ) : null}
                </div>
              </>
            )
          ) : null}
        </div>
      </div>
    </div>
  )
}

export {MedicalConditionForm}
