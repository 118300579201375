import {useFormik} from 'formik'
import {useNavigate, useParams} from 'react-router-dom'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import {useEffect, useState} from 'react'
import {TProductRequest} from '../../coreGlobal/models/products/product'
import {updateProduct} from './core/_request'
import Swal from 'sweetalert2'
import {formatMask} from '../../utils/formatMonetaryToBr'
import {LoadingSpinner} from '../../utils/loading/loadingSpinner'
import {buildCreateRequest} from './core/buildRequest'
import {productSchema} from './core/validation/ProductsVali'
import {FormProvider, useForm} from './core/FormProvider'
import {ProductForm} from './components/ProductForm'
import {PageOverlayer} from '../../components/PageOverlayer'

const ProductsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Produtos',
    path: '/products',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const Update = () => {
  let {id} = useParams()
  const [loadingPage, setLoadingPage] = useState(false)

  const {
    categories,
    getDataCategories,
    setCategory,
    contractTerms,
    getDataContractTerms,
    setContractTerm,
    product,
    getDataProduct,
    setLoading,
    setActive,
  } = useForm()

  const navigate = useNavigate()
  const [productInicitalValues] = useState<TProductRequest>({
    contractTerm: '',
    category: '',
    name: '',
    gender: '',
    price: '',
    packagePrice: '',
    sessionTime: '',
    minDaysBetweenSessions: undefined,
    active: true,
    discount: 0,
  })

  const formik = useFormik({
    initialValues: productInicitalValues,
    validationSchema: productSchema,
    onSubmit: async (values) => {
      const valuesFilter = await buildCreateRequest(values)
      submit(valuesFilter)
    },
  })
  useEffect(() => {
    const init = async () => {
      setLoadingPage(true)
      getDataCategories()
      getDataContractTerms()
      getDataProduct(String(id))
      setLoadingPage(false)
    }
    init()
  }, [])
  useEffect(() => {
    const init = async () => {
      if (product) {
        formik.setValues({
          name: product.name,
          gender: product.gender,
          price: formatMask(product.price),
          packagePrice: formatMask(product.packagePrice),
          category: product.category_id,
          sessionTime: product.sessionTime ? product.sessionTime : '',
          contractTerm: product.contractTerm.id,
          minDaysBetweenSessions: product.minDaysBetweenSessions,
          active: product.active,
          discount: product.defaultMaxDiscount,
        })

        setActive(product.active)

        setCategory(
          categories?.find((category) => {
            return category.value == product.category_id
          })
        )

        setContractTerm(
          contractTerms?.find((contractTerm) => {
            return contractTerm.value == product.contractTerm.id
          })
        )
      }
    }
    init()
  }, [product])

  const submit = async (req: TProductRequest) => {
    await Swal.fire({
      title: 'Deseja atualizar o Produto?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-danger', // Classe personalizada para o botão de confirmação
        cancelButton: 'btn btn-secondary', // Classe personalizada para o botão de cancelamento (opcional)
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true)
        try {
          await updateProduct(String(id), req)
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Salvo com sucesso',
            showConfirmButton: false,
            timer: 1500,
          })
          navigate('/products/list', {replace: true})
        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = ''
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>'
            }
            await Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText,
            })
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 2000,
            })
          }
        } finally {
          setLoading(false)
        }
      }
    })
  }

  return <>{loadingPage ? <PageOverlayer /> : <ProductForm formik={formik} />}</>
}

export const ProductEditPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={ProductsBreadcrumbs}>Atualizar Produto</PageTitle>
      <FormProvider>
        <Update />
      </FormProvider>
    </>
  )
}
