/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useMemo} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {formatMonetaryToBr} from '../../../utils/formatMonetaryToBr'
import {useDashboard} from '../../../hooks/useDashboard'

interface ISellerMan {
  id: number
  name: string
  total_price: string
  total_sold: number
  sexType?: 'M' | 'F'
}

type SellerTableProps = {
  className: string
}

export function SellerTable({className}: SellerTableProps) {
  const {
    query: {data, isLoading},
  } = useDashboard()

  const sellers = data?.topSellers.itens ?? []
  const total = data?.topSellers.total ?? 0

  const totalOfSells = `${total ?? 0} itens vendidos`

  const hasData = !isLoading && sellers && sellers?.length > 0
  const hasNotData = !isLoading && sellers && sellers.length == 0
  const getFormattedName = (name: string): string => {
    if (!name) return ''

    const nameParts = name.trim().split(' ')

    if (nameParts.length === 1) {
      return nameParts[0].toUpperCase()
    }

    const firstName = nameParts[0]
    const lastName = nameParts[nameParts.length - 1]

    return `${firstName.toUpperCase()} ${lastName.toUpperCase()}`
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>TOP Consultores de vendas</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Total: {totalOfSells}</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 overflow-hidden'>
        {/* begin::Table container */}
        {hasData && (
          <div className='table-responsive' style={{height: 460, overflowY: 'auto'}}>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <tbody>
                {sellers?.map((seller) => (
                  <tr key={seller.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bold text-hover-danger fs-6'>
                            {getFormattedName(seller.name)}
                          </a>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            Nome
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className='text-dark fw-bold text-hover-danger d-block fs-6'>
                        {seller.total_sold}
                      </span>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>Volume</span>
                    </td>
                    <td>
                      <span className='text-dark fw-bold text-hover-danger d-block fs-6'>
                        {formatMonetaryToBr(seller.total_price)}
                      </span>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        Valor total
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {isLoading && (
          <div
            className='text-center w-100 align-items-center justify-content-center'
            style={{height: 460, display: `flex`}}
          >
            <span>Carregando...</span>
          </div>
        )}
        {hasNotData && (
          <div className='text-center flex flex-center' style={{height: 460, display: `flex`}}>
            <span style={{alignSelf: `center`}}>Nenhum dado disponível</span>
          </div>
        )}
      </div>
    </div>
  )
}
