import {useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import {useNavigate} from 'react-router-dom'
import {TProductRequest} from '../../coreGlobal/models/products/product'
import {useFormik} from 'formik'
import {productSchema} from './core/validation/ProductsVali'
import {createProduct} from './core/_request'
import Swal from 'sweetalert2'
import {buildCreateRequest} from './core/buildRequest'
import {ProductForm} from './components/ProductForm'
import {FormProvider, useForm} from './core/FormProvider'

const ProductsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Produtos',
    path: '/produts',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const Create = () => {
  const {getDataCategories, getDataContractTerms, setLoading} = useForm()
  const navigate = useNavigate()

  const [productInicialValues] = useState<TProductRequest>({
    contractTerm: '',
    category: '',
    name: '',
    gender: '',
    price: '',
    sessionTime: '',
    minDaysBetweenSessions: undefined,
    active: true,
    discount: 0,
  })

  const formik = useFormik({
    initialValues: productInicialValues,
    validationSchema: productSchema,
    onSubmit: async (values) => {
      const valuesFilter = await buildCreateRequest(values)
      submit(valuesFilter)
    },
  })

  useEffect(() => {
    try {
      getDataCategories()
      getDataContractTerms()
    } catch (error: any) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: error?.response?.data?.message,
        showConfirmButton: true,
      })
    }
  }, [])

  const submit = async (req: TProductRequest) => {
    await Swal.fire({
      title: 'Deseja criar o Produto?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true)
        try {
          await createProduct(req)
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Salvo com sucesso',
            showConfirmButton: false,
            timer: 1500,
          })
          navigate('/products/list', {replace: true})
        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = ''
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>'
            }
            await Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText,
            })
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 2000,
            })
          }
        } finally {
          setLoading(false)
        }
      }
    })
  }

  return (
    <>
      <ProductForm formik={formik} />
    </>
  )
}
export const ProductCreatePage = () => {
  return (
    <>
      <PageTitle breadcrumbs={ProductsBreadcrumbs}>Criar Produto</PageTitle>
      <FormProvider>
        <Create />
      </FormProvider>
    </>
  )
}
