import clsx from 'clsx'
import {toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'
import {useAuth} from '../../../../app/modules/auth'
import {useEffect, useMemo, useState} from 'react'
import {chooseUserFranchise} from '../../../../pages/chooseFranchise/core/_request'
import {Franchise} from '../../../../pages/franchise/list/core/_models'
import {getFranchises} from '../../../../pages/chooseFranchise/core/_request'
import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'
import {MenuComponent} from '../../../assets/ts/components'
import {HeaderNotificationsMenuNav} from './header-menus/HeaderNotifications'

const itemClass = 'ms-1 ms-md-4'
const userAvatarClass = 'symbol-35px'

const Navbar = () => {
  const [loadingPage, setLoadingPage] = useState(false)
  const {currentUser, currentFranchise} = useAuth()
  const [franchises, setFranchises] = useState([])
  const [newNotification, setNewNotification] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (currentUser?.firstLogin) {
      return
    }

    async function init() {
      setLoadingPage(true)
      const franchises = await getFranchises()
      setFranchises(franchises)
      setLoadingPage(false)
    }
    init()
  }, [currentUser])

  const chooseFranchise = async (value) => {
    await Swal.fire({
      title: 'Deseja trocar de Franquia?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-danger', // Classe personalizada para o botão de confirmação
        cancelButton: 'btn btn-secondary', // Classe personalizada para o botão de cancelamento (opcional)
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoadingPage(true)
        try {
          await chooseUserFranchise({franchise: value})
          navigate('/dashboard')
          window.location.reload()
        } catch (error) {
          await Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Tente novamente mais tarde!',
            timer: 1500,
          })
          window.location.reload()
        } finally {
          setLoadingPage(false)
        }
      }
    })
  }

  const handleOpenNotificationMenu = () => {
    setNewNotification(false)
  }

  const notificationCount = useMemo(() => {
    if (!currentUser?.unreadNotifications?.length) return

    if (currentUser?.unreadNotifications?.length > 0) {
      setNewNotification(true)
    }
    return currentUser?.unreadNotifications
  }, [currentUser?.unreadNotifications])

  MenuComponent.reinitialization()

  return (
    <>
      <div className='app-navbar flex-shrink-0'>
        <div className='d-flex align-items-center justify-content-center'>
          <div className='btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-danger w-35px h-35px position-relative'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-danger d-flex align-items-center justify-content-center'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
              onClick={handleOpenNotificationMenu} // Chamamos essa função ao clicar no botão
            >
              <i className='ki-duotone ki-message-text-2 fs-2 text-danger'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
              </i>
              {newNotification && (
                <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
              )}
            </button>
            <HeaderNotificationsMenuNav notifications={notificationCount} />
          </div>
        </div>

        {loadingPage ? (
          <div
            className='spinner-border text-dark mt-4'
            role='status'
            style={{width: '3rem', height: '3rem'}}
          ></div>
        ) : (
          <select
            name='chooseFranchise'
            id='franchise_id'
            className='form-select form-select-sm '
            value={currentFranchise?.person?.juridicalPerson?.cnpjWithoutMask}
            onChange={(e) => {
              chooseFranchise(e.target.value)
            }}
          >
            {franchises.map((franchise: Franchise, index) => (
              <option
                key={index}
                value={String(franchise.person?.juridicalPerson?.cnpjWithoutMask)}
              >
                {franchise.name}
              </option>
            ))}
          </select>
        )}
        <div className={clsx('app-navbar-item', itemClass)}>
          <div className='d-none d-md-flex flex-column'></div>
          <div
            className={clsx('cursor-pointer symbol', userAvatarClass)}
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
          >
            <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='' />
          </div>
          <HeaderUserMenu />
        </div>
      </div>
    </>
  )
}

export {Navbar}
