import { initialQueryState, KTIcon } from '../../../../../../_metronic/helpers'
import useAuthorization from '../../../../../../hooks/useAuthorization'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { useState } from 'react'
import { Option } from 'react-multi-select-component'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { downloadServerFile } from '../../core/_requests'
import { useFilterExport } from '../../FilterProvider'
import { ListSearchSubscriptionBirthDate } from './ListSearchSubscriptionBirthDate'
import { ListSearchSubscriptionGender } from './ListSearchSubscriptionGender'
import { ListSearchSubscriptionPhone } from './ListSearchSubscriptionPhone'
import { ListSearchSubscriptionDocument } from './ListSearchSubscriptionDocument'
import { ListSearchSubscriptionClientSuspended } from './ListSearchSubscriptionClientSuspended'
import { ListSearchSubscriptionShortlistClient } from './ListSearchSubscriptionShortlistClient'
import { ListSearchSubscriptionDateRegister } from './ListSearchSubscriptionDateRegister'
import { cleanMask } from '../../../../../../utils/cleanMask'
import qs from 'qs'
const ListToolbar = () => {
    const { filterExport, setFilterExport } = useFilterExport()

    const { updateState } = useQueryRequest()

    const { isLoading } = useQueryResponse()

    const [searchPhone, setSearchPhone] = useState<string>('');
    const [searchDocument, setSearchDocument] = useState<string>('');
    const [searchDateBirthStart, setSearchDateBirthStart] = useState<string>('')
    const [searchDateBirthEnd, setSearchDateBirthEnd] = useState<string>('')
    const [errorsBirth, setErrorsBirth] = useState<string[]>([])


    const [searchDateRegisterStart, setSearchDateRegisterStart] = useState<string>('')
    const [searchDateRegisterEnd, setSearchDateRegisterEnd] = useState<string>('')
    const [errorsRegister, setErrorsRegister] = useState<string[]>([])


    const [subscriptionGenderSearch, setSubscriptionGenderSearch] = useState<string>('');
    const [selectsGender, setSelectsGender] = useState<Option[]>([]);

    const [subscriptionClientSuspendedSearch, setSubscriptionClientSuspendedSearch] = useState<string>('');
    const [selectsClientSuspended, setSelectsClientSuspended] = useState<Option[]>([]);

    const [subscriptionShortlistClientSearch, setSubscriptionShortlistClientSearch] = useState<string>('');
    const [selectsShortlistClient, setSelectsShortlistClient] = useState<Option[]>([]);

    const isAuthorizedToCreate = useAuthorization("users.create");

    const resetData = () => {

        setFilterExport({ phones: '', document: '', birthDateStart: '', birthDateEnd: '', createdAtStart: '', createdAtEnd: '', gender: '', restrict: '', others: '', ...initialQueryState })

        updateState({ phones: '', document: '', birthDateStart: '', birthDateEnd: '', createdAtStart: '', createdAtEnd: '', gender: '', restrict: '', others: '', ...initialQueryState });

        setSearchPhone('');
        setSearchDocument('');
        setSearchDateBirthStart('');
        setSearchDateBirthEnd('');
        setSearchDateRegisterStart('');
        setSearchDateRegisterEnd('');
        setSubscriptionGenderSearch('');
        setSelectsGender([]);

        setSubscriptionClientSuspendedSearch('');
        setSelectsClientSuspended([]);

        setSubscriptionShortlistClientSearch('');
        setSelectsShortlistClient([]);
    }

    const filterData = () => {
        setFilterExport({ phones: searchPhone ? `${cleanMask(searchPhone)}` : '', document: searchDocument ? `${cleanMask(searchDocument)}` : '', birthDateStart: searchDateBirthStart, birthDateEnd: searchDateBirthEnd, createdAtStart: searchDateRegisterStart, createdAtEnd: searchDateRegisterEnd, gender: subscriptionGenderSearch ? `[${subscriptionGenderSearch}]` : '', restrict: subscriptionClientSuspendedSearch ? `[${subscriptionClientSuspendedSearch}]` : '', others: subscriptionShortlistClientSearch ? `[${subscriptionShortlistClientSearch}]` : '', ...initialQueryState })

        updateState({ phones: searchPhone ? `${cleanMask(searchPhone)}` : '', document: searchDocument ? `${cleanMask(searchDocument)}` : '', birthDateStart: searchDateBirthStart, birthDateEnd: searchDateBirthEnd, createdAtStart: searchDateRegisterStart, createdAtEnd: searchDateRegisterEnd, gender: subscriptionGenderSearch ? `[${subscriptionGenderSearch}]` : '', restrict: subscriptionClientSuspendedSearch ? `[${subscriptionClientSuspendedSearch}]` : '', others: subscriptionShortlistClientSearch ? `[${subscriptionShortlistClientSearch}]` : '', ...initialQueryState })

        const closeButton = document.querySelector<HTMLButtonElement>('#kt_drawer_filter_close');

        if (closeButton) {
            closeButton.click();
        };
    }

    function isNotEmpty(obj: unknown) {
        return obj !== undefined && obj !== null && obj !== ''
    }

    // Função para limpar o objeto, removendo campos vazios, 0 ou chaves específicas
    const cleanedFilterExport = Object.keys(filterExport)
        .filter(key => filterExport[key] !== "" && filterExport[key] !== 0 && key !== "page" && key !== "per_page" && key !== "total")
        .reduce((obj, key) => {
            obj[key] = filterExport[key];
            return obj;
        }, {})

    const downloadFile = async () => {

        await downloadServerFile(isNotEmpty(cleanedFilterExport) ? qs.stringify(cleanedFilterExport) : undefined);
    }

    if (!isAuthorizedToCreate) return <></>;

    return (
        <>
            <button
                disabled={isLoading}
                type='button'
                className='btn btn-light-danger me-3'
                id="kt_drawer_filter_button"
            >
                <KTIcon iconName='filter' className='fs-2' />
                Filtrar
            </button>
            <div
                id="kt_drawer_filter"
                className='bg-body'
                data-kt-drawer='true'
                data-kt-drawer-name='activities'
                data-kt-drawer-activate='true'
                data-kt-drawer-overlay='true'
                data-kt-drawer-width="{default:'300px', 'lg': '500px'}"
                data-kt-drawer-direction='end'
                data-kt-drawer-toggle="#kt_drawer_filter_button"
                data-kt-drawer-close="#kt_drawer_filter_close"

            >
                <div className='card shadow-none rounded-0'>
                    <div className='card-header' id='kt_activities_header'>
                        <h3 className='card-title fw-bolder text-dark'>Opções de Filtro</h3>

                        <div className='card-toolbar'>
                            <button
                                type='button'
                                className='btn btn-sm btn-icon btn-active-light-danger me-n5'
                                id='kt_drawer_filter_close'
                            >
                                <KTIcon iconName='cross' className='fs-1' />
                            </button>
                        </div>
                    </div>
                    <div className='card-body position-relative' id='kt_activities_body'>

                        <div className='timeline'>
                            <div className='px-7 py-5' data-kt-user-table-filter='form'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <ListSearchSubscriptionPhone searchPhone={searchPhone} setSearchPhone={setSearchPhone} />
                                    </div>
                                    <div className='col-12'>
                                        <ListSearchSubscriptionDocument searchDocument={searchDocument} setSearchDocument={setSearchDocument} />
                                    </div>
                                    <div className='col-12'>
                                        <ListSearchSubscriptionBirthDate setSearchDateBirthStart={setSearchDateBirthStart} searchDateBirthStart={searchDateBirthStart} setSearchDateBirthEnd={setSearchDateBirthEnd} searchDateBirthEnd={searchDateBirthEnd} setErrorsBirth={setErrorsBirth} errorsBirth={errorsBirth} />

                                    </div>
                                    <div className='col-12'>
                                        <ListSearchSubscriptionDateRegister setSearchDateRegisterStart={setSearchDateRegisterStart} searchDateRegisterStart={searchDateRegisterStart} setSearchDateRegisterEnd={setSearchDateRegisterEnd} searchDateRegisterEnd={searchDateRegisterEnd} setErrorsRegister={setErrorsRegister} errorsRegister={errorsRegister} />
                                    </div>
                                    <div className='col-12'>
                                        <ListSearchSubscriptionGender setSubscriptionGenderSearch={setSubscriptionGenderSearch} selectsGender={selectsGender} setSelectsGender={setSelectsGender} />
                                    </div>
                                    <div className='col-12'>
                                        <ListSearchSubscriptionClientSuspended setSubscriptionClientSuspendedSearch={setSubscriptionClientSuspendedSearch} selectsClientSuspended={selectsClientSuspended} setSelectsClientSuspended={setSelectsClientSuspended} />
                                    </div>
                                    <div className='col-12'>
                                        <ListSearchSubscriptionShortlistClient setSubscriptionShortlistClientSearch={setSubscriptionShortlistClientSearch} selectsShortlistClient={selectsShortlistClient} setSelectsShortlistClient={setSelectsShortlistClient} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card-footer py-5 text-center' id='kt_activities_footer'>
                        <div className='d-flex justify-content-end'>
                            <button
                                type='button'
                                disabled={isLoading}
                                onClick={resetData}
                                className='btn btn-secondary fw-bold me-2 px-6'
                                data-kt-menu-dismiss='true'
                                data-kt-user-table-filter='reset'
                            >
                                Limpar
                            </button>
                            <button
                                disabled={isLoading}
                                type='button'
                                onClick={filterData}
                                className='btn btn-danger fw-bold px-6'
                                data-kt-menu-dismiss='true'
                                data-kt-user-table-filter='filter'
                            >
                                Filtrar
                            </button>
                        </div>
                    </div>
                </div>
            </div >
            <button
                disabled={isLoading}
                type='button'
                className='btn btn-light-danger me-3'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                onClick={() => {
                    downloadFile()
                }}
            >
                <KTIcon iconName='exit-up' className='fs-2' />
                Export
            </button >
        </>
    )
}

export { ListToolbar }
