// @ts-nocheck
import clsx from 'clsx'
import { FC } from 'react'
import { Row } from 'react-table'
import { User } from '../../core/_models'
import { Link } from 'react-router-dom'
import useAuthorization from '../../../../../hooks/useAuthorization'
import { TooltipWrapper } from '../../../../../components/Tooltip'

type Props = {
  row: Row<User>
}

const CustomRow: FC<Props> = ({ row }) => {
  const id = String(row.original.id) as string
  const isAuthorizedToUpdate = useAuthorization('budgets.update')
  const handleToShow = () => navigate(`/sales/create?budget=${id}`)

  return (
    <tr {...row.getRowProps()}>
      {row.cells.map((cell) => {
        return (
          <td
            {...cell.getCellProps()}
            className={clsx({ 'text-end min-w-100px': cell.column.id === 'actions' })}
          >
            {cell.value === null ? '-' : cell.render('Cell')}
          </td>
        )
      })}
      {isAuthorizedToUpdate && (
        <td className='text-center min-w-120px'>
          <TooltipWrapper
            tooltipText={'Visualizar'}
            tooltipPlacement={'top'}
          >
            <Link className='btn btn-link btn-color-muted btn-active-color-danger' to={`/sales/create?budget=${id}`}>
              <i className="ki-duotone ki-book-open me-5 fs-1">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                <span className="path4"></span>
              </i>
            </Link>
          </TooltipWrapper>
        </td>
      )}
    </tr>
  )
}

export { CustomRow }
