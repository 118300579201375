import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate, Outlet } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardPage} from '../../pages/dashboard'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import FranchisePage from '../../pages/franchise/list'
import MachinePage from '../../pages/machines'
import ProductPage from '../../pages/products/list'
import CategoryPage from '../../pages/categories'
import ClientPage from '../../pages/clients/list'

import GroupPage from '../../pages/groups/list'
import SalePage from '../../pages/sales/list'
import BillingPage from '../../pages/billings/list'
import RoomPage from '../../pages/rooms'
import SchedulePage from '../../pages/schedules'
import BudgetPage from '../../pages/budgets'

////////////////////////////////////////////////////////////////////////////////////////////////////

//Categoria
import { CategoryShowPage } from '../../pages/categories/show'
import { CategoryEditPage } from '../../pages/categories/update'
import { CategoryCreatePage } from '../../pages/categories/create'
//Sala
import { RoomCreatePage } from '../../pages/rooms/RoomCreatePage'
//Produto
import { ProductShowPage } from '../../pages/products/show'
import { ProductEditPage } from '../../pages/products/update'
import { ProductCreatePage } from '../../pages/products/create'
//Grupo
import { GroupShowPage } from '../../pages/groups/show'
//Cliente
import { PeopleShowPage } from '../../pages/clients/show'
import { PeopleEditPage } from '../../pages/clients/update'
import { PeopleCreatePage } from '../../pages/clients/create'

//Venda
import { SalesCreatePage } from '../../pages/sales/create'
import { SalesViewPage } from '../../pages/sales/show'
import { BillingShowPage } from '../../pages/billings/show'
//Maquina
import { MachineCreatePage } from '../../pages/machines/create'
import { MachineEditPage } from '../../pages/machines/update'
import { MachineShowPage } from '../../pages/machines/show'
//Franquia
import { FranchiseEditPage } from '../../pages/franchise/update'
import { FranchiseCreatePage } from '../../pages/franchise/create'
import { FranchiseShowPage } from '../../pages/franchise/show'
import PaymentOptionPage from '../../pages/paymentOptions/list'
import { PaymentOptionEditPage } from '../../pages/paymentOptions/update'
import { PaymentOptionCreatePage } from '../../pages/paymentOptions/create'
import { PaymentOptionShowPage } from '../../pages/paymentOptions/show'
import UserPage from '../../pages/users/list'
import { UserShowPage } from '../../pages/users/show'
import { UserCreatePage } from '../../pages/users/create'
import { UserEditPage } from '../../pages/users/update'
import { ClientTemporarilyEditPage } from '../../pages/ClientsTemporarily/update/update'
import { MedicalConditionShowPage } from '../../pages/medicalCondition/show'
import MedicalConditionPage from '../../pages/medicalCondition/list'
import { MedicalConditionCreatePage } from '../../pages/medicalCondition/create'
import { MedicalConditionEditPage } from '../../pages/medicalCondition/update'
import { isAuthorizedUser } from './utils/permissions'
import { useAuth } from '../modules/auth'
import ClientsTemporarilyPage from '../../pages/ClientsTemporarily/list'
import TransferPage from '../../pages/transfers/list'
import { TransferShowPage } from '../../pages/transfers/show'
import ReportSalePage from '../../pages/reports/reportSale/list'
import ReportUserPage from '../../pages/reports/reportUser/list'
import ReportClientePage from '../../pages/reports/reportCliente/list'


const PermissionsGuard = ({ permission }: { permission: string }) => {
  const { currentUser, currentFranchise } = useAuth()
  const isAuthorized = isAuthorizedUser(currentUser, permission)

  return isAuthorized ? <Outlet /> : <Navigate to='/error/404' />
}

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registration */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />

        <Route path='auth/firstLogin' element={<Navigate to='/auth/resetpassword' />} />
        <Route path='auth/chooseFranchise' element={<Navigate to='/auth/franquias' />} />

        {/* Pages */}
        {
          // FRANQUIAS
        }

        <Route element={<PermissionsGuard permission='franchises.view' />}>
          <Route path='franchises/list' element={<FranchisePage />} />
        </Route>

        <Route element={<PermissionsGuard permission='franchises.view' />}>
          <Route path='franchises/list/:id' element={<FranchiseShowPage />} />
        </Route>

        <Route element={<PermissionsGuard permission='franchises.update' />}>
          <Route path='franchises/list/:id/edit' element={<FranchiseEditPage />} />
        </Route>

        <Route element={<PermissionsGuard permission='franchises.create' />}>
          <Route path='franchises/create' element={<FranchiseCreatePage />} />
        </Route>

        {
          // MAQUINAS
        }

        <Route element={<PermissionsGuard permission='machines.view' />}>
          <Route path='franchises/machines' element={<MachinePage />} />
        </Route>

        <Route element={<PermissionsGuard permission='machines.view' />}>
          <Route path='franchises/machines/:id' element={<MachineShowPage />} />
        </Route>

        <Route element={<PermissionsGuard permission='machines.update' />}>
          <Route path='franchises/machines/:id/edit' element={<MachineEditPage />} />
        </Route>

        <Route element={<PermissionsGuard permission='machines.create' />}>
          <Route path='franchises/machines/create' element={<MachineCreatePage />} />
        </Route>

        {
          // PRODUTOS
        }

        <Route element={<PermissionsGuard permission='products.view' />}>
          <Route path='products/list' element={<ProductPage />} />
        </Route>

        <Route element={<PermissionsGuard permission='products.view' />}>
          <Route path='products/list/:id' element={<ProductShowPage />} />
        </Route>

        <Route element={<PermissionsGuard permission='products.update' />}>
          <Route path='products/list/:id/edit' element={<ProductEditPage />} />
        </Route>

        <Route element={<PermissionsGuard permission='products.create' />}>
          <Route path='products/list/create' element={<ProductCreatePage />} />
        </Route>

        {
          // CATEGORIAS
        }

        <Route element={<PermissionsGuard permission='categories.view' />}>
          <Route path='products/categories/list/:id' element={<CategoryShowPage />} />
        </Route>

        <Route element={<PermissionsGuard permission='categories.update' />}>
          <Route path='products/categories/list/:id/edit' element={<CategoryEditPage />} />
        </Route>

        <Route element={<PermissionsGuard permission='categories.create' />}>
          <Route path='products/categories/list/create' element={<CategoryCreatePage />} />
        </Route>

        <Route element={<PermissionsGuard permission='categories.view' />}>
          <Route path='products/categories/list' element={<CategoryPage />} />
        </Route>

        {
          // FORMAS DE PAGAMENTO
        }

        <Route element={<PermissionsGuard permission='paymentConfig.view' />}>
          <Route path='paymentOptions' element={<PaymentOptionPage />} />
        </Route>

        <Route element={<PermissionsGuard permission='paymentConfig.view' />}>
          <Route path='paymentOptions/:id' element={<PaymentOptionShowPage />} />
        </Route>

        <Route element={<PermissionsGuard permission='paymentConfig.update' />}>
          <Route path='paymentOptions/:id/edit' element={<PaymentOptionEditPage />} />
        </Route>

        <Route element={<PermissionsGuard permission='paymentConfig.create' />}>
          <Route path='paymentOptions/create' element={<PaymentOptionCreatePage />} />
        </Route>

        {
          // PESSOAS
        }

        <Route element={<PermissionsGuard permission='persons.view' />}>
          <Route path='people/list' element={<ClientPage />} />
        </Route>

        <Route element={<PermissionsGuard permission='persons.view' />}>
          <Route path='people/list/:id' element={<PeopleShowPage />} />
        </Route>

        <Route element={<PermissionsGuard permission='persons.update' />}>
          <Route path='people/list/:id/edit' element={<PeopleEditPage />} />
        </Route>

        <Route element={<PermissionsGuard permission='persons.create' />}>
          <Route path='people/create' element={<PeopleCreatePage />} />
        </Route>

        {
          // CLIENTES TEMPORARIOS
        }
        <Route element={<PermissionsGuard permission='permissions.delete' />}>
          <Route path='clientTemporarily/list' element={<ClientsTemporarilyPage />} />
        </Route>
        <Route element={<PermissionsGuard permission='permissions.delete' />}>
          <Route path='clientTemporarily/list/:id/edit' element={<ClientTemporarilyEditPage />} />
        </Route>

        {
          // PESSOAS / CONDICAO MEDICA
        }

        <Route element={<PermissionsGuard permission='medicalConditions.view' />}>
          <Route path='people/medicalConditions' element={<MedicalConditionPage />} />
        </Route>

        <Route element={<PermissionsGuard permission='medicalConditions.view' />}>
          <Route path='people/medicalConditions/:id' element={<MedicalConditionShowPage />} />
        </Route>

        <Route element={<PermissionsGuard permission='medicalConditions.create' />}>
          <Route path='people/medicalConditions/create' element={<MedicalConditionCreatePage />} />
        </Route>

        <Route element={<PermissionsGuard permission='medicalConditions.update' />}>
          <Route path='people/medicalConditions/:id/edit' element={<MedicalConditionEditPage />} />
        </Route>

        {
          // SALAS
        }

        <Route element={<PermissionsGuard permission='rooms.view' />}>
          <Route path='rooms/list' element={<RoomPage />} />
        </Route>

        <Route element={<PermissionsGuard permission='rooms.create' />}>
          <Route path='rooms/create' element={<RoomCreatePage />} />
        </Route>

        {
          // USUARIOS / GRUPOS
        }

        <Route element={<PermissionsGuard permission='groups.view' />}>
          <Route path='users/groups/list' element={<GroupPage />} />
        </Route>

        <Route element={<PermissionsGuard permission='groups.view' />}>
          <Route path='users/groups/list/:id' element={<GroupShowPage />} />
        </Route>

        {
          // TRANSFERENCIAS
        }

        <Route element={<PermissionsGuard permission='users.view' />}>
          <Route path='transfers/list' element={<TransferPage />} />
        </Route>

        <Route element={<PermissionsGuard permission='users.view' />}>
          <Route path='transfers/list/:id' element={<TransferShowPage />} />
        </Route>

        {
          // USUARIOS
        }

        <Route element={<PermissionsGuard permission='users.view' />}>
          <Route path='users/list' element={<UserPage />} />
        </Route>

        <Route element={<PermissionsGuard permission='users.view' />}>
          <Route path='users/list/:id' element={<UserShowPage />} />
        </Route>

        <Route element={<PermissionsGuard permission='users.update' />}>
          <Route path='users/list/:id/edit' element={<UserEditPage />} />
        </Route>

        <Route element={<PermissionsGuard permission='users.create' />}>
          <Route path='users/list/create' element={<UserCreatePage />} />
        </Route>

        {
          // VENDAS
        }

        <Route element={<PermissionsGuard permission='sales.view' />}>
          <Route path='sales/list' element={<SalePage />} />
        </Route>

        <Route element={<PermissionsGuard permission='sales.view' />}>
          <Route path='sales/list/:id' element={<SalesViewPage />} />
        </Route>

        <Route element={<PermissionsGuard permission='sales.create' />}>
          <Route path='sales/create' element={<SalesCreatePage />} />
        </Route>

        {
          // COBRANCAS
        }

        <Route element={<PermissionsGuard permission='billings.view' />}>
          <Route path='sales/billings' element={<BillingPage />} />
        </Route>

        <Route element={<PermissionsGuard permission='billings.view' />}>
          <Route path='sales/billings/:id' element={<BillingShowPage />} />
        </Route>

        {
          // VENDAS / ORÇAMENTO
        }

        <Route element={<PermissionsGuard permission='budgets.view' />}>
          <Route path='sales/budgets' element={<BudgetPage />} />
        </Route>

        {
          // VENDAS / ORÇAMENTO
        }

        <Route element={<PermissionsGuard permission='schedules.view' />}>
          <Route path='schedules' element={<SchedulePage />} />
        </Route>

        {/* <Route path="categories" element={<CategoryPage />} /> */}
        <Route path='dashboard' element={<DashboardPage />} />

        <Route path='builder' element={<BuilderPageWrapper />} />
        {/* <Route path="menu-test" element={<MenuTestPage />} /> */}
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/franchise/*'
          element={
            <SuspensedView>
              <FranchisePage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/machine/*'
          element={
            <SuspensedView>
              <MachinePage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/product/*'
          element={
            <SuspensedView>
              <ProductPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/category/*'
          element={
            <SuspensedView>
              <CategoryPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/dashboard' />} />

        {
          // RELATÓRIOS
        }

        <Route element={<PermissionsGuard permission='reports.view' />}>
          <Route path='reports/reportSale/list' element={<ReportSalePage />} />
        </Route>

        <Route element={<PermissionsGuard permission='reports.view' />}>
          <Route path='reports/reportUser/list' element={<ReportUserPage />} />
        </Route>

        <Route element={<PermissionsGuard permission='reports.view' />}>
          <Route path='reports/reportCliente/list' element={<ReportClientePage />} />
        </Route>
        

      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
