// @ts-nocheck
import clsx from 'clsx'
import { FC } from 'react'
import { Row } from 'react-table'
import { User } from '../../core/_models'
import { useNavigate } from 'react-router-dom'
import useAuthorization from '../../../../../hooks/useAuthorization'
import { TooltipWrapper } from '../../../../../components/Tooltip'

type Props = {
    row: Row<User>
}

const CustomRow: FC<Props> = ({ row }) => {


    const id = String(row.original.id) as string;
    const navigate = useNavigate()
    const isAuthorizedToShow = useAuthorization("franchises.view");
    const handleToShow = () => navigate(`/franchises/list/${id}`)
    const isAuthorizedToUpdate = useAuthorization("franchises.update");
    const handleToEdit = () => navigate(`/franchises/list/${id}/edit`)

    return <tr {...row.getRowProps()}>
        {row.cells.map((cell) => {
            return (
                <td
                    {...cell.getCellProps()}
                    className={clsx({ 'text-end min-w-100px': cell.column.id === 'actions' })}
                >
                    {cell.render('Cell')}
                </td>
            )
        })}
        <td className="text-center min-w-100px">
            {isAuthorizedToShow && (
                <TooltipWrapper
                    tooltipText={'Visualizar'}
                    tooltipPlacement={'top'}
                >
                    <button className="btn btn-link btn-color-muted btn-active-color-danger" onClick={() => handleToShow()}>
                        <i className="ki-duotone ki-book-open me-5 fs-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                            <span className="path3"></span>
                            <span className="path4"></span>
                        </i>
                    </button>
                </TooltipWrapper>
            )}
            {isAuthorizedToUpdate && (
                <TooltipWrapper
                    tooltipText={'Editar'}
                    tooltipPlacement={'top'}
                >
                    <button className="btn btn-link btn-color-muted btn-active-color-danger" onClick={() => handleToEdit()}>
                        <i className="ki-duotone ki-pencil me-5 fs-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                        </i>
                    </button>
                </TooltipWrapper>
            )}
        </td>
    </tr>
}

export { CustomRow }
