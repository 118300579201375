import { KTIcon } from "../../../_metronic/helpers";
import { usePeople } from "../../../coreGlobal/providers/PersonProvider";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Partenership } from "../../../coreGlobal/models/franchise/franchise";
import { useFormFranchise } from "../core/franchiseProvider";
import { updateFranchisePartnership } from "../core/_request";

export const PartnershipForm = ({ formik }) => {
  const { persons } = usePeople()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [newPartnership, setNewPartnership] = useState<Partenership[]>([])
  const [firstPartnership, setFirstPartnership] = useState<Partenership[]>([])
  const [stateToDetectChange, setStateToDetectChange] = useState(false); // Estado para detectar se houve alteraçã
  const location = useLocation();
  let { id } = useParams();

  const {
    franchise
  } = useFormFranchise()

  useEffect(() => {
    setNewPartnership(franchise?.partnerships ? franchise.partnerships : [] );
    setFirstPartnership(franchise?.partnerships ? franchise.partnerships : []);
  }, [])

  const filterByName = () => {
    return newPartnership?.filter((item) =>
      item.destinationFranchiseName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const handleCheckboxChange = (itemId, fieldName) => {
    const novoEstado = newPartnership.map((item) => {
      if (item.id === itemId) {
        if (fieldName == "activeCalculatedTransfer") {
          return { ...item, partnershipTypeId: 1 };
        } else if (fieldName == "activeRestrictedPassTransfer") {
          return { ...item, partnershipTypeId: 2 };
        } else if (fieldName == "activeFreePassTransfer") {
          return { ...item, partnershipTypeId: 3 };
        }
      }
      return item;
    });
    setNewPartnership(novoEstado)
    setStateToDetectChange(true)
  }

  useEffect(() => {

    const popStateHandler = (event) => {
      event.preventDefault();
      submit('As alterações feitas nas parcerias não serão salvas. Deseja salvar?', 'Sim', 'Não');
    };

    const beforeUnloadHandler = (event) => {
      event.preventDefault();
    };

    if (stateToDetectChange) {
      window.addEventListener('beforeunload', beforeUnloadHandler);
      window.addEventListener('popstate', popStateHandler);

    } else {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
      window.removeEventListener('popstate', popStateHandler);
    }

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
      window.removeEventListener('popstate', popStateHandler);
    };
  }, [stateToDetectChange, location, navigate]);

  async function submit(textTitle, textButtonConfirm, textButtoncancel) {
    await Swal.fire({
      title: textTitle,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: textButtonConfirm,
      cancelButtonText: textButtoncancel,
      customClass: {
        confirmButton: 'btn btn-danger', // Classe personalizada para o botão de confirmação
        cancelButton: 'btn btn-secondary' // Classe personalizada para o botão de cancelamento (opcional)
      }
    }).then(async (result) => {
      if (result.isConfirmed && persons.length > 0) {
        setLoading(true)
        try {
          const data = {
            partnerships: newPartnership?.map(({ id, destinationFranchiseName, ...rest }) => ({
              type: Number(rest.partnershipTypeId),
              franchise:  Number(rest.destinationFranchiseId),
            })),
          };

          await updateFranchisePartnership(String(id), data)
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Salvo com sucesso',
            showConfirmButton: false,
            timer: 1500
          })
        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = '';
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>';
            }
            await Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText
            });
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 2000
            })
          }
        } finally {
          setLoading(false)
          setStateToDetectChange(false)
        }
      } else {
        setNewPartnership(firstPartnership)
        setStateToDetectChange(false)
      }
    })
  }
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className="card card-custom">
          <div className="card-header">
            <h3 className="card-title fw-bolder m-0">Parcerias</h3>
            <div className="card-toolbar">
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='name'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Pesquisar Franquia'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div className="card-body">
            <div className="py-5">
              <div className="table-responsive">
                <table className="table table-row-dashed table-row-gray-300 gy-7">
                  <thead>
                    <tr className="fw-bold fs-6 text-gray-800">
                      <th style={{ width: '60%' }}>Unidade</th>
                      <th>Transferência</th>
                      <th>Passe Restrito</th>
                      <th>Passe Livre</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterByName()?.map((item) => (
                      <tr key={item.id}>
                        <td>
                          {item.destinationFranchiseName}
                        </td>
                        <td>
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id={`flexCheckCheckedCalculatedTransfer${item.id}`} checked={item.partnershipTypeId == 1} onChange={() => handleCheckboxChange(item.id, 'activeCalculatedTransfer')} />
                            <label className="form-check-label"></label>
                          </div>
                        </td>
                        <td>
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id={`flexCheckCheckedRestrictedPassTransfer${item.id}`} checked={item.partnershipTypeId == 2} onChange={() => handleCheckboxChange(item.id, 'activeRestrictedPassTransfer')} />
                            <label className="form-check-label"></label>
                          </div>
                        </td>
                        <td>
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id={`flexCheckCheckedFreePassTransfer${item.id}`} checked={item.partnershipTypeId == 3} onChange={() => handleCheckboxChange(item.id, 'activeFreePassTransfer')} />
                            <label className="form-check-label"></label>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type="reset" className="btn btn-secondary me-2" onClick={() => navigate(-1)}>Cancelar</button>
            <button type='submit' className='btn btn-danger' disabled={loading || !persons.length}
              onClick={(e) => { e.preventDefault(); submit('Deseja atualizar as parcerias?', 'Confirmar', 'Cancelar') }}>
              {!loading && 'Salvar'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Salvando...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );

};