/* eslint-disable react-hooks/exhaustive-deps */

import {useNavigate, useParams} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {useEffect, useState} from 'react'
import {TClientTemporarilyRequest} from './models/_models'
import {cepMask} from '../../chooseFranchise/utils/mask'
import {
  ClientTemporarilyUpdateProvider,
  useClientTemporarilyUpdate,
} from '../Provider/UpdateClientProvider'
import {phoneMask} from '../../../utils/phoneMask'
import {useFormik} from 'formik'
import {ClientTemporarilySchema} from './models/Schema'
import Swal from 'sweetalert2'
import {LoadingSpinner} from '../../../utils/loading/loadingSpinner'
import ClientTemporarilyForm from '../../../components/ClientTemporarily/ClientTemporarilyForm'
import {buildClientTemporarilyRequest} from '../../../coreGlobal/models/biuldClientTemporarilyRequest'
import {ExistClientModal} from './ExistClientModal'
import {updateClientTemporarily} from './models/_request'
import {removeSCMask} from '../../../utils/formatMonetaryToBr'
import {validatePerson, validatePersonTemporarily} from '../../../coreGlobal/_resquest'

const peopleBreadcrumbs: Array<PageLink> = [
  {
    title: 'Editar Cliente Temporário',
    path: '/people',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const Update = () => {
  let {id} = useParams()
  const [loadingPage, setLoadingPage] = useState(false)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [dataCep, setDataCep] = useState<any>()
  const {
    getDataPersons,
    setReduceTimeRadio,
    setHideAvatarRadio,
    setOpenClientModal,
    openClientModal,
    setDataValidateDoc,
    dataValidateDoc,
    persons,
  } = useClientTemporarilyUpdate()

  useEffect(() => {
    const init = async () => {
      try {
        setLoadingPage(true)
        const person = await getDataPersons(String(id))
        parseToFormik(person)
        setLoadingPage(false)
      } catch (error: any) {
        if (error.response.status === 422) {
          let errorText = ''
          for (let key in error.response.data.errors) {
            errorText += '<li>' + error.response.data.errors[key][0] + '</li>'
          }
          await Swal.fire({
            icon: 'error',
            title: 'Ops! Revise seu formulário e tente novamente!',
            html: errorText,
            showConfirmButton: true,
          }).then((response) => {
            if (response.isConfirmed) {
              navigate('/clientTemporarily/list')
            }
          })
        } else {
          await Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.response.data.message,
            showConfirmButton: true,
          }).then((response) => {
            if (response.isConfirmed) {
              navigate('/clientTemporarily/list')
            }
          })
        }
      }
    }
    init()
  }, [])

  const initialValues: TClientTemporarilyRequest = {
    type: 1,
    name: '',
    preferredName: '',
    email: '',
    document: '',
    birthDate: '',
    gender: '',
    rg: '',
    job: '',
    nationality: -1,
    reduceTime: true,
    hideAvatar: false,
    oldClientId: -1,
    contacts: [
      {
        type: 0,
        phone: '',
      },
    ],
    addresses: [
      {
        isMain: false,
        zipCode: '',
        street: '',
        number: -1,
        complement: '',
        neighborhood: '',
        city: -1,
      },
    ],
  }

  const parseToFormik = async (people) => {
    if (people.length) {
      const addresses = people?.map((value) => ({
        id: 0,
        isMain: true,
        zipCode: cepMask(value?.cep),
        street: value?.logradouro,
        number: value?.num,
        neighborhood: value?.bairro,
        city: value?.cityId,
        state: value?.stateId,
        ...(value.complemento && {complement: value.complemento}),
      }))

      setDataCep(addresses)

      const contactsData = people?.map((value) => ({
        id: 0,
        type: 1,
        phone: phoneMask(value?.telcel),
      }))

      await formik.setValues({
        id: people[0]?.id,
        type: 1,
        name: people[0].nome,
        preferredName: '',
        email: people[0]?.email,
        document: people[0]?.cpf,
        birthDate: people[0]?.dtnasc,
        gender: people[0]?.sexo,
        rg: people[0]?.rg || '',
        oldClientId: people[0].codcli,
        job: people[0]?.profissao,
        nationality: 22,
        addresses: addresses,
        contacts: contactsData.length > 0 ? contactsData : [{type: 0, phone: ''}],
        reduceTime: true,
        hideAvatar: false,
      })
      setReduceTimeRadio(true)
      setHideAvatarRadio(false)
    }
  }

  const close = () => {
    setOpenClientModal(false)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: ClientTemporarilySchema(true),
    onSubmit: async (values) => {
      const valuesFilter = buildClientTemporarilyRequest(values)
      await submit(valuesFilter, String(values.document))
    },
  })

  const validateDocument = async (value: string) => {
    try {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: `Buscando dados`,
        showConfirmButton: false,
        timer: 1000,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading()
        },
      })
      try {
        const data = await validatePersonTemporarily(value)
        setDataValidateDoc(data)
        return 200
      } catch (error) {
        return 400
      }
    } catch (error: any) {
      return error.response.status
    }
  }
  const submit = async (req: FormData, document: number | string) => {
    await Swal.fire({
      title: 'Deseja atualizar o cliente?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-danger', // Classe personalizada para o botão de confirmação
        cancelButton: 'btn btn-secondary' // Classe personalizada para o botão de cancelamento (opcional)
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        const getResponseValidateDocument = await validateDocument(String(document))
        if (getResponseValidateDocument === 200) {
          setOpenClientModal(true)
          return
        }
        setLoading(true)
        try {
          await updateClientTemporarily(req)

          await Swal.fire({
            icon: 'success',
            title: 'Cliente atualizado com sucesso!',
            timer: 2000,
          })

          navigate('/people/list')
        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = ''
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>'
            }
            await Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText,
            })
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 2000,
            })
          }
        } finally {
          setLoading(false)
        }
      }
    })
  }

  return (
    <>
      {openClientModal && (
        <ExistClientModal data={dataValidateDoc} close={() => close()} oldId={id} />
      )}
      {loadingPage ? (
        <LoadingSpinner title={'Carregando Dados Editar cliente temporário'} />
      ) : (
        <>
          <PageTitle breadcrumbs={peopleBreadcrumbs}>Editar Cliente</PageTitle>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <ClientTemporarilyForm formik={formik} type={1} complete={'required'} cep={dataCep} />
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='reset' className='btn btn-secondary me-2' onClick={() => navigate(-1)}>
                Cancelar
              </button>
              <button
                type='submit'
                className='btn btn-danger'
                disabled={loading || !formik.isValid}
              >
                {!loading && 'Salvar'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Salvando...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </>
      )}
    </>
  )
}

export const ClientTemporarilyEditPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={peopleBreadcrumbs}>Atualizar Cliente Temporário</PageTitle>
      <ClientTemporarilyUpdateProvider>
        <Update />
      </ClientTemporarilyUpdateProvider>
    </>
  )
}
