// @ts-nocheck
import { Column } from 'react-table'
import { CustomHeader } from './CustomHeader'
import { SelectionHeader } from './SelectionHeader'
import { User } from '../../core/_models'
import { CustonCell } from './CustonCell'
import { formatMask } from '../../../../../../utils/formatMonetaryToBr'
import { formatDateTime } from '../../../../../../utils/dayJs/format'
import { PaymentHeader } from './PaymentHeader'
import { StatusCell } from './StatusCell'

const ReportTransfersColumns: ReadonlyArray<Column<User>> = [

  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Cliente' className='min-w-125px' />
    ),
    id: 'client_name',
    Cell: ({ ...props }) => <CustonCell value={`${props.data[props.row.index].clientName}`} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Franquia Origem' className='min-w-125px' />,
    id: 'origin_franchise_name',
    Cell: ({ ...props }) => <CustonCell value={props.data[props.row.index].originFranchiseName} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Contrato origem' className='min-w-125px' />,
    id: 'origin_sale_id',
    Cell: ({ ...props }) => <CustonCell value={props.data[props.row.index].originSaleId} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Franquia Destino' className='min-w-125px' />,
    id: 'destination_franchise_name',
    Cell: ({ ...props }) => <CustonCell value={props.data[props.row.index].destinationFranchiseName} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Contrato Destino' className='min-w-125px' />,
    id: 'new_sale_id',
    Cell: ({ ...props }) => <CustonCell value={props.data[props.row.index].newSaleId} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'status',
    Cell: ({ ...props }) => <StatusCell transfer={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Requisitado Por' className='min-w-125px' />,
    id: 'requested_user_name',
    Cell: ({ ...props }) => <CustonCell value={props.data[props.row.index].requestedUserName} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Aprovado Por' className='min-w-50px' />,
    id: 'approved_user_name',
    Cell: ({ ...props }) => <CustonCell value={props.data[props.row.index].approvedUserName} />,
  },
  {
    Header: (props) => (
      <PaymentHeader tableProps={props} title='Repasse franquia' className='min-w-125px' />
    ),
    id: 'franchise_transfer_value',
    Cell: ({ ...props }) => <CustonCell value={formatMask(props.data[props.row.index].franchiseTransferValue)} />,
  },
  {
    Header: (props) => (
      <PaymentHeader tableProps={props} title='Repasse cliente origem' className='min-w-125px' />
    ),
    id: 'clientBillingToOrigin',
    Cell: ({ ...props }) => <CustonCell value={formatMask(props.data[props.row.index].clientBillingToOrigin)} />,
  },
  {
    Header: (props) => (
      <PaymentHeader tableProps={props} title='Repasse cliente destino' className='min-w-125px' />
    ),
    id: 'clientBillingToDestination',
    Cell: ({ ...props }) => <CustonCell value={formatMask(props.data[props.row.index].clientBillingToDestination)} />,
  },
  {
    Header: (props) => (
      <PaymentHeader tableProps={props} title='Multa' className='min-w-125px' />
    ),
    id: 'fineValue',
    Cell: ({ ...props }) => <CustonCell value={formatMask(props.data[props.row.index].fineValue)} />,
  },
]

export { ReportTransfersColumns }
