import moment from 'moment'
import {useEffect, useState} from 'react'
import {brokenLineMR} from '../../../coreGlobal/Helpers/utils'
import {useAuth} from '../../../app/modules/auth'
import Select from 'react-select'
import {getMedicalRecord, IGetMedicalRecord} from '../list/core/_requests'
import {FormikProps, useFormik, useFormikContext} from 'formik'
import {
  medicalRecordInitialValues,
  medicalRecordSchema,
  TMedicalRecordValues,
} from '../list/core/schemas'
import {ISelectT} from '../../../coreGlobal/models/default'
import {getUsers} from '../../../coreGlobal/_resquest'
import {LoadingSpinner} from '../../../utils/loading/loadingSpinner'
import {updateMedicalRecord} from '../../schedules/core/_request'
import Swal from 'sweetalert2'
import {PageOverlayer} from '../../../components/PageOverlayer'

interface IMedicalRecordFormFinish {
  medicalRecordId: number
  close: any
}

const hasLog = (medicalRecord) => {
  if (medicalRecord !== null && medicalRecord.logs.length !== 0) {
    const dataLog = medicalRecord.logs.map((logs) => logs.length === 0)
    return dataLog[0]
  } else {
    return true
  }
}

const FooterButtonOpen = ({formik, close}) => {
  return (
    <>
      <div className='col-12 mt-4'>
        <div className='col-12 text-end'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              close()
            }}
          >
            Fechar
          </button>
          <button
            type='submit'
            className='btn btn-danger ms-5'
            disabled={!formik.isValid}
            onClick={(e) => {
              e.preventDefault()
              formik.handleSubmit()
            }}
          >
            Salvar
          </button>
        </div>
      </div>
    </>
  )
}

const FooterButtonFinish = ({close}) => {
  return (
    <>
      <div className='col-12 mt-4'>
        <div className='col-12 text-end'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              close()
            }}
          >
            Fechar
          </button>
        </div>
      </div>
    </>
  )
}

const parseToFormik = (response: IGetMedicalRecord, id: number): TMedicalRecordValues => {
  return {
    itemId: response.scheduleItem.id,
    id: response.id,
    status: response.status == 'Realizado' ? 1 : 0,
    attendant: id,
    frequency: response.machineFrequency,
    observation: response.observation || '',
  }
}

export const MedicalRecordFormFinish = ({medicalRecordId, close}: IMedicalRecordFormFinish) => {
  const {currentUser} = useAuth()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [medicalRecord, setMedicalRecord] = useState<IGetMedicalRecord | null>(null)

  useEffect(() => {
    async function init() {
      try {
        setIsLoading(true)
        const response = await getMedicalRecord(medicalRecordId)
        setMedicalRecord(response)
      } catch (error) {
        close()
        Swal.fire({
          icon: 'error',
          title: 'Ops! não conseguimos carrerar as informações, tente mais tarde.',
          showConfirmButton: true,
        })
      } finally {
        setIsLoading(false)
      }
    }
    init()
  }, [])

  if (isLoading) {
    return <LoadingSpinner title={'Carregando'} />
  }

  return (
    <>
      <div className='row mt-5'>
        <div className='col-12'>
          <div className='row'>
            <div className='col-6'>
              <span className='fw-bold ' style={{color: 'red'}}>
                {medicalRecord?.scheduleItem.evaluationProduct
                  ? medicalRecord?.scheduleItem.evaluationProduct.name
                  : medicalRecord?.scheduleItem?.saleItem?.product.name}
              </span>
            </div>
            <div className='col-6'>
              {!medicalRecord?.scheduleItem.evaluationProduct ? (
                <span className='fw-bold ' style={{color: 'red'}}>
                  Sessão {medicalRecord?.scheduleItem.sessionNumber} /{' '}
                  {medicalRecord?.scheduleItem.saleItem?.sessions}
                </span>
              ) : (
                <span className='fw-bold ' style={{color: 'red'}}>
                  Sessão {medicalRecord?.scheduleItem.sessionNumber}
                </span>
              )}
            </div>
          </div>

          <div className='col-12 mt-5'>
            <div className='col-1'>
              <label htmlFor=''>Status</label>
            </div>

            <input
              disabled
              name='status_medical_record'
              className='form-select'
              value={medicalRecord?.status}
            />
          </div>
        </div>
      </div>
      <div className='row mt-5'>
        <div className='col-12 mt-4'>
          <div className='col-6'>
            <label htmlFor='' className='required'>
              Frequência
            </label>
          </div>
          <div className='col-12'>
            <input
              disabled
              type='text'
              className='form-control'
              value={medicalRecord?.machineFrequency}
            />
          </div>
        </div>

        <div className='col-12 mt-4'>
          <div className='col-6'>
            <label htmlFor='' className='required'>
              Observação
            </label>
          </div>
          <div className='col-12'>
            <input
              disabled
              type='text'
              className='form-control'
              value={medicalRecord?.observation}
            />
          </div>
        </div>

        <div className='col-12 mt-4'>
          <div className='col-6'>
            <label htmlFor='' className='required'>
              Evoluido Por
            </label>
          </div>
          <div className='col-12'>
            <Select
              isDisabled
              className='react-select-styled react-select-solid'
              classNamePrefix='react-select'
              value={{
                value: medicalRecord?.attendant.id,
                label: medicalRecord?.attendant.name,
              }}
            />
          </div>
        </div>

        <div className='col-12 mt-4'>
          <div className='col-6'>
            <label htmlFor=''>Alterado Por</label>
          </div>
          <div className='col-12'>
            <input disabled type='text' className='form-control' value={currentUser?.name} />
          </div>
        </div>

        <div className='col-12 mt-5' hidden={hasLog(medicalRecord)}>
          <h3>Histórico</h3>
          <table>
            <thead>
              <tr>
                <th className='fw-bold text-start' style={{color: 'red'}}>
                  Área
                </th>
                <th className='fw-bold text-start' style={{color: 'red'}}>
                  Data
                </th>
                <th className='fw-bold col-6' style={{color: 'red'}}>
                  Alteração
                </th>
                <th className='fw-bold' style={{color: 'red'}}>
                  Operador
                </th>
              </tr>
            </thead>
            <tbody>
              {medicalRecord?.logs.map((log, index) => (
                <>
                  <tr key={index}>
                    <td className='fw-bold'>
                      {medicalRecord?.scheduleItem.evaluationProduct
                        ? medicalRecord?.scheduleItem.evaluationProduct.name
                        : medicalRecord?.scheduleItem.saleItem?.product.name}
                    </td>
                    <td className='fw-bold'>
                      {moment(log.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                    </td>
                    <td className='fw-bold'>{brokenLineMR(log.description)}</td>
                    <td className='text-center fw-bold'>{log?.user?.name}</td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <FooterButtonFinish close={close} />
    </>
  )
}

export const MedicalRecordFormOpen = ({medicalRecordId, close}: IMedicalRecordFormFinish) => {
  const {currentUser} = useAuth()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [medicalRecord, setMedicalRecord] = useState<IGetMedicalRecord | null>(null)
  const [allUsers, setAllUsers] = useState<ISelectT[] | undefined>([])

  useEffect(() => {
    async function init() {
      try {
        setIsLoading(true)
        const response = await getMedicalRecord(medicalRecordId)
        const dataUser = await getUsers()
        setMedicalRecord(response)
        setAllUsers(
          dataUser?.map((data) => {
            return {
              value: String(data.value),
              label: String(data.label),
            }
          })
        )

        formik.setValues(parseToFormik(response, Number(currentUser?.id)), false)
      } catch (error) {
        close()
        Swal.fire({
          icon: 'error',
          title: 'Ops! não conseguimos carrerar as informações, tente mais tarde.',
          showConfirmButton: true,
        })
      } finally {
        setIsLoading(false)
      }
    }
    init()
  }, [])

  const handleSubmit = async (values: TMedicalRecordValues) => {
    await Swal.fire({
      title: 'Deseja atualizar o prontuário?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-danger', // Classe personalizada para o botão de confirmação
        cancelButton: 'btn btn-secondary', // Classe personalizada para o botão de cancelamento (opcional)
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true)
        try {
          await updateMedicalRecord(values, values.id)
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Salvo com sucesso',
            showConfirmButton: false,
            timer: 1500,
          })
        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = ''
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>'
            }
            await Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText,
            })
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 2000,
            })
          }
        } finally {
          close()
          setIsLoading(false)
        }
      }
    })
  }

  const formik: FormikProps<TMedicalRecordValues> = useFormik<TMedicalRecordValues>({
    initialValues: medicalRecordInitialValues,
    validationSchema: medicalRecordSchema,
    onSubmit: handleSubmit,
  })

  if (isLoading) {
    return <PageOverlayer />
  }

  const evolution_by = allUsers?.find((user) => user.value == String(formik.values.attendant))

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='row mt-5'>
        <div className='col-12'>
          <div className='row'>
            <div className='col-6'>
              <span className='fw-bold ' style={{color: 'red'}}>
                {medicalRecord?.scheduleItem.evaluationProduct
                  ? medicalRecord?.scheduleItem.evaluationProduct.name
                  : medicalRecord?.scheduleItem?.saleItem?.product.name}
              </span>
            </div>
            <div className='col-6'>
              {!medicalRecord?.scheduleItem.evaluationProduct ? (
                <span className='fw-bold ' style={{color: 'red'}}>
                  Sessão {medicalRecord?.scheduleItem.sessionNumber} /{' '}
                  {medicalRecord?.scheduleItem.saleItem?.sessions}
                </span>
              ) : (
                <span className='fw-bold ' style={{color: 'red'}}>
                  Sessão {medicalRecord?.scheduleItem.sessionNumber}
                </span>
              )}
            </div>
          </div>

          <div className='col-12 mt-5'>
            <div className='col-1'>
              <label htmlFor=''>Status/Realizado</label>
            </div>

            <select
              name='status_medical_record'
              className='form-select'
              value={formik.values.status}
              onChange={(e) => {
                const value = e.target.value
                formik.setFieldValue('status', value)
              }}
            >
              <option value={-1}>Selecione</option>
              <option value={0}>Não</option>
              <option value={1}>Sim</option>
            </select>
          </div>
        </div>
      </div>
      <div className='row mt-5'>
        <div className='col-12 mt-4'>
          <div className='col-6'>
            <label htmlFor='' className='required'>
              Frequência
            </label>
          </div>
          <div className='col-12'>
            <input
              type='text'
              className='form-control'
              value={formik.values.frequency}
              onChange={(e) => formik.setFieldValue('frequency', e.target.value)}
            />
          </div>
        </div>

        <div className='col-12 mt-4'>
          <div className='col-6'>
            <label htmlFor='' className='required'>
              Observação
            </label>
          </div>
          <div className='col-12'>
            <input
              type='text'
              className='form-control'
              value={formik.values.observation}
              onChange={(e) => formik.setFieldValue('observation', e.target.value)}
            />
          </div>
        </div>

        <div className='col-12 mt-4'>
          <div className='col-6'>
            <label htmlFor='' className='required'>
              Evoluido Por
            </label>
          </div>
          <div className='col-12'>
            <Select
              className='react-select-styled react-select-solid'
              classNamePrefix='react-select'
              value={evolution_by}
              onChange={(option) => {
                const values = option?.value
                formik.setFieldValue(`attendant`, values)
              }}
              options={allUsers}
            />
          </div>
        </div>

        <div className='col-12 mt-4'>
          <div className='col-6'>
            <label htmlFor=''>Alterado Por</label>
          </div>
          <div className='col-12'>
            <input
              readOnly
              disabled
              type='text'
              className='form-control'
              value={currentUser?.name}
            />
          </div>
        </div>

        <div className='col-12 mt-5' hidden={hasLog(medicalRecord)}>
          <h3>Histórico</h3>
          <table>
            <thead>
              <tr>
                <th className='fw-bold text-start' style={{color: 'red'}}>
                  Área
                </th>
                <th className='fw-bold text-start' style={{color: 'red'}}>
                  Data
                </th>
                <th className='fw-bold col-6' style={{color: 'red'}}>
                  Alteração
                </th>
                <th className='fw-bold' style={{color: 'red'}}>
                  Operador
                </th>
              </tr>
            </thead>
            <tbody>
              {medicalRecord?.logs.map((log, index) => (
                <>
                  <tr key={index}>
                    <td className='fw-bold'>
                      {medicalRecord?.scheduleItem.evaluationProduct
                        ? medicalRecord?.scheduleItem.evaluationProduct.name
                        : medicalRecord?.scheduleItem.saleItem?.product.name}
                    </td>
                    <td className='fw-bold'>
                      {moment(log.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                    </td>
                    <td className='fw-bold'>{brokenLineMR(log.description)}</td>
                    <td className='text-center fw-bold'>{log?.user?.name}</td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <FooterButtonOpen close={close} formik={formik} />
    </form>
  )
}
