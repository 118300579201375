import { KTCard } from '../../../../_metronic/helpers'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { Header } from './components/header/Header'
import { FilterProvider } from './core/FilterProvider'
import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { Table } from './table/Table'
import { Totalizer } from './Totalizer'

const groupBreadcrumbs: Array<PageLink> = [
  {
    title: 'Relatório de Vendas',
    path: '/reports/reportSale/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ReportSalePage = () => {

  const { itemIdForUpdate } = useListView()

  return (
    <>
      <PageTitle breadcrumbs={groupBreadcrumbs}>Relatório de Vendas</PageTitle>
      <QueryRequestProvider>
        <QueryResponseProvider>
          <ListViewProvider>
            <>
              <FilterProvider>
                <KTCard>
                  <Header />
                  <Totalizer />
                  <Table />
                </KTCard>
                {itemIdForUpdate !== undefined}
              </FilterProvider>
            </>
          </ListViewProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  )
}

export default ReportSalePage
