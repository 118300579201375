// import { useListView } from '../../core/ListViewProvider'
import { ListToolbar } from './ListToolbar'
// import {UsersListGrouping} from './FranchiseGroupinp'
import { ListSearchComponent } from './ListSearchComponent'
import { useQueryFilterDatesData } from '../../core/QueryResponseProvider'
import { formatDate } from '../../../../../../utils/dayJs/format'

const Header = () => {
    const filterDate = useQueryFilterDatesData()
    function isEmpty(obj: object): boolean {
        return Object.keys(obj).length === 0;
    }

    return (
        <>
            <div className='card-header border-0 pt-6'>
                <ListSearchComponent />
                {/* begin::Card toolbar */}
                <div className='card-toolbar'>
                    {/* begin::Group actions */}
                    {<ListToolbar />}
                    {/* end::Group actions */}
                </div>
                {/* end::Card toolbar */}
            </div>
            {isEmpty(filterDate) ? '' :
                <div className='card-header border-0'>
                    <div className='card-title'></div>
                    <div className='card-toolbar me-3'>
                        {formatDate(filterDate.init)} - {formatDate(filterDate.end)}
                    </div>
                </div>
            }
        </>
    )
}

export { Header }
