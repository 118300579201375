/* eslint-disable jsx-a11y/anchor-is-valid */
import {useMemo} from 'react'
import {formatMonetaryToBr} from '../../../utils/formatMonetaryToBr'
import {useDashboard} from '../../../hooks/useDashboard'

interface TopProductsProps {
  className: string
}

export function TopProducts({className}: TopProductsProps) {
  const {query} = useDashboard()
  const topProducts = query.data?.topProducts.itens ?? []
  const totalSaleItens = query.data?.topProducts.total ?? 0
  const isLoading = query.isLoading

  const hasData = !isLoading && topProducts && topProducts?.length > 0
  const hasNotData = !isLoading && topProducts && topProducts?.length == 0


  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Itens Vendidos</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Total vendido: {totalSaleItens} itens</span>
        </h3>
      </div>

      <div className='card-body py-3 overflow-hidden'>
        {isLoading && (
          <div
            className='text-center flex flex-center'
            style={{overflowY: 'auto', height: 200, display: `flex`}}
          >
            <span style={{alignSelf: `center`}}>Carregando...</span>
          </div>
        )}
        {hasNotData && (
          <div
            className='text-center flex flex-center'
            style={{overflowY: 'auto', height: 200, display: `flex`}}
          >
            <span style={{alignSelf: `center`}}>Nenhum dado disponível</span>
          </div>
        )}
        {hasData && (
          <div className='table-responsive' style={{overflowY: 'auto', height: 200}}>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <tbody>
                {topProducts?.map((product, index) => (
                  <tr key={`product-${index}`}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-40px me-4'>
                          <span className='symbol-label' style={{backgroundColor: 'transparent'}}>
                            <i
                              className={`fa-solid fa-${index == 0 ? 'trophy' : index + 1} ${
                                index == 0 ? 'fa-flip' : ''
                              } fs-1 `}
                              style={{
                                animationIterationCount: index === 0 ? '2' : '0',
                                animationTimingFunction: 'cubic-bezier(1, 1.6, 2, 1)',
                                color: index === 0 ? 'rgb(255,255,25)' : '#cdcdde',
                              }}
                            ></i>
                            <span className='fw-bold fs-2 p-1 h-45px text-gray-500'>
                              {index !== 0 && 'º'}
                            </span>
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className='text-dark fw-bold text-hover-danger d-block fs-6'>
                        {product.product_name.toUpperCase()}
                      </span>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        {product.category_name}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-bold text-hover-danger d-block fs-6'>
                        {formatMonetaryToBr(product.total_price) ?? '---'}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}
