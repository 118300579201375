import {KTIcon} from '../../../../_metronic/helpers'
import {useCart} from '../../../../pages/sales/create/core/cartProvider'
import {formatMask} from '../../../../utils/formatMonetaryToBr'
import {useEffect, useState} from 'react'
import {ISelect, ISelectPaymentOption} from '../../../../coreGlobal/models/default'
import {getPaymentOption} from '../../../../pages/sales/create/core/_request'
import {LoadingSpinner} from '../../../../utils/loading/loadingSpinner'
import {PageOverlayer} from '../../../PageOverlayer'

const AUTOMATIC_PAYMENTS = [1, 2, 3, 4]

const methodSize = (length, index) => {
  if (index == 0 && length === 1) {
    return 'col-10 p-0'
  }

  if (index == 0 && length != 0) {
    return 'col-12 p-0'
  }

  if (index != 0 && index < length - 1) {
    return 'col-10 p-0'
  }

  return 'col-7 p-0'
}

const SwitchAction = ({position, length, addMethodPayment, removeMethodPayment}) => {
  if (position === 1 && length > 1) return <></>
  if (position == 1 && length == 1) {
    return (
      <div className='col-2 p-0 d-flex align-items-center justify-content-center '>
        <span
          onClick={() => addMethodPayment()}
          className='btn btn-light-danger font-weight-bold w-auto'
        >
          <KTIcon iconName='plus' className='svg-icon svg-icon-danger' />
        </span>
      </div>
    )
  }

  if (position !== 0 && position !== length) {
    return (
      <div className='col-2 p-0 d-flex align-items-center justify-content-center '>
        <span
          onClick={() => removeMethodPayment()}
          className='btn btn-light-danger font-weight-bold w-auto'
        >
          <KTIcon iconName='trash' className='svg-icon svg-icon-danger' />
        </span>
      </div>
    )
  }

  if (position !== 0 && position === length) {
    return (
      <>
        <div className='col-2 p-0 ms-2 me-2 d-flex align-items-center justify-content-center '>
          <span
            onClick={() => addMethodPayment()}
            className='btn btn-light-danger font-weight-bold w-auto'
          >
            <KTIcon iconName='plus' className='svg-icon svg-icon-danger' />
          </span>
        </div>
        <div className='col-2 p-0 d-flex align-items-center justify-content-center '>
          <span
            onClick={() => removeMethodPayment()}
            className='btn btn-light-danger font-weight-bold w-auto'
          >
            <KTIcon iconName='trash' className='svg-icon svg-icon-danger' />
          </span>
        </div>
      </>
    )
  }

  return <></>
}

export const PaymentContainer = () => {
  const [paymentOption, setStates] = useState<ISelectPaymentOption[] | undefined>([])
  const [loadingPage, setLoadingPage] = useState(false)
  const [optionInstallment, setOptionInstallment] = useState<number[]>([])

  useEffect(() => {
    async function init() {
      setLoadingPage(true)
      try {
        const data = await getPaymentOption()

        const hasEveryProductCategory = shoppingCart.every((valCart) => valCart.category === 3)

        const dataFilterRecurrency = data?.filter(
          (val) => !hasEveryProductCategory || val.value != '3'
        )

        setStates(dataFilterRecurrency)

        if (dataFilterRecurrency && dataFilterRecurrency.length > 0) {
          handlePaymentDefault(dataFilterRecurrency[0])
        }
      } catch (error) {
      } finally {
        setLoadingPage(false)
      }
    }

    init()
  }, [])
  const {
    payment,
    setPayment,
    addMethodPayment,
    removeMethodPayment,
    handleChangeTypeMethodPayment,
    handleChangeValueMethodPayment,
    handleChangeInstallments,
    sum,
    shoppingCart,
  } = useCart()

  function handlePaymentDefault(dta: any) {
    let newData = [...payment]
    newData[0] = {
      ...payment[0],
      method: dta.value,
      value: 0,
      numberOfInstallments: dta.value == 3 ? 2 : 1,
      hasInstallment: dta.installment_enabled,
      recurrency: dta.value === 3,
    }
    setPayment(newData)
    setOptionInstallment(Array.from({length: Number(dta.numberInstallment)}, (_, i) => i + 1))
  }
  const hasInstallment = (id, index, e) => {
    const data = paymentOption?.find((val) => {
      return val.value == id && val.installment_enabled == true
    })

    if (data !== undefined) {
      handleChangeTypeMethodPayment(index, e, true)
      const installmentsArray = Array.from(
        {length: Number(data?.numberInstallment)},
        (_, i) => i + 1
      )
      setOptionInstallment(installmentsArray)
      return
    }

    handleChangeTypeMethodPayment(index, e, false)
  }

  return (
    <>
      {loadingPage ? (
        <PageOverlayer />
      ) : (
        payment?.map((pay, index) => {
          const paymentMethodSize = methodSize(payment.length, index)
          return (
            <div className='mb-8 w-100'>
              <label>{index + 1}. Forma:</label>
              <div className='row m-0 col-12'>
                <div className={paymentMethodSize}>
                  <select
                    className='form-select form-select-lg form-control form-control-lg form-control-solid mb-3 mb-lg-0 border border-dark'
                    value={pay.method}
                    onChange={(e) => {
                      hasInstallment(e.target.value, index, e)
                    }}
                  >
                    {paymentOption?.some((option) =>
                      AUTOMATIC_PAYMENTS.includes(Number(option.value))
                    ) && (
                      <optgroup label='Automáticos:'>
                        {paymentOption
                          .filter((option) => AUTOMATIC_PAYMENTS.includes(Number(option.value)))
                          .map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                      </optgroup>
                    )}

                    {paymentOption?.some(
                      (option) => !AUTOMATIC_PAYMENTS.includes(Number(option.value))
                    ) && (
                      <optgroup label='Manuais:'>
                        {paymentOption
                          .filter((option) => !AUTOMATIC_PAYMENTS.includes(Number(option.value)))
                          .map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                      </optgroup>
                    )}
                  </select>
                </div>
                <SwitchAction
                  position={index + 1}
                  length={payment.length}
                  addMethodPayment={addMethodPayment}
                  removeMethodPayment={() => {
                    removeMethodPayment(pay.paymentRef)
                  }}
                />
              </div>
              <div className='row mt-2 m-0'>
                <div className={'col-8 p-0'}>
                  <label>Valor:</label>
                  <input
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 border border-dark col-5'
                    value={formatMask(sum === 0 ? 0 : pay.value)}
                    onChange={(e) => {
                      handleChangeValueMethodPayment(index, formatMask(e.target.value))
                    }}
                  />
                </div>

                {pay.hasInstallment && (
                  <div className='col-4 p-0 ps-2'>
                    <label>N. Parcelas:</label>
                    <select
                      className='form-select form-select-lg form-control form-control-lg form-control-solid mb-3 mb-lg-0 border border-dark'
                      value={pay.numberOfInstallments}
                      onChange={(e) => handleChangeInstallments(index, e.target.value)}
                    >
                      {optionInstallment.map((val) => (
                        <option value={val}>{val}</option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
            </div>
          )
        })
      )}
    </>
  )
}
