import {PageLink, PageTitle} from '../../_metronic/layout/core'
import {ID, toAbsoluteUrl} from '../../_metronic/helpers'
import {getsaleById, getSaleMedicalRecordsById} from './core/_request'
import {Outlet, useNavigate, useParams} from 'react-router-dom'
import {BillingProvider, useBillingContext} from './core/billingProvider'
import {ContractProvider, useContractContext} from './core/ContractProvider'
import {ContractListModal} from './components/contracts-list-modal/ContractListModal'
import {ContractList} from './components/ContractList'
import {BillingList} from './components/BillingList'
import {ItemSaleList} from './components/ItemSaleList'
import {useEffect, useState} from 'react'
import {ISaleResponse} from '../../coreGlobal/models/sale'
import {LoadingSpinner} from '../../utils/loading/loadingSpinner'
import {ResponsibleDetail} from './components/ResponsibleDetail'
import {ListSalesMedicalRecords} from './components/ListSalesMedicalRecords'
import {ListSalesHistorySchedules} from './components/ListSalesHistorySchedules'
import Swal from 'sweetalert2'
import {sendLinkChargeToCustomer, resendContractToClient} from './components/core/_requests'
import {copyToPayment} from '../../utils/copyToPayment'
import {formatDateTime} from '../../utils/dayJs/format'
import {ListSalesStatus} from './components/ListSaleStatus'
import {RecurrentPaymentProvider} from './components/modalRecurrentPayment/core/recurrentPaymentProvider'
import {CancelSaleModal} from './payment/components/cancelSaleModal'
import {PageOverlayer} from '../../components/PageOverlayer'
import {phoneMask} from '../../utils/phoneMask'

const AUTOMATIC_PAYMENTS = [1, 2, 3, 4]

const machineBreadcrumbs: Array<PageLink> = [
  {
    title: 'Vendas',
    path: '/sales',
    isSeparator: false,
    isActive: false,
  },
]

const ListSalesHistoryMedicalRecord = ({data}) => {
  const [dataSale, setDataSale] = useState()

  useEffect(() => {
    const init = async () => {
      const salesHistorySchedules = await getSaleMedicalRecordsById(Number(data))
      setDataSale(salesHistorySchedules)
    }
    init()
  }, [])

  return <ListSalesHistorySchedules salesHistorySchedules={dataSale} />
}

export const SaleShowPage = () => {
  let {id} = useParams()
  const {contractView, setContractView} = useContractContext()
  const [status, setStatus] = useState('')
  const [loadingPage, setLoadingPage] = useState(false)
  const {setBillingData} = useBillingContext()
  const [saleData, setSaleData] = useState<ISaleResponse>()
  const [attempts, setAttempts] = useState(3)
  const [openCancelModal, setCancelModal] = useState(false)
  const [hasCancelData, setHasCancelData] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const init = async () => {
      setLoadingPage(true)
      try {
        const data = await getsaleById(String(id))
        setSaleData(data)
        setBillingData(data.billings)
        setLoadingPage(false)
      } catch (error: any) {
        setLoadingPage(false)
        Swal.fire({
          title: 'Erro',
          text: error.response.data.message,
          icon: 'error',
          showConfirmButton: true,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/sales/list')
          }
        })
      }
    }
    init()
  }, [])

  useEffect(() => {
    if (saleData) {
      switch (saleData.status) {
        case 'Pendente':
          setStatus('btn btn-light-warning')
          break
        case 'Pago':
          setStatus('btn btn-light-success')
          break
        case 'Cancelado':
          setStatus('btn btn-light-danger')
          break
        default:
          setStatus('btn btn-light-primary')
      }
    }
  }, [saleData])

  const actionDropdown = async (action: 'RESEND' | 'COPY') => {
    if (action == 'COPY') {
      copyToPayment(saleData?.encryptId!)
      return
    }

    if (action == 'RESEND') {
      Swal.fire({
        title: 'Deseja reenviar o link de pagamento?',
        html: `<b>Email:</b> ${saleData?.client?.email}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-danger', // Classe personalizada para o botão de confirmação
          cancelButton: 'btn btn-secondary', // Classe personalizada para o botão de cancelamento (opcional)
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoadingPage(true)
          try {
            await sendLinkChargeToCustomer(saleData?.id)
            setAttempts(attempts - 1)
            return await Swal.fire({
              position: 'center',
              icon: 'info',
              title: 'Reenviando o Link de pagamento',
              showConfirmButton: true,
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading()
              },
              customClass: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-secondary',
              },
            })
          } catch (error: any) {
            return await Swal.fire({
              position: 'center',
              icon: 'error',
              title: error.response.data.message,
              showConfirmButton: true,
              allowOutsideClick: false,
              customClass: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-secondary',
              },
            })
          } finally {
            setLoadingPage(false)
            Swal.close()
            return
          }
        }
      })
    }
  }

  const resendContract = async () => {
    Swal.fire({
      title: 'Deseja reenviar o contrato?',
      html: `<b>Telefone:</b> ${phoneMask(saleData?.client?.contacts[0].phone)}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-danger', // Classe personalizada para o botão de confirmação
        cancelButton: 'btn btn-secondary', // Classe personalizada para o botão de cancelamento (opcional)
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoadingPage(true)
        try {
          await resendContractToClient(saleData?.id)
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Contrato reenviado para o cliente.',
            showConfirmButton: true,
            allowOutsideClick: false,
            timer: 2000,
            customClass: {
              confirmButton: 'btn btn-danger',
              cancelButton: 'btn btn-secondary',
            },
          })
        } catch (error: any) {
          await Swal.fire({
            position: 'center',
            icon: 'error',
            title: error.response.data.message,
            showConfirmButton: true,
            allowOutsideClick: false,
            customClass: {
              confirmButton: 'btn btn-danger',
              cancelButton: 'btn btn-secondary',
            },
          })
        } finally {
          setLoadingPage(false)
        }
      }
    })
  }

  const hasNonPendingInstallment = (sales) => {
    const hasCancel = sales?.billings.some((billing) => billing.status !== 'Pendente')

    setHasCancelData(hasCancel)
  }

  const get_badge_class = () => {
    let badgeClass = ''
    switch (saleData?.client?.lastClientHistory?.status.name) {
      case 'Ativo':
        badgeClass = 'success'
        break
      case 'Restrito':
        badgeClass = 'warning'
        break
      default:
        badgeClass = 'danger'
        break
    }
    return badgeClass
  }

  const hasAutomaticPayment =
    saleData?.billings.some((billing) => AUTOMATIC_PAYMENTS.includes(billing.paymentOption.id)) ||
    false

  function getAvatarImg(person) {
    if (person?.hideAvatar) {
      return 'avatar-neutro.svg'
    }
    switch (person?.gender) {
      case 'F':
        return 'avatar-feminino.svg'
      case 'M':
        return 'avatar-masculino.svg'
      default:
        return 'avatar-neutro.svg'
    }
  }

  return (
    <>
      {loadingPage ? (
        <PageOverlayer />
      ) : (
        <>
          {openCancelModal && (
            <CancelSaleModal close={() => setCancelModal(false)} saleId={Number(saleData?.id)} />
          )}
          <Outlet />
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-0'>
              <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                <div className='me-7 mb-4'>
                  <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                    <img
                      src={toAbsoluteUrl(
                        `/media/avatars/${getAvatarImg(saleData?.client?.physicalPerson)}`
                      )}
                      alt='Metronic'
                    />
                    <div
                      className={`position-absolute translate-middle bottom-0 start-100 mb-6 bg-${get_badge_class()} rounded-circle border border-4 border-white h-20px w-20px`}
                    ></div>
                  </div>
                </div>
                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center mb-2'>
                        <a className='text-gray-800 text-hover-danger fs-2 fw-bolder me-1'>
                          {`${saleData?.client?.id} - ${saleData?.client?.name} - VENDA: ${saleData?.id}`}
                        </a>
                      </div>
                      <div className='fs-4 fw-bolder me-1'>
                        <strong>{formatDateTime(String(saleData?.createdAt))}</strong>
                      </div>
                    </div>
                    <div className='d-flex '>
                      <h1 className='me-1'>
                        <a className={`${status}`}>Status: {saleData?.status}</a>
                      </h1>
                      {(saleData?.status === 'Pendente' || saleData?.status === 'Emitido') &&
                        (!hasAutomaticPayment ||
                          attempts > 0 ||
                          !hasCancelData ||
                          saleData.status === 'Emitido') && (
                          <div className='dropdown'>
                            <button
                              className='btn btn-link btn-color-muted btn-active-color-danger'
                              style={{marginTop: '-9px'}}
                              type='button'
                              data-bs-toggle='dropdown'
                              aria-expanded='true'
                              onClick={() => {
                                hasNonPendingInstallment(saleData)
                              }}
                            >
                              <i
                                className='ki-duotone ki-dots-square-vertical fs-3x'
                                style={{marginBottom: '-15px'}}
                              >
                                <span className='path1'></span>
                                <span className='path2'></span>
                                <span className='path3'></span>
                                <span className='path4'></span>
                              </i>
                            </button>
                            <ul className='dropdown-menu'>
                              {saleData?.status === 'Pendente' && hasAutomaticPayment && (
                                <li>
                                  <button
                                    className='dropdown-item'
                                    onClick={() => actionDropdown('COPY')}
                                  >
                                    Copiar Link
                                  </button>
                                </li>
                              )}
                              {saleData?.status === 'Pendente' &&
                                hasAutomaticPayment &&
                                attempts > 0 && (
                                  <li>
                                    <button
                                      className='dropdown-item'
                                      onClick={() => actionDropdown('RESEND')}
                                    >
                                      Reenviar Link
                                    </button>
                                  </li>
                                )}
                              {saleData?.status === 'Pendente' && !hasCancelData && (
                                <li>
                                  <button
                                    className='dropdown-item'
                                    onClick={() => setCancelModal(true)}
                                  >
                                    Cancelar Venda
                                  </button>
                                </li>
                              )}
                              {saleData.status === 'Emitido' && (
                                <li>
                                  <button
                                    className='dropdown-item'
                                    onClick={() => resendContract()}
                                  >
                                    Reenviar Contrato
                                  </button>
                                </li>
                              )}
                            </ul>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex overflow-auto h-55px'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                  <li className='nav-item'>
                    <button
                      className='nav-link active'
                      id='pills-items-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-items'
                      type='button'
                      role='tab'
                      aria-controls='pills-items'
                      aria-selected='true'
                    >
                      Items da Venda
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      className='nav-link'
                      id='pills-cobranca-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-cobranca'
                      type='button'
                      role='tab'
                      aria-controls='pills-cobranca'
                      aria-selected='true'
                    >
                      Historico Cobrança
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      className='nav-link'
                      id='pills-contact-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-tabcontract'
                      type='button'
                      role='tab'
                      aria-controls='pills-tabcontract'
                      aria-selected='true'
                    >
                      Contratos
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      className='nav-link'
                      id='pills-medical-records-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-tab-medical-records'
                      type='button'
                      role='tab'
                      aria-controls='pills-tab-medical-records'
                      aria-selected='true'
                    >
                      Prontuário
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      className='nav-link'
                      id='pills-history-schedules-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-history-schedules'
                      type='button'
                      role='tab'
                      aria-controls='pills-history-schedules'
                      aria-selected='true'
                    >
                      Histórico de agendamento
                    </button>
                  </li>
                  {saleData?.responsible && (
                    <li className='nav-item'>
                      <button
                        className='nav-link'
                        id='pills-responsible-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#pills-responsible'
                        type='button'
                        role='tab'
                        aria-controls='pills-responsible'
                        aria-selected='true'
                      >
                        Responsável
                      </button>
                    </li>
                  )}
                  <li className='nav-item'>
                    <button
                      className='nav-link'
                      id='pills-salesHistory-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-salesHistory'
                      type='button'
                      role='tab'
                      aria-controls='pills-salesHistory'
                      aria-selected='true'
                    >
                      Histórico de Status
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <PageTitle breadcrumbs={machineBreadcrumbs}>Detalhamento da Venda</PageTitle>
          <div className='tab-content' id='pills-tabContent'>
            <div
              className='tab-pane fade show active p-4 pt-0 pt-md-4'
              id='pills-items'
              role='tabpanel'
              aria-labelledby='pills-checklist-tab'
            >
              <div className='card mb-5 mb-xl-10'>
                <ItemSaleList itemSaleData={saleData?.items} />
              </div>
            </div>
            <div
              className='tab-pane fade p-4 pt-0 pt-md-4'
              id='pills-cobranca'
              role='tabpanel'
              aria-labelledby='pills-checklist-tab'
            >
              <div className='card mb-5 mb-xl-10'>
                <BillingList saleStatus={saleData?.status} />
              </div>
            </div>
            <div
              className='tab-pane fade p-4 pt-0 pt-md-4'
              id='pills-tabcontract'
              role='tabpanel'
              aria-labelledby='pills-checklist-tab'
            >
              <div className='card mb-5 mb-xl-10'>
                <ContractList saleId={id} />
              </div>
            </div>
            <div
              className='tab-pane fade p-4 pt-0 pt-md-4'
              id='pills-tab-medical-records'
              role='tabpanel'
              aria-labelledby='pills-checklist-tab'
            >
              <div className='card mb-5 mb-xl-10'>
                <ListSalesMedicalRecords saleId={Number(id)} />
              </div>
            </div>
            <div
              className='tab-pane fade p-4 pt-0 pt-md-4'
              id='pills-history-schedules'
              role='tabpanel'
              aria-labelledby='pills-checklist-tab'
            >
              <div className='card mb-5 mb-xl-10'>
                <ListSalesHistoryMedicalRecord data={Number(id)} />
              </div>
            </div>
            {saleData?.responsible && (
              <div
                className='tab-pane fade p-4 pt-0 pt-md-4'
                id='pills-responsible'
                role='tabpanel'
                aria-labelledby='pills-checklist-tab'
              >
                <div className='card mb-5 mb-xl-10'>
                  <ResponsibleDetail responsibleData={saleData?.responsible} />
                </div>
              </div>
            )}
            <div
              className='tab-pane fade p-4 pt-0 pt-md-4'
              id='pills-salesHistory'
              role='tabpanel'
              aria-labelledby='pills-salesHistory'
            >
              <div className='card mb-5 mb-xl-10'>
                <ListSalesStatus history={saleData?.history} />
              </div>
            </div>
          </div>
          {contractView && <ContractListModal saleId={id} />}
        </>
      )}
    </>
  )
}

export const SalesViewPage = () => {
  return (
    <>
      <ContractProvider>
        <BillingProvider>
          <RecurrentPaymentProvider>
            <SaleShowPage />
          </RecurrentPaymentProvider>
        </BillingProvider>
      </ContractProvider>
    </>
  )
}
