import { KTIcon } from "../../../../../../_metronic/helpers";
import { phoneMask } from "../../../../../../utils/phoneMask";

const ListSearchSubscriptionPhone = ({ searchPhone, setSearchPhone }) => {

    return (
        <>
            <div className='mb-5'>
                <label className='form-label fs-6 fw-bold'>Pesquisar por Número de telefone:</label>
                <div className='d-flex align-items-center position-relative my-1'>
                    <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                    <input
                        type='text'
                        data-kt-user-table-filter='phones'
                        className='form-control form-control-solid min-w-250px ps-14'
                        placeholder='Informe o número de telefone'
                        value={phoneMask(searchPhone)}
                        onChange={(e) => {
                            if (e.target.value.length <= 15) {
                                setSearchPhone(e.target.value)
                            }
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export { ListSearchSubscriptionPhone }
