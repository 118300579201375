import { useEffect } from "react";

const ListSearchSubscriptionDate = ({ setSearchDateStart, searchDateStart, setSearchDateFinish, searchDateFinish, setErrors, errors }) => {

    useEffect(() => {
        if (searchDateStart.length === 10 && searchDateFinish.length === 10) {
            if (new Date(searchDateStart).setHours(0, 0, 0, 0) > new Date(searchDateFinish).setHours(0, 0, 0, 0)) {

                const novosErros = [...errors];
                novosErros[0] = 'Data inicial deve ser menor que data final';
                novosErros[1] = 'Data inicial deve maior que data final';
                setErrors(novosErros);
                return
            }
        } else if (searchDateStart.length === 10 && searchDateFinish.length < 10) {
            const novosErros = [...errors];
            novosErros[0] = '';
            novosErros[1] = 'Data final deve ser informada';
            setErrors(novosErros);
            return
        } else if (searchDateFinish.length === 10 && searchDateStart.length < 10) {
            const novosErros = [...errors];
            novosErros[0] = 'Data inicial deve ser informada';
            novosErros[1] = '';
            setErrors(novosErros);
            return
        }
        const novosErros = [...errors];
        novosErros[0] = '';
        novosErros[1] = '';
        setErrors(novosErros);
    }, [searchDateStart, searchDateFinish])

    return (
        <>
            <div className='mb-5 row'>
                <div className="col-6">
                    <label className='form-label fs-6 fw-bold'>Pesquisar por Data: Inicial</label>
                    <input
                        type='date'
                        data-kt-user-table-filter='dtStart'
                        className='form-control form-control-solid w-100'
                        placeholder='Data inícial'
                        value={searchDateStart}
                        onChange={(e) => setSearchDateStart(e.target.value)}
                        min="2015-12-31"
                        maxLength={10}
                    />
                    {errors && errors.length > 0 && errors[0] ? (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block fs-6'>{errors[0]}</div>
                        </div>
                    ) : null}
                </div>
                <div className="col-6">
                    <label className='form-label fs-6 fw-bold'>Pesquisar por Data: Final</label>
                    <input
                        type='date'
                        data-kt-user-table-filter='dtEnd'
                        className='form-control form-control-solid'
                        placeholder='Data Final'
                        value={searchDateFinish}
                        onChange={(e) => setSearchDateFinish(e.target.value)}
                        min="2015-12-31"
                        maxLength={10}
                    />
                    {errors && errors.length > 0 && errors[1] ? (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block fs-6'>{errors[1]}</div>
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    )
}

export { ListSearchSubscriptionDate }
