import {useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import {useNavigate} from 'react-router-dom'
import {AdressProvider, useAdress} from '../../coreGlobal/providers/AdressProvider'
import {useFormik} from 'formik'
import Swal from 'sweetalert2'
import {UserForm} from './components/UserForm'
import {PeopleProvider, usePeople} from '../../coreGlobal/providers/PersonProvider'
import {FranchiseProvider} from '../franchise/core/franchiseProvider'
import {TCreateUserRequest, userInitValues as initialValues} from '../../coreGlobal/models/user'
import {userSchema} from './core/validation/UserVali'
import {userBuildRequest} from './core/UserBuildRequest'
import {UsersForm, UsersFormProvider} from './core/UsersFormProvider'
import {LoadingSpinner} from '../../utils/loading/loadingSpinner'
import {createUser} from './core/_resquest'
import {PageOverlayer} from '../../components/PageOverlayer'

const UserBreadcrumbs: Array<PageLink> = [
  {
    title: 'Usuário',
    path: 'user',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const Create = () => {
  const [loadingPage, setLoadingPage] = useState(false)
  const navigate = useNavigate()
  const {getDataStates, dataStates, dataCep} = useAdress()
  const {getDataGroups, getDataFranchises, setLoading} = UsersForm()
  const {persons} = usePeople()

  useEffect(() => {
    const init = async () => {
      setLoadingPage(true)
      await getDataStates()
      await getDataGroups()
      await getDataFranchises()
      setLoadingPage(false)
    }
    init()
  }, [])

  useEffect(() => {
    if (!dataCep?.data.message) {
      formik.setFieldValue('street', dataCep?.data.address)
      formik.setFieldValue('neighborhood', dataCep?.data.neighbourhood)

      const findState = dataStates?.find((state) => state.uf === dataCep?.data.state)
      if (findState) {
        formik.setFieldValue('state', findState.id)
        const findCity = findState.cities?.find((city) => city.name === dataCep?.data.city)
        if (findCity) {
          formik.setFieldValue('city', findCity.id)
        }
      }
    } else {
      formik.setFieldValue('addresses[0].street', '')
      formik.setFieldValue('addresses[0].neighborhood', '')
      formik.setFieldValue('addresses[0].state', '')
      formik.setFieldValue('addresses[0].city', '')
      formik.setFieldValue('addresses[0].complement', '')
      formik.setFieldValue('addresses[0].number', '')

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Cep inválido',
      })
    }
  }, [dataCep])

  const formik = useFormik({
    initialValues,
    validationSchema: userSchema,
    onSubmit: async (values) => {
      const valuesFilter = await userBuildRequest(
        values,
        Number(persons.map((person) => person.id))
      )
      submit(valuesFilter)
    },
  })

  const submit = async (req: TCreateUserRequest) => {
    await Swal.fire({
      title: 'Deseja criar o acesso ao sistema?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-danger', // Classe personalizada para o botão de confirmação
        cancelButton: 'btn btn-secondary', // Classe personalizada para o botão de cancelamento (opcional)
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true)
        try {
          await createUser(req)
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Salvo com sucesso',
            showConfirmButton: false,
            timer: 1500,
          })
          navigate('/users/list', {replace: true})
        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = ''
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>'
            }
            await Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText,
            })
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 2000,
            })
          }
        } finally {
          setLoading(false)
        }
      }
    })
  }

  return <>{loadingPage ? <PageOverlayer /> : <UserForm formik={formik} id={''} />}</>
}
export const UserCreatePage = () => {
  return (
    <>
      <PageTitle breadcrumbs={UserBreadcrumbs}>Criar Usuário</PageTitle>
      <PeopleProvider>
        <AdressProvider>
          <FranchiseProvider>
            <UsersFormProvider>
              <Create />
            </UsersFormProvider>
          </FranchiseProvider>
        </AdressProvider>
      </PeopleProvider>
    </>
  )
}
