/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { initialQueryState, KTIcon, useDebounce } from '../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { getStatusSales } from '../../core/_requests'
import { MultiSelect, Option } from "react-multi-select-component"
import "../../../../../style/multiSelectSucess.css"

const Search = () => {
  const { updateState } = useQueryRequest()
  const [searchClient, setSearchClient] = useState<string>('')
  const [searchId, setSearchId] = useState<string>('')

  const initialSelectValue = [{ value: '', label: '' }];
  const [saleStatusOption, setSaleStatusOption] = useState<Option[]>(initialSelectValue);
  const [statusValue, setStatusValue] = useState<Option[]>([]);


  // Debounce search term so that it only gives us latest value ...
  // ... if searchClient has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchClient = useDebounce(searchClient, 150)

  const debouncedSearchid = useDebounce(searchId, 150)

  useEffect(() => {
    async function initialize() {
      const data = await getStatusSales()
      setSaleStatusOption(data!)
    }

    initialize()
  }, [])

  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchClient !== undefined && searchClient !== undefined) {
        updateState({ name: debouncedSearchClient, ...initialQueryState })
      }
    },
    [debouncedSearchClient] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )

  useEffect(
    () => {
      if (debouncedSearchid !== undefined && searchId !== undefined) {
        updateState({ id: debouncedSearchid, ...initialQueryState })
      }
    },
    [debouncedSearchid] // Only call effect if debounced search term changes
  )

  return (
    <div className='card-title'>



      <div className="container">
        <div className="row">
          <div className="col">
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1 mx-2'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='number'
                data-kt-user-table-filter='id'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Pesquisar nº venda'
                value={searchId}
                onChange={(e) => setSearchId(e.target.value)}
              />
            </div>
          </div>
          <div className="col">
            <div className='d-flex align-items-center position-relative my-1 mx-2'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='name'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Pesquisar cliente'
                value={searchClient}
                onChange={(e) => setSearchClient(e.target.value)}
              />
            </div>
          </div>
          <div className="col">
            <div className='fv-row my-1 mx-2'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <MultiSelect
                className='form-control w-250px rmscStyle ps-14'
                data-kt-user-table-filter='status'
                options={saleStatusOption}
                value={statusValue}
                onChange={(option) => {
                  const valores = option.map((item) => item.value)
                  updateState({ status: String(valores), ...initialQueryState })
                  setStatusValue(option)
                }}
                labelledBy={''}
                shouldToggleOnHover
                overrideStrings={{
                  selectSomeItems: 'Pesquisar por status',
                  search: 'Procurar',
                  selectAll: 'Selecionar todos',
                  allItemsAreSelected: 'Todos status selecionados',
                  selectAllFiltered: 'Selecionar todos (filtrados)',
                  noOptions: 'Nenhuma opção encontrada',
                }}
              />
            </div>
          </div>
        </div>
      </div>


      {/* end::Search */}
    </div>
  )
}

export { Search }
