import { useParams } from "react-router-dom";
import { PageLink, PageTitle } from "../../_metronic/layout/core";
import { useEffect, useState } from "react";
import { TMachineRequest } from "../../coreGlobal/models/machines/machine";
import { useFormik } from "formik";
import { machineSchema } from "./core/validation/machineVali";
import { updateMachine } from "./core/_request";
import { MachineForm } from "./components/MachineForm";
import { LoadingSpinner } from "../../utils/loading/loadingSpinner";
import Swal from "sweetalert2";
import { MachineProvider, useMachineForm } from "./core/MachineProvider";
import { BuildRequest } from "./core/BuildRequest";

const MachinesBreadcrumbs: Array<PageLink> = [
    {
        title: 'Máquinas',
        path: '/machines',
        isSeparator: false,
        isActive: false
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false
    }
]
const Update = () => {
    let { id } = useParams();
    const { navigate, loading, machine, setLoading, getDataMachine, getDataProducts, setFieldProducts, fieldProducts } = useMachineForm()

    const [loadingPage, setLoadingPage] = useState(false);

    const [machineInicitalValues] = useState<TMachineRequest>({
        name: '',
        description: '',
        products: [{
            product: '',
            sessionTime: ''
        }]
    })

    const formik = useFormik({
        initialValues: machineInicitalValues,
        validationSchema: machineSchema,
        onSubmit: async (values, fields) => {
            const valuesFilter = await BuildRequest(values)
            submit(valuesFilter)
        }
    })

    useEffect(() => {
        const init = async () => {
            setLoadingPage(true)
            await getDataMachine(String(id))
            await getDataProducts()
            setLoadingPage(false)
        }
        init()
    }, [])

    useEffect(() => {
        if (machine.products) {
            const init = async (machine) => {
                
                const data = [{ product: '', sessionTime: '' }];
                await machine.products.map((field, index) => {
                    data[index] = {
                        product: String(field?.product.id), sessionTime: field?.sessionTime
                    }    
                })
                setFieldProducts(data)
    
                formik.setValues({
                    name: machine.name,
                    description: machine.description ? machine.description : '',
                    products: data
                });
            }
            init(machine)
        }
    }, [machine])

    const submit = async (req: TMachineRequest) => {
        await Swal.fire({
            title: 'Deseja atualizar a Máquina?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            customClass: {
                confirmButton: 'btn btn-danger', // Classe personalizada para o botão de confirmação
                cancelButton: 'btn btn-secondary' // Classe personalizada para o botão de cancelamento (opcional)
              }
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true)
                try {
                    await updateMachine(String(id), req)
                    await Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Salvo com sucesso',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    navigate('/franchises/machines', { replace: true });
                } catch (error: any) {
                    if (error.response.status === 422) {
                        let errorText = '';
                        for (let key in error.response.data.errors) {
                            errorText += '<li>' + error.response.data.errors[key][0] + '</li>';
                        }
                        await Swal.fire({
                            icon: 'error',
                            title: 'Ops! Revise seu formulário e tente novamente!',
                            html: errorText
                        });
                    } else {
                        await Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: error.response.data.message,
                            timer: 2000
                        })
                    }
                } finally {
                    setLoading(false)
                }
            }
        })
    }

    return (
        <>
            {loadingPage ? <LoadingSpinner title={"Carregando Máquina"} /> :
                <MachineForm formik={formik} />
            }
        </>
    )
}
export const MachineEditPage = () => {
    return (
        <>
            <PageTitle breadcrumbs={MachinesBreadcrumbs}>Atualizar Máquina</PageTitle>
            <MachineProvider>
                <Update />
            </MachineProvider>

        </>
    )
}