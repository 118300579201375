const QUERIES = {
  FRANCHISE_LIST: 'franchise-list',
  PRODUCT_SHOW: 'product-show',
  PRODUCT_LIST: 'product-list',
  MACHINE_LIST: 'machine-list',
  MACHINE_SHOW: 'machine-show',
  CATEGORY_LIST: 'category-list',
  CATEGORY_SHOW: 'category-show',
  PEOPLE_LIST: 'people-list',
  PEOPLE_SHOW: 'people-show',
  MEDICALCONDITION_LIST: 'medicalConditions-list',
  MEDICALCONDITION_SHOW: 'medicalConditions-show',
  GROUP_LIST: 'group-list',
  GROUP_SHOW: 'group-show',
  SALE_SHOW: 'sale-list',
  BILLING_LIST: 'billing-list',
  BILLING_SHOW: 'billing-show',
  PAYMENT_SHOW: 'payment-show',
  BUDGET_SHOW: 'budget-show',
  ROOM_LIST: 'room-list',
  ROOM_SHOW: 'room-show',
  BUDGET_LIST: 'budget-list',
  CLIENT_TEMPORARILY_LIST: 'client-temporarily-list',
  CHANGE_CART: 'change_cart',
  TRANSFER_CART: 'transfer-cart',
}
export {QUERIES}
