import axios, {AxiosResponse} from 'axios'
import {TransfersQueryResponse} from './_models'
import { Option } from "react-multi-select-component"
import { Response } from '../../../../_metronic/helpers'


const API_URL = process.env.REACT_APP_API_URL + '/api'
const TRANSFER_URL = `${API_URL}/transfers`
const FRANCHISE_URL = `${API_URL}/franchises`

const getTransfers = (query: string): Promise<TransfersQueryResponse> => {
  return axios
    .get(`${TRANSFER_URL}?${query}`)
    .then((d: AxiosResponse<TransfersQueryResponse>) => d.data)
}

const getTransferById = (id: string) => {
  return axios
    .get(`${TRANSFER_URL}/${id}`)
    .then((d: any) => d.data)
    .then((d: any) => d.data)
}

const approveTransfer = (id: string) => {
  return axios
    .put(`${TRANSFER_URL}/${id}/approve`)
    .then((d: any) => d.data)
    .then((d: any) => d.data)
}

const cancelTransfer = (id: string) => {
  return axios
    .put(`${TRANSFER_URL}/${id}/cancel`)
    .then((d: any) => d.data)
    .then((d: any) => d.data)
}

const getPixFranchise = (id: string) => {
  return axios
    .get(`${FRANCHISE_URL}/${id}/pix-key`)
    .then((d: any) => d.data)
    .then((d: any) => d.data)
}

const confirmPaymentReq = (id: string) => {
  return axios
    .put(`${TRANSFER_URL}/${id}/low-franchise-payment`)
    .then((d: any) => d.data)
    .then((d: any) => d.data)
}

const getStatusTransfer = (): Promise<Option[] | undefined> => {
  return axios
    .get(`${TRANSFER_URL}/statuses?all=1`)
    .then((response: AxiosResponse<Response<Option[]>>) => response.data)
    .then((response: Response<Option[]>) => response.data)
}

export {
  getTransfers,
  getTransferById,
  approveTransfer,
  cancelTransfer,
  getPixFranchise,
  confirmPaymentReq,
  getStatusTransfer
}
