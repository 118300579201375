// @ts-nocheck
import { Column } from 'react-table'
import { CustomHeader } from './CustomHeader'
import { SelectionHeader } from './SelectionHeader'
import { User } from '../../core/_models'
import { CustonCell } from './CustonCell'
import { formatMask } from '../../../../../../utils/formatMonetaryToBr'
import { formatDateTime } from '../../../../../../utils/dayJs/format'

const ReportSalesColumns: ReadonlyArray<Column<User>> = [

  {
    Header: (props) => <CustomHeader tableProps={props} title='Nome' className='min-w-125px' />,
    id: 'name',
    Cell: ({ ...props }) => <CustonCell value={props.data[props.row.index].name} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Depilação' className='min-w-125px' />,
    id: 'hair_removal',
    Cell: ({ ...props }) => <CustonCell value={formatMask(props.data[props.row.index].hairRemoval)} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Estética' className='min-w-125px' />,
    id: 'esthetic',
    Cell: ({ ...props }) => <CustonCell value={formatMask(props.data[props.row.index].esthetic)} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Produto' className='min-w-125px' />,
    id: 'product',
    Cell: ({ ...props }) => <CustonCell value={formatMask(props.data[props.row.index].product)} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Qte de Contratos' className='min-w-125px' />,
    id: 'total_sold',
    Cell: ({ ...props }) => <CustonCell value={props.data[props.row.index].totalSold} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Valor Total de Venda' className='min-w-125px' />,
    id: 'price',
    Cell: ({ ...props }) => <CustonCell value={formatMask(props.data[props.row.index].price)} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Valor Total de Desconto' className='min-w-125px' />,
    id: 'discount',
    Cell: ({ ...props }) => <CustonCell value={formatMask(props.data[props.row.index].discount)} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Valor Total Com Desconto' className='min-w-125px' />,
    id: 'total_price',
    Cell: ({ ...props }) => <CustonCell value={formatMask(props.data[props.row.index].total)} />,
  },

]

export { ReportSalesColumns }
