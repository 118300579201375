/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {TransferResponseList} from '../../core/_models'
import {useNavigate} from 'react-router-dom'

type Props = {
  transfer: TransferResponseList
}

export const RequestedByCell: FC<Props> = ({transfer}) => {
  const id = String(transfer.id) as string

  const navigate = useNavigate()

  const handleToShow = () => navigate(`/users/list/${id}`)

  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span
          role='button'
          className='text-gray-800 text-hover-danger mb-1'
          onClick={() => handleToShow()}
        >
          {transfer.requestedUserName}
        </span>
      </div>
    </div>
  )
}
