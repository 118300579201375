import {useNavigate} from 'react-router-dom'

export const FTMessageFinished = ({transfer}) => {
  const navigate = useNavigate()

  return (
    <div className='text-center'>
      <h2 className='fw-bold mb-4'>Transferência pendente</h2>
      <div className='my-3'>
        <i className='bi-info-circle text-warning' style={{fontSize: '4rem'}}></i>
      </div>
      <p className='fs-5 text-dark fw-bold'>Consulte o processo na aba transferência.</p>
      <button
        className='btn btn-light-danger '
        onClick={() => navigate(`/transfers/list/${transfer?.id}`)}
      >
        <i className='ki-duotone ki-book-open me-5 fs-1'>
          <span className='path1'></span>
          <span className='path2'></span>
          <span className='path3'></span>
          <span className='path4'></span>
        </i>
        Visualizar Transferência
      </button>
    </div>
  )
}
