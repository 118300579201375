/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { chooseUserFranchise, getFranchises } from './core/_request'
import Swal from 'sweetalert2'
import { KTIcon } from '../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'
import { Franchise } from '../franchise/list/core/_models'
import { LoadingSpinner } from '../../utils/loading/loadingSpinner'
import { useAuth } from '../../app/modules/auth'

export const AuthCreatePage = () => {
  // const [createdAuthData, setCreateAuthData] = useState<TAuthValidateForm>(initialValues)
  const [loading, setLoading] = useState(false)
  const [loadingPage, setLoadingPage] = useState(false)
  const navigate = useNavigate()
  const [franchises, setFranchises] = useState([])
  const { setCurrentFranchise } = useAuth()

  useEffect(() => {
    async function init() {
      setLoadingPage(true);
      const franchises = await getFranchises()
      setFranchises(franchises)
      setLoadingPage(false);
    }
    init()
  }, [])

  const saveChoose = async (cnpj) => {
    await Swal.fire({
      title: 'Deseja escolher essa franquia?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-danger', // Classe personalizada para o botão de confirmação
        cancelButton: 'btn btn-secondary' // Classe personalizada para o botão de cancelamento (opcional)
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true)
        try {
          const data = await chooseUserFranchise({ 'franchise': cnpj })
          setCurrentFranchise(data)
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Salvo com sucesso',
            showConfirmButton: false,
            timer: 1500,
          })
          navigate(`/auth`)
        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = ''
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>'
            }
            await Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText,
            })
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 2000,
            })
          }
        } finally {
          setLoading(false)
        }
      }
    })
  }

  return (
    <>
      {loadingPage ? (
        <LoadingSpinner title={'Carregando unidades'} />
      ) :
        <div className='d-flex flex-column flex-root'>
          <div className='d-flex flex-column flex-center flex-column-fluid'>
            <div className='d-flex flex-column flex-center text-center p-10'>
              <div className='card card-flush  w-lg-1000px py-5'>
                <div className='card-body py-2 lg-20 h-100'>
                  <div className='w-100'>
                    <div className='pb-10 pb-lg-15'>
                      <h2 className='fw-bolder d-flex align-items-center text-dark justify-content-center'>
                        Escolha a Franquia desejada
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Billing is issued based on your selected account type'
                        ></i>
                      </h2>
                    </div>
                    <div className="container">
                      <div className="row">
                        {franchises.map((franchise: Franchise, index) => (
                          <div key={index} className="col-md-6 col-sm-12 mt-3">
                            <button
                              type='button'
                              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center w-100'
                              value={franchise.person?.juridicalPerson?.cnpjWithoutMask}
                              onClick={() => {
                                const cnpj = franchise.person?.juridicalPerson?.cnpjWithoutMask;
                                saveChoose(cnpj)
                              }}
                            >
                              <KTIcon iconName='briefcase' className='fs-3x me-5' />
                              <span className='d-block fw-bold text-start'>
                                <span className='text-dark fw-bolder d-block fs-4 mb-2'>{franchise.name}</span>
                                <span className='text-gray-400 fw-bold fs-6'>
                                  {franchise.person?.juridicalPerson?.cnpj}
                                </span>
                              </span>
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default AuthCreatePage
