import {PageLink, PageTitle} from '../../_metronic/layout/core'
import {AdressProvider, useAdress} from '../../coreGlobal/providers/AdressProvider'
import {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import {FranchiseProvider} from './core/franchiseProvider'
import {useNavigate} from 'react-router-dom'
import {
  TFranchiseRequest,
  franchiseInitValues as initialValues,
} from '../../coreGlobal/models/franchise/franchise'
import Swal from 'sweetalert2'
import {createFranchise} from './core/_request'
import {buildCreateRequest} from './core/buildCreateRequest'
import {PeopleProvider} from '../../coreGlobal/providers/PersonProvider'
import {PersonValidSchema} from '../../coreGlobal/validation/PersonVali'
import FranchiseForm from './components/FranchiseForm'

const franchiseBreadcrumbs: Array<PageLink> = [
  {
    title: 'franquias',
    path: '/franchise',
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const Create = () => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const {getDataStates, dataStates, dataCep} = useAdress()

  useEffect(() => {
    try {
      getDataStates()
    } catch (error: any) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: error?.response?.data?.message,
        showConfirmButton: true,
      })
    }
  }, [])

  useEffect(() => {
    if (!dataCep?.data.message) {
      formik.setFieldValue('street', dataCep?.data.address)
      formik.setFieldValue('neighborhood', dataCep?.data.neighbourhood)

      const findState = dataStates?.find((state) => state.uf === dataCep?.data.state)
      if (findState) {
        formik.setFieldValue('state', findState.id)
        const findCity = findState.cities?.find((city) => city.name === dataCep?.data.city)
        if (findCity) {
          formik.setFieldValue('city', findCity.id)
        }
      }
    } else {
      formik.setFieldValue('addresses[0].street', '')
      formik.setFieldValue('addresses[0].neighborhood', '')
      formik.setFieldValue('addresses[0].state', '')
      formik.setFieldValue('addresses[0].city', '')
      formik.setFieldValue('addresses[0].complement', '')
      formik.setFieldValue('addresses[0].number', '')

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Cep inválido',
      })
    }
  }, [dataCep])

  const formik = useFormik({
    initialValues,
    validationSchema: PersonValidSchema(true),
    onSubmit: async (values) => {
      const valuesFilter = await buildCreateRequest(values)
      submit(valuesFilter)
    },
  })

  const submit = async (req: TFranchiseRequest) => {
    await Swal.fire({
      title: 'Deseja criar a franquia?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-danger', // Classe personalizada para o botão de confirmação
        cancelButton: 'btn btn-secondary', // Classe personalizada para o botão de cancelamento (opcional)
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true)
        try {
          await createFranchise(req)
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Salvo com sucesso',
            showConfirmButton: false,
            timer: 1500,
          })
          navigate('/franchises/list', {replace: true})
        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = ''
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>'
            }
            await Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText,
            })
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 2000,
            })
          }
        } finally {
          setLoading(false)
        }
      }
    })
  }

  return <FranchiseForm formik={formik} loading={loading} mode='CREATE' />
}

export const FranchiseCreatePage = () => {
  return (
    <>
      <PageTitle breadcrumbs={franchiseBreadcrumbs}>Criar Franquia</PageTitle>
      <PeopleProvider>
        <AdressProvider>
          <FranchiseProvider>
            <Create />
          </FranchiseProvider>
        </AdressProvider>
      </PeopleProvider>
    </>
  )
}
