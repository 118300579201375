import {useIntl} from 'react-intl'
import {PageTitle} from '../../_metronic/layout/core'
import {SellerTable} from './components/SellerTable'
import {useDashboard} from '../../hooks/useDashboard'
import {Sales} from './components/Sales'
import {Contracts} from './components/Contracts'
import {Clients} from './components/Clients'
import {TopProducts} from './components/TopProducts'
import {FilterData} from './components/filter/FilterData'
import {ErrorState} from './components/statesAlert/ErrorState'
import {PageOverlayer} from '../../components/PageOverlayer'

export const Dashboard = () => {
  const intl = useIntl()
  const {
    query: {isLoading, error, refetch},
  } = useDashboard()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <FilterData />
      {isLoading && <PageOverlayer />}
      {error && <ErrorState error={error} onRetry={refetch} />}
      <div className='row g-5 g-xl-10 mb-0 mb-xl-0'>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-0 '>
          <Contracts className='space-dash-graph-height mb-2 mb-xl-6' />
          <Clients className='space-dash-graph-height' />
        </div>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-0 '>
          <Sales className='space-dash-graph-height mb-2 mb-xl-6' />
          <TopProducts className='space-dash-graph-height' />
        </div>
        <div className='col-xxl-4 h-100'>
          <SellerTable className='h-100 mb-0' />
        </div>
      </div>
    </>
  )
}
