import {useQuery} from 'react-query'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import {QUERIES} from '../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import {AxiosError} from 'axios'
import {HeaderForShowPage} from '../../components/HeaderForShowPage'
import {NotFoundIdShowPage} from '../../components/NotFoundIdShowPage'
import {HasErrorInShowPage} from '../../components/HasErrorInShowPage'
import {formatDateTime} from '../../utils/dayJs/format'
import {getMedicalConditionIdById} from './core/_request'
import {LoadingSpinner} from '../../utils/loading/loadingSpinner'
import {PageOverlayer} from '../../components/PageOverlayer'

const medicalConditionBreadcrumbs: Array<PageLink> = [
  {
    title: 'Condição Médica',
    path: '/medicalCondition',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const MedicalConditionShowPage = () => {
  let {id} = useParams()

  const {isLoading, data, error} = useQuery(
    `${QUERIES.MEDICALCONDITION_SHOW}-getById-${id}`,
    () => {
      return getMedicalConditionIdById(String(id))
    },
    {
      cacheTime: 0,
      enabled: true,
      onError: (err) => {},
    }
  )

  if (isLoading) {
    return <PageOverlayer />
  }

  if (error) {
    if (error instanceof AxiosError) {
      const status = error.response?.status

      switch (status) {
        case 404:
          return <NotFoundIdShowPage source='Alergia' />
        default:
          return <HasErrorInShowPage />
      }
    }

    return <HasErrorInShowPage />
  }

  const {
    name,
    description,
    blockExistingAppointments,
    blockNewAppointments,
    blockNewSales,
    products,
    createdAt,
    updatedAt,
  } = data

  return (
    <>
      <PageTitle breadcrumbs={medicalConditionBreadcrumbs}>Dados da Condição Médica</PageTitle>
      <div className='card'>
        <div className='card-body'>
          <HeaderForShowPage title='Informações da Condição Médica' />
          <p>
            <b>Nome:</b> {name || ' '}
          </p>
          <p>
            <b>Descrição:</b> {description || ' '}
          </p>
          <p>
            <b>Bloquear novas vendas:</b> {blockNewSales ? 'Sim' : 'Não'}
          </p>
          <p>
            <b>Bloquear novos agendamentos:</b> {blockNewAppointments ? 'Sim' : 'Não'}
          </p>
          <p>
            <b>Bloquear agendamentos Existentes:</b> {blockExistingAppointments ? 'Sim' : 'Não'}
          </p>
          <p>
            <b>Criado em:</b> {createdAt ? formatDateTime(createdAt) : ' '}
          </p>
          <p>
            <b>Atualizado em:</b> {updatedAt ? formatDateTime(updatedAt) : ' '}
          </p>
          <b>Produtos:</b>
          <div className='card-body py-3'>
            <table className='table table-row-dashed table-row-gray-300'>
              <thead>
                <tr className='fw-bolder fs-6 text-gray-800'>
                  <th>ID</th>
                  <th>Produtos</th>
                  <th>Genero</th>
                </tr>
              </thead>
              <tbody>
                {products?.map((field, index) => (
                  <tr key={`products ${index}`}>
                    <td>{field.id}</td>
                    <td>{field.name}</td>
                    <td>{field.gender}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}
