import {TransferResponse} from '../core/_models'
import {useFranchiseTransferCart} from '../core/FranchiseTransferProvider'

interface ITransferStts {
  transfer: TransferResponse
}
export const FTStatus = ({transfer}: ITransferStts) => {
  return (
    <>
      <div className='container'>
        <div className='card-body py-4'>
          <div className='row'>
            <div className='col'>
              <>
                <div className='row align-items-center mb-4'>
                  <div className='col-6 text-dark fs-5 fw-bold'>
                    Aceite unidade de Destino:{' '}
                    <span
                      className={`badge bg-${
                        transfer?.approvedBy !== null ? 'success' : 'warning'
                      } text-dark fs-6`}
                    >
                      {transfer?.approvedBy !== null ? 'Confirmado' : 'Pendente'}
                    </span>
                  </div>
                  <div className='col-6 d-flex justify-content-end'>
                    <div className='dropdown'>
                      <button
                        className='btn btn-danger dropdown-toggle'
                        type='button'
                        data-bs-toggle='dropdown'
                        aria-expanded='false'
                      >
                        Ações
                      </button>
                      <ul className='dropdown-menu'>
                        <li>
                          <a className='dropdown-item fw-bold text-hover'>Visualizar</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </>

              {transfer?.hasFranchisePayment && (
                <>
                  <div className='row align-items-center mb-4'>
                    <div className='col-6 text-dark fs-5 fw-bold'>
                      Repasse Unidade:{' '}
                      <span
                        className={`badge bg-${
                          transfer?.franchisePaymentCompleted ? 'success' : 'warning'
                        } text-dark fs-6`}
                      >
                        {transfer?.franchisePaymentCompleted ? 'Confirmado' : 'Pendente'}
                      </span>
                    </div>
                    <div className='col-6 d-flex justify-content-end'>
                      <div className='dropdown'>
                        <button
                          className='btn btn-danger dropdown-toggle'
                          type='button'
                          data-bs-toggle='dropdown'
                          aria-expanded='false'
                        >
                          Ações
                        </button>
                        <ul className='dropdown-menu'>
                          <li>
                            <a className='dropdown-item'>Visualizar</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {transfer?.hasClientPaymentOrigin && (
                <>
                  <div className='row align-items-center'>
                    <div className='col-6 text-dark fs-5 fw-bold'>
                      Repasse Cliente Origem:{' '}
                      <span
                        className={`badge bg-${
                          transfer?.clientPaymentOriginCompleted ? 'success' : 'warning'
                        } text-dark fs-6`}
                      >
                        {transfer?.clientPaymentOriginCompleted ? 'Confirmado' : 'Pendente'}
                      </span>
                    </div>
                    <div className='col-6 d-flex justify-content-end'>
                      <div className='dropdown'>
                        <button
                          className='btn btn-danger dropdown-toggle'
                          type='button'
                          data-bs-toggle='dropdown'
                          aria-expanded='false'
                        >
                          Ações
                        </button>
                        <ul className='dropdown-menu'>
                          <li>
                            <a className='dropdown-item'>Visualizar</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {transfer?.hasClientPaymentDestination && (
                <>
                  <div className='row align-items-center mt-5'>
                    <div className='col-6 text-dark fs-5 fw-bold'>
                      Repasse Cliente Destino:
                      <span
                        className={`badge bg-${
                          transfer?.clientPaymentDestinationCompleted ? 'success' : 'warning'
                        } text-dark fs-6`}
                      >
                        {transfer?.clientPaymentDestinationCompleted ? 'Confirmado' : 'Pendente'}
                      </span>
                    </div>
                    <div className='col-6 d-flex justify-content-end'>
                      <div className='dropdown'>
                        <button
                          className='btn btn-danger dropdown-toggle'
                          type='button'
                          data-bs-toggle='dropdown'
                          aria-expanded='false'
                        >
                          Ações
                        </button>
                        <ul className='dropdown-menu'>
                          <li>
                            <a className='dropdown-item'>Visualizar</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
