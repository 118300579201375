/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'

type Props = {
    value: string,
}

const CustonCell: FC<Props> = ({ value }) => {
    return <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
                {value}
        </div>
    </div>
}

export { CustonCell }