import axios, {AxiosResponse} from 'axios'
import {SalesIndexQueryResponse} from './_models'
import { Response } from '../../../../_metronic/helpers'
import { Option } from "react-multi-select-component"

const API_URL = process.env.REACT_APP_API_URL + '/api'
const SALE_URL = `${API_URL}/sales`

const getSales = (query: string): Promise<SalesIndexQueryResponse> => {
    return axios
        .get(`${SALE_URL}?${query}&include=client.physicalPerson`)
        .then((d: AxiosResponse<SalesIndexQueryResponse>) => d.data)
}

const getStatusSales = (): Promise<Option[] | undefined> => {
    return axios
      .get(`${SALE_URL}/statuses?all=1`)
      .then((response: AxiosResponse<Response<Option[]>>) => response.data)
      .then((response: Response<Option[]>) => response.data)
  }

export {getSales, getStatusSales};