import { useEffect, useState } from 'react'
import { Billing } from '../core/_models'
import { formatMask } from '../../../utils/formatMonetaryToBr'
import { formatDate } from '../../../utils/dayJs/format'
import { KTIcon } from '../../../_metronic/helpers'
import { useBillingContext } from '../core/billingProvider'
import { useFormik } from 'formik'
import Swal from 'sweetalert2'
import { updateStatusPayment } from '../core/_request'

interface IBilling {
  billing: Billing
}

const BillingEditStatusModal = ({ billing }: IBilling) => {


  const { setBillingsEditStatus } = useBillingContext()
  const [loading, setLoading] = useState(false);
  const { billingData, setBillingData } = useBillingContext()

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const initialValues = {
    id: billing.id,
    status: billing.status,
  };
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      await submit(values);
    },
  });

  const submit = async (req) => {
    await Swal.fire({
      title: 'Deseja atualizar o status do pagamento?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-danger', // Classe personalizada para o botão de confirmação
        cancelButton: 'btn btn-secondary' // Classe personalizada para o botão de cancelamento (opcional)
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true)
        try {
          const data = await updateStatusPayment(String(req.id), req)
          const updatedBilling = [...billingData]
          
          billingData.map((value, index) => {
            if (value.id === data.id)
              updatedBilling[index] = data
          })
          setBillingData(updatedBilling);

          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Salvo com sucesso',
            showConfirmButton: false,
            timer: 1500
          })
          setBillingsEditStatus(null)
        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = '';
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>';
            }
            await Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText
            });
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 2000
            })
          }
        } finally {
          setLoading(false)
        }
      }
    })
  }

  return (
    <>
      <form id='billingEditStatusFormModal' className='form' onSubmit={formik.handleSubmit}>
        <div
          className='modal fade show d-block'
          id='kt_modal_edit_status'
          role='dialog'
          tabIndex={-1}
          aria-modal='true'
        >
          <div className='modal-dialog modal-dialog-centered mw-800px'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h2 className='fw-bolder'>Editar Status de Pagamento: </h2>
                <div
                  className='btn btn-icon btn-sm btn-active-icon-primary'
                  data-kt-users-modal-action='close'
                  onClick={() => setBillingsEditStatus(null)}
                  style={{ cursor: 'pointer' }}
                >
                  <KTIcon iconName='cross' className='fs-1' />
                </div>
              </div>
              <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                <div className='row mb-6 mt-4'>
                  <div className='table-responsive'>
                    <table className='table table-row-dashed table-row-gray-300 gy-7'>
                      <thead>
                        <tr className='fw-bold text-muted'>
                          <th className='min-w-120px'>Preço</th>
                          <th className='min-w-120px'>Dt de vencimento</th>
                          <th className='min-w-120px'>Parcela</th>
                          <th className='min-w-120px'>Forma de pagamento</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{formatMask(billing.amount)}</td>
                          <td>{formatDate(billing.createdAt)}</td>
                          <td>{billing.numberOfInstallments > 0
                            ? billing.numberOfInstallments
                            : 'À vista'}
                          </td>
                          <td>
                            {billing?.paymentOption?.name}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className='col-lg-12 fv-row'>
                      <b>Status: </b>
                      <select
                        className='form-select form-select-lg form-select-solid'
                        {...formik.getFieldProps('status')}
                        onChange={(e) => {
                          const value = e.target.value
                          formik.setFieldValue('status', value)
                        }}
                      >
                        <option value='1'>Pendente</option>
                        <option value='2'>Pago</option>
                        <option value='3'>Cancelado</option>
                      </select>
                    </div>
                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                      <button type="reset" className="btn btn-secondary me-2" onClick={() => { setBillingsEditStatus(null) }}>Cancelar</button>
                      <button
                        type='submit'
                        className='btn btn-danger'
                        data-kt-users-modal-action='submit'
                        disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                      >
                        <span className='indicator-label'>Salvar</span>
                        {(formik.isSubmitting) && (
                          <span className='indicator-progress'>
                            Aguarde...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='modal-backdrop fade show'></div>
      </form>
    </>
  )
}

export { BillingEditStatusModal }
