import { useState } from "react";
import { MultiSelect, Option } from "react-multi-select-component"
import "../../../../../../style/multiSelectSucess.css"

const ListSearchSubscriptionGender = ({ setSubscriptionGenderSearch, selectsGender, setSelectsGender }) => {
    
    const initialSelectValue = [{value: '1', label: 'Masculino'},{value: '2', label: 'Femino'}];
    const [saleGender, setSaleGender] = useState<Option[]>(initialSelectValue);
    
    return (
        <>
            <div className='mb-5'>
                <label className='form-label fs-6 fw-bold'>Pesquisar por Gênero:</label>
                <MultiSelect
                    className='form-control rmscStyle'
                    data-kt-user-table-filter='gender'
                    options={saleGender}
                    value={selectsGender}
                    onChange={(option) => {
                        const valores = option.map((item) => item.value)
                        setSubscriptionGenderSearch(valores)
                        setSelectsGender(option)
                    }}
                    labelledBy={''}
                    overrideStrings={{
                        selectSomeItems: 'Selecione o(os) Gênero',
                        search: 'Procurar',
                        selectAll: 'Selecionar todos',
                        allItemsAreSelected: 'Todos gêneros selecionados',
                      }}
                />
            </div>
        </>
    )
}

export { ListSearchSubscriptionGender }
