import { useEffect, useState } from 'react'
import { formatMask, formatMonetaryToBr, removeMask } from '../../../../utils/formatMonetaryToBr'
import { Billing, TBillingInstallment } from '../../core/_models'
import { formatDate } from '../../../../utils/dayJs/format'
import { PaymentRecurrentContainer } from './components/selectPaymentMethod'
import { SplitRecurrentPayment } from './components/splitPayment'
import { useRecurrentPayment } from './core/recurrentPaymentProvider'
import { handlePaymentManualReq } from './core/_request'
import Swal from 'sweetalert2'
import { PageOverlayer } from '../../../../components/PageOverlayer'

interface IChangeRecurrentModal {
  billingInstallment: TBillingInstallment | undefined
  billing: Billing | null
  closeModal: () => void
}

export const ChangeRecurrentModal = ({
  billingInstallment,
  billing,
  closeModal,
}: IChangeRecurrentModal) => {
  const { payment, checkPaymentMethod } = useRecurrentPayment()
  const [enableGenerate, setEnableGenerate] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let current = payment?.reduce((previousValue, currentValue) => {
      return Number(previousValue) + Number(removeMask(String(currentValue.value)))
    }, 0)

    setEnableGenerate(Number(current).toFixed(2) === Number(billingInstallment?.amount).toFixed(2))
  }, [payment])

  let current = payment?.reduce((previousValue, currentValue) => {
    return Number(previousValue) + Number(removeMask(String(currentValue.value)))
  }, 0)

  const handlePaymentManual = () => {
    try {
      Swal.fire({
        title: 'Deseja tentar realizar uma nova cobrança ?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-danger', // Classe personalizada para o botão de confirmação
          cancelButton: 'btn btn-secondary', // Classe personalizada para o botão de cancelamento (opcional)
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const dataPayment = {
              method: payment[0].method,
              installments: payment[0].numberOfInstallments,
            }
            setLoading(true)
            await handlePaymentManualReq(
              Number(billing?.recurrencePayment.id),
              Number(billingInstallment?.id),
              dataPayment
            )
            setLoading(false)
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!!!',
              text: 'Cobrança realizada com sucesso!',
              timer: 3000,
            })

            setTimeout(function () {
              window.location.reload()
            }, 2000)
          } catch (error: any) {
            setLoading(false)
            Swal.fire({
              icon: 'warning',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 3000,
            })
          }
        }
      })
    } catch (error: any) {
      setLoading(false)
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: error.response.data.message,
        timer: 3000,
      })
    }
  }

  return (
    <>
      {loading && <PageOverlayer />}
      <div className='modal-content'>
        <div className='mx-14'>
          <div className='row mt-5'>
            <div className='table-responsive'>
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-7'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-120px'>Preço</th>
                    <th className='min-w-120px'>Forma de pagamento</th>
                    <th className='min-w-120px'>Dt de vencimento</th>
                    <th className='min-w-120px'>Parcelas</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>{formatMask(billingInstallment?.amount)}</b>
                    </td>
                    <td>
                      <b>{billing?.paymentOption?.name}</b>
                    </td>
                    <td>
                      <b>{formatDate(String(billingInstallment?.dueDate))}</b>
                    </td>
                    <td>
                      <b>
                        {billingInstallment?.installmentsNumber ?? billingInstallment?.installment} /{' '}
                        {billing?.installments.length}
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='separator '></div>
          <div className='modal-header position-relative '>
            <div className='text-center w-100 '>
              <h3 className='modal-title fw-bold'>
                Escolha a forma de pagamento
              </h3>
            </div>
          </div>
          <div className='modal-body'>
            <div className='row d-flex '>
              <div className={`col-md-5 col-12 h-100`}>
                <div className='d-flex justify-content-between mb-1'>
                  <span className='fw-bold d-block text-danger'>Total a pagar: </span>
                  <span className='fw-semi-bold d-block text-danger'>
                    {formatMonetaryToBr(billingInstallment?.amount)}
                  </span>
                </div>
                <div className='d-flex justify-content-between mb-8'>
                  <span className='fw-bold d-block'>Total a selecionar:</span>
                  <span className='fw-semi-bold d-block'>
                    {formatMonetaryToBr(Number(billingInstallment?.amount) - current)}
                  </span>
                </div>
                <div
                  className='col-12 '
                  style={{ overflowY: 'auto', overflowX: 'hidden' }}
                >
                  <SplitRecurrentPayment payment={payment} />
                </div>
              </div>

              <div
                className='col-md-1 d-flex'
                style={{ alignItems: 'center', justifyContent: 'center' }}
              >
                <div
                  className='border border-left-0 border-top-0 border-bottom-0 border-right-2'
                  style={{ width: 1, minHeight: 200 }}
                ></div>
              </div>
              <div className='col-md-6 col-12 h-100 border-left p-0'>
                <h6 className='mb-2'>Forma de pagamento</h6>
                <div
                  className='col-12 '
                  style={{ maxHeight: '400px', overflowY: 'auto', overflowX: 'hidden' }}
                >
                  <PaymentRecurrentContainer />
                </div>
              </div>
            </div>
          </div>
        </div>
          <div className="modal-footer d-flex justify-content-between w-100">
            <button
              onClick={() => {
                closeModal()
              }}
              type="button"
              className="btn btn-secondary"
            >
              Voltar
            </button>
            <button
              disabled={!enableGenerate}
              onClick={() => {
                handlePaymentManual()
              }}
              type="button"
              className="btn btn-danger"
            >
              Gerar Cobrança
            </button>
          </div>

      </div>
    </>
  )
}
