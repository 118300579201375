// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-swal-bg {
  background-color: #f1416c !important;
}

.custom-swal-title {
  color: white !important;
  font-size: 1.2rem !important;
}

.custom-swal-content {
  color: white;
  font-size: 16px;
}

.custom-swal-button {
  background-color: white !important;
  color: #f1416c;
  border-radius: 10px; 
  padding: 10px 20px;
  font-size: 16px; 
}

div:where(.swal2-container) div:where(.swal2-loader) {
  border-color: #ffffff rgba(0, 0, 0, 0) #ffffff rgba(0, 0, 0, 0) !important; /* Cor do spinner */
}`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/components/statesAlert/CustomSwal.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC;;AAEA;EACE,uBAAuB;EACvB,4BAA4B;AAC9B;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,kCAAkC;EAClC,cAAc;EACd,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,0EAA0E,EAAE,mBAAmB;AACjG","sourcesContent":[".custom-swal-bg {\n  background-color: #f1416c !important;\n}\n\n.custom-swal-title {\n  color: white !important;\n  font-size: 1.2rem !important;\n}\n\n.custom-swal-content {\n  color: white;\n  font-size: 16px;\n}\n\n.custom-swal-button {\n  background-color: white !important;\n  color: #f1416c;\n  border-radius: 10px; \n  padding: 10px 20px;\n  font-size: 16px; \n}\n\ndiv:where(.swal2-container) div:where(.swal2-loader) {\n  border-color: #ffffff rgba(0, 0, 0, 0) #ffffff rgba(0, 0, 0, 0) !important; /* Cor do spinner */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
