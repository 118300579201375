import {useNavigate} from 'react-router-dom'
import {usePaymentOptionForm} from '../core/FormProvider'
import Select from 'react-select'
import {useEffect, useState} from 'react'
import {ISelect} from '../../../coreGlobal/models/default'
import {formatPercentage} from '../../../utils/formatMonetaryToBr'

export const PaymentOptionForm = ({formik, active, data}) => {
  const navigate = useNavigate()
  const {loading, categories, selectedCategory, setSelectedCategory} = usePaymentOptionForm()

  const optionNotRecurrent = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

  const [optionInstallment, setOptionInstallment] = useState<any>(optionNotRecurrent)
  const [inputInstallment, setInputInstallment] = useState<any>(null)
  const [hasInstallment, setHasInstallment] = useState(false)
  const [installmentFees, setInstallmentFees] = useState<any>([])

  const getNumberInstallment = (reccurent: boolean) => {
    if (reccurent) {
      const optionRecurrent = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
      setOptionInstallment(optionRecurrent)
      return optionInstallment
    }
    setOptionInstallment(optionNotRecurrent)
    return optionInstallment
  }

  useEffect(() => {
    if (active) {
      setHasInstallment(data?.installmentEnabled)

      const installment = data?.installmentFees?.map((val) => {
        const array = {
          installment: val.installmentNumber,
          percentage: val.feePercentage,
        }

        return array
      })

      setInstallmentFees(installment)
      formik.setFieldValue('installmentFees', installment)
      returnSliceOption(data?.maxInstallments)
    }
  }, [])

  const handleCheckBox = () => {
    if (hasInstallment) {
      setHasInstallment(false)
      formik.setFieldValue('installmentEnabled', false)
      return
    }
    formik.setFieldValue('installmentEnabled', true)
    setHasInstallment(true)
  }

  const returnSliceOption = (val) => {
    const silceOption = optionInstallment?.slice(0, val)
    setInputInstallment(silceOption)

    // if (installmentFees.length != silceOption.length) {
    //   const filterInstallment = installmentFees.filter((_, index) => {
    //     return silceOption.some((_, indexMap) => indexMap === index)
    //   })

    //   setInstallmentFees(filterInstallment)
    //   formik.setFieldValue('installmentFees', installmentFees)
    // }
  }

  useEffect(() => {
    if (formik.values.category && categories.length > 1) {
      const findCategory = categories.find((category: ISelect) => {
        return category.value === formik.values.category
      })
      const arrayRecurrent = [3, 4, 5, 6]
      const recurrentCategory = arrayRecurrent.some((val) => {
        return val == formik.values.category
      })
      setSelectedCategory(findCategory)
      if (recurrentCategory) {
        getNumberInstallment(true)
        return
      }
      getNumberInstallment(false)
    }
  }, [formik.values.category, categories])

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className='card mb-5 mb-xl-10'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Nome</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Nome da forma de pagamento'
                  {...formik.getFieldProps('name')}
                  maxLength={64}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors?.name}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Categoria</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <Select
                  className='react-select-styled react-select-solid'
                  classNamePrefix='react-select'
                  placeholder='Selecione uma categoria'
                  onChange={(option) => {
                    setSelectedCategory(option!)
                    formik.setFieldValue('category', String(option?.value))
                  }}
                  options={categories}
                  value={selectedCategory}
                />
                {formik.touched?.category && formik.errors?.category ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors?.category}</div>
                  </div>
                ) : null}
              </div>
            </div>
            {active && (
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Status</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <select
                    className='form-select form-select-lg form-select-solid'
                    {...formik.getFieldProps('active')}
                    onChange={(e) => {
                      const value = e.target.value == 'true'
                      formik.setFieldValue('active', value)
                    }}
                  >
                    <option value='true'>Ativo</option>
                    <option value='false'>Inativo</option>
                  </select>
                  {formik.touched.active && formik.errors.active && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.active}</div>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className='row mb-6'>
              <div className='col-lg-2 fs-1'>
                <input
                  type='checkbox'
                  checked={hasInstallment}
                  onClick={() => {
                    handleCheckBox()
                  }}
                />
                <span className='fw-bold fs-4 ms-2'>Parcelamento</span>
              </div>
            </div>

            {hasInstallment && (
              <>
                <div className='row mb-6'>
                  <label className='col-lg-2 col-form-label fw-bold fs-6'>
                    <span>Quantidade de parcelas</span>
                  </label>
                  <div className='col-1 fv-row'>
                    <select
                      className='form-select form-select-lg form-select-solid'
                      defaultValue={data?.maxInstallments ?? 0}
                      onChange={(e) => {
                        returnSliceOption(e.target.value)
                        formik.setFieldValue('maxInstallments', Number(e.target.value))
                      }}
                    >
                      {optionInstallment?.map((option) => (
                        <option value={option}>{option}</option>
                      ))}
                    </select>
                  </div>
                </div>

                {inputInstallment?.map((installment, index) => (
                  <>
                    <div className='row mb-6'>
                      <label className='row col-lg-4 col-form-label fw-bold fs-6'>
                        <div className='col-7'>
                          <span>Parcela: {installment} </span>
                        </div>
                        <div className='col-5 fw-bold fs-5'>
                          <span>Taxa de parcelamento %</span>
                        </div>
                      </label>
                      <div className='col-8 fv-row'>
                        <input
                          type='text'
                          value={installmentFees[index]?.percentage ?? 0.0}
                          className='form-control form-control-lg form-control-solid'
                          onChange={(e) => {
                            const value = e.target.value
                            const updated = [...installmentFees] // Crie uma cópia para evitar mutações diretas
                            const index = updated.findIndex(
                              (item) => item.installment === installment
                            )

                            const formattedValue = formatPercentage(value)

                            if (index >= 0) {
                              updated[index].percentage = formattedValue
                            } else {
                              updated.push({installment, percentage: formattedValue})
                            }

                            setInstallmentFees(updated) // Atualize o estado com a cópia
                            formik.setFieldValue('installmentFees', updated) // Certifique-se de passar o estado atualizado
                          }}
                        />
                      </div>
                    </div>
                  </>
                ))}
              </>
            )}
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='reset' className='btn btn-secondary me-2' onClick={() => navigate(-1)}>
              Cancelar
            </button>

            <button type='submit' className='btn btn-danger' disabled={loading || !formik.isValid}>
              {!loading && 'Salvar'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Salvando...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  )
}
