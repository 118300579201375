import { useEffect, useState } from "react";
import { MultiSelect, Option } from "react-multi-select-component"
import { getCategories, getSaleStatus } from "../../core/_requests";

const ListSearchSubscriptionCategories = ({ setSubscriptionCategoriesSearch, selectsCategories,setSelectsCategories }) => {
    
    const initialSelectValue = [{ value: '', label: '' }];
    const [categories, setCategories] = useState<Option[]>(initialSelectValue);

    useEffect(() => {
        async function init() {
            const data = await getCategories()
            setCategories(data!)
        }
        init();
    }, [])

    return (
        <>
            <div className='mb-5'>
                <label className='form-label fs-6 fw-bold'>Pesquisar por categorias:</label>
                <MultiSelect
                    className='react-select-styled react-select-solid'
                    data-kt-user-table-filter='categories'
                    options={categories}
                    value={selectsCategories}
                    onChange={(option) => {
                        const valores = option.map((item) => item.value)
                        setSubscriptionCategoriesSearch(valores)
                        setSelectsCategories(option)
                    }}
                    labelledBy={''}
                     overrideStrings={{
                        selectSomeItems: 'Selecione a(as) categorias',
                        search: 'Procurar',
                        selectAll: 'Selecionar todos',
                        allItemsAreSelected: 'Todos itens selecionados',
                      }}
                />
            </div>
        </>
    )
}

export { ListSearchSubscriptionCategories }
