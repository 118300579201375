// @ts-nocheck
import { Column } from 'react-table'

import { UpdatedInCell } from './UpdatedInCell'
import { CreatedInCell } from './CreatedInCell'
import { SelectionCell } from './SelectionCell'
import { CustomHeader } from './CustomHeader'
import { SelectionHeader } from './SelectionHeader'
import { TransferResponseList } from '../../core/_models'
import { NameCell } from './NameCell'
import { ApprovedByCell, EmailCell } from './ApprovedBy'
import { CpfCell } from './CpfCell'
import { DestinationFranchiseCell } from './DestinationFranchise'
import { RequestedByCell } from './RequestedBy'
import { OriginFranchiseCell } from './OriginFranchise'
import { OriginSaleCell } from './OriginSale'
import { StatusCell } from './StatusCell'

const UserColumns: ReadonlyArray<Column<TransferResponseList>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <SelectionCell id={props.data[props.row.index].id} />,
  },

  {
    Header: (props) => <CustomHeader tableProps={props} title='Nome' className='min-w-125px' />,
    id: 'name',
    Cell: ({ ...props }) => <NameCell transfer={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Solicitado por' className='min-w-125px' />
    ),
    id: 'requestedBy',
    Cell: ({ ...props }) => <RequestedByCell transfer={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Aprovado por' className='min-w-125px' />
    ),
    id: 'approvedBy',
    Cell: ({ ...props }) => <ApprovedByCell transfer={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Franquia Origem' className='min-w-50px' />
    ),
    id: 'originFranchise',
    Cell: ({ ...props }) => <OriginFranchiseCell transfer={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Franquia Destino' className='min-w-125px' />
    ),
    id: 'destinationFranchise',
    Cell: ({ ...props }) => <DestinationFranchiseCell transfer={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Venda Original' className='min-w-125px' />
    ),
    id: 'originSale',
    Cell: ({ ...props }) => <OriginSaleCell transfer={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'status',
    Cell: ({ ...props }) => <StatusCell transfer={props.data[props.row.index]} />,
  },
]

export { UserColumns }
