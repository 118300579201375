import {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {DateRangePicker} from 'rsuite'
import './Filter.css'

import '../statesAlert/CustomSwal.css'
import {format, subDays} from 'date-fns'
import moment from 'moment'
import {Ranges} from './Ranges'
import {useDashboard} from '../../../../hooks/useDashboard'

import ReactDOM from 'react-dom'

const {afterToday, before, combine} = DateRangePicker

interface FilterDataProps {}

export function FilterData({}: FilterDataProps) {
  const {
    choosedDtaInit,
    choosedDtaEnd,
    setChoosedDtaEnd,
    setChoosedDtaInit,
    setCurrentInitDta,
    setCurrentEndDta,
  } = useDashboard()

  const [opened, setOpen] = useState(false)

  const dateRangeValue = useMemo(
    (): [Date, Date] => [choosedDtaInit, choosedDtaEnd] as [Date, Date],
    [choosedDtaInit, choosedDtaEnd]
  )

  const maxRangeDate = useMemo(() => {
    const maxDate = subDays(choosedDtaInit, 90)
    return format(maxDate, 'MM/dd/yyyy')
  }, [choosedDtaInit])

  const handleRangeDta = useCallback((range: [Date, Date] | any | null): void => {
    if (range) {
      const [start, end] = range

      // Dates using lib filter
      setChoosedDtaInit(start)
      setChoosedDtaEnd(end)

      // Dates using body req api
      setCurrentInitDta(moment(start).format('yyyy-MM-DD'))
      setCurrentEndDta(moment(end).format('yyyy-MM-DD'))
    }
  }, [])

  const handleCleanRangeDta = useCallback((): void => {
    const today = new Date()
    setChoosedDtaInit(today)
    setChoosedDtaEnd(today)

    const formattedToday = moment(today).format('YYYY-MM-DD')
    setCurrentInitDta(formattedToday)
    setCurrentEndDta(formattedToday)
  }, [setChoosedDtaInit, setChoosedDtaEnd, setCurrentInitDta, setCurrentEndDta])

  const dateRestrictions = useMemo(
    () => combine(before(maxRangeDate), afterToday()),
    [maxRangeDate]
  )

  const [container, setContainer] = useState(null)

  useEffect(() => {
    // Aguarde o DOM carregar e encontre o elemento desejado
    const target = document.getElementById('kt_app_toolbar_container') as any
    if (target) {
      setContainer(target)
    }
  }, [])

  if (!container) return null // Evita erro antes do container estar pronto

  return ReactDOM.createPortal(
    <>
      <DateRangePicker
        editable={false}
        className=''
        onOpen={() => setOpen(!opened)}
        ranges={Ranges}
        value={dateRangeValue}
        onOk={(e) => handleRangeDta(e)}
        onShortcutClick={(e) => handleRangeDta(e.value)}
        shouldDisableDate={dateRestrictions}
        onClean={() => handleCleanRangeDta()}
        character={' - '}
        appearance='default'
        cleanable={true}
        placeholder='Subtle'
        placement='bottomEnd'
      />
    </>,
    container
  )
}
