/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Billing } from '../../../core/_models'
import { formatDateTime } from '../../../../../utils/dayJs/format'

type Props = {

    billing: Billing
}

const CreatedInCell: FC<Props> = ({ billing }) => (

    <div className='d-flex flex-column'>
        <a href='#' className='text-gray-800 text-hover-danger mb-1'>
            {billing.createdAt ? formatDateTime(billing.createdAt.toString()) : 'N/A'}
        </a>
    </div>
)

export { CreatedInCell }
