import {ListViewProvider, useListView} from '../core/ListViewProvider'
import {QueryRequestProvider} from '../core/QueryRequestProvider'
import {QueryResponseProvider, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {Header} from './components/header/Header'
import {Table} from './table/Table'
import {KTCard} from '../../../_metronic/helpers'

const List = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <Header />
        <Table />
      </KTCard>
      {itemIdForUpdate !== undefined}
    </>
  )
}

const ListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <List />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ListWrapper}
