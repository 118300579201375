/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {initialQueryState, KTIcon, useDebounce} from '../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'

const Search = () => {
  const {updateState} = useQueryRequest()
  const [searchClient, setSearchClient] = useState<string>('')
  const [searchId, setSearchId] = useState<string>('')
  // Debounce search term so that it only gives us latest value ...
  // ... if searchClient has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchClient = useDebounce(searchClient, 150)

  const debouncedSearchid = useDebounce(searchId, 150)

  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchClient !== undefined && searchClient !== undefined) {
        updateState({name: debouncedSearchClient, ...initialQueryState})
      }
    },
    [debouncedSearchClient] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )
  useEffect(
    () => {
      if (debouncedSearchid !== undefined && searchId !== undefined) {
        updateState({id: debouncedSearchid, ...initialQueryState})
      }
    },
    [debouncedSearchid] // Only call effect if debounced search term changes
  )

  return (
    <div className='card-title'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative my-1'>
        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
        <input
          type='number'
          data-kt-user-table-filter='id'
          className='form-control form-control-solid w-250px ps-14'
          placeholder='Pesquisar Por Nº Venda'
          value={searchId}
          onChange={(e) => setSearchId(e.target.value)}
        />
      </div>
      <div className='d-flex align-items-center position-relative my-1 mx-2'>
        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
        <input
          type='text'
          data-kt-user-table-filter='name'
          className='form-control form-control-solid w-250px ps-14'
          placeholder='Pesquisar Cliente'
          value={searchClient}
          onChange={(e) => setSearchClient(e.target.value)}
        />
      </div>

      {/* end::Search */}
    </div>
  )
}

export {Search}
