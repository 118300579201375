import {useParams} from 'react-router-dom'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import {useEffect, useState} from 'react'
import {getMachineById} from './core/_request'
import {LoadingSpinner} from '../../utils/loading/loadingSpinner'
import {formatDateTime} from '../../utils/dayJs/format'
import {HeaderForShowPage} from '../../components/HeaderForShowPage'
import {MachineResponse} from '../../coreGlobal/models/machines/machine'
import {PageOverlayer} from '../../components/PageOverlayer'
import Swal from 'sweetalert2'

const MachinesBreadcrumbs: Array<PageLink> = [
  {
    title: 'Máquinas',
    path: '/machines',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const Show = () => {
  let {id} = useParams()
  const [loadingPage, setLoadingPage] = useState(false)
  const [machine, setMachine] = useState<MachineResponse>()

  useEffect(() => {
    const machine = async () => {
      setLoadingPage(true)
      const data = await getMachineById(String(id))
      setMachine(data)
      setLoadingPage(false)
    }

    try {
      machine()
    } catch (error: any) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: error?.response?.data?.message,
        showConfirmButton: true,
      })
    }
  }, [])

  return (
    <>
      {loadingPage ? (
        <PageOverlayer />
      ) : (
        <div className='card'>
          <div className='card-body'>
            <HeaderForShowPage title='Informações da Máquina' />
            <p>
              <strong>Nome:</strong> {machine?.name ? machine.name : ' '}
            </p>
            <p>
              <strong>Descrição:</strong> {machine?.description ? machine.description : ' '}
            </p>
            <p>
              <strong>Criado em:</strong>{' '}
              {machine?.createdAt ? formatDateTime(machine.createdAt) : ' '}
            </p>
            <p>
              <strong>Atualizado em:</strong>{' '}
              {machine?.updatedAt ? formatDateTime(machine.updatedAt) : ' '}
            </p>
            <table className='table table-row-dashed table-row-gray-300 gy-7'>
              <thead>
                <tr className='fw-bolder fs-6 text-gray-800'>
                  <th>ID</th>
                  <th>Produto</th>
                  <th>Tempo de execução</th>
                </tr>
              </thead>
              <tbody>
                {machine?.products?.map((field, index) => (
                  <tr>
                    <td>{field.product['id']}</td>
                    <td>{field.product['name']}</td>
                    <td>{field.sessionTime}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  )
}

export const MachineShowPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={MachinesBreadcrumbs}> Visualizar Máquina</PageTitle>
      <Show />
    </>
  )
}
