import { useEffect, useState } from 'react'
import { KTIcon } from '../../../../../_metronic/helpers'
import { getPaymentOption } from '../../../create/core/_request'
import { ISelect } from '../../../../../coreGlobal/models/default'
import { useRecurrentPayment } from '../core/recurrentPaymentProvider'
import { formatMask } from '../../../../../utils/formatMonetaryToBr'
import { PageOverlayer } from '../../../../../components/PageOverlayer'

const methodSize = (length, index) => {
  if (index == 0 && length === 1) {
    return 'col-10 p-0'
  }

  if (index == 0 && length != 0) {
    return 'col-12 p-0'
  }

  if (index != 0 && index < length - 1) {
    return 'col-10 p-0'
  }

  return 'col-7 p-0'
}

const SwitchAction = ({ position, length, addMethodPayment, removeMethodPayment }) => {
  if (position === 1 && length > 1) return <></>
  if (position == 1 && length == 1) {
    return (
      <div className='col-2 p-0 d-flex align-items-center justify-content-center '>
        <span
          onClick={() => addMethodPayment()}
          className='btn btn-light-danger font-weight-bold w-auto'
        >
          <KTIcon iconName='plus' className='svg-icon svg-icon-danger' />
        </span>
      </div>
    )
  }

  if (position !== 0 && position !== length) {
    return (
      <div className='col-2 p-0 d-flex align-items-center justify-content-center '>
        <span
          onClick={() => removeMethodPayment()}
          className='btn btn-light-danger font-weight-bold w-auto'
        >
          <KTIcon iconName='trash' className='svg-icon svg-icon-danger' />
        </span>
      </div>
    )
  }

  if (position !== 0 && position === length) {
    return (
      <>
        <div className='col-2 p-0 ms-2 me-2 d-flex align-items-center justify-content-center '>
          <span
            onClick={() => addMethodPayment()}
            className='btn btn-light-danger font-weight-bold w-auto'
          >
            <KTIcon iconName='plus' className='svg-icon svg-icon-danger' />
          </span>
        </div>
        <div className='col-2 p-0 d-flex align-items-center justify-content-center '>
          <span
            onClick={() => removeMethodPayment()}
            className='btn btn-light-danger font-weight-bold w-auto'
          >
            <KTIcon iconName='trash' className='svg-icon svg-icon-danger' />
          </span>
        </div>
      </>
    )
  }

  return <></>
}

export const PaymentRecurrentContainer = () => {
  const [paymentOption, setStates] = useState<ISelect[] | undefined>([])
  const [loadingPage, setLoadingPage] = useState(false)
  const AUTOMATIC_PAYMENTS = [1, 2, 3, 4]

  useEffect(() => {
    async function init() {
      setLoadingPage(true)
      const data = await getPaymentOption()
      const arrayPayment = data?.filter((val) => {
        const array = [3]
        return !array.includes(Number(val.value))
      })
      setStates(arrayPayment)
      setLoadingPage(false)
    }

    init()
  }, [])
  const {
    payment,
    handleChangeTypeMethodPayment,
    handleChangeValueMethodPayment,
    handleChangeInstallments,
    checkPaymentMethod,
  } = useRecurrentPayment()

  return (
    <>
      {loadingPage ? (
        <PageOverlayer />
      ) : (
        payment?.map((pay, index) => {
          const paymentMethodSize = methodSize(payment.length, index)
          return (
            <>
              <div className='mb-8 w-100'>
                <div className='row m-0 col-12'>
                  <div className={paymentMethodSize}>
                    <select
                      className='form-select form-select-lg form-control form-control-lg form-control-solid mb-3 mb-lg-0 border border-dark'
                      value={pay.method}
                      onChange={(e) => {
                        handleChangeTypeMethodPayment(index, e)
                        checkPaymentMethod()
                      }}
                    >
                      {paymentOption?.some((option) =>
                        AUTOMATIC_PAYMENTS.includes(Number(option.value))
                      ) && (
                          <optgroup label='Automáticos:'>
                            {paymentOption
                              .filter((option) => AUTOMATIC_PAYMENTS.includes(Number(option.value)))
                              .map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                          </optgroup>
                        )}
                      {paymentOption?.some(
                        (option) => !AUTOMATIC_PAYMENTS.includes(Number(option.value))
                      ) && (
                          <optgroup label='Manuais:'>
                            {paymentOption
                              .filter((option) => !AUTOMATIC_PAYMENTS.includes(Number(option.value)))
                              .map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                          </optgroup>
                        )}
                    </select>
                  </div>
                </div>
                <div className='row mt-2 m-0'>
                  <div className={'col-8 p-0'}>
                    <label>Valor:</label>
                    <input
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 border border-dark col-5'
                      value={formatMask(pay.value)}
                      onChange={(e) => {
                        handleChangeValueMethodPayment(index, formatMask(e.target.value))
                      }}
                    />
                  </div>
                  {(pay.method == 2 || pay.method == 3) && (
                    <div className='col-4 p-0 ps-2'>
                      <label>N. Parcelas:</label>
                      <select
                        className='form-select form-select-lg form-control form-control-lg form-control-solid mb-3 mb-lg-0 border border-dark'
                        value={pay.numberOfInstallments}
                        onChange={(e) => handleChangeInstallments(index, e.target.value)}
                      >
                        {!pay.recurrency && <option value='1'>1X</option>}
                        <option value='2'>2X</option>
                        <option value='3'>3X</option>
                        <option value='4'>4X</option>
                        <option value='5'>5X</option>
                        <option value='6'>6X</option>
                        <option value='7'>7X</option>
                        <option value='8'>8X</option>
                        <option value='9'>9X</option>
                        <option value='10'>10X</option>
                        <option value='11'>11X</option>
                        <option value='12'>12X</option>
                        <option value='13'>13X</option>
                        <option value='14'>14X</option>
                        <option value='15'>15X</option>
                        <option value='16'>16X</option>
                        <option value='17'>17X</option>
                        <option value='18'>18X</option>
                      </select>
                    </div>
                  )}
                </div>
              </div>
            </>
          )
        })
      )}
    </>
  )
}
