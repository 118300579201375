import { useEffect } from "react";

const ListSearchSubscriptionBirthDate = ({ setSearchDateBirthStart, searchDateBirthStart, setSearchDateBirthEnd, searchDateBirthEnd, setErrorsBirth, errorsBirth }) => {

    useEffect(() => {
        if (searchDateBirthStart.length === 10 && searchDateBirthEnd.length === 10) {
            if (new Date(searchDateBirthStart).setHours(0, 0, 0, 0) > new Date(searchDateBirthEnd).setHours(0, 0, 0, 0)) {

                const novosErros = [...errorsBirth];
                novosErros[0] = 'Data inicial deve ser menor que data final';
                novosErros[1] = 'Data inicial deve maior que data final';
                setErrorsBirth(novosErros);
                return
            }
        }
        const novosErros = [...errorsBirth];
        novosErros[0] = '';
        novosErros[1] = '';
        setErrorsBirth(novosErros);
    }, [searchDateBirthStart, searchDateBirthEnd])

    return (
        <>
            <div className='mb-5 row'>
                <div className="col-6">
                    <label className='form-label fs-6 fw-bold'>Pesquisar por Data de Nascimento: Inicial</label>
                    <input
                        type='date'
                        data-kt-user-table-filter='birthDate'
                        className='form-control form-control-solid w-100'
                        placeholder='Data inícial'
                        value={searchDateBirthStart}
                        onChange={(e) => setSearchDateBirthStart(e.target.value)}
                        min="2015-12-31"
                        maxLength={10}
                    />
                </div>
                <div className="col-6">

                    <label className='form-label fs-6 fw-bold'>Pesquisar Data de Nascimento: Final</label>
                    <input
                        type='date'
                        data-kt-user-table-filter='dtEnd'
                        className='form-control form-control-solid'
                        placeholder='Data Final'
                        value={searchDateBirthEnd}
                        onChange={(e) => setSearchDateBirthEnd(e.target.value)}
                        min="2015-12-31"
                        maxLength={10}
                    />
                    {errorsBirth && errorsBirth.length > 0 && errorsBirth[1] ? (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block fs-6'>{errorsBirth[1]}</div>
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    )
}

export { ListSearchSubscriptionBirthDate }
