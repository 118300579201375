import {useEffect, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {LoadingSpinner} from '../../../utils/loading/loadingSpinner'
import {PageOverlayer} from '../../../components/PageOverlayer'

interface IPersonModalInfo {
  data: any
  close: any
}

const StatusModal = ({data, close}: IPersonModalInfo) => {
  const [loadingPage, setLoadingPage] = useState(false)

  function closeModal() {
    close()
  }

  return (
    <>
      {loadingPage ? (
        <PageOverlayer />
      ) : (
        <>
          <div
            className='modal fade show d-block'
            id='kt_modal_edit_user'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
          >
            <div className='modal-dialog modal-dialog-centered mw-1000px'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h2 className='fw-bolder' style={{color: '#D31145'}}>
                    Status do cliente
                  </h2>
                  <div
                    className='btn btn-icon btn-sm btn-active-icon-primary'
                    data-kt-users-modal-action='close'
                    onClick={closeModal}
                    style={{cursor: 'pointer'}}
                  >
                    <KTIcon iconName='cross' className='fs-1' />
                  </div>
                </div>
                <div className='modal-body scroll-y'>
                  <div className='col-12 mt-5'>
                    <div className='col-6'>
                      <label htmlFor='' className='required'>
                        <b>Status cliente</b>
                      </label>
                    </div>

                    <select
                      name='status_medical_record'
                      className='form-select'
                      onChange={(e) => {
                        const value = e.target.value
                        // formik.setFieldValue('status', value);
                      }}
                    >
                      <option value='-1'>Selecione</option>
                      {/* <option value="0">Não</option>
                  <option value="1">Sim</option> */}
                    </select>
                  </div>
                  <div className='col-12 mt-4'>
                    <div className='col-6'>
                      <label htmlFor='' className={'required'}>
                        <b>Observação</b>
                      </label>
                    </div>
                    <div className='col-12'>
                      <input
                        type='text'
                        className='form-control'
                        onChange={(e) => {
                          const value = e.target.value
                          // formik.setFieldValue('observation', value);
                        }}
                      />
                    </div>
                    <div className='col-12 mt-4'>
                      <input
                        type='file'
                        className='form-control form-control-lg form-control-solid '
                        // value={values.file === undefined ? [] : formik.values.file[0]}
                        multiple={false}
                        onChange={(event) => {
                          const file = event?.currentTarget?.files
                          // formik.setFieldValue('file', file![0])
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-backdrop fade show'></div>
        </>
      )}
    </>
  )
}

export {StatusModal}
