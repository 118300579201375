import {useQuery} from 'react-query'
import {fetchDataDashboard} from '../core/_requests'
import { IParsedDashboard, parseDashboard } from '../utils/parseDashboard'

export const useDashboardData = (dateInit?: string, dateEnd?: string) => {
  return useQuery<IParsedDashboard>(
    ['dashboardData', dateInit, dateEnd], // Add dates to query key
    () => fetchDataDashboard(dateInit, dateEnd),
    {
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
      refetchOnWindowFocus: false,
      retry: 2,
      select: (data) => parseDashboard(data),
    }
  )
}
