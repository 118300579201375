import { useQueryResponseLoading, useQueryTotalsData } from './core/QueryResponseProvider'
import { formatMask } from '../../../../utils/formatMonetaryToBr'
import { KTIcon } from '../../../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'

const Totalizer = () => {
  const totals = useQueryTotalsData()
  const isLoading = useQueryResponseLoading()

  const navigate = useNavigate()
  const handleToNavigate = () => navigate(`/reports/reportUser/list`)

  return (
    <>
      <div className='row mb-5 m-5'>
        <div className='col'>
          <div className='card hover-elevate-up shadow-sm parent-hover'>
            <div className='card-header'>
              <h3 className='card-title parent-hover-danger text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                Total Contratos
              </h3>
              <div className='card-toolbar'></div>
            </div>
            <div className='card-body p-0'>
              <div className='card-p bg-light-danger'>
                <div className='text-center px-4'>
                  <h2>{totals?.total_sold || 0}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col'>
          <div className='card hover-elevate-up shadow-sm parent-hover'>
            <div className='card-header'>
              <h3 className='card-title parent-hover-danger text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                Total Venda
              </h3>
              <div className='card-toolbar'></div>
            </div>
            <div className='card-body p-0'>
              <div className='card-p bg-light-danger'>
                <div className='text-center px-4'>
                  <h2>{formatMask(totals?.price)}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col'>
          <div className='card hover-elevate-up shadow-sm parent-hover'>
            <div className='card-header'>
              <h3 className='card-title parent-hover-danger text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                Total Desconto
              </h3>
              <div className='card-toolbar'></div>
            </div>
            <div className='card-body p-0'>
              <div className='card-p bg-light-danger'>
                <div className='text-center px-4'>
                  <h2>{formatMask(totals?.discount)}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col'>
          <div className='card hover-elevate-up shadow-sm parent-hover'>
            <div className='card-header'>
              <h3 className='card-title parent-hover-danger text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                Total Com Desconto
              </h3>
              <div className='card-toolbar'></div>
            </div>
            <div className='card-body p-0'>
              <div className='card-p bg-light-danger'>
                <div className='text-center px-4'>
                  <h2>{formatMask(totals?.total)}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row  d-flex flex-end'>
        <div className='col m-5 d-flex flex-row-auto flex-end'>
          <button type='button' className='btn btn-light-danger' onClick={handleToNavigate}>
            <KTIcon iconName='menu' className='fs-2' />
            Resumido
          </button>
        </div>
      </div>
    </>
  )
}

export { Totalizer }
