// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rmscStyle {
    --rmsc-main: #f0f0f1;
    --rmsc-hover: #f0f0f1;
    --rmsc-selected: #e2e6ea;
    --rmsc-border: #f9f9f9 !important;
    --rmsc-gray: #99a1b7;/*texto*/
    --rmsc-bg: #f9f9f9 !important;
    --rmsc-p: 16px; /* Spacing */
    --rmsc-radius: 0.475rem;/* Radius */
    --rmsc-h: 42px; /* Height */
    outline: none !important;
    border: 0 !important;
    padding: 0 !important;   
}`, "",{"version":3,"sources":["webpack://./src/style/multiSelectSucess.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,qBAAqB;IACrB,wBAAwB;IACxB,iCAAiC;IACjC,oBAAoB,CAAC,QAAQ;IAC7B,6BAA6B;IAC7B,cAAc,EAAE,YAAY;IAC5B,uBAAuB,CAAC,WAAW;IACnC,cAAc,EAAE,WAAW;IAC3B,wBAAwB;IACxB,oBAAoB;IACpB,qBAAqB;AACzB","sourcesContent":[".rmscStyle {\n    --rmsc-main: #f0f0f1;\n    --rmsc-hover: #f0f0f1;\n    --rmsc-selected: #e2e6ea;\n    --rmsc-border: #f9f9f9 !important;\n    --rmsc-gray: #99a1b7;/*texto*/\n    --rmsc-bg: #f9f9f9 !important;\n    --rmsc-p: 16px; /* Spacing */\n    --rmsc-radius: 0.475rem;/* Radius */\n    --rmsc-h: 42px; /* Height */\n    outline: none !important;\n    border: 0 !important;\n    padding: 0 !important;   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
