import {createContext, FC, useContext, useState} from 'react'
import {WithChildren} from '../../_metronic/helpers'
import {ISelect} from '../models/default'
import {
  getMedicalConditions,
  getBanks,
  getCountries,
  getMachines,
  getPersonById,
  getProducts,
  listClients,
  getHowMets,
} from '../_resquest'
import {MultiValue} from 'react-select'
import {Option} from 'react-multi-select-component'
import {buildSearchClient} from '../buildSearchClient'
import {ContactRequest, IPersonResponse, TFieldPerson} from '../models/person'
import {ISelectMedicalCondition} from '../models/medicalCondition'

interface IPeopleContext {
  countries: ISelect[]
  setCountries: React.Dispatch<React.SetStateAction<ISelect[]>>
  getDataCountries: () => void

  country: ISelect | undefined
  setCountry: React.Dispatch<React.SetStateAction<ISelect | undefined>>

  banks: ISelect[] | undefined
  setBanks: React.Dispatch<React.SetStateAction<ISelect[] | undefined>>
  getDataBanks: () => void

  howMetOptions: ISelect[] | undefined
  setHowMetOptions: React.Dispatch<React.SetStateAction<ISelect[] | undefined>>
  getDatahowMets: () => void

  medicalCondition: ISelectMedicalCondition | null
  setMedicalCondition: React.Dispatch<React.SetStateAction<ISelectMedicalCondition | null>>

  medicalConditions: ISelectMedicalCondition[]
  setMedicalConditions: React.Dispatch<React.SetStateAction<ISelectMedicalCondition[]>>
  getDataMedicalCondition: () => void

  contacts: ContactRequest[]
  setContacts: React.Dispatch<React.SetStateAction<ContactRequest[]>>

  machines: ISelect[] | undefined
  setMachines: React.Dispatch<React.SetStateAction<ISelect[] | undefined>>
  getDataMachines: () => Promise<ISelect[] | undefined>
  selectsMachines: MultiValue<ISelect> | undefined
  setSelectsMachines: React.Dispatch<React.SetStateAction<MultiValue<ISelect> | undefined>>

  products: Option[]
  setProducts: React.Dispatch<React.SetStateAction<Option[]>>
  getDataProducts: () => void
  selectsProducts: Option[]
  setSelectsProducts: React.Dispatch<React.SetStateAction<Option[]>>

  fetchClient: (client, index) => void
  isFetching: boolean
  search: any[]
  showEditPersonModal: boolean
  setShowEditPersonModal: React.Dispatch<React.SetStateAction<boolean>>
  showCreatePersonModal: boolean
  setShowCreatePersonModal: React.Dispatch<React.SetStateAction<boolean>>
  showCompleteContactsModal: boolean
  setShowCompleteContactsModal: React.Dispatch<React.SetStateAction<boolean>>
  setShowContracts: React.Dispatch<React.SetStateAction<boolean>>
  removePerson: (index) => void
  searchOnDatabase: (index: number) => void

  persons: IPersonResponse[]
  setPersons: React.Dispatch<React.SetStateAction<any>>

  initialData: any
  setInitialData: React.Dispatch<React.SetStateAction<any>>

  getDataPersons: (id: string) => void
  setPerson: (data: IPersonResponse, index: number) => void
  fieldPersons: TFieldPerson[]
  setFieldPersons: React.Dispatch<React.SetStateAction<TFieldPerson[]>>

  reduceTimeRadio: boolean
  setReduceTimeRadio: React.Dispatch<React.SetStateAction<boolean>>

  minor: boolean
  setMinor: React.Dispatch<React.SetStateAction<boolean>>

  hideAvatarRadio: boolean
  setHideAvatarRadio: React.Dispatch<React.SetStateAction<boolean>>
}

const PeopleContext = createContext<IPeopleContext>({} as IPeopleContext)

const PeopleProvider: FC<WithChildren> = ({children}) => {
  const initialSelectValue = [{value: '', label: ''}]
  const [countries, setCountries] = useState<ISelect[]>([{value: '', label: ''}])
  const [country, setCountry] = useState<ISelect | undefined>()
  const [banks, setBanks] = useState<ISelect[] | undefined>()
  const [howMetOptions, setHowMetOptions] = useState<ISelect[] | undefined>()
  const [medicalCondition, setMedicalCondition] = useState<ISelectMedicalCondition | null>(null)
  const [medicalConditions, setMedicalConditions] = useState<ISelectMedicalCondition[]>([
    {value: '', label: '', status: ''},
  ])
  const [initialData, setInitialData] = useState(null)
  const [contacts, setContacts] = useState<ContactRequest[]>([{id: 0, type: 1, phone: ''}])
  const [machines, setMachines] = useState<ISelect[] | undefined>(initialSelectValue)
  const [selectsMachines, setSelectsMachines] = useState<MultiValue<ISelect>>()
  const [products, setProducts] = useState<Option[]>(initialSelectValue)
  const [selectsProducts, setSelectsProducts] = useState<Option[]>(initialSelectValue)

  const [reduceTimeRadio, setReduceTimeRadio] = useState<boolean>(true)
  const [minor, setMinor] = useState<boolean>(false)

  const [hideAvatarRadio, setHideAvatarRadio] = useState<boolean>(false)

  const getDataCountries = async () => {
    const data = await getCountries()
    if (data !== undefined) {
      setCountries(data)
    } else {
      setCountries([{value: '', label: ''}])
    }
  }

  const getDataBanks = async () => {
    const data = await getBanks()
    setBanks(data)
  }

  const getDatahowMets = async () => {
    const data = await getHowMets()
    setHowMetOptions(data)
  }

  const getDataMedicalCondition = async () => {
    const dataModified = [{value: '', label: '', status: ''}]
    const data = await getMedicalConditions()
    data?.map((field, index) => {
      dataModified[index] = {
        value: String(field?.id),
        label: field?.name,
        status: field?.status,
      }
    })
    setMedicalConditions(dataModified)
  }

  const getDataMachines = async (): Promise<ISelect[] | undefined> => {
    const data = await getMachines()

    data!.map((field, index) => {
      data![index] = {
        value: field.value,
        label: field.value + ' - ' + field.label,
      }
    })
    setMachines(data)
    return data
  }

  const getDataProducts = async () => {
    const data = await getProducts()
    setProducts(data!)
  }

  const [persons, setPersons] = useState<IPersonResponse[]>([])
  const [search, setSearch] = useState<any[]>([])
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [showEditPersonModal, setShowEditPersonModal] = useState<boolean>(false)
  const [showCreatePersonModal, setShowCreatePersonModal] = useState<boolean>(false)
  const [showCompleteContactsModal, setShowCompleteContactsModal] = useState<boolean>(false)
  const [showContracts, setShowContracts] = useState<boolean>(false)

  const [fieldPersons, setFieldPersons] = useState<TFieldPerson[]>([
    {
      name: '',
      document: '',
      dropdownOpen: false,
      isFetching: false,
      search: [],
    },
  ])

  const getDataPersons = async (id: string) => {
    const data = await getPersonById(id)
    setPersons([data])
    setInitialData(data)
    return [data]
  }

  const searchOnDatabase = async (index) => {
    const query = buildSearchClient({
      name: fieldPersons[index].name,
      document: fieldPersons[index].document,
    })
    const updatedFieldPersons = [...fieldPersons]
    updatedFieldPersons[index].isFetching = true
    setFieldPersons(updatedFieldPersons)
    try {
      updatedFieldPersons[index].search = await listClients(query)
    } catch (error) {
      updatedFieldPersons[index].search = []
    } finally {
      updatedFieldPersons[index].isFetching = false
      setFieldPersons(updatedFieldPersons)
    }
  }
  const removePerson = (index: number) => {
    const updatedClients = [...persons]
    updatedClients.splice(index, 1)
    setPersons(updatedClients)
  }

  const fetchClient = async (client, index) => {
    const updatedClients = [...persons]
    try {
      updatedClients[index] = client
    } catch (error) {
      updatedClients.splice(index, 1)
    } finally {
      setPersons(updatedClients)
    }
  }

  const setPerson = (data: IPersonResponse, index: number): void => {
    const updatedClients = [...persons]
    updatedClients[index] = data
    setPersons(updatedClients)
  }

  return (
    <PeopleContext.Provider
      value={{
        countries,
        setCountries,
        getDataCountries,

        country,
        setCountry,

        initialData,
        setInitialData,

        banks,
        setBanks,
        getDataBanks,

        howMetOptions, 
        setHowMetOptions,
        getDatahowMets,

        medicalCondition,
        setMedicalCondition,
        medicalConditions,
        setMedicalConditions,
        getDataMedicalCondition,

        contacts,
        setContacts,

        machines,
        setMachines,
        getDataMachines,
        selectsMachines,
        setSelectsMachines,

        getDataProducts,
        products,
        setProducts,
        selectsProducts,
        setSelectsProducts,

        isFetching,
        search,
        showEditPersonModal,
        setShowEditPersonModal,
        showCreatePersonModal,
        setShowCreatePersonModal,
        showCompleteContactsModal,
        setShowCompleteContactsModal,
        fetchClient,
        setShowContracts,
        removePerson,
        searchOnDatabase,

        persons,
        setPersons,
        getDataPersons,
        setPerson,
        fieldPersons,
        setFieldPersons,

        reduceTimeRadio,
        setReduceTimeRadio,
        hideAvatarRadio,
        setHideAvatarRadio,

        minor, 
        setMinor,
      }}
    >
      {children}
    </PeopleContext.Provider>
  )
}

const usePeople = () => useContext(PeopleContext)
export {PeopleProvider, usePeople}
