import * as Yup from 'yup';

export const BankDataValidSchema = Yup.object().shape({
    bank: Yup.string().when('accountType', {
        is: (val) => val != 0 && val != undefined,
        then: () => Yup.string().required("Selecione um banco"),
    }),
    agency: Yup.string().when('accountType', {
        is: (val) => val != 0 && val != undefined,
        then: () => Yup.string().required("Informe a agência"),
    }),
    account: Yup.string().when('accountType', {
        is: (val) => val != 0 && val != undefined,
        then: () => Yup.string().required("Informe a conta"),
    }),
    accountDv: Yup.string().when('accountType', {
        is: (val) => val != 0 && val != undefined,
        then: () => Yup.string()
            .max(1, 'Máximo 1 caractere')
            .required("Informe o digito da conta"),
    }),
});