import * as Yup from 'yup'

export const transferFranchiseSchemas = [
  Yup.object({
    hasFine: Yup.boolean().required('Selecione o tipo de multa!'),
    fineDestination: Yup.number().when('hasFine', {
      is: (val) => {
        return val === true
      },
      then: () => Yup.number().required('Preenchimento obrigatório de destino'),
      otherwise: () => Yup.number().optional(),
    }),
  }),
  Yup.object({
    transferFine: Yup.array()
      .when('hasFine', {
        is: (val) => {
          return val === true
        },
        then: () => {
          return Yup.array().required('Selecione o pagamento da multa!')
        },
        otherwise: () => {
          return Yup.array().optional()
        },
      })
      .label('Selecione o tipo de multa!'),
    billingsOrigin: Yup.array()
      .when('hasClientOriginValue', {
        is: (val) => {
          return val === true
        },
        then: () => {
          return Yup.array().required('Selecione o pagamento da multa!')
        },
        otherwise: () => {
          return Yup.array().optional()
        },
      })
      .label('Selecione o tipo de multa!'),
    billingsDestination: Yup.array()
      .when('hasClientDestinationValue', {
        is: (val) => {
          return val === true
        },
        then: () => {
          return Yup.array().required('Selecione o pagamento da multa!')
        },
        otherwise: () => {
          return Yup.array().optional()
        },
      })
      .label('Selecione o tipo de multa!'),
  }),
]
