import {formatDate} from '../../../../utils/dayJs/format'
import {formatMask} from '../../../../utils/formatMonetaryToBr'

function getNextDueDate(installments) {
  for (let i = 0; i < installments.length; i++) {
    if (installments[i].status === 'Pendente') {
      return installments[i].dueDate
    }
  }
  return null
}

function getFirstAndLastDueDates(installments) {
  const firstDueDate = installments[0]?.dueDate
  const lastDueDate = installments[installments.length - 1]?.dueDate

  return {
    firstDueDate: firstDueDate,
    lastDueDate: lastDueDate,
  }
}

const InfoSection = ({billing}) => {
  const isPix = billing.paymentOption.id == 1

  if (billing.status == 'Pendente' && isPix) {
    return (
      <div className='d-flex flex-column'>
        <span className='fw-bold fs-6 fs-md-2 text-danger'>{billing?.paymentOption?.name}</span>
        <span className='fw-normal fs-6 fs-md-4'>Vencimento</span>
      </div>
    )
  }

  const hasOneInstallments = billing.numberOfInstallments == 1
  const hasMoreOneInstallments = billing.numberOfInstallments > 1

  if (billing.status == 'Pendente' && hasMoreOneInstallments) {
    const {firstDueDate, lastDueDate} = getFirstAndLastDueDates(billing.installments)

    return (
      <div className='d-flex flex-column'>
        <span className='fw-bold fs-6 fs-md-2 text-danger'>{billing?.paymentOption?.name}</span>
        <span className='fw-normal fs-6'>Início das parcelas: {formatDate(firstDueDate)}</span>
        <span className='fw-normal fs-6'>Final das parcelas: {formatDate(lastDueDate)}</span>
      </div>
    )
  }

  if (billing.status == 'Pendente' && hasOneInstallments && !hasMoreOneInstallments) {
    return (
      <div className='d-flex flex-column'>
        <span className='fw-bold fs-6 fs-md-2 text-danger'>{billing?.paymentOption?.name}</span>
        <span className='fw-normal fs-6 fs-md-4'>Vencimento</span>
      </div>
    )
  }

  return <span className='fw-bold fs-6 fs-md-2 text-danger'>{billing?.paymentOption?.name}</span>
}

const SplitSection = ({billing}) => {
  const hasOneInstallments = billing.numberOfInstallments == 1
  const hasNotInstallments = billing.numberOfInstallments == 0
  const installments = billing.installments

  if (hasNotInstallments) {
    return <span className='fw-bold fs-3 text-danger'>{formatMask(billing?.amount)}</span>
  }

  if (hasOneInstallments && installments.length > 0) {
    return (
      <span className='fw-bold fs-3 text-danger'>
        {formatMask(billing.installments[0]?.amount)}
      </span>
    )
  }

  if (hasOneInstallments && installments.length == 0) {
    return <span className='fw-bold fs-3 text-danger'>{formatMask(billing?.amount)}</span>
  }

  return (
    <span className='fw-bold fs-3 text-danger'>
      {billing.numberOfInstallments}X {formatMask(billing.installments[0]?.amount)}
    </span>
  )
}

const PaymentSection = ({billing}) => {
  const isPix = billing.paymentOption.id == 1
  const hasMoreInstallments = billing.numberOfInstallments > 1
  const hasNotInstallments = billing.numberOfInstallments == 0
  const hasOneInstallments = billing.numberOfInstallments == 1
  const installments = billing.installments

  if (billing.status == 'Pendente' && isPix) {
    return (
      <div className='d-flex flex-column align-items-end'>
        <span className='fw-bold fs-6 fs-md-2 text-end'>Aguardando pagamento</span>
        <span className='fw-normal fs-6'>{formatDate(billing.pixPayment.dueDate)}</span>
      </div>
    )
  }

  if (billing.status == 'Pendente' && hasNotInstallments) {
    return (
      <div className='d-flex flex-column'>
        <span className='fw-bold fs-6 fs-md-2 text-end'>Aguardando pagamento</span>
      </div>
    )
  }

  if (billing.status == 'Pendente' && hasMoreInstallments) {
    return (
      <div className='d-flex flex-column align-items-end justify-content-end'>
        <span className='fw-bold fs-6 fs-md-2 text-end'>Aguardando pagamento</span>
        <span className='fw-normal fs-6'>{formatDate(getNextDueDate(billing.installments))}</span>
      </div>
    )
  }

  if (billing.status == 'Pendente' && hasOneInstallments && installments.length == 0) {
    return (
      <div className='d-flex flex-column align-items-end justify-content-end'>
        <span className='fw-bold fs-6 fs-md-2 text-end'>Aguardando pagamento</span>
        <span className='fw-normal fs-6'>{formatDate(billing.createdAt)}</span>
      </div>
    )
  }

  if (billing.status == 'Pendente' && hasOneInstallments && installments.length > 0) {
    return (
      <div className='d-flex flex-column align-items-end justify-content-end'>
        <span className='fw-bold fs-6 fs-md-2 text-end'>Aguardando pagamento</span>
        <span className='fw-normal fs-6'>{formatDate(getNextDueDate(billing.installments))}</span>
      </div>
    )
  }

  return (
    <div className='d-flex flex-row align-items-end justify-content-end'>
      <i className='fa-solid fa-circle-check fs-1 me-2 text-success'></i>
      <span className='fw-bold fs-4'>Pago</span>
    </div>
  )
}

export const PaymentCard = ({billing}) => {
  return (
    <div className='d-flex bg-white px-4 px-md-8 py-4 col-12 flex-column flex-md-row align-items-center justify-content-center'>
      <div className='row col-12'>
        <div className='col-8 col-md-4 d-flex align-items-center justify-content-start'>
          <InfoSection billing={billing} />
        </div>
        <div className='d-none col-0 col-md-4 d-md-flex align-items-center justify-content-start'>
          <SplitSection billing={billing} />
        </div>
        <div className='col-4 col-md-4 d-flex align-items-center justify-content-end'>
          <PaymentSection billing={billing} />
        </div>
      </div>

      <div className='row col-12 col-md-0 d-flex d-md-none text-end align-items-end justify-content-end'>
        <SplitSection billing={billing} />
      </div>
    </div>
  )
}
