import axios from 'axios'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

export const echoReverb = (userId, setCurrentUser) => {
  window.Pusher = Pusher
  const echo = new Echo({
    broadcaster: 'reverb',
    key: process.env.REACT_APP_REVERB_KEY,
    wsHost: process.env.REACT_APP_REVERB_HOST,
    wsPort: Number(process.env.REACT_APP_REVERB_PORT),
    wssPort: Number(process.env.REACT_APP_REVERB_PORT),
    forceTLS: process.env.REACT_APP_REVERB_TLS == 'true',
    enabledTransports: ['ws', 'wss'],
    authorizer: (channel, options) => {
      return {
        authorize: (socketId, callback) => {
          axios
            .post(process.env.REACT_APP_API_URL + '/broadcasting/auth', {
              socket_id: socketId,
              channel_name: channel.name,
            })
            .then((response) => {
              callback(false, response.data)
            })
            .catch((error) => {
              callback(true, error)
            })
        },
      }
    },
  })

  echo.private('users.' + userId).notification((notification) => {
    setCurrentUser((current) => {
      if (!current) return current

      return {
        ...current,
        unreadNotifications: [...(current.unreadNotifications || []), notification],
      }
    })
  })

  return echo
}
