import {PageLink, PageTitle} from '../../_metronic/layout/core'
import {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import {updateMedicalCondition} from './core/_request'
import Swal from 'sweetalert2'
import {medicalConditionSchema} from './core/validation/medicalConditionSchema'
import {
  medicalConditionInitValues as initialValues,
  IMedicalConditionResponse,
} from '../../coreGlobal/models/medicalCondition'
import {MedicalConditionForm} from './components/MedicalConditionForm'
import {FormProvider, MedicalConditionFormProv} from './core/FormProvider'
import {BuildRequest} from './core/BuildRequest'
import {useParams} from 'react-router-dom'
import {LoadingSpinner} from '../../utils/loading/loadingSpinner'
import {PageOverlayer} from '../../components/PageOverlayer'

const medicalConditionBreadcrumbs: Array<PageLink> = [
  {
    title: 'Condição Médica',
    path: '/medicalConditions',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const Update = () => {
  let {id} = useParams()
  const {
    getDataCategories,
    getDataMedicalCondition,
    setSelectsProducts,
    setCheckboxClockNewSales,
    setCheckboxblockNewAppointments,
    setCheckBlockExistingAppointments,
    setHideCategory,
    setHideProduct,
    getDataProducts,
    setSelectsCategories,
    navigate,
  } = MedicalConditionFormProv()
  const [loadingPage, setLoadingPage] = useState(false)
  const [loading, setLoading] = useState(false)

  const formik = useFormik<IMedicalConditionResponse>({
    initialValues,
    validationSchema: medicalConditionSchema,
    onSubmit: async (values) => {
      const valuesFilter = BuildRequest(values)
      await submit(valuesFilter)
    },
  })

  useEffect(() => {
    const init = async () => {
      setLoadingPage(true)
      const dataMedicalCondition = await getDataMedicalCondition(String(id))
      const dataCegories = await getDataCategories()
      await parseToFormik(dataMedicalCondition, dataCegories)
      setLoadingPage(false)
    }

    try {
      init()
    } catch (error: any) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: error?.response?.data?.message,
        showConfirmButton: true,
      })
    }
  }, [])

  async function parseToFormik(dataMedicalCondition, dataCegories) {
    const data = [{value: 0, label: ''}]
    const dataCategory: any = []

    await dataMedicalCondition.products.map((field, index) => {
      data[index] = {
        value: field?.id,
        label: field?.name,
      }
      dataCategory[index] = field?.category_id
    })

    //Falta retorno do banco com as categorias
    const uniqueArrayDataCategory = dataCategory.filter((item, index) => {
      return dataCategory.indexOf(item) === index
    })

    setSelectsCategories(dataCegories.filter((categoria) => dataCategory.includes(categoria.id)))

    //Falta retorno do banco com as categorias
    await getDataProducts(uniqueArrayDataCategory.join(','))

    setSelectsProducts(data)

    formik.setValues({
      name: dataMedicalCondition.name,
      description: dataMedicalCondition.description ? dataMedicalCondition.description : '',
      status: dataMedicalCondition.status ? dataMedicalCondition.status : '',
      blockNewSales: dataMedicalCondition.blockNewSales ? dataMedicalCondition.blockNewSales : true,
      blockNewAppointments: dataMedicalCondition.blockNewAppointments
        ? dataMedicalCondition.blockNewAppointments
        : true,
      blockExistingAppointments: dataMedicalCondition.blockExistingAppointments
        ? dataMedicalCondition.blockExistingAppointments
        : true,
      products: data.filter((item) => item.value > 0).map((item) => Number(item.value)),
      categories: dataCategory,
    })

    if (data.length && dataMedicalCondition.status === 'RESTRICTED') {
      setHideCategory(true)
      setHideProduct(true)
    }
    setCheckboxClockNewSales(dataMedicalCondition.blockNewSales)
    setCheckboxblockNewAppointments(dataMedicalCondition.blockNewAppointments)
    setCheckBlockExistingAppointments(dataMedicalCondition.blockExistingAppointments)
  }

  const submit = async (req: IMedicalConditionResponse) => {
    await Swal.fire({
      title: 'Deseja atualizar a condição médica?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-danger', // Classe personalizada para o botão de confirmação
        cancelButton: 'btn btn-secondary', // Classe personalizada para o botão de cancelamento (opcional)
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true)
        try {
          await updateMedicalCondition(String(id), req)
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Salvo com sucesso',
            showConfirmButton: false,
            timer: 1500,
          })
          navigate('/people/medicalConditions', {replace: true})
        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = ''
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>'
            }
            await Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText,
            })
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 2000,
            })
          }
        } finally {
          setLoading(false)
        }
      }
    })
  }

  return (
    <>
      {loadingPage ? (
        <PageOverlayer />
      ) : (
        <>
          <PageTitle breadcrumbs={medicalConditionBreadcrumbs}>Criar Condição Médica</PageTitle>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <MedicalConditionForm formik={formik} />
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                type='submit'
                className='btn btn-primary'
                disabled={loading || !formik.isValid}
              >
                {!loading && 'Salvar'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Salvando...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </>
      )}
    </>
  )
}

export const MedicalConditionEditPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={medicalConditionBreadcrumbs}>Criar nova forma de pagamento</PageTitle>
      <FormProvider>
        <Update />
      </FormProvider>
    </>
  )
}
