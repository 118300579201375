interface ISpinner {
  title?: string
  style?: {
    width: string
    height: string
    colorText?: string
    colorSpinner?: string
  }
}

function hexToRgb(hex: string) {
  // Remover o caractere "#" caso esteja presente
  hex = hex.replace("#", "");

  // Converter para R, G, B
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return { r, g, b };
}

// Função para criar um degradê
function generateGradient(startColor: { r: number, g: number, b: number }, steps: number,): string[] {
  const gradient: string[] = [];  // Especificando o tipo do array como string[]

  for (let i = 0; i < steps; i++) {
    const r = Math.min(255, Math.round(startColor.r + (255 - startColor.r) * (i * 0.2))); // Aumenta o vermelho em direção ao branco
    const g = Math.min(255, Math.round(startColor.g + (255 - startColor.g) * (i * 0.2))); // Aumenta o verde em direção ao branco
    const b = Math.min(255, Math.round(startColor.b + (255 - startColor.b) * (i * 0.2))); // Aumenta o azul em direção ao branco
  
    gradient.push(`rgb(${r}, ${g}, ${b})`);
  }

  return gradient;
}

export const LoadingSpinner = ({ title, style  }: ISpinner) => {

  var widthStyle = style ? style.width : '5rem'
  var heightStyle = style ? style.height : '5rem'
  var colorText = style?.colorText ? style.colorText : ''
  
  var borderTopColorStyle = '';
  var borderRightColorStyle = '';
  var borderBottomColorStyle = '';
  var borderLeftColorStyle = '';

  if (style?.colorSpinner) {
    var rgb = generateGradient(hexToRgb(`${style.colorSpinner}`), 6);
    borderTopColorStyle = `${rgb[1]}`;//PONTA INICIAL
    borderRightColorStyle = `${rgb[0]}`;
    borderLeftColorStyle = `${rgb[0]}`;//MEIO
    borderBottomColorStyle = `${rgb[5]}`;//PONTA FINAL
  }

  return (
    <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100%' }}>
      <div className="spinner-border text-dark" role="status" style={{
        width: `${widthStyle}`,
        height: `${heightStyle}`,
        borderTopColor: `${borderTopColorStyle}`,
        borderRightColor: `${borderRightColorStyle}`,
        borderBottomColor: `${borderBottomColorStyle}`,
        borderLeftColor: `${borderLeftColorStyle}`,
      }}>
        <span className="sr-only">...</span>
      </div>
      <h4 className="mt-5" style={{ color: `${colorText}` }}>{title}...</h4>
    </div>
  );
};
