import { createContext, FC, useContext, useState } from "react"
import { SearchReportsClients, WithChildren } from "../../../../_metronic/helpers"


interface IFilterContext {
    filterExport: SearchReportsClients
    setFilterExport: React.Dispatch<React.SetStateAction<SearchReportsClients>>
}

const FilterContext = createContext<IFilterContext>({} as IFilterContext)

const FilterProvider: FC<WithChildren> = ({ children }) => {

    const [filterExport, setFilterExport] = useState<SearchReportsClients>({})

    return (
        <FilterContext.Provider
            value={{
                filterExport,
                setFilterExport,
            }}
        >
            {children}
        </FilterContext.Provider>
    )
}

const useFilterExport = () => useContext(FilterContext)
export { FilterProvider, useFilterExport }
