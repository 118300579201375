import {useEffect} from 'react'
import Swal from 'sweetalert2'
import './CustomSwal.css'

interface ISawErrorSpinner {
  error?: any
  onRetry: () => void
}

export const ErrorState = ({error, onRetry}: ISawErrorSpinner) => {

  const message = error.response?.data?.message ?? 'Tente Novamente'

  useEffect(() => {
    Swal.fire({
      position: 'top',
      icon: 'error',
      iconColor: 'white',
      title: 'Error',
      html: `<p style="color: #FFFFFF;">${message}</p>`,
      toast: true,
      showConfirmButton: true,
      confirmButtonText: 'Tentar Novamente',
      customClass: {
        popup: 'custom-swal-bg custom-swal-top-center',
        title: 'custom-swal-title',
        htmlContainer: 'custom-swal-html',
        confirmButton: `custom-swal-button`
      },
      buttonsStyling: false,
    }).then((response) => {
      if (response.isConfirmed) {
        onRetry()
      }
    })

    return () => {
      Swal.close()
    }
  }, [])

  return null
}
