import { cleanMask } from "../utils/cleanMask"
import { TPersonRequest } from "./models/person"

export const buildPersonRequest = (data: TPersonRequest): FormData => {

    const formData = new FormData();

    if (data.id) formData.append('id', String(data.id));
    formData.append('type', '1');
    formData.append('name', data.name);
    if (data.preferredName) formData.append('preferredName', data.preferredName);
    if (data.email) formData.append('email', data.email);
    formData.append('document', cleanMask(data.document));
    if (data.gender) formData.append('gender', data.gender);
    if (data.birthDate) formData.append('birthDate', data.birthDate);
    if (data.rg) formData.append('rg', data.rg);
    if (data.job) formData.append('job', data.job);
    if (data.nationality) formData.append('nationality', String(data.nationality));

    if (data.contacts) {
        data.contacts.forEach((contact, index) => {
            if (contact.id) formData.append(`contacts[${index}][id]`, String(contact.id));
            formData.append(`contacts[${index}][type]`, String(contact.type));
            formData.append(`contacts[${index}][phone]`, cleanMask(contact.phone));
        });
    }

    formData.append('reduceTime', String(data.reduceTime ? 1 : 0));
    formData.append('hideAvatar', String(data.hideAvatar ? 1 : 0));

    if (data.addresses) {
        data.addresses.forEach((address, index) => {
            if (address.id) formData.append(`addresses[${index}][id]`, String(address.id));
            formData.append(`addresses[${index}][isMain]`, '1');
            formData.append(`addresses[${index}][zipCode]`, address.zipCode);
            formData.append(`addresses[${index}][street]`, address.street);
            formData.append(`addresses[${index}][number]`, String(address.number));
            if (address.complement) formData.append(`addresses[${index}][complement]`, String(address.complement));
            formData.append(`addresses[${index}][neighborhood]`, address.neighborhood);
            formData.append(`addresses[${index}][city]`, String(address.city));
        });
    }

    if (data.bankData) {
        data.bankData.forEach((bank, index) => {
            if (bank.id) formData.append(`bankData[${index}][id]`, String(bank.id));
            formData.append(`bankData[${index}][isMain]`, String(bank.isMain === true ? 1 : 0));
            if (bank.accountType) formData.append(`bankData[${index}][accountType]`, String(bank.accountType));
            if (bank.accountType) formData.append(`bankData[${index}][bank]`, String(bank.bank));
            if (bank.accountType) formData.append(`bankData[${index}][agency]`, bank.agency);
            if (bank.accountType && bank.agencyDv) formData.append(`bankData[${index}][agencyDv]`, bank.agencyDv);
            if (bank.accountType) formData.append(`bankData[${index}][account]`, String(bank.account));
            if (bank.accountType) formData.append(`bankData[${index}][accountDv]`, bank.accountDv);
            if (bank.pix) formData.append(`bankData[${index}][pix]`, bank.pix);
        });
    }

    if (data.medicalConditions) {
        data.medicalConditions.forEach((medicalCondition, index) => {
            if (medicalCondition.id) formData.append(`medicalConditions[${index}][id]`, String(medicalCondition.id));
            formData.append(`medicalConditions[${index}][authorized]`, String(medicalCondition.authorized));
            if (medicalCondition.observation) formData.append(`medicalConditions[${index}][observation]`, medicalCondition.observation);
            if (medicalCondition.documentAuthorized && typeof medicalCondition.documentAuthorized != "string") {
                formData.append(`medicalConditions[${index}][file]`, medicalCondition?.documentAuthorized);
            }
        });
    }

    if (data.observations) {
        data.observations.forEach((observation, index) => {
            if (observation.id) formData.append(`observations[${index}][id]`, String(observation.id));
            if (observation.observation) formData.append(`observations[${index}][observation]`, String(observation.observation));
            if (observation.createdAt) formData.append(`observations[${index}][createdAt]`, String(observation.createdAt));
            if (observation.updatedAt) formData.append(`observations[${index}][updatedAt]`, cleanMask(observation.updatedAt));
        });
    }

    return formData;
}
