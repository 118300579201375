import { KTIcon } from "../../../../../../_metronic/helpers";
import { cpfMask } from "../../../../../../utils/cpfMask";

const ListSearchSubscriptionDocument = ({ searchDocument, setSearchDocument }) => {

    return (
        <>
            <div className='mb-5'>
                <label className='form-label fs-6 fw-bold'>Pesquisar por CPF:</label>
                <div className='d-flex align-items-center position-relative my-1'>
                    <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                    <input
                        type='text'
                        data-kt-user-table-filter='document'
                        className='form-control form-control-solid min-w-250px ps-14'
                        placeholder='Informe o CPF'
                        value={cpfMask(searchDocument)}
                        onChange={(e) => {
                            if (e.target.value.length <= 14) {
                                setSearchDocument(e.target.value)
                            }
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export { ListSearchSubscriptionDocument }
