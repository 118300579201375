import {useEffect, useRef, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {FranchiseTransferProductInfo} from './components/FranchiseTransferProductInfo'
import {useFranchiseTransferCart} from './core/FranchiseTransferProvider'
import {transferFranchiseSchemas} from './Validation/FranchiseTransferVali'
import {useFormik} from 'formik'
import {FTChoicePayment} from './components/FTChoicePayment'
import classNames from 'classnames'

import {createTransfer} from './core/_request'
import Swal from 'sweetalert2'
import {LoadingSpinner} from '../../../../utils/loading/loadingSpinner'
import {Transfer} from './core/_models'
import {formatToDecimal} from './Utils/formatMask'

export const FranchiseTransfer = ({close}) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(transferFranchiseSchemas[0])
  const [isSubmitButton, setSubmitButton] = useState(false)
  const [loading, setLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)

  const INITIAL_VALUES_FORMIK: Transfer = {
    saleId: -1,
    destinationFranchise: -1,
    transferFine: undefined,
    billingsOrigin: undefined,
    billingsDestination: undefined,
    hasFine: undefined,
    fineDestination: undefined,
    hasClientOriginValue: undefined,
    hasClientDestinationValue: undefined,
  }

  // const modalSizeClass = classNames({
  //   'modal-xl': currentStep === 0,
  //   'modal-sm': currentStep === 1,
  // })

  const {
    hiddenButton,
    saleId,
    franchiseDestination,
    setHiddenButton,
    dataCalculate,
    amountFine,
    transferFineFranchse,
    setTransferFineFranchse,
    setTransferStatus,
    setHasFine,
    setAmountFine,
  } = useFranchiseTransferCart()

  function changeStepModal(code: number) {
    if (!stepper.current) {
      return
    }
    if (code === 0) {
      setCurrentSchema(transferFranchiseSchemas[stepper.current.currentStepIndex])
    } else {
      setCurrentSchema(transferFranchiseSchemas[stepper.current.currentStepIndex - 1])
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }
  const formik = useFormik({
    initialValues: INITIAL_VALUES_FORMIK,
    validationSchema: currentSchema,
    onSubmit: async (values: Transfer) => {
      let valuesFilter = {
        saleId: saleId,
        destinationFranchise: franchiseDestination,
        ...(values.transferFine && {transferFine: values.transferFine[0]}),
        ...(values.billingsOrigin && {billingsOrigin: values.billingsOrigin}),
        ...(values.billingsDestination && {billingsDestination: values.billingsDestination}),
      }

      handleSubmit(valuesFilter)
    },
  })

  useEffect(() => {
    formik.validateForm()
  }, [formik.errors, formik.values])

  const handleSubmit = async (val) => {
    await Swal.fire({
      title: 'Deseja realizar a transferência ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-danger', // Classe personalizada para o botão de confirmação
        cancelButton: 'btn btn-secondary' // Classe personalizada para o botão de cancelamento (opcional)
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true)
        try {
          const data = await createTransfer(val)
          setTransferStatus(true)
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Transferência realizada com sucesso',
            showConfirmButton: false,
            timer: 1500,
          })
        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = ''
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>'
            }
            await Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText,
            })
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 2000,
            })
          }
        } finally {
          setLoading(false)
        }
      }
    })
  }

  const verifyPaymentAndFine = async (data, dataFine?) => {
    if (
      (data.clientTransferValueToDestination != 0 && data.clientTransferValueToOrigin == 0) ||
      transferFineFranchse == 2
    ) {
      formik.setValues({
        ...formik.values,
        hasClientOriginValue: false,
        hasClientDestinationValue: true,
        ...(dataFine && {transferFine: dataFine}),
      })
      return
    }

    if (
      (data.clientTransferValueToDestination == 0 && data.clientTransferValueToOrigin != 0) ||
      transferFineFranchse == 1
    ) {
      formik.setValues({
        ...formik.values,
        hasClientOriginValue: true,
        hasClientDestinationValue: false,
        ...(dataFine && {transferFine: dataFine}),
      })
      return
    }

    if (data.clientTransferValueToDestination != 0 && data.clientTransferValueToOrigin != 0) {
      formik.setValues({
        ...formik.values,
        hasClientOriginValue: true,
        hasClientDestinationValue: true,
        ...(dataFine && {transferFine: dataFine}),
      })
      return
    }
    formik.setValues({
      ...formik.values,
      hasClientOriginValue: false,
      hasClientDestinationValue: false,
      ...(dataFine && {transferFine: dataFine}),
    })
  }

  const verifyStep = () => {
    if (!stepper.current) {
      return
    }

    if (stepper.current.currentStepIndex === 2) {
      setSubmitButton(true)
      formik.handleSubmit()
    } else {
      if (stepper.current.currentStepIndex === 1) {
        if (!formik.values.hasFine) {
          changeStepModal(0)
          verifyPaymentAndFine(dataCalculate)
          stepper.current.goNext()
          setSubmitButton(false)
          return
        }

        const transferFine = [
          {
            amount: Number(formatToDecimal(amountFine)),
            franchise: transferFineFranchse,
          },
        ]

        changeStepModal(0)
        verifyPaymentAndFine(dataCalculate, transferFine)
        stepper.current.goNext()
        setSubmitButton(false)
      }
    }
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    setSubmitButton(false)
    formik.setValues({
      ...formik.values,
      hasClientOriginValue: undefined,
      hasClientDestinationValue: undefined,
      transferFine: undefined,
      hasFine: undefined,
      fineDestination: undefined,
      billingsOrigin: undefined,
      billingsDestination: undefined,
    })
    setTransferFineFranchse(undefined)
    setHasFine(undefined)
    stepper.current.goPrev()
    changeStepModal(1)
  }

  const cleanView = () => {
    setHasFine(undefined)
    setTransferFineFranchse(undefined)
    setAmountFine('15000')
  }

  return (
    <>
      <div className='modal fade show d-block'>
        <div className={`modal-dialog modal-dialog-centered modal-xl`}>
          <div className='modal-content'>
            {loading && <LoadingSpinner title='Carregando.....' />}
            <div className='modal-header'>
              <h2 className='fw-bolder'>Andamento da Transferência </h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => {
                  close()
                  setHiddenButton(false)
                  cleanView()
                }}
                style={{cursor: 'pointer'}}
              >
                <KTIcon iconName='cross' className='fs-1' />
              </div>
            </div>
            <div
              ref={stepperRef}
              className='row m-8 stepper stepper-links'
              id='kt_create_account_stepper'
            >
              <div className='stepper-nav mb-5' hidden>
                <div className='stepper-item current' data-kt-stepper-element='nav'>
                  <h3 className='stepper-title'>AAAAAA</h3>
                </div>

                <div className='stepper-item' data-kt-stepper-element='nav'>
                  <h3 className='stepper-title'>Account Info</h3>
                </div>
              </div>

              <form noValidate className='form' onSubmit={formik.handleSubmit}>
                <div
                  className='current'
                  data-kt-stepper-element='content'
                  style={{justifyContent: 'center'}}
                >
                  <FranchiseTransferProductInfo formik={formik} close={() => close()} />
                </div>

                <div data-kt-stepper-element='content'>
                  <FTChoicePayment formik={formik} />
                </div>

                <div className='d-flex flex-stack mt-3'>
                  <div className='mr-2'>
                    <button
                      type='button'
                      className='btn btn-lg btn-secondary me-3 mb-3'
                      data-kt-stepper-action='previous'
                      onClick={() => prevStep()}
                      hidden={hiddenButton}
                    >
                      Voltar
                    </button>
                  </div>
                  <button
                    type={!isSubmitButton ? 'button' : 'submit'}
                    onClick={() => {
                      verifyStep()
                    }}
                    className='btn btn-lg btn-danger  me-3  mb-3'
                    disabled={!formik.touched || !formik.isValid}
                    hidden={hiddenButton}
                  >
                    <span className='indicator-label'>Continue</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}
