import {FC, useState, createContext, useContext} from 'react'
import {WithChildren} from '../../../../../_metronic/helpers'
import {getFranchises} from '../../../../users/core/_resquest'
import {BillingTransfer, BillingTransferOrigin, PaymentFine, TransferResponse} from './_models'
import {v4 as uuidv4} from 'uuid'

type TAction = 'ORIGIN' | 'DESTINATION'
interface IFranchiseTransferCartContext {
  franchise: any | null
  setFranchise: React.Dispatch<React.SetStateAction<any>>

  billingOrigin: BillingTransferOrigin[]
  setBillingOrigin: React.Dispatch<React.SetStateAction<BillingTransferOrigin[]>>

  billingDestination: BillingTransfer[]
  setBillingDestination: React.Dispatch<React.SetStateAction<BillingTransfer[]>>

  transferStatus: boolean
  setTransferStatus: React.Dispatch<React.SetStateAction<boolean>>

  // billingFine: PaymentFine[]
  // setBillingFine: React.Dispatch<React.SetStateAction<PaymentFine[]>>

  saleId: any | null
  setSale: React.Dispatch<React.SetStateAction<any>>

  dataPayment: any[]
  setDataPayment: React.Dispatch<React.SetStateAction<any[]>>

  franchiseDestination: any | null
  setFranchiseDestination: React.Dispatch<React.SetStateAction<any>>

  itensProduct: any | null
  setItensProduct: React.Dispatch<React.SetStateAction<any>>

  amountFine: string | undefined
  setAmountFine: React.Dispatch<React.SetStateAction<string | undefined>>

  hiddenButton: boolean
  setHiddenButton: React.Dispatch<React.SetStateAction<boolean>>

  dataCalculate: any | null

  setDataCalculate: React.Dispatch<React.SetStateAction<any>>
  searchOnDatabase: () => void

  search: string
  setSearch: React.Dispatch<React.SetStateAction<any>>

  transferFineFranchse: any
  setTransferFineFranchse: React.Dispatch<React.SetStateAction<any>>

  hasFine: boolean | undefined
  setHasFine: React.Dispatch<React.SetStateAction<boolean | undefined>>
  isFetching: boolean

  handleChangeTypeMethodPaymentBilling: (
    index: any,
    e: any,
    hasInstallment: boolean,
    typeAction: TAction
  ) => void
  handleChangeInstallmentsBilling: (index: any, e: any, typeAction: TAction) => void
  handleChangeValueMethodPaymentBilling: (index: any, e: any, typeAction: TAction) => void
  removeMethodPaymentBilling: (index: any, typeAction: TAction) => void
  addMethodPaymentBilling: (typeAction: TAction) => void
}

const DEFAULT_PAYMENT_BILLING: BillingTransfer = {
  method: 1,
  paymentRef: String(uuidv4()),
  installments: -1,
  amount: 0,
  recurrency: false,
  hasInstallment: false,
}

const DEFAULT_PAYMENT_BILLING_ORIGIN: BillingTransferOrigin = {
  method: 1,
  paymentRef: String(uuidv4()),
  installments: -1,
  amount: 0,
  hasInstallment: false,
}

const FranchiseTransferCardContext = createContext<IFranchiseTransferCartContext>(
  {} as IFranchiseTransferCartContext
)

const FranchiseTransferCardProvider: FC<WithChildren> = ({children}) => {
  const [franchise, setFranchise] = useState<any | null>(null)

  const [dataPayment, setDataPayment] = useState<any[]>([])
  const [franchiseDestination, setFranchiseDestination] = useState<any | null>(null)
  const [transferFineFranchse, setTransferFineFranchse] = useState<any | null>()
  const [hasFine, setHasFine] = useState<boolean | undefined>(undefined)
  const [itensProduct, setItensProduct] = useState<any | null>(null)
  const [amountFine, setAmountFine] = useState<string | undefined>('15000')
  const [transferStatus, setTransferStatus] = useState<boolean>(false)

  const [dataCalculate, setDataCalculate] = useState<any | null>(null)
  const [saleId, setSale] = useState<any | null>(null)
  const [search, setSearch] = useState('')

  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [hiddenButton, setHiddenButton] = useState(false)

  const [billingOrigin, setBillingOrigin] = useState<BillingTransferOrigin[]>([
    DEFAULT_PAYMENT_BILLING_ORIGIN,
  ])
  const [billingDestination, setBillingDestination] = useState<BillingTransfer[]>([
    DEFAULT_PAYMENT_BILLING,
  ])

  const searchOnDatabase = async () => {
    try {
      const data = await getFranchises()
      setFranchise(data)
    } catch (error) {
      setFranchise([])
    }
  }

  const handleChangeTypeMethodPaymentBilling = (index, e, hasInstallment, typeAction) => {
    let method = 1
    let recurrency = false

    switch (Number(e.target.value)) {
      case 1: //PIX
        method = 1
        recurrency = false
        break
      case 2: // CARTAO
        method = 2
        break
      case 3: // CARTAO COM RECORRENCIA
        method = 3
        recurrency = true
        break
      default:
        method = Number(e.target.value)
        break
    }

    if (typeAction == 'DESTINATION') {
      let newData = [...billingDestination]

      newData[index] = {
        ...billingDestination[index],
        method: method,
        recurrency: recurrency,
        amount: 0,
        hasInstallment: hasInstallment,
        ...(recurrency && {installments: 2}),
      }

      setBillingDestination(newData)
    } else {
      let newData = [...billingOrigin]
      newData[index] = {
        ...billingOrigin[index],
        method: method,
        amount: 0,
        hasInstallment: hasInstallment,
        ...(hasInstallment && {installments: 2}),
      }
      setBillingOrigin(newData)
    }
  }

  const handleChangeInstallmentsBilling = (index, installments, typeAction) => {
    if (typeAction == 'DESTINATION') {
      let newData = [...billingDestination]
      newData[index] = {
        ...billingDestination[index],
        installments: Number(installments),
      }
      setBillingDestination(newData)
    } else {
      let newData = [...billingOrigin]
      newData[index] = {
        ...billingOrigin[index],
        installments: Number(installments),
      }
      setBillingOrigin(newData)
    }
  }

  const handleChangeValueMethodPaymentBilling = (index, value, typeAction) => {
    if (typeAction == 'DESTINATION') {
      let newData = [...billingDestination]

      newData[index] = {
        ...billingDestination[index],
        amount: value,
      }
      setBillingDestination(newData)
    } else {
      let newData = [...billingOrigin]

      newData[index] = {
        ...billingOrigin[index],
        amount: value,
      }
      setBillingOrigin(newData)
    }
  }

  const removeMethodPaymentBilling = (uuidV4: string, typeAction) => {
    if (typeAction == 'DESTINATION') {
      setBillingDestination(billingDestination.filter((pay) => pay.paymentRef !== uuidV4))
    } else {
      setBillingOrigin(billingDestination.filter((pay) => pay.paymentRef !== uuidV4))
    }
  }

  const addMethodPaymentBilling = (typeAction) => {
    if (typeAction == 'DESTINATION') {
      setBillingDestination([
        ...billingDestination,
        {
          ...DEFAULT_PAYMENT_BILLING,
          paymentRef: String(uuidv4()),
        },
      ])
    } else {
      setBillingOrigin([
        ...billingOrigin,
        {
          ...DEFAULT_PAYMENT_BILLING_ORIGIN,
          paymentRef: String(uuidv4()),
        },
      ])
    }
  }

  return (
    <FranchiseTransferCardContext.Provider
      value={{
        franchise,

        franchiseDestination,
        setFranchiseDestination,

        itensProduct,
        setItensProduct,

        amountFine,
        setAmountFine,

        transferStatus,
        setTransferStatus,

        dataPayment,
        setDataPayment,

        hasFine,
        setHasFine,

        billingOrigin,
        setBillingOrigin,

        billingDestination,
        setBillingDestination,

        handleChangeTypeMethodPaymentBilling,
        handleChangeInstallmentsBilling,
        handleChangeValueMethodPaymentBilling,
        removeMethodPaymentBilling,
        addMethodPaymentBilling,

        transferFineFranchse,
        setTransferFineFranchse,

        saleId,
        setSale,

        dataCalculate,
        setDataCalculate,

        hiddenButton,
        setHiddenButton,

        setFranchise,
        searchOnDatabase,

        isFetching,

        search,
        setSearch,
      }}
    >
      {children}
    </FranchiseTransferCardContext.Provider>
  )
}

const useFranchiseTransferCart = () => useContext(FranchiseTransferCardContext)

export {FranchiseTransferCardProvider, useFranchiseTransferCart}
