import {ApexOptions} from 'apexcharts'
import {useMemo} from 'react'
import Chart from 'react-apexcharts'

interface ICustomers {
  id: number
  name: string
  quantity_customers: number
}

interface ChartClientProps {
  className: string
  customers: ICustomers[] | undefined
}

const colors = [`#50cd89`, `#FF0000`, `#000000`]

export function ChartClient({className, customers}: ChartClientProps) {
  const chartData = useMemo(() => {
    if (!customers || customers.length === 0) {
      return {series: [], labels: []}
    }

    return {
      series: customers.map((val) => Number(val.quantity_customers)),
      labels: customers.map((val, index) => ({
        name: `${val.name}`,
        quantity: `${val.quantity_customers}`,
        color: colors[index] || '#000000',
      })),
      colors: colors,
    }
  }, [customers])

  const options: ApexOptions = useMemo(
    () => ({
      plotOptions: {
        pie: {donut: {size: '70%'}},
      },
      legend: {
        show: false,
      },
      stroke: {show: true, width: 2, colors: ['transparent']},
      states: {
        normal: {filter: {type: 'none', value: 0}},
        hover: {filter: {type: 'none', value: 0}},
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {type: 'none', value: 0},
        },
      },
      tooltip: {
        style: {fontSize: '12px'},
        y: {formatter: (val: number) => String(val)},
      },
      labels: chartData.labels.map((label) => label.name),
      colors: colors,
    }),
    [chartData]
  )

  const hasData = customers && customers?.length > 0

  return (
    <div className={`d-flex align-items-center justify-content-center w-100 h-100 ${className}`}>
      {hasData ? (
        <>
          <Chart
            options={options}
            series={chartData.series}
            type='donut'
            width='100%'
            height='100%'
          />
          <div className='d-flex flex-column content-justify-center flex-row-fluid'>
            {chartData && chartData.labels?.map((label) => (
              <div className='d-flex fw-semibold align-items-center'>
              <div className='bullet w-8px h-3px rounded-2 me-3' style={{ backgroundColor: label.color}}></div>
              <div className='text-gray-500 me-4'>{label.name}</div>
              <div className='fw-bolder text-gray-700 text-xxl-end'>{label.quantity}</div>
            </div>
            ))}
          </div>
        </>
      ) : (
        <div className='text-center w-100'>Nenhum dado disponível</div>
      )}
    </div>
  )
}
