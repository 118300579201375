import React, {useEffect, useState} from 'react'
import {LoadingSpinner} from '../../../utils/loading/loadingSpinner'
import {getSchedulesItemsByPersonId} from '../../../coreGlobal/_resquest'
import {formatDateTime, formatDateTimeHM} from '../../../utils/dayJs/format'
import {SaleItemResponse} from '../../../coreGlobal/models/sale'
import {MedicalRecordModal} from './MedicalRecordModal'
import {KTIcon} from '../../../_metronic/helpers'
import {PageOverlayer} from '../../../components/PageOverlayer'

interface IPeopleID {
  peopleId: number | null
}

const enableButton = [8, 7, 3]

function changeIcons(status: number, medicalRecordStatus: string) {
  if (status === 8 && medicalRecordStatus !== 'Realizado') {
    //cancelado
    return <KTIcon iconName='cross-circle' className='fs-1 text-danger' />
  } else if (status === 8 || (status === 7 && medicalRecordStatus === 'Realizado')) {
    //finalizado
    return <KTIcon iconName='like' className='fs-1 text-success' />
  } else if (status === 1 || status === 2) {
    //Agendado || confirmado
    return <KTIcon iconName='book' className='fs-1 text-warning' />
  } else if (status === 3 || status === 7) {
    //Compareceu || autorizado
    return <KTIcon iconName='check-circle' className='fs-1 text-success' />
  }
}
export const ListMedicalRecords = ({peopleId}: IPeopleID) => {
  const [showMedicalRecordModal, setShowMedicalRecordModal] = useState<boolean>(false)
  const [medicalRecord, setMedicalRecord] = useState<any>(null)

  const [loading, setLoading] = useState(false)
  const [saleItems, setSaleItems] = useState<SaleItemResponse[]>()
  useEffect(() => {
    const init = async () => {
      setLoading(true)
      if (peopleId) {
        setSaleItems(await getSchedulesItemsByPersonId(peopleId))
      }
      setLoading(false)
    }
    init()
  }, [])
  return (
    <>
      {loading ? (
        <PageOverlayer />
      ) : (
        <>
          <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>
                Informações do Prontuário | Agendado:{' '}
                <KTIcon iconName='book' className='fs-1 text-warning' /> | Compareceu:{' '}
                <KTIcon iconName='check-circle' className='fs-1 text-success' /> | Evoluido:{' '}
                <KTIcon iconName='like' className='fs-1 text-success' /> | Cancelado:{' '}
                <KTIcon iconName='cross-circle' className='fs-1 text-danger' /> |
              </h3>
            </div>
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-bordered table-row-gray-300 gy-7'>
                {saleItems?.map((item, index) => {
                  return (
                    <React.Fragment key={`saleItemsTr${index}`}>
                      <thead>
                        <tr className='table-active'>
                          <th colSpan={7}>
                            {`Contrato: ${item.saleId} | ${item.product.id} - ${
                              item.product.name
                            } | Data contrato: ${formatDateTime(
                              String(item.sale?.createdAt)
                            )} | Total de sessões: ${item.sessions}`}
                          </th>
                        </tr>
                        <tr className='fw-bolder fs-6 text-gray-800 table-active'>
                          <th>Sessão</th>
                          <th>Status</th>
                          <th>Data</th>
                          <th>Parametros</th>
                          <th>Profissional</th>
                          <th>Observação</th>
                          <th>Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.medicalRecords?.map((medicalRecord, index) => {
                          return (
                            <tr key={`medicalRecordsTr${index}`}>
                              <td className='text-center'>{medicalRecord.sessionNumber}</td>
                              <td className='text-center'>
                                {changeIcons(
                                  Number(
                                    medicalRecord.scheduleItem?.schedule?.lastHistory?.status_id
                                  ),
                                  medicalRecord.status
                                )}
                              </td>
                              <td className='text-center'>
                                {formatDateTimeHM(medicalRecord.createdAt)}
                              </td>
                              <td className='text-center'>{medicalRecord.machineFrequency}</td>
                              <td className='text-center'>{medicalRecord.attendant?.name}</td>
                              <td>{medicalRecord.observation}</td>
                              <td className='text-center'>
                                {enableButton.includes(
                                  Number(
                                    medicalRecord.scheduleItem?.schedule?.lastHistory?.status_id
                                  )
                                ) && (
                                  <button
                                    onClick={() => {
                                      setMedicalRecord(medicalRecord)
                                      setShowMedicalRecordModal(true)
                                    }}
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                  >
                                    <KTIcon iconName='gear' className='fs-3' />
                                  </button>
                                )}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </React.Fragment>
                  )
                })}
              </table>
            </div>
          </div>
        </>
      )}
      {showMedicalRecordModal && (
        <MedicalRecordModal
          medicalRecord={medicalRecord}
          close={() => {
            setShowMedicalRecordModal(false)
            setMedicalRecord(null)
          }}
        />
      )}
    </>
  )
}
