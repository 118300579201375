import Swal from "sweetalert2";
import { buildUpdateRequest } from "../core/buildUpdateRequest";
import { updateFranchiseSync } from "../core/_request";
import {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import {franchiseConfigSchema} from '../core/validation/FanchiseVali'
import {IFranchiseConfigResponse} from '../../../coreGlobal/models/franchise/franchise'

type Props = {
  franchiseId: number,
  config: IFranchiseConfigResponse,
  loading: any,
}

const SettingOptionForm = ({ franchiseId, config }: Props) => {
  const [loading, setLoading] = useState(false)

  const initialValues = {
    startTime: '',
    endTime: '',
    gatewayCashier: '',
    gatewayHash: '',
    cieloMerchantId: '',
    cieloMerchantKey: '',
  }

  useEffect(() => {
    if (config) {
      formik.setValues({
        startTime: config.startTime,
        endTime: config.endTime,
        gatewayCashier: config.gatewayCashier,
        gatewayHash: config.gatewayHash,
        cieloMerchantId: config.cieloMerchantId,
        cieloMerchantKey: config.cieloMerchantKey,
      });
    }

  }, [config])

  const formik = useFormik({
    initialValues,
    validationSchema: franchiseConfigSchema,
    onSubmit: async (values) => {
      const valuesFilter = await buildUpdateRequest(values)
      submit(valuesFilter)
    }
  })


  const submit = (valuesFilter) => {
    Swal.fire({
      title: 'Deseja alterar as configurações gerais?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-danger', // Classe personalizada para o botão de confirmação
        cancelButton: 'btn btn-secondary' // Classe personalizada para o botão de cancelamento (opcional)
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true)
        try {
          await updateFranchiseSync(String(franchiseId), valuesFilter)
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Salvo com sucesso',
            showConfirmButton: false,
            timer: 1500
          })

        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = '';
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>';
            }
            await Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText
            });
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 2000
            })
          }
        } finally {
          setLoading(false)
        }
      }
    })
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="container mb-10">
          <div className='row'>
            <div className='column col-lg-6'>
              <label className='col-lg-12 col-form-label required fw-bold fs-6'>Horário de funcionamento:</label>
              <div className='row'>
                <div className='col-lg-5 fv-row'>
                  <input
                    type='time'
                    step={60}
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Horário inícial'
                    {...formik.getFieldProps('startTime')}
                  />
                  {formik.touched?.startTime && formik.errors?.startTime ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        {formik.errors?.startTime}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className='col-lg-1 fv-row'>
                  <b>as</b>
                </div>
                <div className='col-lg-5 fv-row'>
                  <input
                    type='time'
                    step={60}
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Horário final'
                    {...formik.getFieldProps('endTime')}
                  />
                  {formik.touched?.endTime && formik.errors?.endTime ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        {formik.errors?.endTime}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className='column col-lg-12'>
              <label className='col-lg-12 col-form-label fw-bold fs-6'>
                <span>Gateway Cashier</span>
              </label>
              <div className='col-lg-12 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Informe o gateway Cashier'
                  {...formik.getFieldProps('gatewayCashier')}
                />
                {formik.touched?.gatewayCashier && formik.errors?.gatewayCashier ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      {formik.errors?.gatewayCashier}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className='column col-lg-12'>
              <label className='col-lg-12 col-form-label fw-bold fs-6'>
                <span>Gateway Hash</span>
              </label>
              <div className='col-lg-12 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Informe o gateway Cashier'
                  {...formik.getFieldProps('gatewayHash')}
                />
                {formik.touched?.gatewayHash && formik.errors?.gatewayHash ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      {formik.errors?.gatewayHash}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className='column col-lg-6'>
              <label className='col-lg-12 col-form-label fw-bold fs-6'>
                <span>ID Cielo</span>
              </label>
              <div className='col-lg-12 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Informe o ID da Cielo'
                  {...formik.getFieldProps('cieloMerchantId')}
                />
                {formik.touched?.cieloMerchantId && formik.errors?.cieloMerchantId ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      {formik.errors?.cieloMerchantId}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className='column col-lg-6'>
              <label className='col-lg-12 col-form-label fw-bold fs-6'>
                <span>Chave do Comerciante Cielo</span>
              </label>
              <div className='col-lg-12 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Chave do Comerciante Cielo'
                  {...formik.getFieldProps('cieloMerchantKey')}
                />
                {formik.touched?.cieloMerchantKey && formik.errors?.cieloMerchantKey ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      {formik.errors?.cieloMerchantKey}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className='card-footer d-flex justify-content-end py-6 px-9'>

          <button type='submit' className='btn btn-danger' disabled={loading || !formik.isValid}>
            {!loading && 'Salvar'}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Salvando...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </>
  )
}

export default SettingOptionForm