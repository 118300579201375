import {PageLink, PageTitle} from '../../_metronic/layout/core'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import {Outlet, useParams} from 'react-router-dom'
import {getBillingById} from './core/_requests'
import {ContractProvider} from '../sales/core/ContractProvider'
import {useEffect, useState} from 'react'
import {BillingProvider} from '../sales/core/billingProvider'
import {SaleShowPage} from '../sales/show'
import {IBillingRequest} from '../../coreGlobal/models/billing'
import {formatMask} from '../../utils/formatMonetaryToBr'
import {PageOverlayer} from '../../components/PageOverlayer'
import Swal from 'sweetalert2'

const billingBreadcrumbs: Array<PageLink> = [
  {
    title: 'Cobranças',
    path: '/billings',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const BillingShowPage = () => {
  let {id} = useParams()
  const [loadingPage, setLoadingPage] = useState(false)
  const [billingData, setBillingData] = useState<IBillingRequest>()

  useEffect(() => {
    const init = async () => {
      try {
        setLoadingPage(true)
        const data = await getBillingById(String(id))
        setBillingData(data)
        setLoadingPage(false)
      } catch (error: any) {
        await Swal.fire({
          icon: 'warning',
          title: 'Oops...',
          text: error?.response?.data?.message,
          showConfirmButton: true,
        })
      }
    }
    init()
  }, [])

  function valueContent(billingData) {
    if (billingData?.installments.length > 0) {
      return billingData?.installments?.map((value, index) => {
        return (
          <tr key={index}>
            <td>{formatMask(value.amount)}</td>
            <td>{billingData.paymentOption.name}</td>
            <td>{new Date(value.dueDate).toLocaleDateString('pt-BR')}</td>
            <td></td>
            <td>{value.installment}</td>
            <td>
              <a
                className={
                  value.status === 'Pendente' ? 'badge badge-warning' : 'badge badge-success'
                }
                style={{color: 'black'}}
              >
                {value.status}
              </a>
            </td>
          </tr>
        )
      })
    } else if (billingData?.pixPayment.length > 0) {
      return billingData?.pixPayment?.map((value, index) => {
        return (
          <tr key={index}>
            <td>{formatMask(value.amount)}</td>
            <td>{billingData.paymentOption.name}</td>
            <td>{new Date(value.dueDate).toLocaleDateString('pt-BR')}</td>
            <td></td>
            <td>À vista</td>
            <td>
              <a
                className={
                  billingData?.status === 'Pendente' ? 'badge badge-warning' : 'badge badge-success'
                }
                style={{color: 'black'}}
              >
                {billingData?.status}
              </a>
            </td>
          </tr>
        )
      })
    } else {
      return (
        <tr>
          <td>{formatMask(billingData?.amount)}</td>
          <td>{billingData?.paymentOption.name}</td>
          <td></td>
          <td></td>
          <td>À vista</td>
          <td>
            <a
              className={
                billingData?.status === 'Pendente' ? 'badge badge-warning' : 'badge badge-success'
              }
              style={{color: 'black'}}
            >
              {billingData?.status}
            </a>
          </td>
        </tr>
      )
    }
  }

  const get_badge_class = () => {
    let badgeClass = ''
    switch (billingData?.sale?.client?.lastClientHistory?.status.name) {
      case 'Ativo':
        badgeClass = 'success'
        break
      case 'Restrito':
        badgeClass = 'warning'
        break
      default:
        badgeClass = 'danger'
        break
    }
    return badgeClass
  }

  return (
    <>
      {loadingPage ? (
        <PageOverlayer />
      ) : (
        <>
          <Outlet />
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-0'>
              <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                <div className='me-7 mb-4'>
                  <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                    <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='Metronic' />
                    <div
                      className={`position-absolute translate-middle bottom-0 start-100 mb-6 bg-${get_badge_class()} rounded-circle border border-4 border-white h-20px w-20px`}
                    ></div>
                  </div>
                </div>
                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center mb-2'>
                        <a className='text-gray-800 text-hover-danger fs-2 fw-bolder me-1'>
                          {`${billingData?.sale?.client?.id} - ${billingData?.sale?.client?.name} - VENDA: ${billingData?.id}`}
                        </a>
                      </div>
                    </div>
                    <div className='d-flex my-7'>
                      <h1>
                        {/* <a
                            className={`${status}`}
                          >
                            {saleData?.status}
                          </a> */}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex overflow-auto h-55px'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                  <li className='nav-item'>
                    <button
                      className='nav-link active'
                      id='pills-items-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-items'
                      type='button'
                      role='tab'
                      aria-controls='pills-items'
                      aria-selected='true'
                    >
                      Dados cobrança
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <PageTitle breadcrumbs={billingBreadcrumbs}>Detalhamento da Cobrança</PageTitle>
          <div className='tab-content' id='pills-tabContent'>
            <div
              className='tab-pane fade show active p-4 pt-0 pt-md-4'
              id='pills-items'
              role='tabpanel'
              aria-labelledby='pills-checklist-tab'
            >
              <div className='card mb-5 mb-xl-10'>
                <>
                  <div className='card-header cursor-pointer'>
                    <div className='card-title m-0'>
                      <h3 className='fw-bolder m-0'>Informações da Cobrança</h3>
                    </div>
                  </div>
                  <div className='card-body py-3'>
                    <div className='table-responsive'>
                      <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                        <thead>
                          <tr className='fw-bold text-muted'>
                            <th className='min-w-120px'>Preço</th>
                            <th className='min-w-120px'>Forma de pagamento</th>
                            <th className='min-w-120px'>Dt de vencimento</th>
                            <th className='min-w-120px'>Dt de pagamento</th>
                            <th className='min-w-120px'>Parcelas</th>
                            <th className='min-w-120px'>Status</th>
                          </tr>
                        </thead>
                        <tbody>{valueContent(billingData)}</tbody>
                      </table>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export const SalesViewPage = () => {
  return (
    <>
      <ContractProvider>
        <BillingProvider>
          <SaleShowPage />
        </BillingProvider>
      </ContractProvider>
    </>
  )
}
