import {useParams} from 'react-router-dom'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import {useEffect, useState} from 'react'
import {HeaderForShowPage} from '../../components/HeaderForShowPage'
import {formatMonetaryToBr} from '../../utils/formatMonetaryToBr'
import {formatDateTime} from '../../utils/dayJs/format'
import {LoadingSpinner} from '../../utils/loading/loadingSpinner'
import {getProductById} from './core/_request'
import {PageOverlayer} from '../../components/PageOverlayer'
import Swal from 'sweetalert2'

const ProductsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Produtos',
    path: '/produts',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const Show = () => {
  let {id} = useParams()
  const [loadingPage, setLoadingPage] = useState(false)
  const [products, setProducts] = useState({
    name: null,
    gender: null,
    price: null,
    packagePrice: null,
    category_id: '',
    contractTerm: {name: null},
    category: {name: null},
    createdAt: null,
    updatedAt: null,
    sessionTime: null,
    minDaysBetweenSessions: null,
    active: null,
  })

  useEffect(() => {
    const product = async () => {
      setLoadingPage(true)
      const data = await getProductById(String(id))
      setProducts(data)
      setLoadingPage(false)
    }
    try {
      product()
    } catch (error: any) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: error?.response?.data?.message,
        showConfirmButton: true,
      })
    }
  }, [])

  const createBadge = (gender: string) => {
    let badge

    switch (gender) {
      case 'm':
        badge = <span className='gap-1 badge badge-primary p-2'>Masculino</span>
        break
      case 'f':
        badge = <span className='gap-1 badge badge-danger p-2'>Feminino</span>
        break
      case 'o':
        badge = (
          <span>
            <span className='gap-1 badge badge-primary p-2'>Masculino</span>{' '}
            <span className='gap-1 badge badge-danger p-2'>Feminino</span>
          </span>
        )
        break
      default:
        badge = <span className='gap-1 badge badge-warning p-2'>Não informado</span>
        break
    }
    return badge
  }

  return (
    <>
      {loadingPage ? (
        <PageOverlayer />
      ) : (
        <div className='card'>
          <div className='card-body'>
            <HeaderForShowPage title='Informações do Produto' />
            <p>
              <strong>Nome:</strong> {products?.name ? products?.name : ' '}
            </p>
            <p className='d-flex flex-row gap-2'>
              <strong>Gênero:</strong>
              {String(products.gender)
                ? createBadge(String(products.gender).toLocaleLowerCase())
                : 'N/A'}
            </p>
            <div></div>
            <p>
              <strong>Preço:</strong> {products.price ? formatMonetaryToBr(products.price) : ' '}
            </p>
            <p>
              <strong>Preço Pacote:</strong>{' '}
              {products.packagePrice ? formatMonetaryToBr(products.packagePrice) : ' '}
            </p>
            <p>
              <strong>Categoria:</strong> {products.category.name ? products.category.name : ' '}
            </p>
            <p>
              <strong>Tempo de execução:</strong>{' '}
              {products.sessionTime ? products.sessionTime : ' '}
            </p>
            <p>
              <strong>Tempo mínimo para agendamento:</strong> {products.minDaysBetweenSessions}{' '}
              dia(s)
            </p>
            <p>
              <strong>Termo:</strong>{' '}
              {products.contractTerm.name ? products.contractTerm.name : ' '}
            </p>
            <p>
              <strong>Ativo:</strong> {products.active ? 'Sim' : 'Não'}
            </p>
            <p>
              <strong>Criado em:</strong>{' '}
              {products.createdAt ? formatDateTime(products.createdAt) : ' '}
            </p>
            <p>
              <strong>Atualizado em:</strong>{' '}
              {products.updatedAt ? formatDateTime(products.updatedAt) : ' '}
            </p>
          </div>
        </div>
      )}
    </>
  )
}
export const ProductShowPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={ProductsBreadcrumbs}>Visualizar Produto</PageTitle>
      <Show />
    </>
  )
}
