/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { TransfersReports } from '../../core/_models'
import { useNavigate } from 'react-router-dom'

type Props = {
  transfer: TransfersReports
}

const get_badge_class = (text) => {
  let badgeClass = ''
  switch (text) {
    case 'Aprovado':
      badgeClass = 'success'
      break
    case 'Completado':
      badgeClass = 'info'
      break
    case 'Pendente':
      badgeClass = 'warning'
      break
    default:
      badgeClass = 'danger'
      break
  }
  return badgeClass
}

export const StatusCell: FC<Props> = ({ transfer }) => {
  const id = String(transfer.id) as string

  const navigate = useNavigate()

  const handleToShow = () => navigate(`/users/list/${id}`)

  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span
          role='button'
          className='text-gray-800 text-hover-danger mb-1'
          onClick={() => handleToShow()}
        >
          <span className={`badge badge-${get_badge_class(transfer.status)}`}>
            {transfer.status}
          </span>
        </span>
      </div>
    </div>
  )
}
