import { useEffect } from "react";

const ListSearchSubscriptionDateRegister = ({ setSearchDateRegisterStart, searchDateRegisterStart, setSearchDateRegisterEnd, searchDateRegisterEnd, setErrorsRegister, errorsRegister }) => {

    useEffect(() => {
        if (searchDateRegisterStart.length === 10 && searchDateRegisterEnd.length === 10) {
            if (new Date(searchDateRegisterStart).setHours(0, 0, 0, 0) > new Date(searchDateRegisterEnd).setHours(0, 0, 0, 0)) {
                const novosErros = [...errorsRegister];
                novosErros[0] = 'Data inicial deve ser menor que data final';
                novosErros[1] = 'Data inicial deve maior que data final';
                setErrorsRegister(novosErros);
                return
            }
        } else if (searchDateRegisterStart.length === 10 && searchDateRegisterEnd.length < 10) {
            console.log("aqui...")
            const novosErros = [...errorsRegister];
            novosErros[0] = '';
            novosErros[1] = 'Data final deve ser informada';
            setErrorsRegister(novosErros);
            return
        } else if (searchDateRegisterEnd.length === 10 && searchDateRegisterStart.length < 10) {
            const novosErros = [...errorsRegister];
            novosErros[0] = 'Data inicial deve ser informada';
            novosErros[1] = '';
            setErrorsRegister(novosErros);
            return
        }
        const novosErros = [...errorsRegister];
        novosErros[0] = '';
        novosErros[1] = '';
        setErrorsRegister(novosErros);
    }, [searchDateRegisterStart, searchDateRegisterEnd])
    return (
        <>
            <div className='mb-5 row'>
                <div className="col-6">
                    <label className='form-label fs-6 fw-bold'>Pesquisar por Data de Cadastro: Inicial</label>
                    <input
                        type='date'
                        data-kt-user-table-filter='createdAt'
                        className='form-control form-control-solid w-100'
                        placeholder='Data inícial'
                        value={searchDateRegisterStart}
                        onChange={(e) => setSearchDateRegisterStart(e.target.value)}
                        min="2015-12-31"
                        maxLength={10}
                    />
                    {errorsRegister && errorsRegister.length > 0 && errorsRegister[0] ? (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block fs-6'>{errorsRegister[0]}</div>
                        </div>
                    ) : null}
                </div>
                <div className="col-6">
                    <label className='form-label fs-6 fw-bold'>Pesquisar por Data de Cadastro: Final</label>
                    <input
                        type='date'
                        data-kt-user-table-filter='dtEnd'
                        className='form-control form-control-solid'
                        placeholder='Data Final'
                        value={searchDateRegisterEnd}
                        onChange={(e) => setSearchDateRegisterEnd(e.target.value)}
                        min="2015-12-31"
                        maxLength={10}
                    />
                    {errorsRegister && errorsRegister.length > 0 && errorsRegister[1] ? (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block fs-6'>{errorsRegister[1]}</div>
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    )
}

export { ListSearchSubscriptionDateRegister }
