import {useEffect, useState} from 'react'
import {getMedicalRecordsBySale} from '../core/_request'
import {LoadingSpinner} from '../../../utils/loading/loadingSpinner'
import {formatDateTimeHM} from '../../../utils/dayJs/format'
import {SaleItemResponse} from '../../../coreGlobal/models/sale'
import {PageOverlayer} from '../../../components/PageOverlayer'

interface ISaleID {
  saleId: number | null
}

export const ListSalesMedicalRecords = ({saleId}: ISaleID) => {
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState<SaleItemResponse[]>()
  useEffect(() => {
    const init = async () => {
      setLoading(true)
      setItems(await getMedicalRecordsBySale(String(saleId)))
      setLoading(false)
    }
    init()
  }, [])

  return (
    <>
      {loading ? (
        <PageOverlayer />
      ) : (
        <>
          <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Informações do Prontuário</h3>
            </div>
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bolder fs-6 text-gray-800'>
                    <th>Sessão</th>
                    <th>Status</th>
                    <th>Area</th>
                    <th>Data</th>
                    <th>Parametros</th>
                    <th>Profissional</th>
                    <th>Observação</th>
                  </tr>
                </thead>
                <tbody>
                  {items?.map((item, index) => {
                    return item.medicalRecords?.map((medicalRecord) => {
                      let badgeClass = ''
                      switch (medicalRecord.status) {
                        case 'Realizado':
                          badgeClass = 'success'
                          break
                        case 'Não realizado':
                          badgeClass = 'danger'
                          break
                        default:
                          badgeClass = 'primary'
                          break
                      }
                      return (
                        <tr>
                          <td>{medicalRecord.sessionNumber}</td>
                          <td>
                            {' '}
                            <span className={`badge badge-${badgeClass}`}>
                              {medicalRecord?.status}
                            </span>
                          </td>
                          <td>{item.product?.name}</td>
                          <td>
                            {medicalRecord?.createdAt
                              ? formatDateTimeHM(medicalRecord.createdAt)
                              : ''}
                          </td>
                          <td>{medicalRecord?.machineFrequency}</td>
                          <td>{medicalRecord?.attendant?.name}</td>
                          <td>{medicalRecord?.observation}</td>
                        </tr>
                      )
                    })
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </>
  )
}
