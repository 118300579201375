/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { People } from '../../core/_models'

type Props = {
    people: People
}

const get_badge_class = (text) => {
    let badgeClass = ''
    switch (text) {
        case 'Ativo':
            badgeClass = 'success'
            break
        case 'Restrito':
            badgeClass = 'warning'
            break
        default:
            badgeClass = 'danger'
            break
    }
    return badgeClass
}

const StatusCell: FC<Props> = ({ people }) => (
    <>
        <div className='d-flex align-items-center'>
            <div className='d-flex flex-column'>
                <span className={`badge badge-${get_badge_class(people.lastClientHistory?.status?.name)}`}>
                    {people.lastClientHistory?.status?.name}
                </span>
            </div>
        </div>
    </>
)


export { StatusCell }
