import axios, {AxiosResponse} from 'axios'
import {PeopleQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL + '/api'
const PEOPLE_URL = `${API_URL}/people`
const MEDICAL_RECORD = `${API_URL}/medical-record`

const listPeople = (query: string): Promise<PeopleQueryResponse> => {
  return axios
    .get(`${PEOPLE_URL}?${query}&include=physicalPerson&type=1`)
    .then((d: AxiosResponse<PeopleQueryResponse>) => d.data)
}

interface IUser {
  id: number
  name: string
  cpf: string
  email: string
  phone: string
  personId: any
  firstLogin: false
  createdAt: string
  updatedAt: string
}

interface IAttendant {
  id: number
  name: string
  cpf: string
  email: string
  phone: string
  personId: any
  firstLogin: boolean
  createdAt: string
  updatedAt: string
}

export interface IGetMedicalRecord {
  id: number
  status: string
  machineFrequency: string
  observation: string
  createdAt: string
  updatedAt: string
  user: IUser
  attendant: IAttendant
  logs: any[]
  scheduleItem: {
    id: number
    sessionNumber: number
    evaluationProduct: any
    saleItem: any
  }
}

const getMedicalRecord = (id: number): Promise<IGetMedicalRecord> => {
  return axios
    .get(
      `${MEDICAL_RECORD}/${String(
        id
      )}?include=scheduleItem.saleItem.product,scheduleItem.evaluationProduct,user,attendant,logs.user`
    )
    .then((d: any) => d.data)
    .then((d: any) => d.data)
}

export const linkClient = (document: number | string): Promise<any> => {
  return axios
    .post(`${PEOPLE_URL}/link-client`, {document: document})
    .then((d: any) => d.data)
    .then((d: any) => d.data)
}

export {listPeople, getMedicalRecord}
