import {useNavigate, useParams} from 'react-router-dom'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import {useEffect, useState} from 'react'
import {TPaymentOptionResponse} from '../../coreGlobal/models/paymentOption'
import {getPaymentOptionsById} from './core/_request'
import {LoadingSpinner} from '../../utils/loading/loadingSpinner'
import {HeaderForShowPage} from '../../components/HeaderForShowPage'
import {formatDateTime} from '../../utils/dayJs/format'
import {PageOverlayer} from '../../components/PageOverlayer'
import Swal from 'sweetalert2'

const PaymentOptionsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Forma de Pagamento',
    path: '/paymentOptions',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const Show = () => {
  let {id} = useParams()
  const navigate = useNavigate()
  const [loadingPage, setLoadingPage] = useState(false)
  const [paymentOption, setPaymentOption] = useState<TPaymentOptionResponse>()

  useEffect(() => {
    const init = async () => {
      setLoadingPage(true)
      try {
        const data = await getPaymentOptionsById(String(id))
        setPaymentOption(data)
        setLoadingPage(false)
      } catch (error: any) {
        setLoadingPage(false)
        Swal.fire({
          title: 'Erro',
          text: error.response.data.message,
          icon: 'error',
          showConfirmButton: true,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/paymentOptions')
          }
        })
      }
    }
    init()
  }, [])

  return (
    <>
      {loadingPage ? (
        <PageOverlayer />
      ) : (
        <div className='card'>
          <div className='card-body'>
            <HeaderForShowPage title='Informações do Produto' />
            <p>
              <strong>Nome:</strong> {paymentOption?.name}
            </p>
            <p>
              <strong>Status:</strong> {paymentOption?.active ? 'Ativo' : 'Inativo'}
            </p>
            <p>
              <strong>Criado em:</strong>{' '}
              {paymentOption?.createdAt ? formatDateTime(paymentOption.createdAt) : ' '}
            </p>
            <p>
              <strong>Atualizado em:</strong>{' '}
              {paymentOption?.updatedAt ? formatDateTime(paymentOption.updatedAt) : ' '}
            </p>
          </div>
        </div>
      )}
    </>
  )
}

export const PaymentOptionShowPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={PaymentOptionsBreadcrumbs}>Visualizar forma de pagamento</PageTitle>
      <Show />
    </>
  )
}
