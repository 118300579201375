import React, {useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import {LoadingSpinner} from '../../utils/loading/loadingSpinner'
import {TFranchiseResponse} from '../../coreGlobal/models/franchise/franchise'
import {Outlet, useParams} from 'react-router-dom'
import {getFranchiseById} from './core/_request'
import {formatDateTime} from '../../utils/dayJs/format'
import {phoneMask} from '../../utils/phoneMask'
import {cepMask} from '../../utils/cepMask'
import {formatMonetaryToBr} from '../../utils/formatMonetaryToBr'
import {KTIcon, toAbsoluteUrl} from '../../_metronic/helpers'
import {PageOverlayer} from '../../components/PageOverlayer'

const franchiseBreadcrumbs: Array<PageLink> = [
  {
    title: 'Franquias',
    path: '/franchise',
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const Show = () => {
  let {id} = useParams()
  const [loadingPage, setLoadingPage] = useState(false)
  const [franchise, setFranchise] = useState<TFranchiseResponse>()

  useEffect(() => {
    const machine = async () => {
      setLoadingPage(true)
      const data = await getFranchiseById(String(id))
      setFranchise(data)
      setLoadingPage(false)
    }
    machine()
  }, [])

  return (
    <>
      {loadingPage ? (
        <PageOverlayer />
      ) : (
        <>
          <Outlet />
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-0'>
              <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                <div className='me-7 mb-4'>
                  <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                    <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='Metronic' />
                    <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
                  </div>
                </div>
                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center mb-2'>
                        <a className='text-gray-800 text-hover-danger fs-2 fw-bolder me-1'>
                          {franchise?.person.name}
                        </a>
                        <KTIcon iconName='verify' className='fs-1 text-primary' />
                      </div>
                      <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                        <a
                          href='#'
                          className='d-flex align-items-center text-gray-400 text-hover-danger me-5 mb-2'
                        >
                          <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                          Ativa
                        </a>
                        <a
                          href='#'
                          className='d-flex align-items-center text-gray-400 text-hover-danger me-5 mb-2'
                        >
                          <KTIcon iconName='geolocation' className='fs-4 me-1' />
                          {franchise?.name}
                        </a>
                        <a
                          href='#'
                          className='d-flex align-items-center text-gray-400 text-hover-danger mb-2'
                        >
                          <KTIcon iconName='sms' className='fs-4 me-1' />
                          {franchise?.person?.email}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex overflow-auto h-55px'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                  <li className='nav-item'>
                    <button
                      className='nav-link active'
                      id='pills-data-franchise-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-data-franchise'
                      type='button'
                      role='tab'
                      aria-controls='pills-data-franchise'
                      aria-selected='true'
                    >
                      Dados da Franquia
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      className='nav-link'
                      id='pills-adress-franchise-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-adress-franchise'
                      type='button'
                      role='tab'
                      aria-controls='pills-adress-franchise'
                      aria-selected='true'
                    >
                      Endereço
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      className='nav-link'
                      id='pills-contact-franchise-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-contact-franchise'
                      type='button'
                      role='tab'
                      aria-controls='pills-contact-franchise'
                      aria-selected='true'
                    >
                      Dados de Contato
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      className='nav-link'
                      id='pills-machine-franchise-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-machine-franchise'
                      type='button'
                      role='tab'
                      aria-controls='pills-machine-franchise'
                      aria-selected='true'
                    >
                      Máquinas
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      className='nav-link'
                      id='pills-product-franchise-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-product-franchise'
                      type='button'
                      role='tab'
                      aria-controls='pills-product-franchise'
                      aria-selected='true'
                    >
                      Produtos
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      className='nav-link'
                      id='pills-owner-franchise-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-owner-franchise'
                      type='button'
                      role='tab'
                      aria-controls='pills-owner-franchise'
                      aria-selected='true'
                    >
                      Sócios
                    </button>
                  </li>
                  {franchise && franchise.config && (
                    <li className='nav-item'>
                      <button
                        className='nav-link'
                        id='pills-settings-franchise-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#pills-settings-franchise'
                        type='button'
                        role='tab'
                        aria-controls='pills-settings-franchise'
                        aria-selected='true'
                      >
                        Configurações
                      </button>
                    </li>
                  )}
                  <li className='nav-item'>
                    <button
                      className='nav-link'
                      id='pills-partnership-franchise-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-partnership-franchise'
                      type='button'
                      role='tab'
                      aria-controls='pills-partnership-franchise'
                      aria-selected='true'
                    >
                      Parcerias
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <PageTitle breadcrumbs={franchiseBreadcrumbs}>Detalhamento da Franquia</PageTitle>
          <div className='tab-content' id='pills-tabContent'>
            <div
              className='tab-pane fade show active p-4 pt-0 pt-md-4'
              id='pills-data-franchise'
              role='tabpanel'
              aria-labelledby='pills-checklist-tab'
            >
              <div className='card mb-5 mb-xl-10'>
                <>
                  <div className='card-header cursor-pointer'>
                    <div className='card-title m-0'>
                      <h3 className='fw-bolder m-0'>Informações comerciais</h3>
                    </div>
                  </div>
                  <div className='card-body py-3'>
                    <div className='card-body p-9'>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted'>CNPJ</label>
                        <div className='col-lg-8'>
                          <span className='fw-bold fs-6 text-gray-800'>
                            {franchise?.person?.juridicalPerson?.cnpj}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted'>Nome da Franquia</label>
                        <div className='col-lg-8'>
                          <span className='fw-bold fs-6 text-gray-800'>{franchise?.name}</span>
                        </div>
                      </div>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted'>Razão social</label>
                        <div className='col-lg-8'>
                          <span className='fw-bold fs-6 text-gray-800'>
                            {franchise?.person.name}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted'>Nome fantasia</label>
                        <div className='col-lg-8'>
                          <span className='fw-bold fs-6 text-gray-800'>
                            {franchise?.person?.juridicalPerson?.fantasyName}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted'>E-mail</label>
                        <div className='col-lg-8'>
                          <span className='fw-bold fs-6 text-gray-800'>
                            {franchise?.person?.email}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted'>
                          Horário de funcionamento
                        </label>
                        <div className='col-lg-8'>
                          <span className='fw-bold fs-6 text-gray-800'>
                            {franchise?.startTime} as {franchise?.endTime}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted'>Criado em</label>
                        <div className='col-lg-8'>
                          <span className='fw-bold fs-6 text-gray-800'>
                            {franchise?.createdAt ? formatDateTime(franchise.createdAt) : ' '}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted'>Atualizado em</label>
                        <div className='col-lg-8'>
                          <span className='fw-bold fs-6 text-gray-800'>
                            {franchise?.updatedAt ? formatDateTime(franchise.updatedAt) : ' '}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
            <div
              className='tab-pane fade p-4 pt-0 pt-md-4'
              id='pills-adress-franchise'
              role='tabpanel'
              aria-labelledby='pills-checklist-tab'
            >
              <div className='card mb-5 mb-xl-10'>
                <>
                  <div className='card-header cursor-pointer'>
                    <div className='card-title m-0'>
                      <h3 className='fw-bolder m-0'>Informações do endereço</h3>
                    </div>
                  </div>
                  <div className='card-body py-3'>
                    <div className='card-body p-9'>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted'>CEP</label>
                        <div className='col-lg-8'>
                          <span className='fw-bold fs-6 text-gray-800'>
                            {franchise?.person?.addresses?.[0]?.zipCode
                              ? cepMask(franchise.person.addresses[0]?.zipCode)
                              : ''}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted'>Logradouro</label>
                        <div className='col-lg-8'>
                          <span className='fw-bold fs-6 text-gray-800'>
                            {franchise?.person.addresses?.[0]?.street}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted'>Bairro</label>
                        <div className='col-lg-8'>
                          <span className='fw-bold fs-6 text-gray-800'>
                            {franchise?.person.addresses?.[0]?.neighborhood}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted'>Número</label>
                        <div className='col-lg-8'>
                          <span className='fw-bold fs-6 text-gray-800'>
                            {franchise?.person.addresses?.[0]?.number}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted'>Complemento</label>
                        <div className='col-lg-8'>
                          <span className='fw-bold fs-6 text-gray-800'>
                            {franchise?.person.addresses?.[0]?.complement}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted'>Estado (UF)</label>
                        <div className='col-lg-8'>
                          <span className='fw-bold fs-6 text-gray-800'>
                            {franchise?.person.addresses?.[0]?.city.state?.uf}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-7'>
                        <label className='col-lg-4 fw-semibold text-muted'>Cidade</label>
                        <div className='col-lg-8'>
                          <span className='fw-bold fs-6 text-gray-800'>
                            {franchise?.person.addresses?.[0]?.city.state.name}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
            <div
              className='tab-pane fade p-4 pt-0 pt-md-4'
              id='pills-contact-franchise'
              role='tabpanel'
              aria-labelledby='pills-checklist-tab'
            >
              <div className='card mb-5 mb-xl-10'>
                <>
                  <div className='card-header cursor-pointer'>
                    <div className='card-title m-0'>
                      <h3 className='fw-bolder m-0'>Informações de contato</h3>
                    </div>
                  </div>
                  <div className='card-body py-3'>
                    <table className='table table-row-dashed table-row-gray-300'>
                      <thead>
                        <tr className='fw-bolder fs-6 text-gray-800'>
                          <th colSpan={3}>E-mail: {franchise?.person?.email}</th>
                        </tr>
                        <tr className='fw-bolder fs-6 text-gray-800'>
                          <th>ID</th>
                          <th>Tipo</th>
                          <th>Telefone</th>
                        </tr>
                      </thead>
                      <tbody>
                        {franchise?.person?.contacts?.map((value, index) => (
                          <tr key={`person${index}`}>
                            <td>{value.id}</td>
                            <td>{value.typeName}</td>
                            <td>{phoneMask(value.phone)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              </div>
            </div>

            <div
              className='tab-pane fade p-4 pt-0 pt-md-4'
              id='pills-machine-franchise'
              role='tabpanel'
              aria-labelledby='pills-checklist-tab'
            >
              <div className='card mb-5 mb-xl-10'>
                <>
                  <div className='card-header cursor-pointer'>
                    <div className='card-title m-0'>
                      <h3 className='fw-bolder m-0'>Informações de Máquinas</h3>
                    </div>
                  </div>
                  <div className='card-body py-3'>
                    <table className='table table-row-dashed table-row-gray-300'>
                      <thead>
                        <tr className='fw-bolder fs-6 text-gray-800'>
                          <th>ID</th>
                          <th>Máquina</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(franchise?.machines) &&
                          franchise?.machines.map((field, index) => (
                            <tr key={index}>
                              <td>{field.machine['id']}</td>
                              <td>{field.machine['name']}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </>
              </div>
            </div>

            <div
              className='tab-pane fade p-4 pt-0 pt-md-4'
              id='pills-product-franchise'
              role='tabpanel'
              aria-labelledby='pills-checklist-tab'
            >
              <div className='card mb-5 mb-xl-10'>
                <>
                  <div className='card-header cursor-pointer'>
                    <div className='card-title m-0'>
                      <h3 className='fw-bolder m-0'>Informações do produto</h3>
                    </div>
                  </div>
                  <div className='card-body py-3'>
                    <table className='table table-row-dashed table-row-gray-300'>
                      <thead>
                        <tr className='fw-bolder fs-6 text-gray-800'>
                          <th>ID</th>
                          <th>Produtos</th>
                          <th>Genero</th>
                          <th>Preço</th>
                          <th>Status Venda</th>
                          <th>Status Agenda</th>
                          <th>% desconto máximo</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(franchise?.products) &&
                          franchise?.products.map((field, index) => (
                            <tr key={`products ${index}`}>
                              <td>{field.id}</td>
                              <td>{field.name}</td>
                              <td>{field.gender}</td>
                              <td>{formatMonetaryToBr(field.price)}</td>
                              <td>{field.activeSale ? 'Ativo' : 'Inativo'}</td>
                              <td>{field.activeSchedule ? 'Ativo' : 'Inativo'}</td>
                              <td>{field.maxDiscount}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </>
              </div>
            </div>

            <div
              className='tab-pane fade p-4 pt-0 pt-md-4'
              id='pills-owner-franchise'
              role='tabpanel'
              aria-labelledby='pills-checklist-tab'
            >
              <div className='card mb-5 mb-xl-10'>
                <>
                  <div className='card-header cursor-pointer'>
                    <div className='card-title m-0'>
                      <h3 className='fw-bolder m-0'>Informações dos sócios</h3>
                    </div>
                  </div>
                  <div className='card-body py-3'>
                    <table className='table table-row-dashed table-row-gray-300'>
                      <thead>
                        <tr className='fw-bolder fs-6 text-gray-800'>
                          <th>ID</th>
                          <th>Nome</th>
                          <th>Documento</th>
                          <th>RG</th>
                          <th>Gênero</th>
                          <th>Contato</th>
                          <th>E-mail</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(franchise?.owners) &&
                          franchise?.owners.map((field, index) => (
                            <tr key={`owners${index}`}>
                              <td>{field.id}</td>
                              <td>{field.name}</td>
                              <td>{field.physicalPerson.document}</td>
                              <td>{field.physicalPerson.rg}</td>
                              <td>{field.physicalPerson.gender}</td>
                              <td>
                                {Array.isArray(field?.contacts) &&
                                  field.contacts.map((contact, index) => (
                                    <div key={`contactsOwners${index}`}>
                                      {`Telefone ${index + 1}: ${contact.typeName} - ${phoneMask(
                                        contact?.phone
                                      )}`}
                                    </div>
                                  ))}
                              </td>
                              <td>{field.email}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </>
              </div>
            </div>

            <div
              className='tab-pane fade p-4 pt-0 pt-md-4'
              id='pills-settings-franchise'
              role='tabpanel'
              aria-labelledby='pills-checklist-tab'
            >
              <div
                className='tab-pane fade show active p-4 pt-0 pt-md-4'
                id='pills-data-franchise'
                role='tabpanel'
                aria-labelledby='pills-checklist-tab'
              >
                <div className='card mb-5 mb-xl-10'>
                  <>
                    <div className='card-header cursor-pointer'>
                      <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Configurações Gerais</h3>
                      </div>
                    </div>
                    <div className='card-body py-3'>
                      <div className='card-body p-9'>
                        <div className='row mb-7'>
                          <label className='col-lg-4 fw-semibold text-muted'>
                            Horário de funcionamento
                          </label>
                          <div className='col-lg-8'>
                            <span className='fw-bold fs-6 text-gray-800'>
                              {franchise?.config?.startTime} as {franchise?.config?.endTime}
                            </span>
                          </div>
                        </div>
                        <div className='row mb-7'>
                          <label className='col-lg-4 fw-semibold text-muted'>Gateway Cashier</label>
                          <div className='col-lg-8'>
                            <span className='fw-bold fs-6 text-gray-800'>
                              {franchise?.config?.gatewayCashier}
                            </span>
                          </div>
                        </div>
                        <div className='row mb-7'>
                          <label className='col-lg-4 fw-semibold text-muted'>Gateway Hash</label>
                          <div className='col-lg-8'>
                            <span className='fw-bold fs-6 text-gray-800'>
                              {franchise?.config?.gatewayHash}
                            </span>
                          </div>
                        </div>
                        <div className='row mb-7'>
                          <label className='col-lg-4 fw-semibold text-muted'>ID Cielo</label>
                          <div className='col-lg-8'>
                            <span className='fw-bold fs-6 text-gray-800'>
                              {franchise?.config?.cieloMerchantId}
                            </span>
                          </div>
                        </div>
                        <div className='row mb-7'>
                          <label className='col-lg-4 fw-semibold text-muted'>
                            Chave do Comerciante Cielo
                          </label>
                          <div className='col-lg-8'>
                            <span className='fw-bold fs-6 text-gray-800'>
                              {franchise?.config?.cieloMerchantKey}
                            </span>
                          </div>
                        </div>
                        <div className='row mb-7'>
                          <label className='col-lg-4 fw-semibold text-muted'>
                            Sincronizado usuários servidor antigo
                          </label>
                          <div className='col-lg-8'>
                            <span className='fw-bold fs-6 text-gray-800'>
                              {franchise?.config?.syncUsers ? 'Sim' : 'Não'}
                            </span>
                          </div>
                        </div>
                        <div className='row mb-7'>
                          <label className='col-lg-4 fw-semibold text-muted'>
                            Sincronizado clientes servidor antigo
                          </label>
                          <div className='col-lg-8'>
                            <span className='fw-bold fs-6 text-gray-800'>
                              {franchise?.config?.syncClients ? 'Sim' : 'Não'}
                            </span>
                          </div>
                        </div>
                        <div className='row mb-7'>
                          <label className='col-lg-4 fw-semibold text-muted'>
                            Sincronizado vendas servidor antigo
                          </label>
                          <div className='col-lg-8'>
                            <span className='fw-bold fs-6 text-gray-800'>
                              {franchise?.config?.syncSales ? 'Sim' : 'Não'}
                            </span>
                          </div>
                        </div>
                        <div className='row mb-7'>
                          <label className='col-lg-4 fw-semibold text-muted'>
                            Sincronizado agenda servidor antigo
                          </label>
                          <div className='col-lg-8'>
                            <span className='fw-bold fs-6 text-gray-800'>
                              {franchise?.config?.syncEvents ? 'Sim' : 'Não'}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>

            <div
              className='tab-pane fade p-4 pt-0 pt-md-4'
              id='pills-partnership-franchise'
              role='tabpanel'
              aria-labelledby='pills-checklist-tab'
            >
              <div
                className='tab-pane fade show active p-4 pt-0 pt-md-4'
                id='pills-data-partnership'
                role='tabpanel'
                aria-labelledby='pills-checklist-tab'
              >
                <div className='card mb-5 mb-xl-10'>
                  <>
                    <div className='card-header cursor-pointer'>
                      <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Parcerias</h3>
                      </div>
                    </div>
                    <div className='card-body py-3'>
                      <div className='card-body p-9'>
                        {Array.isArray(franchise?.partnerships) &&
                          franchise?.partnerships.map((field, index) => (
                            <div className='row mb-7'>
                              <label className='col-lg-4 fw-semibold text-muted'>
                                {field.destinationFranchiseName}
                              </label>
                              <div className='col-lg-8'>
                                <span className='fw-bold fs-6 text-gray-800'>
                                  {field.partnershipTypeName}
                                </span>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export const FranchiseShowPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={franchiseBreadcrumbs}>Visualizar Franquia</PageTitle>
      <Show />
    </>
  )
}
