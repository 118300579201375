import { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react'
import qs from 'qs'
import { ID, QueryResponseContextProps, QueryState } from './models'

function createResponseContext<T>(initialState: QueryResponseContextProps<T>) {
  return createContext(initialState)
}

function isNotEmpty(obj: unknown) {
  return obj !== undefined && obj !== null && obj !== ''
}

// Example: page=1&items_per_page=10&sort=id&order=desc&search=a&filter_name=a&filter_online=false
function stringifyRequestQuery(state: QueryState): string {
  const pagination = qs.stringify(state, { filter: ['page', 'per_page'], skipNulls: true })
  const sort = qs.stringify(state, { filter: ['sort', 'order'], skipNulls: true })
  const name = isNotEmpty(state.name)
    ? qs.stringify(state, { filter: ['name'], skipNulls: true })
    : ''
  const active = isNotEmpty(state.active)
    ? qs.stringify(state, { filter: ['active'], skipNulls: true })
    : ''
  const phone = isNotEmpty(state.phone)
    ? qs.stringify(state, { filter: ['phone'], skipNulls: true })
    : ''
  const document = isNotEmpty(state.document)
    ? qs.stringify(state, { filter: ['document'], skipNulls: true })
    : ''
  const id = isNotEmpty(state.id) ? qs.stringify(state, { filter: ['id'], skipNulls: true }) : ''
  const clientId = isNotEmpty(state.clientId)
    ? qs.stringify(state, { filter: ['clientId'], skipNulls: true })
    : ''

  const filter = state.filter
    ? Object.entries(state.filter as Object)
      .filter((obj) => isNotEmpty(obj[1]))
      .map((obj) => {
        return `filter_${obj[0]}=${obj[1]}`
      })
      .join('&')
    : ''

  const seller = isNotEmpty(state.seller)
    ? qs.stringify(state, { filter: ['seller'], skipNulls: true })
    : ''
  const dtStart = isNotEmpty(state.dtStart)
    ? qs.stringify(state, { filter: ['dtStart'], skipNulls: true })
    : ''
  const dtEnd = isNotEmpty(state.dtEnd)
    ? qs.stringify(state, { filter: ['dtEnd'], skipNulls: true })
    : ''
  const status = isNotEmpty(state.status)
    ? qs.stringify(state, { filter: ['status'], skipNulls: true })
    : ''
  const categories = isNotEmpty(state.categories)
    ? qs.stringify(state, { filter: ['categories'], skipNulls: true })
    : ''
  const types = isNotEmpty(state.types)
    ? qs.stringify(state, { filter: ['types'], skipNulls: true })
    : ''
  const items = isNotEmpty(state.items)
    ? qs.stringify(state, { filter: ['items'], skipNulls: true })
    : ''


  const birthDate = isNotEmpty(state.birthDate)
    ? qs.stringify(state, { filter: ['birthDate'], skipNulls: true })
    : ''
  const birthDateStart = isNotEmpty(state.birthDateStart)
    ? qs.stringify(state, { filter: ['birthDateStart'], skipNulls: true })
    : ''
  const birthDateEnd = isNotEmpty(state.birthDateEnd)
    ? qs.stringify(state, { filter: ['birthDateEnd'], skipNulls: true })
    : ''
  const createdAt = isNotEmpty(state.createdAt)
    ? qs.stringify(state, { filter: ['createdAt'], skipNulls: true })
    : ''
  const createdAtStart = isNotEmpty(state.createdAtStart)
    ? qs.stringify(state, { filter: ['createdAtStart'], skipNulls: true })
    : ''
  const createdAtEnd = isNotEmpty(state.createdAtEnd)
    ? qs.stringify(state, { filter: ['createdAtEnd'], skipNulls: true })
    : ''
  const gender = isNotEmpty(state.gender)
    ? qs.stringify(state, { filter: ['gender'], skipNulls: true })
    : ''
  const restrict = isNotEmpty(state.restrict)
    ? qs.stringify(state, { filter: ['restrict'], skipNulls: true })
    : ''
  const others = isNotEmpty(state.others)
    ? qs.stringify(state, { filter: ['others'], skipNulls: true })
    : ''
  const phones = isNotEmpty(state.phones)
    ? qs.stringify(state, { filter: ['phones'], skipNulls: true })
    : ''

  return [pagination, sort, name, id, clientId, phone, filter, document, active, seller, dtStart, dtEnd, status, categories, types, items, birthDate, birthDateStart, birthDateEnd, createdAt, createdAtStart, createdAtEnd, gender, restrict, others, phones]
    .filter((f) => f)
    .join('&')
    .toLowerCase()
}

function parseRequestQuery(query: string): QueryState {
  const cache: unknown = qs.parse(query)
  return cache as QueryState
}

function calculatedGroupingIsDisabled<T>(isLoading: boolean, data: Array<T> | undefined): boolean {
  if (isLoading) {
    return true
  }

  return !data || !data.length
}

function calculateIsAllDataSelected<T>(data: Array<T> | undefined, selected: Array<ID>): boolean {
  if (!data) {
    return false
  }

  return data.length > 0 && data.length === selected.length
}

function groupingOnSelect(
  id: ID,
  selected: Array<ID>,
  setSelected: Dispatch<SetStateAction<Array<ID>>>
) {
  if (!id) {
    return
  }

  if (selected.includes(id)) {
    setSelected(selected.filter((itemId) => itemId !== id))
  } else {
    const updatedSelected = [...selected]
    updatedSelected.push(id)
    setSelected(updatedSelected)
  }
}

function groupingOnSelectAll<T>(
  isAllSelected: boolean,
  setSelected: Dispatch<SetStateAction<Array<ID>>>,
  data?: Array<T & { id?: ID }>
) {
  if (isAllSelected) {
    setSelected([])
    return
  }

  if (!data || !data.length) {
    return
  }

  setSelected(data.filter((item) => item.id).map((item) => item.id))
}

// Hook
function useDebounce(value: string | undefined, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

export {
  createResponseContext,
  stringifyRequestQuery,
  parseRequestQuery,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  groupingOnSelectAll,
  useDebounce,
  isNotEmpty,
}
