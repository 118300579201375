import { useEffect, useState } from 'react'
import { BillingDetailModalHeader } from './billing-detail-modal/BillingDetailModalHeader'
import { Billing, Installments, InstallmentsRecurrence, TBillingInstallment } from '../core/_models'
import { formatMask } from '../../../utils/formatMonetaryToBr'
import { formatDate, formatDateTime } from '../../../utils/dayJs/format'
import { PaymentRecurrentModal } from './modalRecurrentPayment/paymentModal'
import { ChangeRecurrentModal } from './modalRecurrentPayment/changeModal'
import { cpfMask } from '../payment/utils/mask'
import { sendLinkChangeCartReq } from './modalRecurrentPayment/core/_request'
import Swal from 'sweetalert2'
import { MenuComponent } from '../../../_metronic/assets/ts/components/MenuComponent'
import { PageOverlayer } from '../../../components/PageOverlayer'

interface IBilling {
  billing: Billing | null
}

interface ITbodyProps {
  billing: Billing | null
  verifyAction: (action: string, billing: Installments | InstallmentsRecurrence) => void
  options: { value: string; label: string }[]
  openViewCollapse?: string | null
}

const BillingDetailModal = ({ billing }: IBilling) => {
  const options = [
    {
      value: 'change',
      label: 'Alterar forma de pagamento',
      status: 1
    },
    {
      value: 'payment',
      label: 'Cobrar parcela',
      status: 1
    },
    {
      value: 'viewAcordion',
      label: 'Visualizar',
      status: 0
    },
  ]

  const [dataPaymentRecurrent, setDataPaymentRecurrent] = useState<TBillingInstallment>()
  const [dataChangeRecurrent, setDataChangeRecurrent] = useState()
  const [openPaymentRecurrentModal, setOpenPaymentRecurrentModal] = useState(false)
  const [openChangeRecurrentModal, setOpenChangeRecurrentModal] = useState(false)
  const [openDetailModal, setOpenDetailModal] = useState(true)
  const [openViewCollapse, setOpenViewCollapse] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const verifyAction = (action, billing) => {
    if (action === 'payment') {
      setDataPaymentRecurrent(billing)
      setOpenDetailModal(false)
      setOpenPaymentRecurrentModal(true)
      setOpenChangeRecurrentModal(false)
      setOpenViewCollapse(null)
      return
    }

    if (action == 'viewAcordion') {
      setOpenViewCollapse((prevState) => {
        return prevState == billing.id ? null : billing.id;
      })
      return
    }

    setDataChangeRecurrent(billing)
    setOpenDetailModal(false)
    setOpenPaymentRecurrentModal(false)
    setOpenViewCollapse(null)
    setOpenChangeRecurrentModal(true)
  }

  const closeModals = () => {
    setOpenPaymentRecurrentModal(false)
    setOpenChangeRecurrentModal(false)
    setOpenViewCollapse(null)
    setOpenDetailModal(true)
  }

  const sendLinkChangeCart = async () => {
    Swal.fire({
      title: 'Deseja enviar um link para realizar a troca de cartão ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-danger', // Classe personalizada para o botão de confirmação
        cancelButton: 'btn btn-secondary', // Classe personalizada para o botão de cancelamento (opcional)
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true)
          await sendLinkChangeCartReq(Number(billing?.recurrencePayment?.id))
          setLoading(false)

          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Link enviado com sucesso!!',
            timer: 3000,
          })
        } catch (error: any) {
          setLoading(false)
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: error.response.data.message,
            timer: 3000,
          })
        }
      }
    })
  }

  return (
    <>
      {loading && <PageOverlayer />}

      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-800px'>
          <div className='modal-content'>
            <BillingDetailModalHeader />
            {openDetailModal && !openChangeRecurrentModal && !openPaymentRecurrentModal && (
              <>
                <div className='modal-body mx-5 mx-xl-7'>
                  {billing?.recurrencePayment && (
                    <div className="border border-gray-300 border-dashed rounded" style={{ backgroundColor: 'rgb(249, 234, 233)' }}>
                      <div className='container m-5'>
                        <div className="d-flex flex-wrap py-5">
                          <div className="flex-equal me-5">
                            <table className="table table-flush fw-semibold gy-1" hidden={billing?.recurrencePayment ? false : true}>
                              <tbody>
                                <tr>
                                  <td className="text-black fw-bold min-w-125px w-125px">Nome</td>
                                  <td className="text-gray-800">{billing?.recurrencePayment?.creditCard?.holderName}</td>
                                </tr>
                                <tr>
                                  <td className="text-black fw-bold min-w-125px w-125px">Número</td>
                                  <td className="text-gray-800">**** {billing?.recurrencePayment?.creditCard?.lastNumbers}</td>
                                </tr>
                                <tr>
                                  <td className="text-black fw-bold min-w-125px w-125px">Validade</td>
                                  <td className="text-gray-800">{billing?.recurrencePayment?.creditCard?.expirationMonth}/{billing?.recurrencePayment?.creditCard?.expirationYear}</td>
                                </tr>
                                <tr>
                                  <td className="text-black fw-bold min-w-125px w-125px">Tipo</td>
                                  <td className="text-gray-800">{billing?.paymentOption.category.name}</td>
                                </tr>
                                <tr>
                                  <td className="text-black fw-bold min-w-125px w-125px">CPF</td>
                                  <td className="text-gray-800">{cpfMask(billing?.recurrencePayment?.creditCard?.holderDocument)}{' '}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="flex-equal">
                            <table className="table table-flush fw-semibold gy-1">
                              <tbody>
                                <tr>
                                  <td>
                                    <button className='btn btn-danger col-10 p-11' onClick={() => sendLinkChangeCart()}>
                                      Alterar Cartão
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className='table-responsive'>
                    {billing?.recurrencePayment ? (
                      <TbodyRecurrent
                        billing={billing}
                        verifyAction={verifyAction}
                        options={options}
                        openViewCollapse={openViewCollapse}
                      />
                    ) : (
                      <TbodyNotRecurrent
                        billing={billing}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
            {openPaymentRecurrentModal && !openDetailModal && !openChangeRecurrentModal && (
              <PaymentRecurrentModal
                billingInstallment={dataPaymentRecurrent}
                billing={billing}
                closeModal={() => closeModals()}
              />
            )}

            {openChangeRecurrentModal && !openDetailModal && !openPaymentRecurrentModal && (
              <ChangeRecurrentModal
                billingInstallment={dataChangeRecurrent}
                billing={billing}
                closeModal={() => closeModals()}
              />
            )}
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

const TbodyNotRecurrent = ({ billing }) => {
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  return (
    <>
      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-5'>
        <thead>
          <tr className='fw-bold text-muted'>
            <th className='min-w-120px'>Preço</th>
            <th className='min-w-120px'>Dt de vencimento</th>
            <th className='min-w-120px'>Parcela</th>
            <th className='min-w-120px'>Status</th>
            <th className='min-w-120px'></th>
          </tr>
        </thead>
        <tbody>
          {billing?.installments.map((installment, index) => (
            <tr key={index}>
              <td>
                <b>{formatMask(installment.amount)}</b>
              </td>
              <td>
                <b>{formatDate(installment.dueDate)}</b>
              </td>
              <td>
                <b>{installment.installment}</b>
              </td>
              <td>
                <span
                  className={
                    installment.status === 'Pendente' ? 'badge badge-warning' : 'badge badge-success'
                  }
                >
                  {installment.i18nStatus ?? installment.status}
                </span>
              </td>
            </tr>
          ))
          }
        </tbody>
      </table>
    </>
  )
}
const TbodyRecurrent: React.FC<ITbodyProps> = ({ billing, verifyAction, options, openViewCollapse }) => {
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  return (
    <>
      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-5 mt-5'>
        <thead>
          <tr className='fw-bold text-muted'>
            <th className='min-w-120px'>Preço</th>
            <th className='min-w-120px'>Dt de vencimento</th>
            <th className='min-w-100'>Dt de processamento</th>
            <th className='min-w-120px'>Parcela</th>
            <th className='min-w-120px'>Status</th>
            <th className='min-w-80px'>Ações</th>
          </tr>
        </thead>
        <tbody>
          {billing?.recurrencePayment?.installments?.map((installment, index) => (
            <>
              <tr className='accordion-item' key={index} >
                <td>
                  <b>{formatMask(installment.amount)}</b>
                </td>
                <td>
                  <b>{formatDate(installment.dueDate)}</b>
                </td>
                <td>
                  <b>{installment?.paidAt ? formatDateTime(installment.paidAt) : ''}</b>
                </td>
                <td>
                  <b>{installment.installmentsNumber}</b>
                </td>
                <td>
                  <span
                    className={
                      `badge badge-${installment.status.color}`
                    }
                  >
                    {installment.status.i18n}
                  </span>
                </td>
                <td>
                  <div>
                    <a
                      href='#'
                      className='btn btn-color-muted btn-active-color-danger menu-dropdown'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                    >
                      <i className="ki-duotone ki-gear me-5 fs-1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                      </i>
                    </a>
                    <div
                      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-danger fw-semibold fs-7 w-auto py-2'
                      data-kt-menu='true'
                      data-popper-placement='bottom-end'
                    >
                      <div className='menu-item px-3'>

                        {options.map((option: any) => (

                          (option.status == 0 || option.status == installment.status.id) && (
                            <span
                              key={option.value}
                              className='menu-link px-3'
                              onClick={() => verifyAction(option.value, installment)}
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {option.label}
                            </span>
                          )
                        ))}
                      </div>
                    </div>
                  </div>
                </td>
              </tr >
              <tr className={`accordion-collapse collapse ${openViewCollapse == installment.id ? 'show' : ''}`} key={`second-tr-${index}`}>
                <td colSpan={6}>
                  <div className="table-responsive">
                    <table className="table table-row-dashed table-row-gray-300">
                      <thead>
                        <tr key={`three-tr-head-${index}`} className="fw-bold">
                          <th>Tipo</th>
                          <th>Dt processamento</th>
                          <th>Status</th>
                          <th>Motivo</th>
                        </tr>
                      </thead>
                      <tbody>
                        {installment.creditCardPayments?.map((payment, index) => (
                          <tr key={`three-tr-${index}`}>
                            <td>Tentativa</td>
                            <td>{formatDateTime(payment.createdAt)}</td>
                            <td>{payment.status}</td>
                            <td>{payment.returnCode == '00' ? 'Capturado' : `Erro: ${payment.returnCode}`}</td>
                          </tr>
                        ))}
                        {installment.settledBilling && (
                          <tr key={`four-tr-${index}`}>
                            <td>Alterada</td>
                            <td>{formatDateTime(installment.settledBilling.createdAt)}</td>
                            <td>{installment.settledBilling.status}</td>
                            <td>{installment.settledBilling.paymentOption.name}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </>
          ))
          }
        </tbody>
      </table>
    </>
  )
}

export { BillingDetailModal }
