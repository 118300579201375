// @ts-nocheck
import { Column } from 'react-table'
import { NameCell } from './NameCell'
import { UpdatedInCell } from './UpdatedInCell'
import { CreatedInCell } from './CreatedInCell'
import { SelectionCell } from './SelectionCell'
import { CustomHeader } from './CustomHeader'
import { SelectionHeader } from './SelectionHeader'
import { People } from '../../core/_models'
import { DocCell } from './DocCell'
import { PhoneCell } from './PhoneCell'
import { StatusCell } from './StatusCell'

const PeopleColumns: ReadonlyArray<Column<People>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <SelectionCell id={props.data[props.row.index].id} />,
  },

  {
    Header: (props) => <CustomHeader tableProps={props} title='Nome' className='min-w-125px' />,
    id: 'name',
    Cell: ({ ...props }) => <NameCell people={props.data[props.row.index]} />,
  },

  {
    Header: (props) => <CustomHeader tableProps={props} title='Documento' className='min-w-125px' />,
    id: 'doc',
    Cell: ({ ...props }) => <DocCell people={props.data[props.row.index]} />,
  },

  {
    Header: (props) => <CustomHeader tableProps={props} title='Telefone' className='min-w-125px' />,
    id: 'phone',
    Cell: ({ ...props }) => <PhoneCell people={props.data[props.row.index]} />,
  },

  {
    Header: (props) => <CustomHeader tableProps={props} title='Status' className='min-w-125px' />,
    id: 'status',
    Cell: ({ ...props }) => <StatusCell people={props.data[props.row.index]} />,
  },


  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Criado em' className='min-w-125px' />
    ),
    id: 'data',
    Cell: ({ ...props }) => <CreatedInCell people={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Atualizado em' className='min-w-125px' />
    ),
    id: 'two_steps',
    Cell: ({ ...props }) => <UpdatedInCell people={props.data[props.row.index]} />,
  },

]

export { PeopleColumns }
