import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

export const MachineForm = ({ formik }) => {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className='card mb-5 mb-xl-10'>
                <div className='card-body border-top p-9'>
                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                            <span className='required'>Nome</span>
                        </label>
                        <div className='col-lg-8 fv-row'>
                            <input
                                type='text'
                                className='form-control form-control-lg form-control-solid'
                                placeholder='Nome da categoria'
                                {...formik.getFieldProps('name')}
                            />
                            {formik.touched.name && formik.errors.name && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors?.name}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                            <span>Observação</span>
                        </label>
                        <div className='col-lg-8 fv-row'>
                            <input
                                id="observation"
                                type='text'
                                className='form-control form-control-lg form-control-solid'
                                placeholder='Observação'
                                {...formik.getFieldProps('observation')}
                            />
                            {formik.touched.observation && formik.errors.observation ? (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors?.observation}</div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                    <button type="reset" className="btn btn-secondary me-2" onClick={() => navigate(-1)}>Cancelar</button>
                    <button type='submit' className='btn btn-danger' disabled={loading || !formik.isValid}>
                        {!loading && 'Salvar'}
                        {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Salvando...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
            </div>
        </form>
    );
};