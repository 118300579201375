import { useState } from "react";
import { MultiSelect, Option } from "react-multi-select-component"
import "../../../../../../style/multiSelectSucess.css"

const ListSearchSubscriptionClientSuspended = ({ setSubscriptionClientSuspendedSearch, selectsClientSuspended, setSelectsClientSuspended }) => {
    
    const initialSelectValue = [{value: '1', label: 'Sim'},{value: '0', label: 'Não'}];
    const [saleClientSuspended, setSaleClientSuspended] = useState<Option[]>(initialSelectValue);
    
    return (
        <>
            <div className='mb-5'>
                <label className='form-label fs-6 fw-bold'>Pesquisar cliente suspenso:</label>
                <MultiSelect
                    className='form-control rmscStyle'
                    data-kt-user-table-filter='suspended'
                    options={saleClientSuspended}
                    value={selectsClientSuspended}
                    onChange={(option) => {
                        const valores = option.map((item) => item.value)
                        setSubscriptionClientSuspendedSearch(valores)
                        setSelectsClientSuspended(option)
                    }}
                    labelledBy={''}
                    overrideStrings={{
                        selectSomeItems: 'Selecione a(as) opões',
                        search: 'Procurar',
                        selectAll: 'Selecionar todos',
                        allItemsAreSelected: 'Todos opções selecionados',
                      }}
                />
            </div>
        </>
    )
}

export { ListSearchSubscriptionClientSuspended }
