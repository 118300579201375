import {useEffect, useState} from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'
import {formatMonetaryToBr, removeMask} from '../../../../../utils/formatMonetaryToBr'

import {useFranchiseTransferCart} from '../core/FranchiseTransferProvider'
import {formatMonetaryToBrTransfer, formatToDecimal} from '../Utils/formatMask'
import {PaymentRecurrentClientDestination} from '../Utils/PaymentRecurrentClientDestination'
import {SplitTransfer} from '../Utils/splitPaymentTransfer'

export const FTPaymentClientDestinationModal = ({close, formik, data}) => {
  const {billingDestination} = useFranchiseTransferCart()
  const [enableConfirm, setEnableConfirm] = useState(false)

  const totalValue = formatMonetaryToBr(data)

  let current = billingDestination?.reduce((previousValue, currentValue) => {
    return Number(previousValue) + Number(removeMask(String(currentValue.amount)))
  }, 0)

  useEffect(() => {
    let current = billingDestination?.reduce((previousValue, currentValue) => {
      return Number(previousValue) + Number(removeMask(String(currentValue.amount)))
    }, 0)

    setEnableConfirm(Number(current).toFixed(2) === Number(data).toFixed(2))
  }, [billingDestination])

  const verifyPayment = () => {
    const newData = billingDestination.map((val) => {
      const data = {
        method: val.method,
        ...(val.hasInstallment && {installments: val.installments}),
        amount: removeMask(val.amount),
      }
      return data
    })

    formik.setValues({
      ...formik.values,
      billingsDestination: newData,
    })
    close()
  }
  return (
    <div className='container-fluid'>
      <div className='content-wrapper'>
        <div className='header py-3 position-relative d-flex justify-content-center align-items-center'>
          <h3 className='fw-bold' style={{color: '#f1416c', flexGrow: 1, textAlign: 'center'}}>
            Escolha a forma de pagamento
          </h3>
          <div
            className='btn btn-icon btn-sm btn-active-icon-primary'
            data-kt-users-modal-action='close'
            onClick={() => close()}
            style={{cursor: 'pointer'}}
          >
            <KTIcon iconName='cross' className='fs-3' />
          </div>
        </div>

        <div className='body py-4'>
          <div className='row d-flex m-0'>
            <div className='col-md-5 col-12 h-100'>
              <div className='d-flex justify-content-between mb-1'>
                <span className='fw-bold d-block text-danger'>Total a pagar: </span>
                <span className='fw-semi-bold d-block text-danger'>{totalValue}</span>
              </div>
              <div className='d-flex justify-content-between mb-8'>
                <span className='fw-bold d-block'>Total a selecionar:</span>
                <span className='fw-semi-bold d-block'>{formatMonetaryToBr(data - current)}</span>
              </div>
              <div
                className='col-12 '
                style={{maxHeight: '300px', overflowY: 'auto', overflowX: 'hidden'}}
              >
                <SplitTransfer payment={billingDestination} />
              </div>
            </div>

            <div
              className='col-md-1 d-flex'
              style={{alignItems: 'center', justifyContent: 'center'}}
            >
              <div
                className='border border-left-0 border-top-0 border-bottom-0 border-right-2'
                style={{width: 1, minHeight: 400}}
              ></div>
            </div>

            <div className='col-md-6 col-12 h-100 border-left p-0'>
              <h6 className='mb-2'>Forma de pagamento</h6>
              <div
                className='col-12'
                style={{maxHeight: '400px', overflowY: 'auto', overflowX: 'hidden'}}
              >
                <PaymentRecurrentClientDestination />
              </div>
            </div>
            <button
              className='btn btn-danger'
              type='button'
              disabled={!enableConfirm}
              onClick={() => verifyPayment()}
            >
              Confirmar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
