import {formatDateTimeH, formatDateTimeHM} from '../../../utils/dayJs/format'
import {getColorStatus} from '../../schedules/utils/parseToEvents'
import {TooltipWrapper} from '../../../components/Tooltip'
import {KTIcon} from '../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import moment from 'moment'
import useAuthorization from '../../../hooks/useAuthorization'

export const ListSalesHistorySchedules = ({salesHistorySchedules}) => {
  const navigate = useNavigate()

  const isAuthorizedToViewAgenda = useAuthorization('schedules.view')

  return (
    <>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Informações do histórico de agendamento</h3>
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bolder fs-6 text-gray-800'>
                <th>Contrato</th>
                <th>Início</th>
                <th>Fim</th>
                <th>Sala</th>
                <th>Situação</th>
                <th></th>
                <th>Criado por</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {salesHistorySchedules?.map((item, index) => {
                return (
                  <tr key={`historySchedulesTr${index}`}>
                    <td>{item?.items[0].saleItem?.saleId}</td>
                    <td>{item?.dateInit ? formatDateTimeHM(item.dateInit) : ''}</td>
                    <td>{item?.dateEnd ? formatDateTimeH(item?.dateEnd) : ''}</td>
                    <td>{item?.room?.name}</td>
                    <td>
                      <span
                        className={'badge badge'}
                        style={{backgroundColor: getColorStatus(item.status_id)}}
                      >
                        {item?.lastHistory?.status?.name}
                      </span>
                    </td>
                    <td>
                      <table className='table table-row-bordered'>
                        <thead>
                          <tr className='fw-bolder fs-6 text-gray-800'>
                            <th>Sessão</th>
                            <th>Item</th>
                            <th>Status sessão</th>
                          </tr>
                        </thead>
                        <tbody>
                          {item?.items?.map((itemsInternal, indexItemsInternal) => {
                            return (
                              <tr key={indexItemsInternal}>
                                <td>{itemsInternal?.sessionNumber}</td>
                                <td>{itemsInternal?.saleItem?.product?.name}</td>
                                <td>
                                  {itemsInternal?.medicalRecord?.status}
                                  {item?.lastHistory?.status?.id === 8 &&
                                    itemsInternal?.medicalRecord?.status === 'Não realizado' && (
                                      <TooltipWrapper
                                        tooltipText={itemsInternal?.medicalRecord?.observation}
                                        tooltipPlacement={'top'}
                                      >
                                        <KTIcon iconName='information' className='fs-3' />
                                      </TooltipWrapper>
                                    )}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </td>
                    <td>{item?.user?.name ?? item?.lastHistory?.user?.name}</td>
                    <td>
                      {isAuthorizedToViewAgenda && (
                        <div
                          className='d-inline-flex flex-row align-items-center py-1 px-4 border border-danger bg-white rounded cursor-pointer custom-div'
                          onClick={() => {
                            if (item?.id) {
                              const url = `/schedules?view=month&date=${moment(
                                item.dateInit
                              ).format('YYYY-MM-DD')}&schedule=${Number(item.id)}`
                              navigate(url)
                            }
                          }}
                        >
                          <KTIcon iconName='book-open' className='fs-1 text-danger' />
                          <span className='ms-2 text-danger'>Acessar agendamento</span>
                        </div>
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}
