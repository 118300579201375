/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {KTIcon} from '../../../../helpers'
import type {TNotification} from '../../../../../app/modules/auth'
import {dateMask} from '../../../../../utils/dateMask'
import {cleanNotifications} from './core/_request'
import Swal from 'sweetalert2'

interface Props {
  notifications: TNotification[] | undefined
}

const HeaderNotificationsMenuNav: FC<Props> = ({notifications}: Props) => {
  const handleMarkAsRead = async (notification) => {
    await Swal.fire({
      title: 'Deseja limpar as notificações ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-danger', // Classe personalizada para o botão de confirmação
        cancelButton: 'btn btn-secondary', // Classe personalizada para o botão de cancelamento (opcional)
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await cleanNotifications()
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Sucesso',
            showConfirmButton: false,
            timer: 1500,
          })
          window.location.reload()
        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = ''
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>'
            }
            await Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText,
            })
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 2000,
            })
          }
        }
      }
    })
  }
  return (
    <div className='menu menu-sub menu-sub-dropdown menu-column  w-lg-400px' data-kt-menu='true'>
      {notifications !== undefined && notifications.length > 0 ? (
        <>
          <div className='d-flex flex-column rounded-top' style={{backgroundColor: '#f1116c'}}>
            <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
              Notificações{' '}
              <span className='fs-7 opacity-75 ps-3'>{notifications.length} reports</span>
            </h3>
            <button className='btn btn-sm btn-danger' onClick={() => handleMarkAsRead(alert)}>
              Limpar Notificações
            </button>
          </div>

          <div className='tab-content'>
            <div className='scroll-y mh-325px my-5 px-8'>
              {notifications.map((alert, index) => (
                <div key={`alert${index}`} className='d-flex flex-stack py-4'>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-35px me-4'>
                      <span className={clsx('symbol-label', 'bg-light-primary')}>
                        <KTIcon iconName={'information-5'} className='fs-2 text-primary' />
                      </span>
                    </div>

                    <div className='mb-0 me-2'>
                      <div className='d-flex align-items-center justify-content-between'>
                        <a
                          href={`/transfers/list/${alert.transfer_id}`}
                          className='fs-6 text-gray-800 text-hover-danger fw-bolder me-3'
                        >
                          Transferência aguardando aprovação
                        </a>
                      </div>

                      {/* Descrição da Notificação */}
                      <div className='text-gray-400 fs-6 mt-2'>
                        {alert?.origin_franchise_name} {'->'}{' '}
                        {alert?.destination_franchise_name ?? '---'}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='d-flex flex-column rounded-top' style={{backgroundColor: '#f1416c'}}>
            <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
              Notificações <span className='fs-8 opacity-75 ps-3'>0 reports</span>
            </h3>
          </div>

          <div className='tab-content'>
            <div className='scroll-y mh-325px my-5 px-8'>
              <h1>Nenhuma Notificação encontrada!!</h1>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export {HeaderNotificationsMenuNav}
