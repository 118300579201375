import {useEffect, useState} from 'react'
import {useFranchiseTransferCart} from './core/FranchiseTransferProvider'
import Swal from 'sweetalert2'
import {Franchise} from '../../../franchise/list/core/_models'
import {verifyFranchisePartner} from './core/_request'
import {PartnerNotFoundModal} from './PartnerNotFound'
import {useAuth} from '../../../../app/modules/auth'
import {LoadingSpinner} from '../../../../utils/loading/loadingSpinner'
import {FranchiseTransfer} from '.'

export const SearchFranchise = ({saleId, close, itens}) => {
  const {currentFranchise} = useAuth()
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const {
    franchise,
    searchOnDatabase,
    isFetching,
    search,
    setSearch,
    setSale,
    setFranchiseDestination,
    setItensProduct,
  } = useFranchiseTransferCart()

  const [franchiseSelected, setFranchiseSelected] = useState<Franchise[]>([])
  const [openPartnerModal, setPartnerModal] = useState<boolean>(false)
  const [openInfoFranchiseModal, setInfoFranchiseModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    try {
      searchOnDatabase()
      setSale(saleId)
      setItensProduct(itens)
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.response.data.message,
        timer: 2000,
      })
    }
  }, [])

  useEffect(() => {
    if (search === '') {
      return setDropdownOpen(false)
    }

    return setDropdownOpen(true)
  }, [search])

  const filterByName = (name) => {
    if (!name || !franchise) return

    const data = franchise.filter(
      (franq) =>
        franq?.name.toLowerCase().includes(name.toLowerCase()) && franq.id !== currentFranchise?.id
    )
    setFranchiseSelected(data)
    return data
  }

  const handleFranchise = async (franchise) => {
    const dataFranq = {
      destinationFranchise: franchise?.id,
    }
    try {
      setLoading(true)
      const data = await verifyFranchisePartner(dataFranq)

      setLoading(false)

      setFranchiseDestination(franchise?.id)
      setInfoFranchiseModal(true)
    } catch (error: any) {
      setLoading(false)
      if (error.response.status === 400) {
        setPartnerModal(true)
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error?.response?.data?.message,
          timer: 2000,
        })
      }
    }
  }

  return (
    <>
      {openPartnerModal && (
        <PartnerNotFoundModal data={currentFranchise} close={() => setPartnerModal(false)} />
      )}
      {openInfoFranchiseModal && <FranchiseTransfer close={() => setInfoFranchiseModal(false)} />}
      {loading && <LoadingSpinner title='Carregando.....' />}
      <div className='w-100 mb-20'>
        <h6 className='card-title mb-2 text-align-center required fw-bold'>Unidade de destino </h6>
        <div className='row mb-3'>
          <div className='column col-lg-6'>
            <div className='fv-row'>
              <input
                type='text'
                value={search}
                className='form-control form-control-lg form-control-solid'
                placeholder='Pesquisar Franquia'
                onChange={(e) => {
                  filterByName(e.target.value)
                  setSearch(e.target.value)
                }}
              />
            </div>
          </div>
        </div>
        <div className='column col-lg-12'>
          <div className={`position-relative ${dropdownOpen ? 'show' : ''}`} style={{zIndex: 100}}>
            <div className='dropdown position-absolute w-100'>
              <div
                className={`dropdown-menu position-static w-100 ${
                  dropdownOpen ? 'show' : ''
                } p-0 border border-2 border-danger`}
              >
                {!isFetching && franchiseSelected && franchiseSelected.length > 0 ? (
                  franchiseSelected.map((client) => (
                    <span
                      key={client.id}
                      className='dropdown-item border border-1 m-0'
                      onClick={(e) => {
                        e.preventDefault()
                        handleFranchise(client)
                      }}
                      style={{display: 'flex', justifyContent: 'space-between'}}
                    >
                      <span>{client?.name}</span>
                      <button
                        type='button'
                        onClick={(e) => {
                          e.preventDefault()
                        }}
                        className='btn text-white bg-danger border btn-sm p-2'
                        style={{fontSize: 12, minHeight: 12}}
                      >
                        Selecionar
                      </button>
                    </span>
                  ))
                ) : (
                  <>
                    <span className='dropdown-item disabled'>Nenhum resultado encontrado</span>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
