import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL + '/api'
const TRANSFER_URL = `${API_URL}/transfers`
const PAYMENT_OPTION_URL = `${API_URL}/franchises`

export const verifyFranchisePartner = (data) => {
  return axios
    .post(`${TRANSFER_URL}/verify-partnerships`, data)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

export const calculateValues = (data) => {
  return axios
    .post(`${TRANSFER_URL}/calculate-values`, data)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

export const createTransfer = (data) => {
  return axios
    .post(`${TRANSFER_URL}`, data)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

export const getPaymentOptionTransfer = (id: string): Promise<any> => {
  return axios
    .get(`${PAYMENT_OPTION_URL}/${id}/payment-options`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}
