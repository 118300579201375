import { useNavigate } from 'react-router-dom';
import { endsWithLetterA } from '../../utils/endsWithLetterA';

interface INotFoundIdShowPage {
    source: string
}

export const NotFoundIdShowPage = ({source}: INotFoundIdShowPage) => {
  const navigate = useNavigate();

  const handleVoltar = () => {
    navigate(-1);
  };

  const _endsWithLetterA = endsWithLetterA(source)

  const title = `${source} não ${ _endsWithLetterA ? "encontrada" : "encontrado"}`
  const subTitle = `Desculpe, ${ _endsWithLetterA ? "a" : "o"} ${source} que você está procurando não foi ${ _endsWithLetterA ? "encontrada" : "encontrado"}.`

  return (
    <div className="container">
      <div className="text-center mt-5">
        <h2>{title}</h2>
        <p>{subTitle}</p>
        <button
          className="btn btn-danger"
          onClick={handleVoltar}
        >
          Voltar
        </button>
      </div>
    </div>
  );
};
