import moment from 'moment'
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useReducer,
  useCallback,
} from 'react'
import {getFilteredSchedules} from '../pages/schedules/core/_request'
import {parseToEvents} from '../pages/schedules/utils/parseToEvents'
import {isEmptyArray} from 'formik'
import Swal from 'sweetalert2'
import {getRooms} from '../coreGlobal/_resquest'
import {parseToResource} from '../pages/schedules/utils/parseToResource'

export interface CalendarContextType {
  rooms: IRooms[]
  currentDateInit: any
  currentDateEnd: any
  events: any
  setEvents: React.Dispatch<React.SetStateAction<any>>
  selectedEvent: any
  setSelectedEvent: React.Dispatch<React.SetStateAction<any>>
  selectedFilterRoom: string
  setSelectedFilterRoom: React.Dispatch<React.SetStateAction<string>>
  fetchRooms: any
}

export const CalendarContext = createContext<CalendarContextType | undefined>(undefined)

interface CalendarProviderProps {
  children: ReactNode
}

interface ITime {
  startTime: string
  endTime: string
}

interface IOpenTime {
  dayOfWeek: number
  dayOfWeekDescription: string
  times: ITime[]
}
interface IRooms {
  id: number
  title: string
  color: string
  openTimes: IOpenTime[]
}

type CalendarAction =
  | {type: 'SET_EVENTS'; payload: any[]}
  | {type: 'SET_SELECTED_EVENT'; payload: any}
  | {type: 'SET_FILTER_ROOM'; payload: string}
  | {type: 'SET_ROOMS'; payload: IRooms[]}

function calendarReducer(state, action: CalendarAction) {
  switch (action.type) {
    case 'SET_EVENTS':
      return {...state, events: action.payload}
    case 'SET_SELECTED_EVENT':
      return {...state, selectedEvent: action.payload}
    case 'SET_FILTER_ROOM':
      return {...state, selectedFilterRoom: action.payload}
    case 'SET_ROOMS':
      return {...state, rooms: action.payload}
    default:
      return state
  }
}

export function CalendarProvider({children}: CalendarProviderProps) {
  const currentDateInit = moment().startOf('month').format('yyyy-MM-DD')
  const currentDateEnd = moment().endOf('month').format('yyyy-MM-DD')

  const [state, dispatch] = useReducer(calendarReducer, {
    events: [],
    selectedEvent: undefined,
    rooms: [],
    selectedFilterRoom: '',
  })

  const setEvents = useCallback((events) => dispatch({type: 'SET_EVENTS', payload: events}), [])

  const setSelectedEvent = useCallback(
    (event) => dispatch({type: 'SET_SELECTED_EVENT', payload: event}),
    []
  )

  const setSelectedFilterRoom = useCallback(
    (room) => dispatch({type: 'SET_FILTER_ROOM', payload: room}),
    []
  )

  async function fetchRooms() {
    try {
      const data = await getRooms()
      const parsed = parseToResource(data)
      dispatch({type: 'SET_ROOMS', payload: parsed})
    } catch (error) {}
  }

  useEffect(() => {
    fetchRooms()
  }, [])

  return (
    <CalendarContext.Provider
      value={{
        events: state.events,
        setEvents,
        selectedEvent: state.selectedEvent,
        setSelectedEvent,
        currentDateInit,
        currentDateEnd,
        rooms: state.rooms,
        selectedFilterRoom: state.selectedFilterRoom,
        setSelectedFilterRoom,
        fetchRooms,
      }}
    >
      {children}
    </CalendarContext.Provider>
  )
}
