import { useEffect } from "react";

const ListSearchDateUser = ({ filterByMonthYear, setFilterByMonthYear, setErrors, errors }) => {

    return (
        <>
            <div className='mb-5 row'>
                <div className="col-12">
                    <label className='form-label fs-12 fw-bold'>Pesquisar por Data:</label>
                    <input
                        type='month'
                        data-kt-user-table-filter='dtStart'
                        className='form-control form-control-solid w-100'
                        placeholder='Data inícial'
                        value={filterByMonthYear}
                        onChange={(e) => setFilterByMonthYear(e.target.value)}
                        min="2015-12-31"
                        maxLength={10}
                    />
                    {errors && errors.length > 0 && errors[0] ? (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block fs-12'>{errors[0]}</div>
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    )
}

export { ListSearchDateUser }
