import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { TPersonRequest } from '../../coreGlobal/models/person';
import { usePeople } from '../../coreGlobal/providers/PersonProvider';
import { KTIcon } from '../../_metronic/helpers';
import PeopleForm from './PeopleForm';
import { PersonValidSchema } from '../../coreGlobal/validation/PersonVali';
import Swal from 'sweetalert2';
import { buildPersonRequest } from '../../coreGlobal/buildPersonRequest';
import { createPerson, updatePerson } from '../../coreGlobal/_resquest';

interface IPersonInfo {
  action?: (values: any )=> void
  close?: () => void
  person: TPersonRequest
  index: number
}

const PersonCreateModal = ({ action = () => {}, close = () => {}, person, index = 0 }: IPersonInfo) => {
  const { showCompleteContactsModal, removePerson, setShowCreatePersonModal, persons, setPersons } = usePeople();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.body.classList.add('modal-open');
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []);

  function closeModal() {
    setShowCreatePersonModal(false);
    close();
    if (showCompleteContactsModal) {
      removePerson(0);
    }
  }

  const initialValues: TPersonRequest = {
    type: 1,
    name: '',
    preferredName: '',
    email: '',
    document: '',
    birthDate: '',
    gender: '',
    rg: '',
    job: '',
    nationality: 22,
    reduceTime: true,
    hideAvatar: false,
    contacts: [
      {
        type: 0,
        phone: ""
      }
    ],
    medicalConditions: [],
  };

  const formik = useFormik({
    initialValues,
    validationSchema: PersonValidSchema(true),
    onSubmit: async (values) => {
      const valuesFilter = await buildPersonRequest(values)
      submit(valuesFilter)
    },
  });

  const submit = async (req: FormData) => {
    await Swal.fire({
      title: 'Deseja criar o cliente?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-danger', // Classe personalizada para o botão de confirmação
        cancelButton: 'btn btn-secondary' // Classe personalizada para o botão de cancelamento (opcional)
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true)
        try {
          const data =  await createPerson(req)
          const updatedClients = [...persons]
          updatedClients[index] = data
          setPersons(updatedClients);
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Salvo com sucesso',
            showConfirmButton: false,
            timer: 1500
          })
          action(data)
          closeModal()
        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = '';
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>';
            }
            await Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText
            });
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 2000
            })
          }
        } finally {
          setLoading(false)
        }
      }
    })
  }

  return (
    <form id='personFormModal' className='form' onSubmit={formik.handleSubmit} noValidate>
      <div className='modal fade show d-block' id='kt_modal_create_user' role='dialog' tabIndex={-1} aria-modal='true'>
        <div className='modal-dialog modal-dialog-centered mw-950px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'>Informe os Dados</h2>
              <div className='btn btn-icon btn-sm btn-active-icon-primary' data-kt-users-modal-action='close'
                onClick={closeModal} style={{ cursor: 'pointer' }}>
                <KTIcon iconName='cross' className='fs-1' />
              </div>
            </div>
            <div className='modal-body scroll-y'>
              <PeopleForm formik={formik} type={person.type} complete={'required'} mode='CREATE'/>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type="reset" className="btn btn-secondary me-2" onClick={closeModal}>Cancelar</button>
              <button
                type='submit'
                className='btn btn-danger'
                onClick={(e) => { e.preventDefault(); formik.handleSubmit() }}
                disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
              >
                <span className='indicator-label'>Salvar</span>
                {(formik.isSubmitting) && (
                  <span className='indicator-progress'>
                    Aguarde...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </form>
  );
};

export { PersonCreateModal };