import {useMemo} from 'react'
import Chart from 'react-apexcharts'
import {formatMonetaryToBr} from '../../../../utils/formatMonetaryToBr'
import {ApexOptions} from 'apexcharts'

interface Sale {
  id: number
  name: string
  total_price: string
}

interface ChartSalesByProduct {
  className: string
  sales: Sale[] | undefined
}

const colors = [`#50cd89`, `#3e97ff`, `#000000`]

export function ChartSalesByProduct({className, sales}: ChartSalesByProduct) {
  const chartData = useMemo(() => {
    if (!sales || sales.length === 0) {
      return {series: [], labels: []}
    }

    return {
      series: sales.map((val) => Number(val.total_price)),
      labels: sales.map((val, index) => ({
        name: `${val.name}`,
        price: formatMonetaryToBr(val.total_price),
        color: colors[index] || "#000000"
      })),
      colors: colors
    }
  }, [sales])

  const options: ApexOptions = useMemo(
    () => ({
      chart: {
        type: 'donut',
        toolbar: {show: false},
      },
      plotOptions: {
        pie: {donut: {size: '70%'}},
      },
      legend: {
        show: false,
      },
      stroke: {show: true, width: 1, colors: ['transparent']},
      states: {
        normal: {filter: {type: 'none', value: 0}},
        hover: {filter: {type: 'none', value: 0}},
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {type: 'none', value: 0},
        },
      },
      tooltip: {
        style: {fontSize: '12px'},
        y: {
          formatter: (val: number) => formatMonetaryToBr(val),
        },
      },
      labels: chartData.labels.map((val, index) => `${val.name}` ),
      colors: chartData.colors,
    }),
    [chartData]
  )

  const hasData = sales && sales?.length > 0

  return (
    <div className={`d-flex flex-column text-start align-items-start justify-content-start w-100 h-100 ${className}`}>
      {hasData ? (
        <><Chart options={options}  series={chartData.series} type='donut' width='60%' height='80%' />
        <div className='d-flex flex-column content-justify-center flex-row-fluid w-100'>
          {chartData && chartData?.labels.map((chart)=>(
            <div className='d-flex fw-semibold align-items-center'>
            <div className='bullet w-8px h-3px rounded-2 me-3' style={{backgroundColor: chart.color}}></div>
            <div className='text-gray-500 flex-grow-1 me-4'>{chart.name}</div>
            <div className='fw-bolder text-gray-700 text-xxl-end'>{chart.price}</div>
          </div>
          ))}
        </div></>
      ) : (
        <div className='text-center w-100'>Nenhum dado disponível</div>
      )}
    </div>
  )
}
