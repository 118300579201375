import 'moment/locale/pt-br'
import {KTIcon} from '../../../../_metronic/helpers'
import {useFormik} from 'formik'
import {cancelSaleSchema} from '../core/Validation/cancelSaleVali'
import Swal from 'sweetalert2'
import {cancelSale} from '../core/_request'

interface ICancelSale {
  close: () => void
  saleId: number
}
export const CancelSaleModal = ({close, saleId}: ICancelSale) => {
  const formik = useFormik({
    initialValues: {status: -1, observation: ''},
    isInitialValid: true,
    validationSchema: cancelSaleSchema,
    onSubmit: (values) => {
      handleSubmit(values)
    },
  })

  const handleSubmit = async (values: {status: number; observation: string}) => {
    try {
      Swal.fire({
        title: 'Deseja cancelar a venda ?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-danger', // Classe personalizada para o botão de confirmação
          cancelButton: 'btn btn-secondary' // Classe personalizada para o botão de cancelamento (opcional)
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await cancelSale(saleId, values)
            return await Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Venda cancelada com sucesso!',
              showConfirmButton: false,
              allowOutsideClick: false,
              timer: 2000,
            })
          } catch (error: any) {
            await Swal.fire({
              position: 'center',
              icon: 'error',
              title: error.response.data.message,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          } finally {
            close()
            return
          }
        }
      })
    } catch (error: any) {
      await Swal.fire({
        position: 'center',
        icon: 'error',
        title: error.response.data.message,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
  }
  return (
    <>
      <div className='modal fade show d-flex'>
        <div className='mx-auto mw-50 w-50 pb-10' id='validToken'>
          <div className='modal-dialog modal-dialog-centered mw 550px'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h2 className='fw-bolder'>Cancelamento Venda</h2>
                <div
                  className='btn btn-icon btn-sm btn-active-icon-primary'
                  data-kt-users-modal-action='close'
                  onClick={() => close()}
                  style={{cursor: 'pointer'}}
                >
                  <KTIcon iconName='cross' className='fs-1' />
                </div>
              </div>

              <form onSubmit={formik.handleSubmit}>
                <div>
                  <div className='row mb-4 px-4'>
                    <div className='col-lg-12 mt-6'>
                      <label className='fs-6 fw-bold mb-2'>Selecione o motivo: </label>
                      <div className='col-12'>
                        <select
                          className='form-select'
                          onChange={(e) => {
                            formik.setFieldValue('status', e.target.value)
                          }}
                        >
                          <option value=''>Selecione o motivo</option>
                          <option value='14'>Alteração de produto</option>
                          <option value='9'>Cancelamento cliente</option>
                          <option value='3'>Cancelamento clínica</option>
                        </select>
                      </div>
                    </div>

                    <div className='col-lg-12 mt-6'>
                      <label className='fs-6 fw-bold mb-2'>Observação: </label>
                      <div className='col-12'>
                        <input
                          type='text'
                          className='form-control'
                          onChange={(e) => {
                            formik.setFieldValue('observation', e.target.value)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <span className='d-flex border-bottom border-1 py-2'></span>
                <div className='col-12 mt-6 border-top-2 pb-4 px-4'>
                  <div className='d-flex flex-row align-items-center justify-content-end'>
                    <button type='button' className='btn btn-secondary ' onClick={() => close()}>
                      Cancelar
                    </button>

                    <button
                      type='submit'
                      className='btn btn-danger ms-5'
                      disabled={!formik.isValid}
                    >
                      Confirmar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}
