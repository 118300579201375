import {useEffect, useState} from 'react'
import {useFranchiseTransferCart} from '../core/FranchiseTransferProvider'
import {calculateValues} from '../core/_request'
import {formatMonetaryToBr} from '../../../../../utils/formatMonetaryToBr'
import Swal from 'sweetalert2'
import {formatMonetaryToBrTransfer, formatToDecimal, removeMaskTransfer} from '../Utils/formatMask'
import '../Utils/css/radioButtons.css'
export const FranchiseTransferProductInfo = ({formik, close}) => {
  const {
    saleId,
    franchiseDestination,
    setDataCalculate,
    setTransferFineFranchse,
    transferFineFranchse,
    setHasFine,
    hasFine,
    amountFine,
    setAmountFine,
    dataCalculate,
  } = useFranchiseTransferCart()
  const [valueOrigin, setValueOrigin] = useState(dataCalculate?.clientTransferValueToOrigin)
  const [valueDestination, setValueDestination] = useState(
    dataCalculate?.clientTransferValueToDestination
  )

  useEffect(() => {
    const init = async () => {
      const arrayDestination = {
        destinationFranchise: franchiseDestination,
        saleId: saleId,
      }
      try {
        const data = await calculateValues(arrayDestination)
        setDataCalculate(data)
        setValueOrigin(data.clientTransferValueToOrigin)
        setValueDestination(data.clientTransferValueToDestination)
        if (data.bonusItems.length > 0) {
          Swal.fire({
            icon: 'info',
            title: 'Essa transferência possui itens bonificados',
            html: `O contrato possui <b>${
              data.bonusItems.length
            }</b> <p><b>Itens bonificados:</b></p>
            <ul style="text-align: center; list-style-type: none; padding-left: 0;">
              ${data.bonusItems.map((item) => `<li><b>${item.productName}</b></li>`).join('')}
            </ul>`,
            showConfirmButton: true,
          })
        }
      } catch (error: any) {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.message,
          showConfirmButton: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            close()
          }
        })
      }
    }
    init()
  }, [])

  const verifyFineDestination = () => {
    if (transferFineFranchse === 1) {
      let origVal = dataCalculate?.clientTransferValueToOrigin + Number(formatToDecimal(amountFine))
      setValueOrigin(origVal)
      setValueDestination(dataCalculate?.clientTransferValueToDestination)
      return
    }
    if (transferFineFranchse === 2) {
      let destinVal =
        dataCalculate?.clientTransferValueToDestination + Number(formatToDecimal(amountFine))
      setValueDestination(destinVal)
      setValueOrigin(dataCalculate?.clientTransferValueToOrigin)
      return
    }
    setValueDestination(dataCalculate?.clientTransferValueToDestination)
    setValueOrigin(dataCalculate?.clientTransferValueToOrigin)
  }

  useEffect(() => {
    verifyFineDestination()
  }, [transferFineFranchse, amountFine])

  const verifyFine = (bool) => {
    if (!bool) {
      setHasFine(false)
      formik.setFieldValue('hasFine', false)
      formik.setFieldValue('fineDestination', undefined)
      setValueOrigin(dataCalculate?.clientTransferValueToOrigin)
      setValueDestination(dataCalculate?.clientTransferValueToDestination)
      setTransferFineFranchse(undefined)
      return
    }
    setHasFine(true)
    formik.setFieldValue('hasFine', true)
  }

  const veriftFineDestination = (val) => {
    setTransferFineFranchse(val)
    formik.setFieldValue('fineDestination', val)
  }

  const MAX_VALUE = 15000

  const handleChange = (event) => {
    event.preventDefault()
    const rawValue = removeMaskTransfer(event.target.value)
    const numericValue = Number(rawValue)

    if (numericValue <= MAX_VALUE) {
      setAmountFine(rawValue)
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Ops! Revise seu formulário e tente novamente!',
        html: 'Valor máximo permitido é R$ 150,00',
      })
    }
  }

  return (
    <div className='container'>
      <div className='card-body pt-12 pb-0'>
        <div className='card-header'>
          <div className='card-title m-0'>
            <h2 className='fw-bolder m-0'>Informações do produto</h2>
          </div>
        </div>
        <div className='card-body py-3 mt-3'>
          <div className='table-responsive'>
            <table
              className='table table-bordered table-hover table-row-bordered table-row-gray-100'
              style={{tableLayout: 'fixed', width: '100%'}}
            >
              <thead>
                <tr className='fw-bold text-muted text-center'>
                  <th style={{width: '10%'}}>Produto</th>
                  <th style={{whiteSpace: 'nowrap'}}>Sessões</th>
                  <th style={{whiteSpace: 'nowrap'}}>Realizadas</th>
                  <th style={{whiteSpace: 'nowrap'}}>Pendente</th>
                  <th style={{whiteSpace: 'nowrap'}}>Valor UN</th>
                  <th style={{whiteSpace: 'nowrap'}}>Total</th>
                  <th>Valor pendentes</th>
                  <th style={{whiteSpace: 'nowrap'}}>Deságio</th>
                  <th style={{whiteSpace: 'nowrap'}}>Repasse</th>
                </tr>
              </thead>
              <tbody>
                {dataCalculate?.itemsTransferValues.map((item, index) => (
                  <tr key={item?.id || index}>
                    <td
                      className='align-middle text-start text-nowrap'
                      style={{whiteSpace: 'nowrap'}}
                    >
                      <span className='text-dark fw-bold text-hover-danger fs-6'>
                        {item?.productName}
                      </span>
                    </td>
                    <td
                      className='align-middle text-center text-nowrap'
                      style={{whiteSpace: 'nowrap'}}
                    >
                      <span className='text-dark fw-bold text-hover-danger fs-6'>
                        {item?.sessions}
                      </span>
                    </td>
                    <td
                      className='align-middle text-center text-nowrap'
                      style={{whiteSpace: 'nowrap'}}
                    >
                      <span className='text-dark fw-bold text-hover-danger fs-6'>
                        {item?.sessionsRealized}
                      </span>
                    </td>
                    <td
                      className='align-middle text-center text-nowrap'
                      style={{whiteSpace: 'nowrap'}}
                    >
                      <span className='text-dark fw-bold text-hover-danger fs-6'>
                        {item?.remainingSessions}
                      </span>
                    </td>
                    <td
                      className='align-middle text-center text-nowrap'
                      style={{whiteSpace: 'nowrap'}}
                    >
                      <span className='text-dark fw-bold text-hover-danger fs-6'>
                        {formatMonetaryToBr(item?.sessionValue)}
                      </span>
                    </td>
                    <td
                      className='align-middle text-center text-nowrap'
                      style={{whiteSpace: 'nowrap'}}
                    >
                      <span className='text-dark fw-bold text-hover-danger fs-6'>
                        {formatMonetaryToBr(item?.totalValue)}
                      </span>
                    </td>
                    <td
                      className='align-middle text-center text-nowrap'
                      style={{whiteSpace: 'nowrap'}}
                    >
                      <span className='text-dark fw-bold text-hover-danger fs-6'>
                        {formatMonetaryToBr(item?.remainingSessionsValue)}
                      </span>
                    </td>
                    <td
                      className='align-middle text-center text-nowrap'
                      style={{whiteSpace: 'nowrap'}}
                    >
                      <span className='text-dark fw-bold text-hover-danger fs-6'>
                        {formatMonetaryToBr(item?.deductedValue)}
                      </span>
                    </td>
                    <td
                      className='align-middle text-center text-nowrap'
                      style={{whiteSpace: 'nowrap'}}
                    >
                      <span className='text-dark fw-bold text-hover-danger fs-6'>
                        {formatMonetaryToBr(item?.transferValue)}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className='card-body pt-2 pb-0'>
        <div className='card-body py-3 text-start'>
          <div className='row '>
            <h2 className='text-dark fw-bold text-hover-danger'>Valor do Repasse</h2>
            <hr className='gray-300' />
            <div className='col-4'>
              <div className='fs-5 mt-2 text-dark fw-bold'>Multa de Transferência:</div>
              {hasFine && (
                <>
                  <div className='fs-5 mt-4 text-dark fw-bold'>Valor da multa:</div>
                  <div className='fs-5 mt-4 text-dark fw-bold'>Qual Unidade irá receber:</div>
                </>
              )}
            </div>
            <div className='col-8'>
              <div className='d-flex align-items-center mb-3'>
                <label className='custom-radio me-3'>
                  <input
                    type='radio'
                    id='hasFine'
                    checked={hasFine === true}
                    name='transferFineGroup'
                    onClick={() => verifyFine(true)}
                  />
                  <span className='checkmark'></span>
                  Sim
                </label>
                <label className='custom-radio'>
                  <input
                    type='radio'
                    id='hasntFine'
                    checked={hasFine === false}
                    name='transferFineGroup'
                    onClick={() => verifyFine(false)}
                  />
                  <span className='checkmark'></span>
                  Não
                </label>
              </div>
              {hasFine && (
                <>
                  <div className='form-group mt-4 col-3'>
                    <input
                      type='text'
                      id='amountFine'
                      className='form-control fw-bold text-black text-center'
                      placeholder='Digite o valor da multa'
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault()
                        }
                      }}
                      value={formatMonetaryToBrTransfer(amountFine)}
                      onChange={handleChange}
                      style={{backgroundColor: '#99a1b7'}}
                    />
                  </div>
                  <div className='d-flex align-items-center mt-3'>
                    <label className='custom-radio me-3'>
                      <input
                        type='radio'
                        id='hasFineDestination'
                        checked={transferFineFranchse === 1}
                        name='transferGroup'
                        onClick={() => veriftFineDestination(1)}
                      />
                      <span className='checkmark'></span>
                      Origem
                    </label>
                    <label className='custom-radio'>
                      <input
                        type='radio'
                        id='hasntFineDestination'
                        checked={transferFineFranchse === 2}
                        name='transferGroup'
                        onClick={() => veriftFineDestination(2)}
                      />
                      <span className='checkmark'></span>
                      Destino
                    </label>
                  </div>
                </>
              )}
            </div>
            <h2 className='mt-6'>Entre unidades</h2>
            <hr className='gray-300' />

            <div className='col-5 mt-1'>
              <div className='fs-5 mt-2 fw-bold'>
                Valor total repasse: {formatMonetaryToBr(dataCalculate?.franchiseTransferValue)}
              </div>
            </div>
            <h2 className='mt-6'>Cliente</h2>
            <hr className='gray-300' />

            <div className='col-5 mt-1'>
              <div className='fs-5 mt-2 fw-bold'>
                Valor devido Origem: {formatMonetaryToBr(valueOrigin)}
              </div>
              <div className='fs-5 mt-2 fw-bold'>
                Valor devido Destino: {formatMonetaryToBr(valueDestination)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
