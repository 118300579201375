/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { PaymentOption } from '../../../core/_models'

type Props = {

    paymentOption: PaymentOption,

}

const CategoryCell: FC<Props> = ({ paymentOption }) => {

    return <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            <span role='button' className=' text-hover-danger'>{paymentOption.category?.name}</span>
        </div>
    </div>
}

export { CategoryCell }
