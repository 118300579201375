import { useNavigate } from 'react-router-dom'
import { KTIcon } from '../../../../../_metronic/helpers'
import useAuthorization from '../../../../../hooks/useAuthorization'

const Toolbar = () => {
    const navigate = useNavigate()
    const handleToShow = () => navigate(`/sales/create`)
    const isAuthorizedToCreate = useAuthorization("sales.create");
    if (!isAuthorizedToCreate) return <></>;

    return (
        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base' >
            <button className='btn btn-light-danger' onClick={() => handleToShow()} >
                <KTIcon iconName='plus' className='fs-2' />
                Realizar venda
            </button>
        </div>
    )
}

export { Toolbar }
