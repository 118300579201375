/* eslint-disable jsx-a11y/anchor-is-valid */
import {useMemo} from 'react'
import moment from 'moment'
import {useDashboard} from '../../../hooks/useDashboard'
import {capitalizeWords} from '../../../coreGlobal/Helpers/utils'
import {ChartClient} from './charts/ChartClient'

interface ICustomers {
  id: number
  name: string
  quantity_customers: number
}

interface ClientsProps {
  className: string
}

export function Clients({className}: ClientsProps) {
  const {currentInitDta, currentEndDta, query} = useDashboard()
  const customers = query?.data?.customers?.itens ?? []
  const isLoading = query.isLoading
  const totalCustomers = query?.data?.customers?.total ?? 0

  const verifyMouth = useMemo(() => {
    if (!currentInitDta || !currentEndDta) return

    const mouthText = moment(currentInitDta).format('MMMM')
    const mouthsText = mouthText + ' - ' + moment(currentEndDta).format('MMMM')

    if (moment(currentInitDta).month() == moment(currentEndDta).month()) {
      return mouthText
    }
    return mouthsText
  }, [currentInitDta, currentEndDta])

  return (
    <div className={`card card-flush ${className}`}>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{totalCustomers}</span>
          </div>
          <span className='text-gray-400 pt-1 fw-semibold fs-6'>
            Clientes de {capitalizeWords(verifyMouth)}
          </span>
        </div>
      </div>

      <div className='card-body pt-1 pb-4 d-flex flex-wrap flex-center'>
        <div className='d-flex flex-center   w-100'>
          {isLoading && (
            <div className='text-center w-100 align-items-center justify-content-center'>
              <span>Carregando...</span>
            </div>
          )}
          {!isLoading && (
            <>
              <ChartClient className='w-100 h-100' customers={customers} />
            </>
          )}
        </div>
      </div>
    </div>
  )
}
