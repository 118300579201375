import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_API_URL + '/api'
const DASHBOARD_URL = `${API_URL}/dashboard`

export const fetchDataDashboard = (dateInit, dateEnd): Promise<any> => {
  const params = new URLSearchParams()
  if (dateInit) params.append('init', dateInit)
  if (dateEnd) params.append('end', dateEnd)

  return axios
    .get(`${DASHBOARD_URL}${params.toString() ? `?${params.toString()}` : ''}`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}
