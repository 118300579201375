// import { useListView } from '../../core/ListViewProvider'
import {ListToolbar} from './ListToolbar'
// import {UsersListGrouping} from './FranchiseGroupinp'
import {ListSearchComponent} from './ListSearchComponent'

const Header = () => {
  // const { selected } = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      {/* <ListSearchComponent /> */}

      <div className='card-toolbar'>{/* {<ListToolbar />} */}</div>
    </div>
  )
}

export {Header}
