interface ILoading {
    title?: string
}

export const LoadingListLoading = ({ title }: ILoading) => {
    const styles = {
        position: 'absolute',  // Position the element relative to its parent or viewport
        top: '50%',            // Position the element at 50% from the top
        left: '50%',           // Position the element at 50% from the left
        transform: 'translate(-50%, -50%)',  // Offset by 50% of the element's width and height to center it
        borderRadius: '0.75rem', // Aumentei o border-radius para tornar os cantos mais arredondados
        boxShadow: '0 0 100px 0 rgb(82 63 105 / 15%)', // Aumentei a intensidade da sombra
        backgroundColor: 'rgba(255, 255, 255, 0.9)', // Cor de fundo com transparência
        color: '#f1416c',
        fontWeight: '500',
        margin: '0',
        width: 'auto',
        padding: '2rem 4rem',
        fontSize: '1.2rem'
    }

    return <div id="listLoading" style={{ ...styles, position: 'absolute', textAlign: 'center' }}>{title}...</div>
}



