/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { initialQueryState, KTIcon, useDebounce } from '../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useFilterExport } from '../../core/FilterProvider'

const ListSearchNumberContract = ({ searchNumberContract, setSearchNumberContract }) => {

    return (
        <div className='card-title'>
            <div className="row min-w-100">
                <div className="col">
                    {/* begin::Search */}
                    <label className='form-label fs-6 fw-bold'>Pesquisar por contrato:</label>
                    <div className='d-flex align-items-center position-relative my-1'>
                        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                        <input
                            type='text'
                            data-kt-user-table-filter='contract'
                            className='form-control form-control-solid ps-14'
                            placeholder='Pesquisar Por Contrato'
                            value={searchNumberContract}
                            onChange={(e) => setSearchNumberContract(e.target.value)}
                        />
                    </div>
                    {/* end::Search */}
                </div>
            </div>
        </div>
    )
}

export { ListSearchNumberContract }
