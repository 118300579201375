import axios, {AxiosResponse} from 'axios'
import {SaleReportsQueryResponse} from './_models'
import { Option } from 'react-multi-select-component'
import { Response, SearchReportsSales } from '../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL + '/api'
const REPORTS_URL = `${API_URL}/reports`
const SALE_URL = `${API_URL}/sales`
const PRODUCTS_URL = `${API_URL}/products`
const FILE_URL = `${API_URL}/files`

const getSaleReports = (query: string): Promise<SaleReportsQueryResponse> => {
    return axios
        .get(`${REPORTS_URL}/sale-items?${query}`)
        .then((d: AxiosResponse<SaleReportsQueryResponse>) => d.data)
}

const getSaleStatus = (): Promise<Option[] | undefined> => {
    return axios.get(`${SALE_URL}/statuses?all=1`)
        .then((response: AxiosResponse<Response<Option[]>>) => response.data)
        .then((response: Response<Option[]>) => response.data)
}

const getCategories = (): Promise<Option[] | undefined> => {
    return axios.get(`${PRODUCTS_URL}/categories/all`)
        .then((response: AxiosResponse<Response<Option[]>>) => response.data)
        .then((response: Response<Option[]>) => response.data)
}

const getAvailableProducts = (): Promise<Option[] | undefined> => {
    return axios.get(`${REPORTS_URL}/available-products`)
        .then((response: AxiosResponse<Response<Option[]>>) => response.data)
        .then((response: Response<Option[]>) => response.data)
}

const downloadServerFile = (filter: string | undefined) => {
    var filterAdjust =  filter !== undefined ? `?${filter}`: ''
    return axios({
      url: `${REPORTS_URL}/sale-items/export${filterAdjust}`, //your url
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);
  
      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'Relatorio_vendas.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
  
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  }

export {getSaleReports, getSaleStatus, getCategories, getAvailableProducts, downloadServerFile};