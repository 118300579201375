interface IItemSale {
    category_id: number
    product_name: string
    category_name: string
    total_sold: number
    total_price: number
}

interface IParseSaleItemsReturn {
    itens: IItemSale[];
    total: number;
};

const parseSaleItems = (saleItems: IItemSale[]): IParseSaleItemsReturn => {
    if (!saleItems) return { total: 0, itens: [] };

    const total = saleItems.reduce(
        (total, item) => total + (item.total_sold || 0),
        0
    );

    return { total, itens: saleItems };
};

interface ISellerMan {
    id: number
    name: string
    total_price: string
    total_sold: number
    sexType?: 'M' | 'F'
}

interface IParseSellersReturn {
    itens: ISellerMan[];
    total: number;
};

const parseSellersData = (sellersData: ISellerMan[]): IParseSellersReturn => {
    if (!sellersData) return { itens: [], total: 0 };

    const sellers = sellersData.map((seller) => ({
        ...seller,
        sexType: seller.name.toLowerCase().endsWith("o") ? "F" as const : "M" as const
    }));

    const totalSoldSeller = sellersData.reduce(
        (total, seller) => total + (seller.total_sold ?? 0),
        0
    );

    return { itens: sellers, total: totalSoldSeller };
};

interface ICustomers {
    id: number
    name: string
    quantity_customers: number
}

interface IParseCustomersReturn {
    itens: ICustomers[];
    total: number;
};

const parseCustomersData = (customers: ICustomers[]): IParseCustomersReturn => {
    if (!customers) return { itens: [], total: 0 };

    const totalCustomers = customers.reduce(
        (total, customer) => total + (customer.quantity_customers || 0),
        0
    );

    return { itens: customers, total: totalCustomers };
};

interface Sale {
    id: number
    name: string
    total_price: string
}

interface IParseSalesDataReturn {
    itens: Sale[];
    total: number;
};

const parseSalesData = (salesByProduct: Sale[]): IParseSalesDataReturn => {
    if (!salesByProduct) return { itens: [], total: 0 };

    const totalPriceSales = salesByProduct.reduce(
        (total, sale) => total + parseFloat(sale.total_price || "0"),
        0
    );

    return { itens: salesByProduct, total: totalPriceSales };
};

interface SignedSale {
   total_sales: number,
   total_signed_sales: number
}

interface SignedSaleReturn {
    total: number
    signed: number
}

const parseSignedSalesData = (sale: SignedSale): SignedSaleReturn => {
    return {
        total: sale.total_sales,
        signed: sale.total_signed_sales
    }
};


export interface IParsedDashboard {
    sales: IParseSalesDataReturn;
    customers: IParseCustomersReturn
    topSellers: IParseSellersReturn;
    topProducts: IParseSaleItemsReturn;
    signedSales: SignedSaleReturn;
}

export const parseDashboard = (data): IParsedDashboard => {
    return {
        signedSales: parseSignedSalesData(data?.sales),
        sales: parseSalesData(data?.salesByProductCategory),
        customers: parseCustomersData(data?.customers),
        topSellers: parseSellersData(data?.salesman),
        topProducts: parseSaleItems(data?.topProducts)
    };
};
