import {
  addDays,
  startOfWeek,
  endOfWeek,
  subDays,
  startOfMonth,
  endOfMonth,
  addMonths,
} from 'date-fns'

type DateRange = [Date, Date]

type RangePlacement = 'bottom' | 'left'

interface RangeType {
  label: React.ReactNode
  value: DateRange | ((value?: DateRange) => DateRange)
  closeOverlay?: boolean
  placement?: RangePlacement
}

// Define the ranges array with proper typing
export const Ranges: RangeType[] = [
  {
    label: 'Hoje',
    value: [new Date(), new Date()],
    placement: 'left',
  },
  {
    label: 'Ontem',
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: 'left',
  },
  {
    label: 'Esta Semana',
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
    placement: 'left',
  },
  {
    label: 'Últimos 7 dias',
    value: [subDays(new Date(), 6), new Date()],
    placement: 'left',
  },
  {
    label: 'Últimos 30 dias',
    value: [subDays(new Date(), 29), new Date()],
    placement: 'left',
  },
  {
    label: 'Este mês',
    value: [startOfMonth(new Date()), new Date()],
    placement: 'left',
  },
  {
    label: 'Mês passado',
    value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
    placement: 'left',
  },
]
