/* eslint-disable react-hooks/exhaustive-deps */
import { KTIcon } from '../../../../../../_metronic/helpers'

const ListSearchNumberContractOrigin = ({ searchOriginSale, setSearchOriginSale }) => {

    return (
        <div className='card-title'>
            <div className="row min-w-100">
                <div className="col">
                    {/* begin::Search */}
                    <label className='form-label fs-6 fw-bold'>Pesquisar por contrato origem:</label>
                    <div className='d-flex align-items-center position-relative my-1'>
                        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                        <input
                            type='text'
                            data-kt-user-table-filter='originSale'
                            className='form-control form-control-solid ps-14'
                            placeholder='Pesquisar por contrato origem'
                            value={searchOriginSale}
                            onChange={(e) => setSearchOriginSale(e.target.value)}
                        />
                    </div>
                    {/* end::Search */}
                </div>
            </div>
        </div>
    )
}

export { ListSearchNumberContractOrigin }
