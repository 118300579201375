import moment from 'moment'
import { IPersonResponse } from '../../coreGlobal/models/person'
import { phoneMask } from '../../utils/phoneMask'
import { PersonEditModal } from './PersonEditModal'
import { FinishSaleButtons } from '../../pages/sales/create/components/FinishSaleButtons'
import { getBadgePerson } from './utils/getBadgePerson'
import useAuthorization from '../../hooks/useAuthorization'
import { TooltipWrapper } from '../Tooltip'
interface IPersonInfo {
  person: IPersonResponse
  responsible: boolean
  provider: any
  index: number
  hasBudget: boolean
  source?: 'OWNER' | 'SALE' | 'USER' | ''
}

export const PersonInfo = ({
  person,
  responsible,
  provider,
  index,
  hasBudget,
  source = '',
}: IPersonInfo) => {
  const { removePerson, setShowEditPersonModal, showEditPersonModal } = provider()
  const dataClient = getBadgePerson(person.lastClientHistory?.status_id)

  const isAuthorizedToShow = useAuthorization('persons.view')
  const isAuthorizedToUpdate = useAuthorization('persons.update')

  return (
    <>
      <div className='row mb-2'>
        <div className='row'>
          <div className='col-8 d-flex'>
            <span className='fw-bold d-block w-auto'>
              <h2><span className="me-5 text-danger">{person?.name}</span>
              {!hasBudget && (
                <TooltipWrapper
                  tooltipText={'Alterar Pessoa'}
                  tooltipPlacement={'top'}
                >
                  <button className="btn btn-link btn-color-muted btn-active-color-danger" onClick={() => removePerson(index)}>
                    <i className="ki-duotone ki-arrows-circle me-5 fs-1">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                  </button>
                </TooltipWrapper>
              )}
              {isAuthorizedToUpdate && (
                <TooltipWrapper
                  tooltipText={'Editar dados cadastrais'}
                  tooltipPlacement={'top'}
                >
                  <button className="btn btn-link btn-color-muted btn-active-color-danger" onClick={() => setShowEditPersonModal(true)}>
                    <i className="ki-duotone ki-user-edit me-5 fs-1">
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                    </i>
                  </button>
                </TooltipWrapper>
              )}
              {isAuthorizedToShow && (
                <TooltipWrapper
                  tooltipText={'Visualizar contratos'}
                  tooltipPlacement={'top'}
                >
                  <button className="btn btn-link btn-color-muted btn-active-color-danger" onClick={() => window.open(`/people/list/${person.id}`, '_blank')}>
                    <i className="ki-duotone ki-book-open me-5 fs-1">
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                      <span className="path4"></span>
                    </i>
                  </button>
                </TooltipWrapper>
              )}
              </h2>
            </span>
          </div>
          <div className='col-4 d-flex justify-content-end'>
            {!responsible && source == 'SALE' && <FinishSaleButtons />}
          </div>
        </div>

        <div className='row w-100 mt-2'>
          <div className='column col-lg-2'>
            <strong>Cliente Desde:</strong>{' '}
            {moment(person?.createdAt).format('DD/MM/YYYY')}
          </div>
          <div className='column col-lg-2'>
            <strong className='me-1'>Status:</strong>
            <span className={`${dataClient?.color} w-auto`}> {dataClient?.name}</span>
          </div>
        </div>
        <div className='row w-100 mt-4'>
          <div className='column col-lg-1'>
            <strong>Idade:</strong> {person?.physicalPerson?.age}
          </div>
          <div className='column col-lg-2'>
            <strong>Aniversário:</strong>{' '}
            {moment(person?.physicalPerson?.birthDate).format('DD/MM/YYYY') || '-'}
          </div>
          <div className='column col-lg-2'>
            <strong>Profissão:</strong> {person?.physicalPerson?.job || '-'}
          </div>
          <div className='column col-lg-3'>
            <strong>E-mail:</strong> {person?.email || '-'}
          </div>
          <div className='column col-lg-2'>
            <strong>Telefone:</strong>{' '}
            {person?.contacts?.[0] ? phoneMask(person.contacts[0].phone) : '-'}
          </div>
        </div>
      </div>
      {showEditPersonModal && (
        <PersonEditModal
          person={person}
          provider={provider}
          index={index}
          close={() => setShowEditPersonModal(false)}
        />
      )}
    </>
  )
}
