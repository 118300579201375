/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { formatDateTime } from '../../../../../utils/dayJs/format'
import { BudgetResponse } from '../../../../../coreGlobal/models/budgets/budget'

type Props = {

    budget: BudgetResponse
}

const CreatedInCell: FC<Props> = ({ budget }) => (

    <div className='d-flex flex-column'>
        <a href='#' className='text-gray-800 text-hover-danger mb-1'>
            {budget.createdAt ? formatDateTime(budget.createdAt.toString()) : 'N/A'}
        </a>
        {/* <span>{franchise.createdAt ? franchise.createdAt.toString() : 'N/A'}</span> */}
    </div>
)

export { CreatedInCell }
