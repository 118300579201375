import {useQuery} from 'react-query'
import {QUERIES, toAbsoluteUrl} from '../../../_metronic/helpers'
import {getSaleById} from './core/_request'
import axios, {AxiosError} from 'axios'
import {useParams} from 'react-router-dom'
import {NotFoundIdShowPage} from '../../../components/NotFoundIdShowPage'
import {HasErrorInShowPage} from '../../../components/HasErrorInShowPage'
import {Spinner} from '../../../components/Spinner'
import {formatMask} from '../../../utils/formatMonetaryToBr'
import {PaymentAccordion} from '../components/payment/PaymentAccordion'
import {PaymentCard} from './components/paymentCard'
import './styles/indicator.scss'
import {useState} from 'react'

function getSessionName(num: number | any): string {
  if (typeof num === 'number') {
    return num === 1 ? `${num} Sessāo` : `${num} Sessões`
  }
  return ''
}

export const PaymentPage = () => {
  let {id} = useParams()

  const csrf = () => axios.get(process.env.REACT_APP_API_URL + '/sanctum/csrf-cookie')
  const [hasFetched, setHasFetched] = useState(false) // Controle de requisição
  const {isLoading, data, error} = useQuery(
    `${QUERIES.PAYMENT_SHOW}-${String(id)}`,
    async () => {
      await csrf()
      return getSaleById(String(id))
    },
    {
      cacheTime: 0,
      enabled: !hasFetched,
      onSuccess: () => setHasFetched(true),
      onError: (err) => {
        setHasFetched(true)
      },
    }
  )

  if (isLoading) {
    return <Spinner />
  }

  if (error) {
    if (error instanceof AxiosError) {
      const status = error.response?.status
      switch (status) {
        case 404:
          return <NotFoundIdShowPage source='Venda' />
        default:
          return <HasErrorInShowPage />
      }
    }
    return <HasErrorInShowPage />
  }

  const {billings, franchise, client} = data

  return (
    <>
      <div className='conteiner ' style={{backgroundColor: '#D31145', height: '60px'}}>
        <div className='text-center m-4'>
          <img src={toAbsoluteUrl('/media/misc/marcaDrlaserBranca.png')} className='h-30px' />
        </div>
      </div>
      <div className='d-flex flex-column flex-center p-0 p-md-10'>
        <div
          className='card w-lg-1000px pt-2 pb-5 border border-white'
          style={{borderWidth: '50px'}}
        >
          <div className='card-body p-1 p-md-10'>
            <div className='p-2 ps-8 bg-redlaser welcome-payment'>
              <div className='col-12'>
                <span className='fs-2 fw-normal text-white'>
                  Olá, <span className='fs-2 fw-bold text-white'>{data?.client?.name}</span>
                </span>
              </div>
            </div>

            <div className='mt-10 px-6 py-4 pb-8' style={{backgroundColor: '#F8EDF1'}}>
              <div className='mb-4'>
                <span className='fs-1 fw-bold text-redlaser mb-4'>Serviço</span>
              </div>
              {data.items.map((item, index) => (
                <div key={index}>
                  <div className='row'>
                    <div className='col-9 col-md-6 d-flex align-items-center justify-content-start fs-3'>
                      <span>{item.product?.category?.name}, </span>
                      <span>{item.product?.name}, </span>
                      <span className='fw-bold'>{getSessionName(item.sessions)}</span>
                    </div>
                    <div className='col-3 col-md-6 d-flex align-items-center justify-content-end'>
                      <span className='fs-3'>{formatMask(item.totalPrice)}</span>
                    </div>
                  </div>
                  {index < data.items.length - 1 && (
                    <div className='border-top border-secondary border-1 my-3' />
                  )}
                </div>
              ))}
              <div className='border-top border-danger border-2 my-3' />
              <div className='row align-items-center justify-content-end'>
                <span className='fs-2 fw-bold text-redlaser mb-4 text-end'>
                  Total: {formatMask(data.price)}
                </span>
              </div>
            </div>

            <div className='mt-10 px-6 py-4 pb-8' style={{backgroundColor: '#F8EDF1'}}>
              <div className='mb-4'>
                <span className='fs-1 fw-bold text-redlaser mb-4'>Forma de Pagamento</span>
              </div>
              {billings.map((billing, index) => (
                <div key={index} className='row'>
                  <PaymentCard billing={billing} />
                  {index < billings.length - 1 && <div className='my-3' />}
                </div>
              ))}
              <div className='border-top border-danger border-2 my-3' />
              <div className='row align-items-center justify-content-end'>
                <span className='fs-2 fw-bold text-redlaser mb-4 text-end'>
                  Total: {formatMask(data.price)}
                </span>
              </div>
            </div>
          </div>

          <div className='p-2'>
            {data.status === 'Pendente' || data.status === 'Inadimplente' ? (
              <div className='accordion' id='kt_accordion_1'>
                <h2 className='m-7 mb-4 text-redlaser'>Escolha uma forma de pagamento</h2>
                {billings.map((billing) => {
                  return (
                    <PaymentAccordion
                      key={billing.id}
                      billing={billing}
                      saleId={data.encryptId}
                      adress={client.addresses}
                    />
                  )
                })}
              </div>
            ) : (
              <div className='m-10 p-8 d-flex flex-column justify-content-center align-items-center'>
                <i
                  className='fa-solid fa-circle-check me-2 text-success'
                  style={{fontSize: 48}}
                ></i>
                <span className='fs-1 text-redlaser fw-bold'>
                  Finalizamos o processo de pagamento!
                </span>
                <span className='fs-3 text-textlaser text-center w-75'>
                  Assim que confirmamos o mesmo você receberá no seu e-mail o contrato para
                  assinatura digital.
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
