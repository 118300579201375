export function PageOverlayer() {
  return (
    <div
      className='overlay-layer position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-dark bg-opacity-50'
      style={{zIndex: 1050}}
    >
      <div className='spinner-border text-danger' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </div>
    </div>
  )
}
