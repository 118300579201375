/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { formatDateTime } from '../../../../../utils/dayJs/format'
import { RoomList } from '../../../../../coreGlobal/models/default'

type Props = {

    room: RoomList
}

const CreatedInCell: FC<Props> = ({ room }) => (

    <div className='d-flex flex-column'>
        <a href='#' className='text-gray-800 text-hover-danger mb-1'>
            {room.createdAt ? formatDateTime(room.createdAt.toString()) : 'N/A'}
        </a>
    </div>
)

export { CreatedInCell }
