import {useEffect, useState} from 'react'
import { endsWithLetterA } from '../../utils/endsWithLetterA'
import { ID, KTIcon, TItemForDelete } from '../../_metronic/helpers'
import { useFormik } from 'formik'
import { confirmPassWordSchema } from './validate/confirmPassWordSchema'
import { TPasswordValidadeForm, passwordValidadeFormInitialValues as initialValues } from './core/models/password'
import { useMutation, useQueryClient } from 'react-query'
import { confirmPassword } from './core/_request'
import Swal from 'sweetalert2'

interface IConfirmDeleteModal {
    itemForDelete: TItemForDelete,
    setItemForDelete?: React.Dispatch<React.SetStateAction<TItemForDelete | null | undefined>>
    source: string
    submit: (id: ID) => Promise<any>
    queryInvalidate: string
}

export const ConfirmDeleteModal = ({ source, submit, itemForDelete, queryInvalidate, setItemForDelete }: IConfirmDeleteModal) => {

  const queryClient = useQueryClient()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const subTitle = endsWithLetterA(source) ? `a ${source}` : `o ${source}`

  const validateForDelete = async (id: ID) => {
    await confirmPassword(id)
    await submit(id)
  }

  const deleteItem = useMutation(() => validateForDelete(itemForDelete.id), {
    onSuccess: () => {
      queryClient.invalidateQueries([queryInvalidate])
      Swal.fire({
        position: 'top-right',
        icon: 'success',
        title: 'Excluido com sucesso',
        showConfirmButton: false,
        timer: 2000
        })
        setLoading(false)
        setItemForDelete && setItemForDelete(null)
    },
    onError: () => {
        Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Tente novamente mais tarde',
            showConfirmButton: false,
            timer: 2000
        })
        setLoading(false)
        setItemForDelete && setItemForDelete(null)
    }
  })

  const formik = useFormik<TPasswordValidadeForm>({
    initialValues,
    validationSchema: confirmPassWordSchema,
    
    onSubmit: async (values) => {
       setLoading(true)
       await deleteItem.mutateAsync()
    },
  })

  return (
    <>
    <form onSubmit={formik.handleSubmit} noValidate className='form'>
      <div
        className='modal fade show d-block h-auto'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Deseja excluir {subTitle}: {itemForDelete.name}?</h5>
                    <div
                    role='button'
                    className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={()=>{ !loading && setItemForDelete && setItemForDelete(null)}}
                    >
                    <KTIcon iconName='cross' className='fs-1' />
                    </div>
                </div>
                <div className="modal-body">
                <div className='col-lg-12 fv-column'>
                    <p>Confirme sua senha:</p>
                    <input
                      {...formik.getFieldProps('password')}
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 border-1 border-black'
                      placeholder='******'
                      maxLength={30}
                    />
                    {formik.touched?.password && formik.errors?.password && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors?.password}</div>
                    </div>
                    )}
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                    type="button"
                    className="btn btn-light"
                    data-bs-dismiss="modal"
                    disabled={loading}
                    onClick={()=>setItemForDelete && setItemForDelete(null)}
                    >
                    Cancelar
                    </button>
                    <button 
                    type='submit' 
                    className='btn btn-danger' 
                    disabled={loading || !formik.isValid}>
                    {!loading && 'Confirmar'}
                    {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                        Confirmando...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                    </button>
                </div>
            </div>
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      </form>
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}
