import { useCart } from "../../core/cartProvider";

export const SaleButton = ({ badge, action }) => {
    const { enableBudget, budgetForSale } = useCart();

    return (
        <>
            <button
                onClick={action}
                className='btn btn-light-danger w-100 text-center d-flex align-items-center justify-content-center'
                style={{ overflow: 'hidden', lineHeight: '1' }}
            >
                <i className="ki-duotone ki-dollar fs-2">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                </i> {badge}
            </button>
            <div className="position-relative">
                {budgetForSale && <label className="position-absolute right-1 text-danger fw-bold mt-4" style={{ right: 0 }}><b>Orçamento:</b> {Number(budgetForSale)}</label>}
            </div>
        </>)
}