// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-radio {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
            user-select: none;
  }
  
  .custom-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  .custom-radio .checkmark {
    height: 20px;
    width: 20px;
    background-color: #f0f0f0;
    border: 2px solid #ccc;
    border-radius: 4px; 
    display: inline-block;
    position: relative;
    transition: all 0.3s ease;
  }
  
  .custom-radio input:checked ~ .checkmark {
    background-color: #3e97ff;
    border-color: #3e97ff;
  }

  .gray-300 {
    border: 0;
    border-top: 1px solid #c9c3c3; 
    margin: 1rem 0;
  }
  
  .custom-radio .checkmark::after {
    content: '';
    position: absolute;
    display: none;
  }
  
  .custom-radio input:checked ~ .checkmark::after {
    display: block;
  }
  
  .custom-radio .checkmark::after {
    left: 6px;
    top: 2px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/clients/components/FranchiseTransfer/Utils/css/radioButtons.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,eAAe;IACf,eAAe;IACf,yBAAiB;YAAjB,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;IAClB,UAAU;IACV,eAAe;EACjB;;EAEA;IACE,YAAY;IACZ,WAAW;IACX,yBAAyB;IACzB,sBAAsB;IACtB,kBAAkB;IAClB,qBAAqB;IACrB,kBAAkB;IAClB,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,qBAAqB;EACvB;;EAEA;IACE,SAAS;IACT,6BAA6B;IAC7B,cAAc;EAChB;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,aAAa;EACf;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,SAAS;IACT,QAAQ;IACR,UAAU;IACV,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,wBAAwB;EAC1B","sourcesContent":[".custom-radio {\n    position: relative;\n    display: flex;\n    align-items: center;\n    gap: 8px;\n    cursor: pointer;\n    font-size: 16px;\n    user-select: none;\n  }\n  \n  .custom-radio input {\n    position: absolute;\n    opacity: 0;\n    cursor: pointer;\n  }\n  \n  .custom-radio .checkmark {\n    height: 20px;\n    width: 20px;\n    background-color: #f0f0f0;\n    border: 2px solid #ccc;\n    border-radius: 4px; \n    display: inline-block;\n    position: relative;\n    transition: all 0.3s ease;\n  }\n  \n  .custom-radio input:checked ~ .checkmark {\n    background-color: #3e97ff;\n    border-color: #3e97ff;\n  }\n\n  .gray-300 {\n    border: 0;\n    border-top: 1px solid #c9c3c3; \n    margin: 1rem 0;\n  }\n  \n  .custom-radio .checkmark::after {\n    content: '';\n    position: absolute;\n    display: none;\n  }\n  \n  .custom-radio input:checked ~ .checkmark::after {\n    display: block;\n  }\n  \n  .custom-radio .checkmark::after {\n    left: 6px;\n    top: 2px;\n    width: 6px;\n    height: 12px;\n    border: solid white;\n    border-width: 0 2px 2px 0;\n    transform: rotate(45deg);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
