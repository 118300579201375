/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { initialQueryState, KTIcon, useDebounce } from '../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { cpfMask, phoneMask } from '../../../../chooseFranchise/utils/mask'
import { removeMask, removeSCMask } from '../../../../../utils/formatMonetaryToBr'
import { MultiSelect, Option } from 'react-multi-select-component'
import { getStatusClient } from '../../core/_requests'
import "../../../../../style/multiSelectSucess.css"

const Search = () => {
  const { updateState } = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchPhone, setSearchPhone] = useState<string>('')
  const [searchDocument, setSearchDocument] = useState<string>('')

  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  const debouncedSearchPhone = useDebounce(searchPhone, 150)
  const debouncedSearchDocument = useDebounce(searchDocument, 150)

  const [clientStatusOption, setClientStatusOption] = useState<Option[]>([{ value: '', label: '' }]);
  const [statusValue, setStatusValue] = useState<Option[]>([]);

  useEffect(() => {
    async function initialize() {
      const data = await getStatusClient()
      setClientStatusOption(data!)
    }
    initialize()
  }, [])

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({ name: debouncedSearchTerm, ...initialQueryState })
    }
  }, [debouncedSearchTerm])

  useEffect(() => {
    if (
      debouncedSearchPhone !== undefined &&
      searchPhone !== undefined &&
      (debouncedSearchPhone.length > 13 || debouncedSearchPhone.length == 0)
    ) {
      updateState({ phone: String(removeSCMask(debouncedSearchPhone)), ...initialQueryState })
    }
  }, [debouncedSearchPhone])

  useEffect(() => {
    if (
      debouncedSearchDocument !== undefined &&
      searchDocument !== undefined &&
      (debouncedSearchDocument.length > 13 || debouncedSearchDocument.length == 0)
    ) {
      updateState({ document: String(removeSCMask(debouncedSearchDocument)), ...initialQueryState })
    }
  }, [debouncedSearchDocument])

  return (
    <div className='card-title'>
      {/* begin::Search */}
      <div className="container">
        <div className="row">
          <div className="col">
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-1' />
              <input
                type='text'
                data-kt-user-table-filter='name'
                className='form-control form-control-solid w-200px ps-14'
                placeholder='Pesquisar cliente'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div className="col">
            <div className='d-flex align-items-center position-relative'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='phone'
                className='form-control form-control-solid w-200px ps-14'
                placeholder='Pesquisar telefone'
                value={phoneMask(searchPhone)}
                onChange={(e) => setSearchPhone(e.target.value)}
              />
            </div>
          </div>
          <div className="col">
            <div className='d-flex align-items-center position-relative'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='document'
                className='form-control form-control-solid w-200px ps-14'
                placeholder='Pesquisar documento'
                value={cpfMask(searchDocument)}
                onChange={(e) => setSearchDocument(e.target.value)}
              />
            </div>
          </div>
          <div className="col">
            <div className='fv-row'>
              <MultiSelect
                className='form-control w-250px rmscStyle ps-14'
                data-kt-user-table-filter='status'
                options={clientStatusOption}
                value={statusValue}
                onChange={(option) => {
                  const valores = option.map((item) => item.value)
                  updateState({ status: String(valores), ...initialQueryState })
                  setStatusValue(option)
                }}
                labelledBy={''}
                shouldToggleOnHover
                overrideStrings={{
                  selectSomeItems: 'Pesquisar por status',
                  search: 'Procurar',
                  selectAll: 'Selecionar todos',
                  allItemsAreSelected: 'Todos status selecionados',
                  selectAllFiltered: 'Selecionar todos (filtrados)',
                  noOptions: 'Nenhuma opção encontrada',
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* end::Search */}
    </div>
  )
}

export { Search }
