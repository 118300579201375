/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { initialQueryState, KTIcon } from '../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'

import { MultiSelect, Option } from "react-multi-select-component"
import "../../../../../style/multiSelectSucess.css"
import { getStatusTransfer } from '../../core/_requests'

const Search = () => {
  const { updateState } = useQueryRequest()
  
  const initialSelectValue = [{ value: '', label: '' }];
  const [saleStatusTransfer, setSaleStatusTransfer] = useState<Option[]>(initialSelectValue);
  const [statusValue, setStatusValue] = useState<Option[]>([]);

  useEffect(() => {
    async function initialize() {
      const data = await getStatusTransfer()
      setSaleStatusTransfer(data!)
    }

    initialize()
  }, [])

  return (
    <div className='card-title'>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className='fv-row my-1 mx-2'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <MultiSelect
                className='form-control w-250px rmscStyle ps-14'
                data-kt-user-table-filter='status'
                options={saleStatusTransfer}
                value={statusValue}
                onChange={(option) => {
                  const valores = option.map((item) => item.value)
                  updateState({ status: String(valores), ...initialQueryState })
                  setStatusValue(option)
                }}
                labelledBy={''}
                shouldToggleOnHover
                overrideStrings={{
                  selectSomeItems: 'Pesquisar por status',
                  search: 'Procurar',
                  selectAll: 'Selecionar todos',
                  allItemsAreSelected: 'Todos status selecionados',
                  selectAllFiltered: 'Selecionar todos (filtrados)',
                  noOptions: 'Nenhuma opção encontrada',
                }}
              />
            </div>
          </div>
        </div>
      </div>


      {/* end::Search */}
    </div>
  )
}

export { Search }
