// @ts-nocheck
import { Column } from 'react-table'
import { CustomHeader } from './CustomHeader'
import { SelectionHeader } from './SelectionHeader'
import { User } from '../../core/_models'
import { CustonCell } from './CustonCell'
import { formatMask } from '../../../../../../utils/formatMonetaryToBr'
import { formatDateTime } from '../../../../../../utils/dayJs/format'
import { phoneMask } from '../../../../../../utils/phoneMask'
import { cpfMask } from '../../../../../../utils/cpfMask'

const ReportSalesColumns: ReadonlyArray<Column<User>> = [

  {
    Header: (props) => <CustomHeader tableProps={props} title='Nome' className='min-w-125px' />,
    id: 'name',
    Cell: ({ ...props }) => <CustonCell value={props.data[props.row.index].name} />,
  },

  {
    Header: (props) => <CustomHeader tableProps={props} title='Dt Cadastro' className='min-w-125px' />,
    id: 'createdAt',
    Cell: ({ ...props }) => <CustonCell value={formatDateTime(props.data[props.row.index].createdAt)} />,
  },

  {
    Header: (props) => <CustomHeader tableProps={props} title='Dt Nascimento' className='min-w-50px' />,
    id: 'birthDate',
    Cell: ({ ...props }) => <CustonCell value={props.data[props.row.index].birthDate} />,
  },

  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Gênero' className='min-w-125px' />
    ),
    id: 'gender',
    Cell: ({ ...props }) => <CustonCell value={props.data[props.row.index].gender} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='E-mail' className='min-w-125px' />
    ),
    id: 'email',
    Cell: ({ ...props }) => <CustonCell value={`${props.data[props.row.index].email}`} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='phone' />
    ),
    id: 'phones',
    Cell: ({ ...props }) => <CustonCell value={props.data[props.row.index].phones} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='CPF' />
    ),
    id: 'document',
    Cell: ({ ...props }) => <CustonCell value={cpfMask(props.data[props.row.index].document)} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'status',
    Cell: ({ ...props }) => <CustonCell value={props.data[props.row.index].status} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Motivo Cliente Supenso' className='min-w-125px' />
    ),
    id: 'restrictObservation',
    Cell: ({ ...props }) => <CustonCell value={props.data[props.row.index].restrictObservation} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Data Cliente Suspenso' className='min-w-125px' />
    ),
    id: 'restrictCreatedAt',
    Cell: ({ ...props }) => <CustonCell value={props.data[props.row.index].restrictCreatedAt} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Lista Outros' className='min-w-125px' />
    ),
    id: 'othersObservation',
    Cell: ({ ...props }) => <CustonCell value={props.data[props.row.index].othersObservation} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Data Cliente Lista Outros' className='min-w-125px' />
    ),
    id: 'othersCreatedAt',
    Cell: ({ ...props }) => <CustonCell value={props.data[props.row.index].othersCreatedAt} />,
  },
]

export { ReportSalesColumns }
