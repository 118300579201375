import { useQueryResponseLoading, useQueryTotalsData, useQueryTotalsPaymentsData } from "./core/QueryResponseProvider";
import { formatMask } from "../../../../utils/formatMonetaryToBr";
import { KTIcon } from "../../../../_metronic/helpers";
import { useNavigate } from "react-router-dom";
import { ChartSalesByProduct } from "../../../dashboard/components/charts/ChartSalesByProduct";

const Totalizer = () => {
    const totals = useQueryTotalsData()
    const totalsPayments = useQueryTotalsPaymentsData()
    const isLoading = useQueryResponseLoading()

    const navigate = useNavigate()
    const handleToNavigate = () => navigate(`/reports/reportSaleDetail/list`)

    // if (isLoading) {
    //     return <></>
    // }

    return (
        <>
            <div className='row mb-5 m-5'>
                <div className='col-4'>
                    <div className="card hover-elevate-up shadow-sm parent-hover">
                        <div className="card-header">
                            <h3 className="card-title parent-hover-danger text-start text-muted fw-bolder fs-7 text-uppercase gs-0">Total Venda</h3>
                            <div className="card-toolbar">
                            </div>
                        </div>
                        <div className="card-body p-0">
                            <div className="card-p bg-light-danger">
                                <div className="text-center px-4">
                                    <h2>{formatMask(totals?.price)}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-4'>
                    <div className="card hover-elevate-up shadow-sm parent-hover">
                        <div className="card-header">
                            <h3 className="card-title parent-hover-danger text-start text-muted fw-bolder fs-7 text-uppercase gs-0">Total Desconto</h3>
                            <div className="card-toolbar">
                            </div>
                        </div>
                        <div className="card-body p-0">
                            <div className="card-p bg-light-danger">
                                <div className="text-center px-4">
                                    <h2>{formatMask(totals?.discount)}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-4'>
                    <div className="card hover-elevate-up shadow-sm parent-hover">
                        <div className="card-header">
                            <h3 className="card-title parent-hover-danger text-start text-muted fw-bolder fs-7 text-uppercase gs-0">Total Com Desconto</h3>
                            <div className="card-toolbar">
                            </div>
                        </div>
                        <div className="card-body p-0">
                            <div className="card-p bg-light-danger">
                                <div className="text-center px-4">
                                    <h2>{formatMask(totals?.total)}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {totalsPayments.length > 0 &&
                <div className='row mb-5 m-5'>
                    <div className='col-12'>
                        <div className="card hover-elevate-up shadow-sm parent-hover">
                            <div className="card-header">
                                <h3 className="card-title parent-hover-danger text-start text-muted fw-bolder fs-7 text-uppercase gs-0">Formas de pagamento</h3>
                                <div className="card-toolbar">
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <div className="card-p bg-light-danger">
                                    <div className="text-center px-4">
                                        <ChartSalesByProduct className='w-100 h-100' sales={totalsPayments} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className='row  d-flex flex-end'>
                <div className='col m-5 d-flex flex-row-auto flex-end'>
                    <button
                        disabled={isLoading}
                        type='button'
                        className='btn btn-light-danger me-3'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        onClick={handleToNavigate}
                    >
                        <KTIcon iconName='sort' className='fs-2' />
                        Detalhado
                    </button >
                </div>
            </div>
        </>
    )
}

export { Totalizer }
